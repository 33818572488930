import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector } from "@redux/hook.type";
import { actions as userActions } from "@redux/user/userListRedux";
import { TimeZones } from "common/constants";
import userDataAccess from "dataAccess/user/userDataAccess";
import { InputFieldOneRow } from "libComp/common/inputRenderes";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { required } from "redux-form-validators";
import {
  email,
  notEmpty,
  password,
  passwordsMustMatch,
} from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const AddEditUser = (props) => {
  let userId = props.match.params.id;

  let dispatch = useDispatch();

  const commonActions = useAppSelector(
    (state) => state.commonState.commonActions
  );

  let urlString = props.location.pathname;

  const isOrgUser = urlString.includes("/admin/org_user");

  useEffect(() => {
    if (userId !== "create") {
      dispatch(userActions.getUserDetail(userId));
    } else {
      dispatch(userActions.cleanUsers(userId));
    }
  }, []);

  const onSubmit = (data) => {
    userDataAccess
      .saveUserInfo(data)(commonActions)
      .then((res) => {
        toast.success("User info saved successfuly");
        props.history.push("/admin/users/current");
      });
  };

  const style = useStyleClass();

  let MenuItems = [];
  if (userId !== "create") {
    MenuItems = [
      {
        title: "Admin",
        to: null,
      },
      {
        title: "Users",
        to: isOrgUser ? "/admin/users/all" : "/admin/users/current",
      },
      {
        title: "Edit",
        to: null,
      },
    ];
  } else {
    MenuItems = [
      {
        title: "Admin",
        to: null,
      },
      {
        title: "Users",
        to: isOrgUser ? "/admin/users/all" : "/admin/users/current",
      },

      {
        title: "New",
        to: null,
      },
    ];
  }

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader
          header={userId === "create" ? "Add User" : "Edit User"}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          noValidate
          autoComplete="off"
          onSubmit={props.handleSubmit((data) => onSubmit(data))}
        >
          <Grid container spacing={10}>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <Field
                name="userName"
                validate={required({
                  msg: "User Name is a required field",
                })}
                component={InputFieldOneRow}
                label="User Name"
              />

              {userId === "create" && (
                <>
                  <Field
                    name="password"
                    type="password"
                    required={true}
                    component={InputFieldOneRow}
                    validate={[password]}
                    label="Password"
                  />
                  <Field
                    name="confirmPassword"
                    type="password"
                    validate={[passwordsMustMatch]}
                    required={true}
                    component={InputFieldOneRow}
                    label="Confirm Password"
                  />
                </>
              )}
              <Field
                name="email"
                type="email"
                component={InputFieldOneRow}
                label="Email"
                required={true}
                validate={[email]}
              />

              <Field
                name="timeZone"
                component={solpayReactSelect}
                options={TimeZones}
                label="Time Zone"
                placeholder=""
              />

              <Field
                name="userType"
                component={solpayReactSelect}
                options={[
                  { value: "", label: "--- Select User type ---" },
                  { value: "Admin", label: "Admin" },
                  { value: "General", label: "General" },
                ]}
                label="Type"
                validate={[notEmpty]}
              />

              <Field
                name="isLocked"
                label="Is Locked?"
                component={SolpayCheckBox}
              />

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              type="submit"
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

const addEditUser = reduxForm<any>({
  form: "addEditUser",
  enableReinitialize: true,
})(AddEditUser);

const mapStateToProps = (state, ownProps) => {
  const userId = ownProps.match.params.id;

  if (userId !== "create") {
    return {
      initialValues: state.userList.userDetail,
    };
  } else {
    return {
      initialValues: { userName: "", passord: "" },
    };
  }
};

export default connect(mapStateToProps)(addEditUser);
