import { GridColumns } from "@mui/x-data-grid";
import { formatDate } from "libComp/utility.service";

export const getBillPaymentTable = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Amount",
      field: "amount",
    },
    {
      headerName: "TxnID",
      field: "txnID",
    },
    {
      headerName: "Triggered On",
      field: "triggeredOn",
      renderCell: (params) => {
        return formatDate(params.row?.triggeredOn);
      },
    },
    {
      headerName: "Due Date",
      field: "dueDate",
      renderCell: (params) => {
        return formatDate(params.row?.dueDate);
      },
    },
    {
      headerName: "Settlement Date",
      field: "settlementDate",
      renderCell: (params) => {
        return formatDate(params.row?.settlementDate);
      },
    },
    {
      headerName: "Successful",
      field: "successful",
      sortable: true,
    },
    {
      headerName: "Response Msg.",
      field: "responseText",
      sortable: true,
    },
  ];
  return columns;
};
