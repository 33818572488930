import {
  FETCH_TAXES_FAILURE,
  FETCH_ALL_TAXES,
  FETCH_TAXES_SUCCESS,
  FETCH_TAXES_REQUEST,
} from './taxTypes';
import taxCodeDataAccess from 'dataAccess/bkp/taxCodeDataAccess';
import { IReduxState } from '@redux';

export const fetchTaxes = () => (dispatch, getState) => {
  dispatch(fetchAllTax());
  const state: IReduxState = getState();
  taxCodeDataAccess
    .getAll()(state.commonState.commonActions)
    .then((response) => {
      // response.data is the users
      const taxes = response.data;
      const taxList = response.data.map(function (value, index) {
        return {
          label: value.name,
          value: value.id,
        };
      });
      var taxmodel = { taxes: taxes, taxlist: taxList };
      dispatch(fetchTaxesSuccess(taxmodel));
    })
    .catch((error) => {
      // error.message is the error message
      dispatch(fetchTaxesFailure(error.message));
    });
};

const fetchAllTax = () => {
  return {
    type: FETCH_ALL_TAXES,
  };
};

const fetchTaxesSuccess = (taxes) => {
  return {
    type: FETCH_TAXES_SUCCESS,
    payload: taxes,
  };
};

const fetchTaxesFailure = (error) => {
  return {
    type: FETCH_TAXES_FAILURE,
    payload: error,
  };
};
