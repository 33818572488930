import AssessmentIcon from "@mui/icons-material/Assessment";
import { Box, Button, Divider, Grid } from "@mui/material";
import { IReduxState } from "@redux";
import customerDataAccess from "dataAccess/report/customerDataAccess";
import {
  AutoCompleteInputFieldOneRowMui,
  InputHidden,
} from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { change, Field, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
// Import Worker
import { Worker } from "@react-pdf-viewer/core";
// Import the main Viewer component
import { TextDirection, Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// default layout plugin
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

function CustomerStatement(props) {
  const dispatch = useDispatch();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [reportdata, setreportdata] = useState("");
  const [options, setOptions] = useState([]);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const onLoadData = (event) => {
    if (event.target.value !== "") {
      customerDataAccess
        .getClientList(event.target.value)(commonState.commonActions)
        .then((data) => {
          setOptions(
            data.data.map((item) => ({
              ...item,
            }))
          );
        })
        .catch((error) => {
          //console.log(error);
        });
    } else {
      setOptions([]);
      return;
    }
  };

  const onFormSubmit = (model) => {
    customerDataAccess
      .getCustomerStatement(model)(commonState.commonActions)
      .then((data) => {
        setreportdata(data);
        // const response: APIResponse = data;
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // const createMarkup = () => {
  //   return { __html: reportdata };
  // };

  const style = useStyleClass();

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Reports",
      to: null,
    },
    {
      title: "Sales",
      to: "/reports/sales",
    },
    {
      title: "Customer Statement",
      to: "/reports/sales/customer-statement",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="Customer Statement" />

        <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
          <br />
          <form
            noValidate
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Field name="clientId" component={InputHidden} />

                <Field
                  name="ClientName"
                  component={AutoCompleteInputFieldOneRowMui}
                  dataSource={options}
                  onChange={onLoadData}
                  onDataSelect={(data) => {
                    dispatch(change("CustomerStatement", "clientId", data.id));
                  }}
                  label="Client Name"
                  placeholder="Client Name"
                />
              </Grid>
            </Grid>
            <Divider />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                classes={{ root: style.button }}
                sx={{ m: 2 }}
                variant="contained"
                type="submit"
                disabled={props.submitting}
              >
                {props.submitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <AssessmentIcon />
                &nbsp;View Report
              </Button>
            </Box>
          </form>
          <Grid item xs={12} md={12}>
            {reportdata ? (
              <div className="viewer">
                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js">
                  <Viewer
                    fileUrl={reportdata}
                    theme={{
                      theme: "auto",
                      direction: TextDirection.RightToLeft,
                    }}
                    plugins={[defaultLayoutPluginInstance]}
                    // renderLoader={(percentages: number) => (
                    //   <div style={{ width: '240px' }}>
                    //     <ProgressBar progress={Math.round(percentages)} />
                    //   </div>
                    // )}
                  />
                </Worker>
              </div>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  border: "2px dashed rgba(0, 0, 0, .3)",
                  display: "flex",
                  fontSize: "2rem",
                  height: "100%",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Preview area
              </div>
            )}

            <br></br>

            {/* <div dangerouslySetInnerHTML={createMarkup()}></div> */}
          </Grid>
        </Box>
        <br />
      </div>
      <br></br>
      {/* <div dangerouslySetInnerHTML={createMarkup()}></div> */}
    </>
  );
}

const customerStatement = reduxForm<any>({
  form: "CustomerStatement",
  enableReinitialize: true,
})(CustomerStatement);
const mapStateToProps = ({ invoice }) => ({
  clientId: 0,
  clientName: "",
});
export default connect(mapStateToProps)(customerStatement);
