import {
  Alert,
  Box,
  Button,
  CardMedia,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as tenantActions, ITenantState } from "@redux/tnt/tenantRedux";
import { apiUrl } from "common/config";
import { CRMEntityTypes } from "common/constants";
import tenantDataAccess from "dataAccess/tnt/tenantDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  InputDeleteImage,
  InputFieldOneRow,
  InputHidden,
  InputUploadImage,
} from "libComp/common/inputRenderes";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  change,
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { email, maxLength, phone, required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import AddEditAddress from "../../../crm/addEditAddress";
import AddEditBankInfo from "../../../crm/addEditBankInfo";
import AddEditBusinessBankInfo from "pages/crm/addEditBusinessBankInfo";
import AddEditBusinessAddress from "pages/crm/addEditBusinessAddress";
const maxLength100 = maxLength(100);
const maxLength200 = maxLength(200);
const maxLength20 = maxLength(20);
const maxLength25 = maxLength(25);
const maxLength30 = maxLength(30);
interface Props {
  tenantId: string;
  tenant: ITenantState;
  history: any;
}
function TabBusinessInfo(props: InjectedFormProps<any, Props> & Props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const tenantId = props.tenant.businessInfo.id;
  const tenantState: ITenantState = props.tenant;
  const dispatch = useDispatch();

  const onFormSubmit = (model) => {
    return tenantDataAccess
      .saveBusinessInfo(model)(commonState.commonActions)
      .then((data) => {
        toast.success("Saved Successful");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const _handleReaderLoaded = (readerEvt: any) => {
    let binaryString = readerEvt.target.result;
    dispatch(change("TabUpsertBusinessInfo", "File", btoa(binaryString)));
  };

  const fileChangeHandler = (event) => {
    if (event.target.files.length === 0) return;
    let url = URL.createObjectURL(event.target.files[0]);
    setFile(url);
    tenantState.businessInfo.logoFilePath = null;
    var file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = _handleReaderLoaded;
      reader.readAsBinaryString(file);
      dispatch(change("TabUpsertBusinessInfo", "FileName", file.name));
      reader.onerror = function (error) {};
    }
  };

  const removeImage = () => {
    tenantState.businessInfo.logoFilePath = null;
    setFile("");
  };

  const style = useStyleClass();

  const [file, setFile] = React.useState("");

  return (
    <>
      <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        >
          {tenantState.uiMessages.map((item, index) => (
            <>
              <Alert
                key={index}
                variant="filled"
                severity="warning"
                onClose={() => {
                  dispatch(tenantActions.removeUiMessage(index));
                }}
              >
                {item.messageText}
              </Alert>
              <br />
            </>
          ))}

          <Grid container spacing={6}>
            <Grid item xl={6} md={6}>
              <Field name="tenantId" component={InputHidden} />
              <Field name="file" component={InputHidden} />
              <Field name="fileName" component={InputHidden} />
              <Field
                name="name"
                component={InputFieldOneRow}
                label="Name"
                placeholder="Company Name"
                validate={[required, maxLength100]}
              />
              <Field
                name="tradeAs"
                component={InputFieldOneRow}
                label="Trading Name"
                placeholder="Trading Name"
                validate={maxLength200}
              />
              <Field
                name="abn"
                component={InputFieldOneRow}
                label="ABN"
                placeholder="ABN"
                validate={[required, maxLength20]}
              />
              <Field
                name="acn"
                component={InputFieldOneRow}
                label="ACN"
                placeholder="ACN"
              />
              <Field
                name="taxFileNo"
                component={InputFieldOneRow}
                label="Tax File No"
                placeholder="Tax File No"
                validate={maxLength20}
              />
              <Field
                name="mainPhone"
                component={InputFieldOneRow}
                label="Main Phone"
                validate={[required, phone, maxLength25]}
                placeholder="* e.g. 07 3454 6789 or 0734546789"
              />
              <Field
                name="otherPhone"
                component={InputFieldOneRow}
                label="Other Phone"
                placeholder="* e.g. 07 3454 6789 or 0734546789"
                validate={[phone, maxLength25]}
              />
              <Field
                name="fax"
                component={InputFieldOneRow}
                label="Fax"
                placeholder="Fax"
                validate={maxLength30}
              />
              <Field
                name="email"
                component={InputFieldOneRow}
                label="Email"
                placeholder="Email"
                validate={[required, email]}
              />
              <Field
                name="website"
                component={InputFieldOneRow}
                label="Website"
                validate={maxLength100}
                placeholder="Website"
              />
              {tenantState.businessInfo.logoFilePath && (
                <CardMedia
                  component="img"
                  height="auto"
                  sx={{ width: 250, marginLeft: "auto" }}
                  image={apiUrl + tenantState.businessInfo.logoFilePath}
                  alt="logo"
                />
              )}

              {file.length > 0 && (
                <CardMedia
                  component="img"
                  alt="new logo"
                  height="auto"
                  sx={{ width: 250, marginLeft: "auto" }}
                  image={file}
                />
              )}

              <Box sx={{ textAlign: "right" }}>
                <Field
                  name=""
                  onChange={(event) => fileChangeHandler(event)}
                  component={InputUploadImage}
                  validate={maxLength100}
                  placeholder="Upload Logo"
                />

                <Field
                  name=""
                  onChange={(event) => fileChangeHandler(event)}
                  component={InputDeleteImage}
                  validate={maxLength100}
                  placeholder="Upload Logo"
                  onClick={removeImage}
                />
              </Box>

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
            <Grid item xl={6} md={6} xs={12} sm={12}>
              {/* <AddEditAddress
                entityId={tenantId}
                entityType={CRMEntityTypes.Company}
                address={tenantState.businessInfo.businessAddress}
              />
              <br />
              <br /> */}
              <AddEditBusinessAddress 
              entityId={tenantId}
              entityType={CRMEntityTypes.Company}
              //address={tenantState.businessInfo.businessAddress}
              />
              <br />
              <br />
              {/* <AddEditBankInfo entityId={tenantId} />
              <br />
              <br /> */}
              <AddEditBusinessBankInfo entityId={tenantId} />
              <br />
              <br />
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
const tabUpsertBusinessInfo = reduxForm<any, Props>({
  form: "TabUpsertBusinessInfo",
  enableReinitialize: true,
})(TabBusinessInfo);
const mapStateToProps = (state, ownProps) => ({
  tenant: state.tenant,
  initialValues: state.tenant.businessInfo,
});

export default connect(mapStateToProps)(tabUpsertBusinessInfo);
