
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { fetchTaxes } from "@redux/bkp/tax/taxActions";
import { actions as transactionActions } from "@redux/bkp/transactionRedux";
import { actions as productActions } from "@redux/inv/productRedux";
import productDataAccess from "dataAccess/inv/productDataAccess";
import { InputFieldOneRow } from "libComp/common/inputRenderes";
import SolpayGroupSelectMui from "libComp/common/solpayGroupSelectMui";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import { SolpayTextArea } from "libComp/common/solpayTextArea";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { numericality, required } from "redux-form-validators";
import asyncValidateProductCode from "services/asyncValidate";
import { useStyleClass } from "styles/theming/useStyleClass";

function ProductFormView(props) {
  const style = useStyleClass();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const productId =
    props.showInModal || typeof props.match == "undefined"
      ? "0"
      : props.match.params.productId;
  // const productState: IProductState = props.product;
  const commonState = useSelector((state: IReduxState) => state.commonState);

  //const model=new ProductModel();
  const dispatch = useDispatch();

  useEffect(() => {
    if (productId !== "0") {
      dispatch(productActions.getProduct(productId));
    }
    dispatch(fetchTaxes());
    dispatch(transactionActions.getAllAccountHead());
  }, [productId]);

  const onProductFormSubmit = (model) => {
    return productDataAccess
      .saveProduct(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success)
          enqueueSnackbar("Product has been saved!", { variant: "success" });
        else
          enqueueSnackbar("Product could not been saved!", {
            variant: "error",
          });

        props.showInModal
          ? modalClose(data)
          : props.history.push("/accounting/purchase/products");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };
  const modalClose = (data) => {
    props.setProduct(data);
    // props.hideModal();
  };

  // Breadcrumb
  let MenuItems = [];
  MenuItems = [
    {
      title: "Accounting",
      to: null,
    },
    {
      title: "Products",
      to: "/accounting/purchase/products",
    },
  ];

  if (productId > 0) {
    MenuItems.push({
      title: "Edit Product",
      to: "/accounting/purchase/product/addedit/{productId}",
    });
  } else {
    MenuItems.push({
      title: "New Product",
      to: "/accounting/purchase/product/addedit/0",
    });
  }

  return (
    <div>
      {!props.showInModal && <PageBreadcrumb menuItem={MenuItems} />}
      {!props.showInModal && typeof props.match == "undefined" ? (
        ""
      ) : (
        <div>
          <Stack sx={{ width: "96%", mx: "auto" }}>
            {!props.showInModal && (
              <>
                <PageTitleHeader
                  header={productId === "0" ? "New Product" : "Edit Product"}
                />
              </>
            )}
          </Stack>

          <Box
            sx={{
              flex: 1,
              width: "96%",
              mx: "auto",
              border: 1,
              borderRadius: 1,
              borderColor: "grey.200",
              p: 2,
            }}
          >
            <form
              noValidate
              name="productForm"
              onSubmit={props.handleSubmit((data) => onProductFormSubmit(data))}
            >
              <Grid container spacing={10}>
                <Grid item xl={6} md={6}>
                  <Field
                    name="ProductName"
                    label="Product Name"
                    placeholder="Product Name"
                    component={InputFieldOneRow}
                    validate={required({
                      msg: "Product Name is a required field",
                    })}
                    required={true}
                  />
                  <Field
                    name="ProductCode"
                    label="Product Code"
                    placeholder="Product Code"
                    component={InputFieldOneRow}
                    required={true}
                    validate={required({
                      msg: "Product Code is a required field",
                    })}
                  />

                  <Field
                    name="PurchaseUnitPrice"
                    label="Purchase Unit Price"
                    placeholder="Purchase Unit Price"
                    component={InputFieldOneRow}
                    type="number"
                    required={true}
                    validate={[
                      required({
                        msg: "Purchase Unit Price is a required field",
                      }),
                      numericality({ ">=": 0 }),
                    ]}
                  />
                  <Field
                    name="PurchaseAccountId"
                    label="Purchase Account"
                    options={props.Accounts}
                    component={SolpayGroupSelectMui}
                    // component={solpayGroupSelect}
                  />
                  <Field
                    name="PurchaseDescription"
                    label="Purchase Description"
                    component={SolpayTextArea}
                    type="textarea"
                  />
                  <Field
                    name="PurchaseTaxId"
                    label="Purchase Tax Rate"
                    component={solpayReactSelect}
                    options={props.TaxModels}
                  />
                </Grid>
                <Grid item xl={6} md={6}>
                  <Field
                    name="Price"
                    label="Sales Unit Price"
                    placeholder="Sales Unit Price"
                    component={InputFieldOneRow}
                    type="number"
                    required={true}
                    validate={[
                      required({
                        msg: "Price is a required field",
                      }),
                      numericality({ ">=": 0 }),
                    ]}
                  />
                  <Field
                    name="SalesAccountId"
                    label="Sales Account"
                    options={props.Accounts}
                    component={SolpayGroupSelectMui}
                    // component={solpayGroupSelect}
                  />
                  <Field
                    name="SalesDescription"
                    label="Sales Description"
                    component={SolpayTextArea}
                    type="textarea"
                  />
                  <Field
                    name="SaleTaxId"
                    label="Sales Tax Rate"
                    placeholder="Sales Tax Rate"
                    component={solpayReactSelect}
                    options={props.TaxModels}
                    required={true}
                    validate={required({
                      msg: "Sales Tax is a required field",
                    })}
                  />

                  <Field
                    name="IsService"
                    label="Is Service"
                    component={SolpayCheckBox}
                  />
                  <Field
                    name="IsActive"
                    label="Active?"
                    component={SolpayCheckBox}
                  />
                  <Field
                    name="IsTraceable"
                    label="Is Traceable"
                    component={SolpayCheckBox}
                  />
                </Grid>
              </Grid>
              <Grid>
                {props.error && (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    classes={{ root: style.error }}
                  >
                    {props.error}
                  </Typography>
                )}
              </Grid>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}
                  Save
                </Button>
              </Box>
            </form>
          </Box>
          <br />
        </div>
      )}
    </div>
  );
}

const upsertProduct = reduxForm({
  form: "ProductFormView",
  enableReinitialize: true,
  asyncValidate: asyncValidateProductCode,
  asyncChangeFields: ["ProductCode"],
})(ProductFormView);
const selector = formValueSelector("ProductFormView");
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getProduct: () =>
      dispatch(productActions.getProduct(ownProps.match.params.productId)),
  };
};

const mapStateToProps = (state, ownProps) => {
  if (
    typeof ownProps.showInModal === "undefined" ||
    ownProps.showInModal === false
  ) {
    if (typeof ownProps.match != "undefined") {
      if (ownProps.match.params.productId > 0) {
        const productId = ownProps.match.params.productId;
        const product = state.product.product;

        if (typeof product != undefined && product != null)
          return {
            TaxModels: state.tax.taxList,
            Accounts: state.journal.accountHeadSelectListByGroup,
            ShownInModal: false,
            showInModal: false,
            SelectedAccountId: {
              label: "(5-1000) Current Year Earnings",
              value: 534,
              accountType: "Equity",
            },
            // productId : ownProps.match.params.productId,
            initialValues: {
              Price: product.price,
              IsService: product.isService,
              ProductCode: product.productCode,
              IsGSTApplicable: product.isGSTApplicable,
              PurchaseUnitPrice: product.purchaseUnitPrice,
              IsActive: product.isActive,
              ProductName: product.productName,
              SaleTaxId: product.saleTaxId,
              PurchaseTaxId: product.purchaseTaxId,
              Id: product.id,
              IsTraceable: product.isTraceable,
              PurchaseDescription: product.purchaseDescription,
              SalesDescription: product.salesDescription,
              TenantId: product.tenantId,
              PurchaseAccountId: product.purchaseAccountId,
              SalesAccountId: product.salesAccountId,
            },
          };
      } else {
        return {
          TaxModels: state.tax.taxList,
          Accounts: state.journal.accountHeadSelectListByGroup,
          showInModal: false,
          initialValues: {
            Price: 0,
            // IsService: true,
            // ProductCode: product.productCode,
            IsGSTApplicable: true,
            PurchaseUnitPrice: 0,
            IsActive: true,
            IsTraceable: true,
          },
        };
      }
    }
  } else {
    return {
      TaxModels: state.tax.taxList,
      Accounts: state.journal.accountHeadSelectListByGroup,
      ShownInModal: ownProps.showInModal,
      initialValues: {
        Price: 0,
        // IsService: true,
        // ProductCode: product.productCode,
        IsGSTApplicable: true,
        PurchaseUnitPrice: 0,
        IsActive: true,
        IsTraceable: true,
      },
    };
  }
};
export default connect(mapStateToProps, mapDispatchToProps)(upsertProduct);
