import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as tenantActions, ITenantState } from "@redux/tnt/tenantRedux";
import { CRMEntityTypes } from "common/constants";
import tenantDataAccess from "dataAccess/tnt/tenantDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { email, maxLength, mobile, phone, required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import AddEditAddress from "../../../crm/addEditAddress";

interface Props {
  tenantId: string;
  tenant: ITenantState;
  history: any;
}
const maxLength100 = maxLength(100);
const maxLength50 = maxLength(50);
const maxLength25 = maxLength(25);
function TabPayerInfo(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const entityId = props.tenant.payerInfo ?? 0;
  const tenantState: ITenantState = props.tenant;
  const dispatch = useDispatch();
  const onFormSubmit = (model) => {
    return tenantDataAccess
      .savePayerInfo(model)(commonState.commonActions)
      .then((data) => {
        toast.success("Saved Successful");
        dispatch(tenantActions.getPayerInfo());
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <>
      <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        >
          {tenantState.uiMessages.map((item, index) => (
            <>
              <Alert
                key={index}
                variant="filled"
                severity="warning"
                onClose={() => {
                  dispatch(tenantActions.removeUiMessage(index));
                }}
              >
                {item.messageText}
              </Alert>
              <br />
            </>
          ))}

          <Grid container spacing={6}>
            <Grid item xl={6} md={6}>
              <Field name="tenantId" component={InputHidden} />
              <Field
                name="title"
                component={InputFieldOneRow}
                label="Title"
                placeholder="Title"
                validate={[maxLength50]}
              />
              <Field
                name="firstName"
                component={InputFieldOneRow}
                label="First Name"
                placeholder="First Name"
                validate={maxLength50}
              />
              <Field
                name="lastName"
                component={InputFieldOneRow}
                label="Last Name"
                placeholder="Last Name"
                validate={maxLength50}
              />
              <Field
                name="dob"
                component={DatePickerFieldOneRow}
                label="Date of Birth"
                placeholder="Date of Birth"
              />
              <Field
                name="email"
                component={InputFieldOneRow}
                label="Email"
                placeholder="Email"
                validate={[required, email, maxLength100]}
              />
              <Field
                name="homePhone"
                component={InputFieldOneRow}
                label="Phone Number"
                validate={[required, phone, maxLength25]}
                placeholder="* e.g. 07 3454 6789 or 0734546789"
              />
              <Field
                name="mobile"
                component={InputFieldOneRow}
                label="Mobile Number"
                placeholder="Mobile No"
                validate={[mobile, maxLength25]}
              />
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
            {entityId.toString() !== "0" && (
              <Grid item xl={6} md={6} xs={12} sm={12}>
                <AddEditAddress
                  entityId={entityId}
                  entityType={CRMEntityTypes.Contact}
                  address={tenantState.payerInfo.crmAddress}
                />
              </Grid>
            )}
          </Grid>
        </form>
      </Box>
    </>
  );
}
const tabPayerInfo = reduxForm<any, Props>({
  form: "TabPayerInfo",
  enableReinitialize: true,
})(TabPayerInfo);
const mapStateToProps = (state, ownProps) => ({
  tenant: state.tenant,
  initialValues: state.tenant.payerInfo,
});

export default connect(mapStateToProps)(tabPayerInfo);
