export const keys = {
  authorization: "AUTHORIZATION",
};

export const defaultPageSize = 25;
//export const defaultPageSize = 10;

export const filterOptions = {
  startsWith: { label: "Starts with", value: "StartsWith" },
  contains: { label: "Contains", value: "Contains" },
  isNotNull: { label: "Is not null", value: "IsNotNull" },
  equals: { label: "Equals", value: "Equals" },
  lessThanEqual: { label: "Less then equal", value: "LessThanEqual" },
  greaterThanEqual: { label: "Greater than equal", value: "GreaterThanEqual" },
  greaterThan: { label: "Greater than", value: "GreaterThan" },
};

export const stateList = [
  {
    label: "ACT",
    value: "ACT",
  },
  {
    label: "NSW",
    value: "NSW",
  },
  {
    label: "NT",
    value: "NT",
  },
  {
    label: "QLD",
    value: "QLD",
  },
  {
    label: "SA",
    value: "SA",
  },
  {
    label: "TAS",
    value: "TAS",
  },
  {
    label: "VIC",
    value: "VIC",
  },
  {
    label: "WA",
    value: "WA",
  },
  {
    label: "OTH",
    value: "OTh",
  },
];

export const salaryModes = [
  {
    label: "Annually",
    value: 1,
  },
  {
    label: "Hourly",
    value: 2,
  },
];

export const localStorageKeys = {
  user: "a3j3d$s",
  authorization: "s7asl3de2",
};

export const CRMEntityTypes = {
  Company: 1,
  Individual: 2,
  Employee: 3,
  Contact: 4,
};

export const userTypes = {
  user: "USER",
  admin: "ADMIN",
  superAdmin: "SUPER_ADMIN",
};

export interface ValueLabelType<TValue, TLabel> {
  value: TValue;
  label: TLabel;
}

export const TimeZones: ValueLabelType<number, string>[] = [
  { value: -12, label: "(GMT-12:00) International dateline, west" },
  { value: -11, label: "(GMT-11:00) Midway Islands, Samoan Islands" },
  { value: -10, label: "(GMT-10:00) Hawaii" },
  { value: -9, label: "(GMT-09:00) Alaska" },
  { value: -8, label: "(GMT-08:00) Pacific Time (USA og Canada); Tijuana" },
  { value: -7, label: "(GMT-07:00) Mountain Time (USA og Canada)" },
  { value: -6, label: "(GMT-06:00) Central time (USA og Canada)" },
  { value: -5, label: "(GMT-05:00) Eastern time (USA og Canada)" },
  { value: -4, label: "(GMT-04:00) Atlantic Time (Canada)" },
  { value: -3.5, label: "(GMT-03:30) Newfoundland" },
  { value: -3, label: "(GMT-03:00) Brasilia" },
  { value: -2, label: "(GMT-02:00) Mid-Atlantic" },
  { value: -1, label: "(GMT-01:00) Azorerne" },
  {
    value: 0,
    label: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lissabon, London",
  },
  {
    value: 1,
    label: "(GMT+01:00) Amsterdam, Berlin, Bern, Rom, Stockholm, Wien",
  },
  { value: 2, label: "(GMT+02:00) Athen, Istanbul, Minsk" },
  { value: 3, label: "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
  { value: 3.5, label: "(GMT+03:30) Teheran" },
  { value: 4.5, label: "(GMT+04:30) Kabul" },
  { value: 5, label: "(GMT+05:00) Islamabad, Karachi, Tasjkent" },
  { value: 5.5, label: "(GMT+05:30) Kolkata, Chennai, Mumbai, New Delhi" },
  { value: 5.75, label: "(GMT+05:45) Katmandu" },
  { value: 6, label: "(GMT+06:00) Astana, Dhaka" },
  { value: 6.5, label: "(GMT+06:30) Rangoon" },
  { value: 7, label: "(GMT+07:00) Bangkok, Hanoi, Djakarta" },
  { value: 8, label: "(GMT+08:00) Beijing, Chongjin, SAR Hongkong, Ürümqi" },
  { value: 9, label: "(GMT+09:00) Osaka, Sapporo, Tokyo" },
  { value: 9.5, label: "(GMT+09:30) Adelaide" },
  { value: 10, label: "(GMT+10:00) Canberra, Melbourne, Sydney" },
  { value: 11, label: "(GMT+11:00) Magadan, Solomon Islands, New Caledonien" },
  { value: 12, label: "(GMT+12:00) Fiji, Kamtjatka, Marshall Islands" },
  { value: 13, label: "(GMT+13:00) Nuku'alofa" },
];
