import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as payRunActions, IPayRunState } from "@redux/pyr/payRunRedux";
import payRunDataAccess from "dataAccess/pyr/payrunDataAccess";
import {
  InputFieldOneRow,
  InputFieldWithoutLabelMuiTable,
  InputHidden,
} from "libComp/common/inputRenderes";
import { SolpayTextArea } from "libComp/common/solpayTextArea";
import { useSnackbar } from "notistack";
import { ChangeEvent, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  change,
  Field,
  FieldArray,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

function ReviewPaySlip(props): JSX.Element {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const payrunState: IPayRunState = props.payRun;
  const payrunId = payrunState.payRun.id;
  const [notes, setNotes] = useState("");
  const [unpaidleave, setUnpaidleave] = useState("");
  const [personalleave, setPersonalleave] = useState("");
  const [annualleave, setAnnualleave] = useState("");
  const [normalHour, setNormalHour] = useState("");
  const [overTime, setOverTime] = useState("");

  const [isFormDirty, setIsFormDirty] = useState(false);
  const [alertDialog, setAlertDialog] = useState(false);
  const [isNext, setIsNext] = useState(false);
  const [isPrevious, setIsPrevious] = useState(false);

  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const handleInputChange = (event, setStateFn) => {
    setStateFn(event.target.value);
    setIsFormDirty(true);
  };

  useEffect(() => {
    if (
      notes.length > 0 ||
      unpaidleave.length > 0 ||
      personalleave.length > 0 ||
      annualleave.length > 0 ||
      normalHour.length > 0 ||
      overTime.length > 0
    ) {
      setIsFormDirty(true);
    }
    if (
      notes.length === 0 &&
      unpaidleave.length === 0 &&
      personalleave.length === 0 &&
      annualleave.length === 0 &&
      normalHour.length === 0 &&
      overTime.length === 0
    ) {
      setIsFormDirty(false);
    }
  }, [notes, unpaidleave, personalleave, annualleave, normalHour, overTime]);

  useEffect(() => {
    if (props.paySlipId !== "0")
      dispatch(payRunActions.getPaySlipToReview(props.paySlipId));
  }, [props.paySlipId]);

  const handleDialogOpen = () => {
    if (isFormDirty) {
      setAlertDialog(true);
    }
  };

  const handleDialogClose = () => {
    setAlertDialog(false);
  };

  const handleResetInputForm = () => {
    setNotes("");
    setUnpaidleave("");
    setPersonalleave("");
    setAnnualleave("");
    setNormalHour("");
    setOverTime("");
  };

  const handleDialogButton = () => {
    handleDialogClose();

    if (isNext) {
      props.handleIncrementIndex(props.currentIndex);
      setIsNext(false);
    }
    if (isPrevious) {
      props.handledecrementIndex(props.currentIndex);
      setIsPrevious(false);
    }
  };

  const handleDialogNoButton = () => {
    handleDialogClose();
    handleResetInputForm();

    if (isNext) {
      props.handleIncrementIndex(props.currentIndex);
      setIsNext(false);
    }
    if (isPrevious) {
      props.handledecrementIndex(props.currentIndex);
      setIsPrevious(false);
    }
  };

  const handlePrevButtonClick = () => {
    if (isFormDirty) {
      handleDialogOpen();
      setIsPrevious(true);
    } else {
      props.handledecrementIndex(props.currentIndex);
      setIsFormDirty(false);
    }
  };

  const handleNextButtonClick = () => {
    if (isFormDirty) {
      handleDialogOpen();
      setIsNext(true);
    } else {
      props.handleIncrementIndex(props.currentIndex);
      setIsFormDirty(false);
    }
  };

  const onFormSubmit = (model) => {
    model.payRun = {};
    setIsFormDirty(false);
    setAlertDialog(false);
    return payRunDataAccess
      .updatePaySlip(model)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          dispatch(payRunActions.getPayRunToReview(payrunId));
          dispatch(payRunActions.getPaySlipToReview(props.paySlipId));
          enqueueSnackbar("PaySlip has been updated!", { variant: "success" });
        } else
          enqueueSnackbar(data.message, {
            variant: "error",
          });
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();
  return (
    <>
      <form
        onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        noValidate
      >
        <Dialog
          open={alertDialog}
          onClose={handleDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to update payslip?"}
          </DialogTitle>
          {/* <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Let Google help apps determine location. This means sending anonymous
            location data to Google, even when no apps are running.
          </DialogContentText>
        </DialogContent> */}
          <form
        onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        noValidate
      >
            <DialogActions>
              <Button variant="contained" onClick={handleDialogNoButton}>
                No
              </Button>

              <Button
                classes={{ root: style.button }}
                sx={{ m: 2, ml: 0 }}
                variant="contained"
                type="submit"
                disabled={!isFormDirty || props.submitting}
                onClick={handleDialogButton}
              >
                {props.submitting && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: "blue",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                )}
                Update Pay Slip
              </Button>
            </DialogActions>
         </form>
        </Dialog>

        {payrunState.paySlip && (
          <>
            <FieldArray
              name="paySlipDetails"
              component={PaySlipDetail}
              props={{
                payslip: payrunState.paySlip,
                isFormDirty,
                handleInputChange,
                setNormalHour,
                setOverTime,
              }}
            />
            <Grid container>
              <Grid item xl={4} md={4} xs={6}>
                <Typography variant="subtitle1" gutterBottom component="div">
                  Gross:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {payrunState.paySlip.amountGross
                      ? payrunState.paySlip.amountGross.toFixed(2)
                      : ""}
                  </span>
                </Typography>
              </Grid>
              <Grid item xl={4} md={4} xs={6}>
                <Typography
                  align="center"
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                >
                  PAYG:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {payrunState.paySlip.taxPaid
                      ? payrunState.paySlip.taxPaid.toFixed(2)
                      : ""}
                  </span>
                </Typography>
              </Grid>
              <Grid item xl={4} md={4} xs={6}>
                <Typography
                  align="right"
                  variant="subtitle1"
                  gutterBottom
                  component="div"
                >
                  Take Home:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {payrunState.paySlip.takeHome
                      ? payrunState.paySlip.takeHome.toFixed(2)
                      : ""}
                  </span>
                </Typography>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xl={6} md={6} xs={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    Super Contribution:
                  </Typography>
                </Box>
              </Grid>
              <Grid item xl={6} md={6} xs={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Typography variant="subtitle1" gutterBottom component="div">
                    <strong>
                      {payrunState.paySlip.superContribution
                        ? payrunState.paySlip.superContribution.toFixed(2)
                        : ""}
                    </strong>
                  </Typography>
                </Box>
              </Grid>
            </Grid>

            {payrunState.paySlip.annualLeaveEarned > 0 && (
              <Grid container>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      Annual Leave Available:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      <strong>{payrunState.paySlip.annualLeaveAv}</strong> hours
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            {payrunState.paySlip.personalLeaveEarned > 0 && (
              <Grid container>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      Personal Leave Available:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      <strong>{payrunState.paySlip.personalLeaveAv}</strong>{" "}
                      hours
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
            <br></br>

            {payrunState.paySlip.annualLeaveEarned > 0 && (
              <Grid container>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      Annual Leave Taken:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Field
                      label=""
                      name="annualLeaveTaken"
                      align="right"
                      component={InputFieldOneRow}
                      endAdornment="hours"
                      onChange={(event: ChangeEvent<any>) =>
                        handleInputChange(event, setAnnualleave)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
            {payrunState.paySlip.personalLeaveEarned > 0 && (
              <Grid container>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <Typography
                      variant="subtitle1"
                      gutterBottom
                      component="div"
                    >
                      Personal Leave Taken:
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xl={6} md={6} xs={6}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <Field
                      label=""
                      name="personalLeaveTaken"
                      component={InputFieldOneRow}
                      endAdornment="hours"
                      onChange={(event: ChangeEvent<any>) =>
                        handleInputChange(event, setPersonalleave)
                      }
                    />
                  </Box>
                </Grid>
              </Grid>
            )}

            {!payrunState.paySlip.isHourlySalary &&
              payrunState.paySlip.hourWorked > 0 && (
                <>
                  <Grid container>
                    <Grid item xl={6} md={6} xs={6}>
                      <Box
                        sx={{ display: "flex", justifyContent: "flex-start" }}
                      >
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          component="div"
                        >
                          Unpaid Leave Taken:
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xl={6} md={6} xs={6}>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Field
                          label=""
                          name="unpaidLeaveTaken"
                          component={InputFieldOneRow}
                          endAdornment="hours"
                          onChange={(event: ChangeEvent<any>) =>
                            handleInputChange(event, setUnpaidleave)
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            <Field
              name="note"
              label="Note: "
              labelCol={1}
              fieldColumn={11}
              component={SolpayTextArea}
              type="textarea"
              onChange={(event: ChangeEvent<any>) =>
                handleInputChange(event, setNotes)
              }
            />
            <Grid container>
              <Grid item xl={12} md={12} xs={12}></Grid>
            </Grid>
          </>
        )}
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Button
            classes={{ root: style.button }}
            sx={{ m: 2, ml: 0 }}
            variant="contained"
            type="submit"
            disabled={!isFormDirty || props.submitting}
          >
            {props.submitting && (
              <CircularProgress
                size={24}
                sx={{
                  color: "blue",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
            Update Pay Slip
          </Button>
          <Box>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2, mr: 1 }}
              variant="contained"
              disabled={!props.isPrevious}
              onClick={handlePrevButtonClick}
              // onClick={() => {
              //   props.handledecrementIndex(props.currentIndex);
              //   setIsFormDirty(false);

              //   handleDialogOpen();
              // }}
            >
              Previous
            </Button>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2, mr: 0 }}
              variant="contained"
              disabled={!props.isNext}
              onClick={handleNextButtonClick}
              // onClick={() => {
              //   //props.handleIncrementIndex(props.currentIndex);
              //   setIsFormDirty(false);

              //   handleDialogOpen();
              // }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </form>
    </>
  );
}
const reviewPaySlip = reduxForm<any>({
  form: "ReviewPaySlip",
  enableReinitialize: true,
})(ReviewPaySlip);
const mapStateToProps = (state: IReduxState, ownProps) => ({
  paySlips:
    state.payrun.payRun && state.payrun.payRun.paySlipIdList
      ? state.payrun.payRun.paySlipIdList
      : "",
  payRun: state.payrun,
  initialValues: {
    ...state.payrun.paySlip,
    paySlipDetails: state.payrun.paySlip
      ? state.payrun.paySlip.paySlipDetails
      : [],
  },
});

export default connect(mapStateToProps)(reviewPaySlip);

const PaySlipDetail = ({
  fields,
  meta,
  payslip,
  handleInputChange,
  setNormalHour,
  setOverTime,
}) => {
  const dispatch = useDispatch();
  const style = useStyleClass();

  const peyeeHeaders = ["Description", "Hour", "Rate", "Amount"];

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          height: "auto",
        }}
      >
        <Table>
          <TableHead sx={{ minWidth: 650 }} aria-label="simple table">
            <TableRow>
              {payslip.isHourlySalary ? (
                peyeeHeaders.map((item, index) => (
                  <TableCell key={index} align={index === 3 ? "right" : "left"}>
                    {item}
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell align="left">Description</TableCell>
                  <TableCell colSpan={4} align="right">
                    Amount
                  </TableCell>
                </>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <EachRow
                key={index}
                field={field}
                index={index}
                fields={fields}
                payslip={payslip}
                setNormalHour={setNormalHour}
                setOverTime={setOverTime}
                handleInputChange={handleInputChange}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <br />
    </>
  );
};

const EachRow = ({
  fields,
  field,
  index,
  payslip,
  handleInputChange,
  setNormalHour,
  setOverTime,
}) => {
  const dispatch = useDispatch();
  const hourWorked = fields.get(index).hourWorked;

  useEffect(() => {
    if (hourWorked) {
      dispatch(
        change("ReviewPaySlip", `${field}.hourWorked`, hourWorked.toFixed(2))
      );
    } else dispatch(change("ReviewPaySlip", `${field}.hourWorked`, "0.00"));
  }, []);

  let isNormalPay =
    payslip &&
    payslip.isHourlySalary &&
    fields.get(index).paymentHead &&
    fields.get(index).paymentHead.isNormalPay;

  let isOvertimePay =
    payslip &&
    payslip.isHourlySalary &&
    fields.get(index).paymentHead &&
    fields.get(index).paymentHead.isOvertimePay;
  useEffect(() => {
    if (isNormalPay)
      dispatch(change("ReviewPaySlip", `hourWorked`, hourWorked));

    if (isOvertimePay)
      dispatch(
        change(
          "ReviewPaySlip",
          `${field}.amount`,
          hourWorked * fields.get(index).hourlyRate
        )
      );
  }, [hourWorked]);
  return (
    <>
      <TableRow key={index}>
        <TableCell align="left">
          {fields.get(index).paymentHead?.name}
        </TableCell>

        {isNormalPay ? (
          <>
            <TableCell sx={{ p: 0 }} align="left">
              <Field
                name={`${field}.hourWorked`}
                component={InputFieldWithoutLabelMuiTable}
                onChange={(event: ChangeEvent<any>) =>
                  handleInputChange(event, setNormalHour)
                }
              />
            </TableCell>
            <TableCell sx={{ p: 0 }} align="left">
              {fields?.get(index).hourlyRate
                ? fields.get(index).hourlyRate.toFixed(2)
                : "0.00"}
            </TableCell>
          </>
        ) : isOvertimePay ? (
          <>
            <TableCell sx={{ p: 0 }} align="left">
              <Field
                name={`${field}.hourWorked`}
                component={InputFieldWithoutLabelMuiTable}
                onChange={(event: ChangeEvent<any>) =>
                  handleInputChange(event, setOverTime)
                }
              />
            </TableCell>
            <TableCell sx={{ p: 0 }} align="left">
              {fields.get(index).hourlyRate
                ? fields.get(index).hourlyRate.toFixed(2)
                : "0.00"}
            </TableCell>
          </>
        ) : payslip.isHourlySalary && !isNormalPay ? (
          <>
            <TableCell sx={{ p: 0 }} align="left">
              <Field name={`${field}.hourWorked`} component={InputHidden} />
            </TableCell>
          </>
        ) : (
          <>
            <TableCell colSpan={3} sx={{ p: 0 }} align="left"></TableCell>
          </>
        )}

        <TableCell sx={{}} align="right">
          {fields.get(index).paymentHead?.isNormalPay ||
          fields.get(index).paymentHead?.isOvertimePay ||
          fields.get(index).isDeduction ? (
            fields.get(index).isDeduction ? (
              `(${
                fields.get(index)?.amount
                  ? fields.get(index)?.amount.toFixed(2)
                  : "0.00"
              })`
            ) : fields.get(index).amount ? (
              fields?.get(index).hourlyRate ? (
                fields.get(index).hourWorked * fields.get(index).hourlyRate
              ) : (
                fields.get(index)?.amount.toFixed(2)
              )
            ) : (
              "0.00"
            )
          ) : (
            <Field
              name={`${field}.amount`}
              component={InputFieldWithoutLabelMuiTable}
            />
          )}
        </TableCell>
      </TableRow>
    </>
  );
};
