import { IListState } from "@redux/listRedux";
import {
  actions as superFundActions,
  ISuperFundState,
} from "@redux/pyr/superFundRedux";
import { defaultPageSize, filterOptions } from "common/constants";
// import AdminPageMenuHeader from "libComp/pageMenuHeaders/adminMenuHeader";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getSuperFundsTable } from "./superFunds.config";

function SuperFunds(props) {
  const dispatch = useDispatch();
  const superFundState: ISuperFundState = props.superFund;
  const [selection, setSelection] = useState(null);
  const listState: IListState = props.listState;

  const getSuperFundsList = useCallback(
    (pageNo = 0, pageSize = defaultPageSize, filters = [], sortPreference = "") => {
      dispatch(
        superFundActions.getSuperFunds(
          pageNo + 1,
          pageSize,
          filters,
          sortPreference
        )
      );
    },
    [selection]
  );

  const tableColumns = getSuperFundsTable(props);

  return (
    <div className="page-container">
      <PageTitleHeader
        header="Super Fund List"
        buttons={[
          {
            title: "Add Super Fund",
            onClick: (index) => {
              props.history.push("/admin/superfund/addEdit/0");
            },
            iconName: "fa fa-plus",
            id: "sfladd1",
          },
        ]}
      />
      <Filter
        filterOptions={[
          {
            propertyName: "FundName",
            placeholder: "Fund Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ABN",
            placeholder: "ABN",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },

          {
            propertyName: "USI",
            placeholder: "USI",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            superFundActions.getSuperFunds(pageNo, pageSize, filters, sortData)
          );
        }}
      />

      <SolpayGrid
        columns={tableColumns}
        rows={superFundState.superFunds}
        rowCount={superFundState.totalCount}
        loading={superFundState.isLoading}
        loadData={getSuperFundsList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getSuperFundsList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}
const mapStateToProps = ({ superFund, listState }) => ({
  superFund,
  listState,
});

export default connect(mapStateToProps)(SuperFunds);
