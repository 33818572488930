import clientDataAccess from "dataAccess/contact/clientDataAccess";
import supplierDataAccess from "dataAccess/contact/supplierDataAccess";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import productDataAccess from "dataAccess/inv/productDataAccess";
import payrunDataAccess from "dataAccess/pyr/payrunDataAccess";

export const clientCodeAsyncValidate = (values) => {
  if (values.clientCode === "" || !values.clientCode) {
    return new Promise(function (resolve, reject) {
      resolve("");
    });
  }
  return clientDataAccess
    .isClientCodeAvaiable(values.clientCode, values.clientId)
    .then((data) => {
      if (!data.data) {
        throw { clientCode: data.message };
      }
    })
    .catch((error: Error) => {
      //console.log(error);
      throw { clientCode: "Client code not available" };
    });
};

export const superFundNameAsyncValidate = (values) => {
  return employeeDataAccess
    .isSuperFundAvailable(values?.superFundName)
    .then((data) => {
      if (!data.data) {
        throw { superFundName: values?.superFundName + " is not available" };
      }
    })
    .catch((error: Error) => {
      //console.log(error);
      throw { superFundName: values?.superFundName + " is not available" };
    });
};

export const supplierCodeAsyncValidate = (values) => {
  if (values.supplierCode === "" || !values.supplierCode) {
    return new Promise(function (resolve, reject) {
      resolve("");
    });
  }
  return supplierDataAccess
    .isSupplierCodeAvaiable(values.supplierCode, values.supplierId)
    .then((data) => {
      if (!data.data) {
        throw { supplierCode: data.message };
      }
    })
    .catch((error: Error) => {
      //console.log(error);
      throw { supplierCode: "supplier code not available" };
    });
};

const asyncValidateProductCode = (values) => {
  let id = typeof values.Id === "undefined" ? "" : values.Id;
  return productDataAccess
    .isProductCodeExist(values.ProductCode, id)
    .then((data) => {
      if (!data.data) {
        throw { ProductCode: data.message };
      }
    })
    .catch((error: Error) => {
      //console.log(error);
      throw { ProductCode: "This Product Code already exits!!!" };
    });
};

export const asyncValidatePayrunFrequency = (values) => {
  //console.log("values", values);
  let frequency = values.frequency;
  let payFrom = values.payFrom;
  let payTo = values.payTo;

  return payrunDataAccess
    .isPayToDateCorrect(frequency, payFrom, payTo)
    .then((response) => {
      if (!response.data.isPayToDate)
        throw response.data;
      // if (!response.data) {
      //   throw { frequency: data.message };
      // }
    })
    .catch((error: Error) => {
      //console.log(error.message);
      throw { frequency: error.message };
    });
};

export default asyncValidateProductCode;
