import { buttonUnstyledClasses } from "@mui/base/ButtonUnstyled";
import TabPanelUnstyled from "@mui/base/TabPanelUnstyled";
import TabsListUnstyled from "@mui/base/TabsListUnstyled";
import TabsUnstyled from "@mui/base/TabsUnstyled";
import TabUnstyled, { tabUnstyledClasses } from "@mui/base/TabUnstyled";
import { Box, Grid, useMediaQuery } from "@mui/material";
import { styled } from "@mui/system";

const blue = {
  50: "#F0F7FF",
  100: "#C2E0FF",
  200: "#80BFFF",
  300: "#66B2FF",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  800: "#004C99",
  900: "#003A75",
};

const Tab = styled(TabUnstyled)`
  font-family: IBM Plex Sans, sans-serif;
  color: ${blue[900]};
  cursor: pointer;
  font-size: 0.875rem;
  font-weight: bold;
  background-color: #fff;
  padding: 12px 16px;
  margin: 6px 2px;
  margin-right: 1em;
  border: 1px solid ${blue[700]};
  border-radius: 5px;
  display: flex;
  justify-content: center;
  transition: 0.3s;

  &:hover {
    background-color: ${blue[400]};
    color: ${blue[50]};
    border: 1px solid ${blue[500]};
  }

  &:focus {
    color: #fff;
    border-radius: 3px;
    outline: none;
    outline-offset: none;
  }

  &.${tabUnstyledClasses.selected} {
    background-color: ${blue[400]};
    color: ${blue[50]};
    border: 1px solid ${blue[500]};
  }

  &.${buttonUnstyledClasses.disabled} {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const TabPanel = styled(TabPanelUnstyled)`
  width: 100%;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 0.875rem;
  margin-top: 2em;
`;

const TabsList = styled(TabsListUnstyled)`
  width: 90%;
  margin: 0 1.6em;
  min-width: 220px;
  background-color: #fff;
  border-radius: 8px;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: space-between;
`;

export default function PageTabsMui(props) {
  const matches = useMediaQuery("(min-width:600px)");
  return (
    <Grid item>
      <TabsUnstyled defaultValue={0}>
        <TabsList
          sx={{ flexWrap: { md: matches === false ? "noWrap" : "wrap" } }}
        >
          {props?.tabItems.map((item, index) => (
            <Tab
              disabled={item?.disabled}
              key={"tabValue_" + index}
              onChange={props.handleTabChange}
            >
              {item?.title}
            </Tab>
          ))}
        </TabsList>
        <Box
          sx={{
            flex: 1,
            width: "96%",
            mx: "auto",
            border: 1,
            borderRadius: 1,
            borderColor: "grey.200",
            px: 2,
            minHeight: "75%",
          }}
        >
          {props.tabItems.map((item, index) => (
            <TabPanel key={"tabItem_" + index} value={item.value}>
              {item.component}
            </TabPanel>
          ))}
        </Box>
      </TabsUnstyled>
    </Grid>
  );
}

export function PageTabsMuiForList(props) {
  // const matches = useMediaQuery("(min-width:600px)");
  return (
    <Grid item>
      <TabsUnstyled defaultValue={0}>
        <TabsList sx={{ ml: 0 }}>
          {props.tabItems.map((item, index) => (
            <Tab key={item.value} onChange={props.handleTabChange}>
              {item.title}
            </Tab>
          ))}
        </TabsList>
      </TabsUnstyled>
    </Grid>
  );
}
