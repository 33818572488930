function Footer() {
  var dt = new Date();
  var year = dt.getFullYear();
  return (
    <footer className="footer">
      Copyright © 2014-{year} <a href="https://solpay.com.au">Solpay</a>. All
      rights reserved.
    </footer>
  );
}

export default Footer;
