import { Typography } from "@mui/material";
import { IReduxState } from "@redux";
import { actions as invoiceAction } from "@redux/inv/invoiceRedux";
import invoiceDataAccess from "dataAccess/inv/invoiceDataAccess";
import {
  DateFieldReadOnlyRenderer,
  DateFieldRenderer,
  InputFieldOneRow,
  InputFieldOneRowReadOnly,
  InputSimpleSelect,
} from "libComp/common/inputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import { APIResponse } from "models/response";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, SubmissionError } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const OrderPaymentFormViewInvoice = (props) => {
  const error = props.error;

  const [paymentMethodOptions, setPaymentMethodOptions] = useState();
  const [accountList, setAccountList] = useState(
    props.initialValues.accountList
  );
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const dispatch = useDispatch();

  useEffect(() => {
    invoiceDataAccess
      .getAllPaymentMethod()(commonState.commonActions)
      .then((data) => {
        setPaymentMethodOptions(
          data.data.map((item, index) => ({
            ...item,
            value: item.id,
          }))
        );
      });
  }, []);

  const onOrderFormSubmit = (model) => {
    const paymentModel = {
      invoiceId: props.initialValues.id,
      paymentMethod: model.paymentMethod,
      paidAmount: model.payAmount,
      paymentDate: model.paymentDate,
      accountId: model.accountId,
      // accountId: props.initialValues.accountList.id
    };
    return invoiceDataAccess
      .saveInvoicePayment(paymentModel)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) {
          // toast.success("Bill Saved Successful");
          // props.history.push("/accounting/purchase/bill");
          dispatch(invoiceAction.getInvoice(paymentModel.invoiceId));
          modalClose(data);
        }
        //  else toast.error("Saved UnSuccessful");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const modalClose = (data) => {
    props.setPayment(data);
  };

  const style = useStyleClass();

  return (
    <div>
      <Row>
        <Col md={6} sm={6} xs={6}>
          <Field
            name="invoiceNo"
            label="Invoice Number"
            component={InputFieldOneRowReadOnly}
          ></Field>
          <Field
            name="clientName"
            label="Client"
            component={InputFieldOneRowReadOnly}
          ></Field>
        </Col>
        <Col md={6} sm={6} xs={6}>
          <Field
            name="invoiceDate"
            label="Invoice Date"
            component={DateFieldReadOnlyRenderer}
          ></Field>
          <Field
            name="amountDue"
            label="Total Due"
            component={InputFieldOneRowReadOnly}
          ></Field>
        </Col>

        <Col md={12} sm={12} xs={12}>
          <form noValidate onSubmit={props.handleSubmit(onOrderFormSubmit)}>
            <Row>
              <Col md={6} sm={6} xs={6}>
                <Field
                  name="accountId"
                  label="Payment Account"
                  component={solpayReactSelect}
                  options={accountList}
                ></Field>

                <Field
                  name="payAmount"
                  label="Payment Amount"
                  component={InputFieldOneRow}
                  type="number"
                  validate={[
                    required({ msg: "Payment Amount is a required field" }),
                    numericality({
                      ">=": 0,
                      msg: "Payment Amount can't be negative value",
                    }),
                    numericality({
                      "<=": props.initialValues.amountDue,
                      msg: "Payment Amount can't be greater than due amount",
                    }),
                  ]}
                ></Field>
              </Col>
              <Col md={6} sm={6} xs={6}>
                <Field
                  name="paymentDate"
                  component={DateFieldRenderer}
                  label="Payment Date"
                />
                <Field
                  name="paymentMethod"
                  label="Payment Method"
                  component={InputSimpleSelect}
                  options={paymentMethodOptions}
                ></Field>
              </Col>
            </Row>
            <Row>
              <Container
                fluid
                className="inner-container"
                style={{ padding: "20px" }}
              >
                {error && (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    classes={{ root: style.error }}
                  >
                    {error}
                  </Typography>
                )}
                <Button type="submit" disabled={props.submitting}>
                  {props.submitting ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}
                  Save
                </Button>
              </Container>
            </Row>
          </form>
        </Col>
      </Row>
    </div>
  );
};

export default OrderPaymentFormViewInvoice;
