import {
  FormControl,
  Grid,
  InputLabel,
  ListSubheader,
  MenuItem,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Select from "react-select";

const SolpayGroupSelectMui = ({
  label,
  input,
  options,
  multi,
  group,
  child,
  meta: { touched, error, warning },
  labelCol,
  fieldColumn,
}) => {
  const { name, value, onBlur, onChange, onFocus } = input;

  const transformedValue = transformValue(value, options);

  let config = { layout: "horizontal" };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";

  const [val, setValue] = useState(
    input.value == null || undefined ? "" : input.value
  );
  const containsText = (text, searchText) =>
    text.toString().toLowerCase().indexOf(searchText.toString().toLowerCase()) >
    -1;

  const [searchText, setSearchText] = useState("");
  const renderSelectGroup = (item) => {
    const items = item.options.map((p) => {
      return (
        <MenuItem key={p.value} value={p.value}>
          {p.label}
        </MenuItem>
      );
    });
    return [<ListSubheader>{item.label}</ListSubheader>, items];
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  useEffect(() => setValue(input.value), [input.value]);

  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        flexDirection: layout === "horizontal" ? "row" : "column",
        marginBottom: "21px",
      }}
    >
      <Grid
        item
        md={labelCol ? labelCol : 3.95}
        // xl={labelCol ? (labelCol > 11 ? labelCol : labelCol + 1) : 5}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={fieldColumn ? fieldColumn : 8.04}
        // xl={
        //   fieldColumn ? (fieldColumn > 11 ? fieldColumn : fieldColumn + 1) : 7
        // }
      >
        <FormControl size="small" style={{ width: "98%" }}>
          {/* <InputLabel id="select-label">{label}</InputLabel> */}
          {/* <Select
            size="small"
            label={label}
            {...input}
            style={{ width: '100%' }}
            name={name}
            value={val}
            multi={multi}
            options={options}
            onChange={multi ? multiChangeHandler(onChange) : handleChange}
          >
            
            {options?.map(p => renderSelectGroup(p))}
   
          </Select> */}
          <Select
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
            menuPortalTarget={document.body}
            closeMenuOnScroll={(e: any) =>
              !e.target.classList.contains("css-4ljt47-MenuList")
            }
            menuPosition={"fixed"}
            valueKey="value"
            name={name}
            value={transformedValue}
            options={options}
            onChange={
              multi
                ? multiChangeHandler(onChange)
                : singleChangeHandler(onChange)
            }
          />
        </FormControl>
        {touched && error && (
          <InputLabel
            style={{
              display: "block",
              color: "red",
              fontSize: "12px",
              margin: "2px",
            }}
          >
            {error}
          </InputLabel>
        )}
      </Grid>
    </Grid>
  );
};

SolpayGroupSelectMui.defaultProps = {
  multi: false,
};

SolpayGroupSelectMui.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    //value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  className: PropTypes.string,
};

export default SolpayGroupSelectMui;

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "");
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map((value) => value.value));
  };
}

/**
 
 */
function transformValue(value, options) {
  if (typeof value === "string" || options.length === 0) return [];
  const optionLength = options.length;
  let selectop = [];
  for (let i = 0; i < optionLength; i++) {
    const filteredOptions = options[i].options.filter((option) => {
      return option.value === value;
    });
    if (filteredOptions.length > 0) selectop.push(filteredOptions);
    //  for(let j=0;j<options[i].options.length;j++){

    //     if(options[i].options[j].value===value){
    //       selectop[0]= {
    //        type: options[i].options[j].type,
    //        label: options[i].options[j].label,
    //        value: options[i].options[j].value}
    //        break;

    //  }
  }

  return selectop[0];
}
