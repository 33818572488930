import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as tenantActions } from "@redux/tnt/tenantRedux";
import { CRMEntityTypes, stateList } from "common/constants";
import addressDataAccess from "dataAccess/crm/addressDataAccess";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Field,
  initialize,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface IProps {
  entityId?: string;
  entityType?: number;
  address: any;
}

function AddEditBusinessAddress(props: InjectedFormProps<any, IProps> & IProps) {


  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const style = useStyleClass();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = (address) => {
    return addressDataAccess
      .saveAddress(
        address,
        props.entityId,
        //props.entityType
        CRMEntityTypes.Company
      )(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        enqueueSnackbar("Address has been saved!", { variant: "success" });
        if (props.entityType === CRMEntityTypes.Company)
          dispatch(tenantActions.getBusinessInfo());
        if (props.entityType === CRMEntityTypes.Contact)
          dispatch(tenantActions.getPayerInfo());
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const AddressButton = [
    {
      id: "button1",
      // title: editMode ? "Cancel" : "Edit",
      // iconName: editMode ? "fa fa-times" : "fa fa-edit",
      title: "Edit",
      iconName: "fa fa-edit",
      onClick: (index) => {
        handleClickOpen();
        if (!open)
          dispatch(
            initialize("AddEditCRMAddress", props.address ?? { state: "ACT" })
          );
        // setEditMode(!editMode);
      },
    },
  ];

  return (
    <>
      <PageTitleHeader header="Address" buttons={AddressButton} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography>Edit Address</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="cancel" color="primary">
                <HighlightOffIcon onClick={() => handleClose()} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
            noValidate
          >
            <TableContainer component={Paper} sx={{ p: 1 }}>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                    padding: 0,
                  },
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Field name="id" component={InputHidden} />
                      <Field name="tenantId" component={InputHidden} />
                      <Field
                        name="street1"
                        component={InputFieldOneRow}
                        label="Street Address"
                        placeholder="Street Line 1"
                        validate={required}
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Field
                        name="street2"
                        component={InputFieldOneRow}
                        label=" "
                        placeholder="Street Line 2"
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Field
                        name="suburb"
                        component={InputFieldOneRow}
                        label="Suburb"
                        placeholder="Suburb"
                        validate={required}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Field
                        name="state"
                        component={solpayReactSelect}
                        label="State"
                        options={stateList}
                        placeholder=""
                      />
                    </TableCell>
                  </TableRow>

                  <TableRow>
                    <TableCell>
                      <Field
                        name="postCode"
                        component={InputFieldOneRow}
                        label="Post Code"
                        placeholder="Post Code"
                        validate={required}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Field
                        name="country"
                        component={InputFieldOneRow}
                        label="Country"
                        placeholder="Country"
                        validate={required}
                      />
                    </TableCell>
                  </TableRow>
                  {props.error && (
                    <Typography
                      variant="subtitle2"
                      component="div"
                      classes={{ root: style.error }}
                    >
                      {props.error}
                    </Typography>
                  )}
                  <TableRow>
                    <TableCell>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          classes={{ root: style.button }}
                          sx={{ m: 2 }}
                          variant="contained"
                          type="submit"
                          disabled={props.submitting}
                        >
                          {props.submitting ? (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "blue",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          ) : (
                            <i className="fa fa-check"></i>
                          )}{" "}
                          Save
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </DialogContent>
      </Dialog>
      <Box>
      <TableContainer component={Paper}>
            <Table
              sx={{
                [`& .${tableCellClasses.root}`]: {
                  borderBottom: "none",
                },
              }}
              aria-label="caption table"
            >
              <TableBody>
                <TableRow>
                  <TableCell style={{ fontWeight: 500 }} align="left">
                    Street Address
                  </TableCell>
                  <TableCell>{props.address.street1 ?? ""}</TableCell>
                </TableRow>
                {props.address.street2 && (
                  <TableRow>
                    <TableCell
                      style={{ fontWeight: 500 }}
                      align="left"
                    ></TableCell>
                    <TableCell>{props.address.street2}</TableCell>
                  </TableRow>
                )}
                <TableRow>
                  <TableCell style={{ fontWeight: 500 }} align="left">
                    Suburb
                  </TableCell>
                  <TableCell>{props.address.suburb ?? ""}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }} align="left">
                    State
                  </TableCell>
                  <TableCell>{props.address.state ?? ""}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }} align="left">
                    Post Code
                  </TableCell>
                  <TableCell>{props.address.postCode ?? ""}</TableCell>
                </TableRow>

                <TableRow>
                  <TableCell style={{ fontWeight: 500 }} align="left">
                    Country
                  </TableCell>
                  <TableCell>{props.address.country ?? ""}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
      </Box>
    </>
  );
}

const addEditBusinessAddress = reduxForm<any, IProps>({
  form: "AddEditBusinessAddress",
  enableReinitialize: true,
})(AddEditBusinessAddress);
const mapStateToProps = (state) => ({
  address: state.tenant.businessInfo.businessAddress ?? {},
  initialValues: state.tenant.businessInfo.businessAddress ?? {},
});

export default connect(mapStateToProps)(addEditBusinessAddress);