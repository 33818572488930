import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Link } from "react-router-dom";

interface IBreadcrumbsrProps {
  menuItems?: IBreadcrumbs[];
}
export interface IBreadcrumbs {
  title: string;
  to: string;
}

const PageMenuTitle = (props: IBreadcrumbsrProps) => {
  return (
    <>
      <AppBar
        position="fixed"
        style={{ backgroundColor: "#fff", zIndex: 4 }}
        sx={{ mt: 5.65, mb: 1, px: 3, width: 1, boxShadow: 1 }}
      >
        {/* <Container maxWidth="xl"> */}
        <Toolbar disableGutters variant="dense" sx={{ my: 0, mx: 0 }}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "flex" },
            }}
          >
            <Breadcrumbs aria-label="breadcrumb" sx={{ fontSize: 14 }}>
              {/* {props.menuItems.length > 0  ? (
                        <Link to="/tenant">
                            Home
                        </Link>
                    ) : (
                        <Typography
                            color="text.primary"
                            sx={{ fontSize: 14 }}
                        >
                            Home
                        </Typography>)} */}

              {props.menuItems.map((item, index) => {
                const isLast = props.menuItems.length - 1;
                return isLast === index || item.to === null ? (
                  <Typography
                    color="text.primary"
                    key={"pgmt_" + index}
                    sx={{ fontSize: 14 }}
                  >
                    {item.title}
                  </Typography>
                ) : (
                  <Link key={"pgmt_" + index} to={item.to}>
                    {item.title}
                  </Link>
                );
              })}
            </Breadcrumbs>
          </Box>
        </Toolbar>
        {/* </Container> */}
      </AppBar>
    </>
  );
};

export default PageMenuTitle;
