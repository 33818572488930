import { Box, Button, CircularProgress, Divider, Grid } from "@mui/material";
import { IReduxState } from "@redux";
import { GetBillById } from "@redux/bill/billActions";
import billDataAccess from "dataAccess/bill/billDataAccess";
import invoiceDataAccess from "dataAccess/inv/invoiceDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  DateFieldRenderer,
  InputFieldOneRow,
  InputFieldOneRowReadOnly,
  InputSimpleSelect,
} from "libComp/common/inputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import { APIResponse } from "models/response";
import React, { useEffect, useState } from "react";
import {} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, SubmissionError } from "redux-form";
import { numericality, required } from "redux-form-validators";
import { useStyleClass } from "styles/theming/useStyleClass";

function OrderPaymentFormView(props) {
  const error = props.error;
  const [paymentMethodOptions, setPaymentMethodOptions] = useState();
  const [bankAccountList, setBankAccountList] = useState(
    props.initialValues.bankAccountList
  );
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const dispatch = useDispatch();
  useEffect(() => {
    invoiceDataAccess
      .getAllPaymentMethod()(commonState.commonActions)
      .then((data) => {
        setPaymentMethodOptions(
          data.data.map((item, index) => ({
            ...item,
            value: item.id,
          }))
        );
      });
  }, []);

  const onOrderFormSubmit = (model) => {
    //model.billId= props.initialValues.Id;
    const paymentModel = {
      billId: props.initialValues.id,
      paymentMethod: model.paymentMethod,
      paidAmount: model.payAmount,
      paymentDate: model.paymentDate,
      accountId: model.accountId,
    };
    return billDataAccess
      .saveBillPayment(paymentModel)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) {
          // toast.success("Bill Saved Successful");
          // props.history.push("/accounting/purchase/bill");
          dispatch(GetBillById(paymentModel.billId));
          modalClose(data);
        }
        //  else toast.error("Saved UnSuccessful");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };
  const modalClose = (data) => {
    props.setPayment(data);
    // props.hideModal();
  };

  const style = useStyleClass();

  return (
    <>
      <form noValidate onSubmit={props.handleSubmit(onOrderFormSubmit)}>
        <Grid container spacing={10}>
          <Grid item xl={6} md={6}>
            <Field
              name="billNumber"
              label="Bill Number"
              component={InputFieldOneRowReadOnly}
            />
            <Field
              name="supplierName"
              label="Supplier"
              component={InputFieldOneRowReadOnly}
            />
            <Field
              name="purchaseDate"
              label="Purchase Date"
              component={DatePickerFieldOneRow}
            />
            <Field
              name="totalDue"
              label="Total Due"
              component={InputFieldOneRowReadOnly}
            />
            <Field
              name="accountId"
              label="Payment Account"
              component={solpayReactSelect}
              options={bankAccountList}
            />
            <Field
              name="payAmount"
              label="Payment Amount"
              component={InputFieldOneRow}
              type="number"
              validate={[
                required({ msg: "Payment Amount is a required field" }),
                numericality({
                  ">=": 0,
                  msg: "Payment Amount can't be negative value",
                }),
                numericality({
                  "<=": props.initialValues.totalDue,
                  msg: "Payment Amount can't be greater than due amount",
                }),
              ]}
            />
          </Grid>
          <Grid item xl={6} md={6}>
            <Field
              name="paymentDate"
              component={DateFieldRenderer}
              label="Payment Date"
            />
            <Field
              name="paymentMethod"
              label="Payment Method"
              component={InputSimpleSelect}
              options={paymentMethodOptions}
            />
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            classes={{ root: style.button }}
            sx={{ m: 2 }}
            variant="contained"
            type="submit"
            disabled={props.submitting}
          >
            {props.submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "blue",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <i className="fa fa-check"></i>
            )}{" "}
            Save
          </Button>
        </Box>
      </form>
    </>
  );
}
export default OrderPaymentFormView;
