import {
  actions as journalActions,
  IJournalState,
} from "@redux/bkp/transactionRedux";
import { useAppSelector } from "@redux/hook.type";
import { IReduxState } from "@redux";
import { defaultPageSize, filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
// import AccountingPageMenuHeader from "libComp/pageMenuHeaders/accountingPageMenuHeader";
import moment from "moment";
import { useCallback, useState } from "react";
import { IDataTableColumn } from "react-data-table-component";
import { connect, useDispatch, useSelector } from "react-redux";
import { getJournalListColumns } from "./journal.config";

function Journals(props) {
  const [selection, setSelection] = useState(null);
  const dispatch = useDispatch();
  const journalState = useSelector((state: IReduxState) => state?.journal);
  const listState = useSelector((state: IReduxState) => state?.listState);
  const getJournalList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "TransactionDate-desc"
    ) => {
      dispatch(
        journalActions.getAll(pageNo + 1, pageSize, filters, sortPreference)
      );
    },
    [selection]
  );

  const columns: Array<IDataTableColumn> = [
    {
      name: "Reference",
      selector: "transactionNo",
      id: "transactionNo",
      sortable: false,
    },
    {
      name: "Date",
      selector: "transactionDate",
      id: "transactionDate",
      sortable: false,
      format: (row, index) =>
        (row.payDate = moment(row.transactionDate).format(
          "DD/MM/YYYY hh:mm:ss A"
        )),
    },
    {
      name: "Note",
      selector: "narration",
      id: "narration",
      sortable: false,
    },
    {
      name: "",
      selector: "progress",
      minWidth: "70px",
      maxWidth: "70px",
      cell: (row) => (
        <div className="">
          <span
            onClick={() => {
              props.history.push("/accounting/journal/addedit/" + row.id);
            }}
          >
            <i className="fa fa-edit list-action-button"></i>
          </span>
        </div>
      ),
      sortable: false,
    },
  ];

  const tableColumn = getJournalListColumns(props);

  return (
    <div className="page-container">
      <PageTitleHeader
        header="Journals"
        buttons={[
          {
            title: "Add Journal",
            onClick: (index) => {
              props.history.push("/accounting/journal/addedit/0");
            },
            iconName: "fa fa-plus",
            id: "jouradd1",
          },
        ]}
      />
      <Filter
        filterOptions={[
          {
            propertyName: "FromDate",
            placeholder: "From Date",
            inputType: "date",
            typeOptions: [filterOptions.greaterThanEqual],
          },
          {
            propertyName: "ToDate",
            placeholder: "To Date",
            inputType: "date",
            typeOptions: [filterOptions.lessThanEqual],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(journalActions.getAll(pageNo, pageSize, filters, sortData));
        }}
      />

      <SolpayGrid
        columns={tableColumn}
        rows={journalState.journals}
        rowCount={journalState.totalCount}
        loading={journalState.isLoading}
        loadData={getJournalList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getJournalList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ journal, listState }) => ({ journal, listState });
export default connect(mapStateToProps)(Journals);
