import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import moment from "moment";

export const getTicketTableColumns = (props, extra?) => {
  const columns: GridColumns = [
    {
      headerName: "Ticket No",
      field: "ticketNo",
    },
    {
      headerName: "Type",
      field: "type",
    },
    {
      headerName: "Subject",
      field: "subject",
    },
    {
      headerName: "Client Org",
      field: "tenant",
    },
    {
      headerName: "Client Name",
      field: "createdByName",
    },
    {
      headerName: "Created On",
      field: "createdOn",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Resolved",
      field: "resolved",
      valueFormatter: (params) => {
        return params.value ? "Yes" : "No";
      },
    },
    {
      headerName: "Resolved On",
      field: "resolvedOn",
      valueFormatter: (params) => {
        return params.value ? moment(params.value as any).format("DD-MM-yyyy") : "";
      },
    },
    {
      headerName: "Edit",
      field: "progress",
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              title="Edit"
              onClick={() => {
                props.history.push("/admin/ticket/" + params.row.id);
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
