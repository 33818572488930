import { Dispatch } from 'redux';
import {
  FETCH_TAXES_FAILURE,
  FETCH_ALL_TAXES,
  FETCH_TAXES_SUCCESS,
  FETCH_TAXES_REQUEST,
} from './taxTypes';

export interface ITaxState {
  taxes: Array<any>;
  tax: any;
  taxList: Array<any>;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}
const initialState: ITaxState = {
  taxes: [],
  tax: {},
  taxList: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ALL_TAXES:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TAXES_SUCCESS:
      return {
        loading: false,
        taxes: action.payload.taxes,
        taxList: action.payload.taxlist,
        error: '',
      };
    case FETCH_TAXES_FAILURE:
      return {
        loading: false,
        taxes: [],
        error: action.payload,
      };
    default:
      return state;
  }
};
