import {
  actions as paymentAction,
  IPaymentHeadState,
} from "@redux/pyr/paymentHeadRedux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getPayrollOverTimePaymentTable,
  getPayrollRegularPaymentTable,
} from "./payrollSettings.config";

function PayrollSettings(props) {
  const dispatch = useDispatch();
  const paymentState: IPaymentHeadState = props.paymenthead;

  useEffect(() => {
    getPaymentHeads();
    getOvertimePaymentHeads();
  }, []);

  const getPaymentHeads = useCallback(() => {
    dispatch(paymentAction.getPaymentHeads());
  }, []);

  const getOvertimePaymentHeads = useCallback(() => {
    dispatch(paymentAction.getOvertimePaymentHeads());
  }, []);

  const tableColumnsRegular = getPayrollRegularPaymentTable(props);
  const tableColumnsOverTime = getPayrollOverTimePaymentTable(props);

  return (
    <div className="page-container">
      <PageTitleHeader
        header="Addition/Deduction Payment List"
        buttons={[
          {
            title: "Add Addition/Deduction Payment",
            onClick: (index) => {
              props.history.push(
                "/payroll/payroll-settings/paymenthead/addedit/0"
              );
            },
            iconName: "fa fa-plus",
            id: "dpadd1",
          },
        ]}
      />
      <SolpayGrid
        columns={tableColumnsRegular}
        rows={paymentState.paymentheads}
        rowCount={paymentState.totalCount}
        loading={paymentState.isLoading}
        loadData={getPaymentHeads}
        triggerChange={() => {
          getPaymentHeads();
        }}
      />
      <br />
      <PageTitleHeader
        header="Addition/Deduction Overtime Payment List"
        buttons={[
          {
            title: "Add Overtime Payment",
            onClick: (index) => {
              props.history.push(
                "/payroll/payroll-settings/paymenthead/addedit/0/true"
              );
            },
            iconName: "fa fa-plus",
            id: "payadd1",
          },
        ]}
      />
      <SolpayGrid
        columns={tableColumnsOverTime}
        rows={paymentState.overtimePaymentheads}
        rowCount={paymentState.totalCount}
        loading={paymentState.isLoading}
        loadData={getOvertimePaymentHeads}
        triggerChange={() => {
          getOvertimePaymentHeads();
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ paymenthead, listState }) => ({
  paymenthead,
  listState,
});
export default connect(mapStateToProps)(PayrollSettings);
