import { ICommonActions } from "@redux/commonRedux";
import { IFilter } from "@redux/listRedux";
import { filterOptions } from "common/constants";
import commonDataAccess from "dataAccess/commonDataAccess";
class userDataAccess {
  saveEmployee = (employee) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Employee/saveemployee",
      employee
    )(commonActions);
  };
  getEmployeeList =
    (pageNo, pageSize, filters: Array<IFilter>, sortPreference) =>
      (commonActions: ICommonActions) => {
        let _filters = {};
        filters.forEach((item) => {
          if (item.value) {
            _filters["filter." + item.propertyName] =
              item.value + "-" + item.type;
          }
        });
        return commonDataAccess.getData("Employee/getall", {
          pageNo,
          pageSize,
          sortPreference,
          ..._filters, 
        })(commonActions);
      };
  getEmployee = (employeeId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Employee/get", { id: employeeId })(
      commonActions
    );
  };
  deleteEmployee = (employeeId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Employee/delete",
      {},
      { id: employeeId }
    )(commonActions);
  };
  getPayInfo = (employeeId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("EmployeePayInfo/getbyemployeeid", {
      employeeId: employeeId,
    })(commonActions);
  };
  savePayInfo = (employeeId, data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "EmployeePayInfo/save",
      { ...data, employeeId },
      {}
    )(commonActions);
  };
  getTaxInfo = (employeeId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("EmployeeTaxInfo/getbyemployeeid", {
      employeeId: employeeId,
    })(commonActions);
  };
  saveTaxInfo = (employeeId, data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "EmployeeTaxInfo/save",
      { ...data, employeeId },
      {}
    )(commonActions);
  };
  getLeaveInfo = (employeeId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("EmployeeLeaveInfo/getbyemployeeid", {
      employeeId: employeeId,
    })(commonActions);
  };
  saveLeaveInfo = (employeeId, data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "EmployeeLeaveInfo/save",
      { ...data, employeeId },
      {}
    )(commonActions);
  };
  getAllSuperFund =
    (searchString, pageNo = 1, pageSize = 50, sortPreference = "") =>
      (commonActions: ICommonActions) => {
        return commonDataAccess.getData("Payroll/getallsuperfund", {
          "filter.FundName": searchString + "-" + filterOptions.contains.value,
          pageNo,
          pageSize,
          sortPreference,
        })(commonActions);
      };
  getSuperFundByName =
    (superFundName: string) => (commonActions: ICommonActions) => {
      return commonDataAccess.getData("Payroll/getsuperfundbyname", {
        term: superFundName,
      })(commonActions);
    };
  isSuperFundAvailable = (superFundName: string) => {
    return commonDataAccess.getData("Payroll/issuperfundavailable", {
      superFundName,
    })(null);
  };
  getAdditionDeduction =
    (employeeId: string) => (commonActions: ICommonActions) => {
      return commonDataAccess.getData("EmployeeAdditionDeduction/get", {
        employeeId,
      })(commonActions);
    };
  saveAdditionDeduction =
    (additionDeductions, employeeId) => (commonActions: ICommonActions) => {
      return commonDataAccess.postData("EmployeeAdditionDeduction/save", {
        employeeId,
        ...additionDeductions,
      })(commonActions);
    };
  getEmployeePaySlips =
    (employeeId: string, pageNo, pageSize, filters, sortPreference) =>
      (commonActions: ICommonActions) => {
        let _filters = {};
        filters.forEach((item) => {
          if (item.value && item.value.length > 0) {
            _filters["filter." + item.propertyName] =
              item.value + "-" + item.type;
          }
        });
        return commonDataAccess.getData("EmployeePaySlip/getbyemployeeid", {
          employeeId,
          pageNo,
          pageSize,
          ..._filters,
          sortPreference,
        })(commonActions);
      };
  getPaymentHeadList = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("EmployeePaymentHead/getheadlist")(
      commonActions
    );
  };
  deletePaySlip = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "EmployeePaySlip/deletepayslip",
      {},
      { id: id }
    )(commonActions);
  };

  mailPaySlip = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "EmployeePaySlip/emailpayslip",
      {},
      { id }
    )(commonActions);
  };
  getEmployeeType = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Employee/employee-number-exceed")(
      commonActions
    );
  };
}
export default new userDataAccess();
