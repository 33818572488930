/* eslint-disable import/no-anonymous-default-export */
import { IReduxState } from "@redux";
import { localStorageKeys } from "common/constants";
import { modules } from "common/enums";
import NotAuthorize from "pages/notAuthorize";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

export default function (
  TargetComponent,
  modules: Array<modules>,
  //isOpen: boolean = false,
  //isLogin: boolean = false,
  authorizationNeeded: boolean = true
) {
  return (props) => {
    const history = useHistory();
    const isAuthorized = localStorage.getItem(localStorageKeys.authorization)
      ? true
      : false;
    const user = useSelector((state: IReduxState) => state.user.user);
    if (!authorizationNeeded) {
      return <TargetComponent {...props} />;
    } else if (authorizationNeeded && !isAuthorized) {
      history.push("/login");
    } else if (authorizationNeeded && isAuthorized) {
      if (
        modules.length === 0 ||
        user?.userModulePermissions.some((item) => modules.includes(item))
      ) {
        return <TargetComponent {...props} />;
      } else {
        return <NotAuthorize />;
      }
    }
    // if (!authorization && !isOpen && !isLogin) {
    //   history.push("/login");
    // } else if (!authorization && isLogin) {
    //   return <TargetComponent {...props} />;
    // }
    //if he is not permitted to this current module send him to a different page
    // let hasMatchedModule = false;
    // //check if user has a matched module
    // for (let i = 0; i < modules.length; i++) {
    //   let index = user?.userModulePermissions
    //     ? user?.userModulePermissions.indexOf(modules[i])
    //     : -1;
    //   if (index !== -1) {
    //     hasMatchedModule = true;
    //     break;
    //   }
    // }
    // if (!hasMatchedModule && modules.length > 0) {
    //   return <NotAuthorize />;
    // }

    // //if he is permitted then show him desired component
    // return <TargetComponent {...props} />;
  };
}
