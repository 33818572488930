import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class YearClosingAccess {
  getClosingYears = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('AccountHeads/getclosingyears')(
      commonActions
    );
  };
  closeYear = (product) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'AccountHeads/closeyear',
      product
    )(commonActions);
  };
}

export default new YearClosingAccess();
