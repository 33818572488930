/* eslint-disable react/jsx-no-undef */
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as employeeActions } from "@redux/emp/employeeRedux";
import { salaryModes } from "common/constants";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  AutoCompleteInputFieldOneRowMui,
  InputFieldOneRow,
  InputFieldOneRowReadOnly,
  InputHidden,
} from "libComp/common/inputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  change,
  Field,
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import employeeOvertimePayFields from "./employeeOvertimePayFields";

interface Props {
  employeeId: string;
  salaryMode: string;
  superFundName?: string;
}
function TabPayAndSuperInfo(props: InjectedFormProps<any, Props> & Props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const employeeId = props?.employeeId;
  const salaryMode = props?.salaryMode ? props?.salaryMode : "1";
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [superFundName, setSuperFundName] = React.useState(
    props?.initialValues?.superFundName
  );
  const leaveFields = props?.initialValues?.pyrEmployeeOvertimePayments;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();

  const onFormSubmit = (model) => {
    return employeeDataAccess
      .savePayInfo(
        props?.employeeId,
        model
      )(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Super Info has been saved!", { variant: "success" });
        dispatch(employeeActions.getPayAndSuperInfo(employeeId));
      })
      .catch((error: Error) => {
        enqueueSnackbar("Super Info could not been saved!", {
          variant: "error",
        });
        throw new SubmissionError({ _error: error.message });
      });
  };

  useEffect(() => {}, [salaryMode]);

  useEffect(() => {
    if (employeeId === "0") {
      history.push("/payroll/employees");
    } else {
      if (props?.superFundName && props?.superFundName?.length > 1) {
        employeeDataAccess
          .getSuperFundByName(props?.superFundName)(commonState.commonActions)
          .then((data) => {
            setOptions(
              data?.data.map((item) => ({
                ...item,
              }))
            );
          })
          .catch((error) => {
            //console.log(error);
          });
      }
    }
  }, [props?.superFundName]);

  const onLoadData = (event) => {
    employeeDataAccess
      .getSuperFundByName(event.target.value)(commonState.commonActions)
      .then((data) => {
        setOptions(
          data?.data?.map((item) => ({
            ...item,
          }))
        );
      })
      .catch((error) => {
        //console.log(error);
      });
    //console.log(event);
  };

  const style = useStyleClass();

  return (
    <>
      <Box sx={{ flex: 1, width: "100%", mx: "auto" }}>
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid container spacing={6}>
            <Grid item xl={7} md={7} sm={12} xs={12}>
              <Field name="Id" component={InputHidden} />
              <Field name="TenantId" component={InputHidden} />
              <Field name="EmployeeId" component={InputHidden} />
              <Field name="CreatedBy" component={InputHidden} />
              <Field name="CreatedOn" component={InputHidden} />
              <Field name="FundId" component={InputHidden} />
              <Field
                name="salaryMode"
                component={solpayReactSelect}
                label="Salary Mode"
                options={salaryModes}
                placeholder=""
              />
              {salaryMode.toString() !== "2" && (
                <Field
                  name="annualSalary"
                  component={InputFieldOneRow}
                  label="Annual Salary"
                  placeholder="Annual Salary"
                  validate={required}
                />
              )}
              <Field
                name="anualSalaryEffectiveStartDate"
                component={DatePickerFieldOneRow}
                label="Annual Salary Effective Start Date"
                placeholder="Annual salary effective start date"
              />
              {salaryMode.toString() === "2" && (
                <>
                  <Field
                    name="hourlyRate"
                    component={InputFieldOneRow}
                    label="Hourly Rate"
                    placeholder="Hourly Rate"
                  />
                  {/* {leaveFields.map((item, index) => (
                    <React.Fragment key={index}>
                      <Field
                        key={index}
                        name={"leaveFields[" + index + "].amount"}
                        component={InputFieldOneRow}
                        label={leaveFields[index].paymentHead}
                        placeholder={leaveFields[index].paymentHead}
                      />
                    </React.Fragment>
                  ))} */}
                  <FieldArray
                    name="pyrEmployeeOvertimePayments"
                    component={employeeOvertimePayFields}
                    props={{}}
                  />
                </>
              )}
              <Field
                name="weeklyHourContribution"
                component={InputFieldOneRow}
                label="Hours/Week"
                placeholder="Weekly Hour Contribution"
              />
              <Field
                name="fundRegistrationDate"
                component={DatePickerFieldOneRow}
                label="Super Fund Registration Date"
                placeholder="Super Fund Registration Date"
              />
              <Field
                name="fundCommenceDate"
                component={DatePickerFieldOneRow}
                label="Super Fund Commence Date"
                placeholder="Super Fund Commence Date"
              />
              <Field
                name="superFundName"
                component={AutoCompleteInputFieldOneRowMui}
                dataSource={options}
                onChange={onLoadData}
                onDataSelect={(data) => {
                  dispatch(
                    change("TabPayAndSuperInfo", "superFundName", data?.text)
                  );
                  dispatch(change("TabPayAndSuperInfo", "fundUSI", data?.usi));
                }}
                label="Super Fund Name"
                placeholder="Super Fund Name"
              />
              <Field
                name="fundUSI"
                component={InputFieldOneRowReadOnly}
                label="Super Fund Usi"
                disabled
                placeholder="Super Fund Usi"
              />
              <Field
                name="superMemberNumber"
                component={InputFieldOneRow}
                label="Membership Number"
                placeholder="Membership Number"
              />
              <Field
                name="superPercentage"
                component={InputFieldOneRow}
                label="Super Percentage"
                placeholder="Super Percentage"
              />

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <br />
    </>
  );
}
const tabPayAndSuperInfo = reduxForm<any, Props>({
  form: "TabPayAndSuperInfo",
  enableReinitialize: true,
  // asyncValidate: superFundNameAsyncValidate,
  asyncChangeFields: ["superFundName"],
})(TabPayAndSuperInfo);
const selector = formValueSelector("TabPayAndSuperInfo");
//const selector = formValueSelector("TabPayAndSuperInfo");
const mapStateToProps = (state, ownProps) => ({
  salaryMode: selector(state, "salaryMode"),
  //console.log("employee", state.employee);
  superFundName: state?.employee?.psInfo?.superFundName,
  initialValues: state?.employee?.psInfo,
  //employee: state.employee,
});

export default connect(mapStateToProps)(tabPayAndSuperInfo);
