import {
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Typography,
  Avatar,
  Divider,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";
import { useHistory } from "react-router-dom";

interface button {
  imageSrc: any;
  imageAlt: string;
  header: string;
  description: string;
  to: string;
}
interface Props {
  buttons: Array<button>;
}
function PageButtons(props: Props) {
  const history = useHistory();

  return (
    <>
      <br />
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          {props.buttons.map((button, index) => (
            <React.Fragment key={index}>
              <Grid
                item
                sm={6}
                md={4}
                onClick={() => history.push(button.to)}
                key={index}
              >
                <Card
                  elevation={1}
                  sx={{
                    maxWidth: 600,
                    border: 1,
                    borderColor: "grey.100",
                  }}
                >
                  <CardActionArea>
                    <CardContent>
                      <Avatar
                        alt={button.imageAlt}
                        src={button.imageSrc}
                        variant="square"
                        sx={{ mx: "auto", mb: 2 }}
                      ></Avatar>
                      <Typography
                        gutterBottom
                        variant="h6"
                        component="div"
                        sx={{
                          fontSize: 16,
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        {button.header}
                      </Typography>
                      <Divider
                        sx={{
                          marginTop: 1,
                          marginBottom: 1,
                          color: "grey",
                          borderColor: "primary.main",
                        }}
                      />
                      <Typography
                        variant="body2"
                        sx={{
                          color: "primary.main",
                          fontSize: 12,
                          fontWeight: 500,
                          textAlign: "center",
                        }}
                      >
                        {button.description}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              <br />
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    </>
  );
}

export default PageButtons;
