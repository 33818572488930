import { ThemeOptions } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyleClass = makeStyles((theme: ThemeOptions) => ({
  primaryBackground: {
    backgroundColor: theme.palette.primary["main"],
  },
  primaryColor: {
    color: "#fff",
  },
  secondaryBackground: {
    backgroundColor: theme.palette.secondary["main"],
  },
  tableHeader: {
    // backgroundColor: theme.palette.secondary["main"],
    backgroundColor: "#4196eb",
    // backgroundColor: "#eeeff7",
    // color: theme.palette.secondary["contrastText"],
    color: "#fff",
  },
  button: {
    backgroundColor: "#3182dd",
    color: "#fff",
    border: "none",
    outline: "none",
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "#2974c9",
    },
    "&:active": {
      boxShadow: "none",
    },
    boxShadow: "none",
  },
  box: {
    // "& .MuiPaper-root": {
    flex: 1,
    width: "96%",
    mx: "auto",
    border: 1,
    borderRadius: 1,
    borderColor: "grey.200",
    p: 2,
    // },
  },
  error: {
    color: "red",
  },
  divider: {
    // background: "black",
    width: "80%",
    marginLeft: "auto",
  },
  detailPageTableCell: {
    padding: 4,
    borderBottom: 2,
    fontSize: "1rem",
  },
  detailPageTableCellSize: {
    minWidth: 130,
    padding: 2,
  },
  inputStyle: {
    "& label.Mui-focused": {
      display: "none",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiInputLabel-root": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: 0,
    },
  },
  inputPlaceholderStyle: {
    "&::placeholder": {
      fontSize: "14px",
    },
  },
}));
