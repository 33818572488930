import { Box, CardMedia, Grid, Typography } from "@mui/material";
import logo from "media/images/solpay-logo.png";

function FooterCopyright() {
  return (
    <>
      <Box
        component="footer"
        sx={{
          py: 3,
          px: 2,
          mt: "auto",
          backgroundColor: "#1976d2",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={2}
        >
          <Grid item xs={4} md={4}>
            <CardMedia
              component="img"
              sx={{ width: 151, mx: 4 }}
              image={logo}
              alt="Solpay Logo"
            />
          </Grid>
          <Grid
            item
            md={8}
            sx={{ display: { xs: "none", sm: "none", md: "block" } }}
          >
            <Typography variant="subtitle2" color="#fff">
              {
                "Copyright © 2014-2020 Solpay. All rights reserved. Developed By BrisByte ABN 60 156 196 897. Solpay offers online payroll accounting. Our payroll software requires no installation, is cloud based, and easy to use. Our office is located at Suite F8, 101 Rookwood Road, Yagoona NSW 2199."
              }
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default FooterCopyright;
