// import AdminPageMenuHeader from "libComp/pageMenuHeaders/adminMenuHeader";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as tenantActions } from "@redux/tnt/tenantRedux";
import tenantDataAccess from "dataAccess/tnt/tenantDataAccess";
import { InputFieldOneRow } from "libComp/common/inputRenderes";
import { PageTabsMuiForList } from "libComp/sections/pageTabsMui";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import PackageChange from "./packageChange";

interface IProps {
  id: number;
  organization: any;
  history: any;
}
function AddEditOrganization(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const orgId = props.match.params.id;
  const dispatch = useDispatch();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  useEffect(() => {
    if (orgId !== "0") dispatch(tenantActions.getById(orgId));
  }, [orgId]);

  const style = useStyleClass();
  const [value, setValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };
  const tabItems = [
    {
      title: "Edit Organisation",

      // component: (
      //   <TabUpsertEmployee employeeId={employeeId} history={props.history} />
      // ),
      value: 0,
    },
    {
      title: "Access Package",
      // component: (
      //   <TabUpsertEmployee employeeId={employeeId} history={props.history} />
      // ),
      value: 1,
    },
  ];
  const onFormSubmit = (model) => {
    return tenantDataAccess
      .saveOrganization(model)(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Organization has been saved!", { variant: "success" });
        props.history.push("/admin/organizations");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  let MenuItems = [];
  MenuItems = [
    {
      title: "Admin",
      to: null,
    },
    {
      title: "Organizations",
      to: "/admin/organizations",
    },
    {
      title: "Edit Organisaton",
      to: null,
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} /> <br />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader
          header={
            orgId === "0"
              ? "New Organisation"
              : "Edit Organisaton " + props.organization.name
          }
          buttons={[]}
        />
        <PageTabsMuiForList
          tabItems={tabItems}
          handleTabChange={handleTabChange}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        {selectedTabIndex === 0 && (
          <Grid container sx={{ width: "90%" }}>
            <Grid item xl={6} md={6}>
              <form
                noValidate
                onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
              >
                <Field
                  name="name"
                  component={InputFieldOneRow}
                  label="Name"
                  placeholder="Name"
                  validate={required}
                />
                <Field
                  name="abn"
                  component={InputFieldOneRow}
                  label="ABN"
                  placeholder="ABN"
                />
                <Field
                  name="acn"
                  component={InputFieldOneRow}
                  label="ACN"
                  placeholder="ACN"
                />
                {props.error && (
                  <div className="error-message">{props.error}</div>
                )}

                <Divider />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    variant="contained"
                    type="submit"
                    classes={{ root: style.button }}
                    sx={{ m: 2 }}
                    disabled={props.submitting}
                  >
                    {props.submitting ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    ) : (
                      <i className="fa fa-check"></i>
                    )}{" "}
                    Save
                  </Button>
                </Box>
              </form>
            </Grid>
          </Grid>
        )}
        {selectedTabIndex === 1 && orgId !== "0" && (
          <PackageChange history={props.history}></PackageChange>
        )}
      </Box>
    </>
  );
}

const addEditOrganization = reduxForm<any, IProps>({
  form: "AddEditOrganization",
  enableReinitialize: true,
})(AddEditOrganization);

const mapStateToProps = (state, ownProps) => ({
  organization: state.tenant.organization,
  initialValues:
    ownProps.match.params.id === "0" ? {} : state.tenant.organization,
});

export default connect(mapStateToProps)(addEditOrganization);
