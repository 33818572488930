import groupBy from '@redux/bkp/transactionRedux';
import {
  BILL_ADD_TO_LIST,
  BILL_FAILED,
  BILL_LOADING,
  BILL_REMOVE_UI_MESSAGE,
  CLEAN_BILL,
  GET_BILL,
  GET_LOOKUPDATA
} from './billTypes';

export interface IBillState {
  bills: Array<any>;
  purchaseBillDetails: Array<any>;
  bankAccountList: Array<any>;
  bill: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  supplierList: Array<any>;
  productList: Array<any>;
  accountList: Array<any>;
  taxList: Array<any>;
  selectedAccountModel: any;
  accountListWithoutGroup: Array<any>;
  currentBill: string;
}

const initialState: IBillState = {
  bills: [],
  purchaseBillDetails: [],
  bill: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
  supplierList: [],
  productList: [],
  accountList: [],
  taxList: [],
  selectedAccountModel: {},
  currentBill: '',
  accountListWithoutGroup: [],
  bankAccountList: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IBillState = initialState,
  action: Action
): IBillState => {
  switch (action.type) {
    case CLEAN_BILL:
      if (
        state.bill.id &&
        state.bill.id.toString() === action.payload.toString()
      ) {
        return state;
      } else {
        return { ...state, uiMessages: [], bill: {} };
      }
    case BILL_ADD_TO_LIST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        bills: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case GET_LOOKUPDATA:
      return {
        ...state,
        supplierList: action.payload.lookUpData.supplierListmodels,
        productList: action.payload.lookUpData.productListmodels,
        accountList: Object.entries(
          groupBy(action.payload.lookUpData.accountListmodels, (t) => t.type)
        ).map(([label, options]) => ({ label, options })),
        taxList: action.payload.lookUpData.taxListmodels,
        bankAccountList: Object.entries(
          groupBy(
            action.payload.lookUpData.bankAccountListmodels,
            (t) => t.type
          )
        ).map(([label, options]) => ({ label, options })),
        currentBill: action.payload.lookUpData.currentBill,
        selectedAccountModel: action.payload.lookUpData.selectedAccountModel,
        accountListWithoutGroup: action.payload.lookUpData.accountListmodels,
      };
    case GET_BILL:
      return {
        ...state,
        isLoading: false,
        bill: action.payload,
      };
    case BILL_REMOVE_UI_MESSAGE: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case BILL_LOADING:
      return { ...state, isLoading: true, isError: false };

    case BILL_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
