import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BusinessCard from '../cards/businessCard';
import imageEasy from 'media/images/easy-n.png';
import imageCloud from 'media/images/cloud-n.png';
import imageReport from 'media/images/report-n.png';
import imageNoContract from 'media/images/icon-nocontract.png';
import imageData from 'media/images/data-n.png';
import imageSupport from 'media/images/support-n.png';

function BusinessFit() {
  return (
    <div className="section">
      <Container>
        <Row>
          <Col>
            <h2 className="text-center mb-5">How solpay fits your business</h2>
            <Row>
              <BusinessCard
                imageSrc={imageEasy}
                imageAlt="Easy to Use in Solpay"
                header="Easy to Use"
                description="With even basic computer skills you will be able to easily complete payroll an invoicing tasks check out our support page for informative videos."
              />
              <BusinessCard
                imageSrc={imageCloud}
                imageAlt="Cloud base Technology in Solpay"
                header="Cloud base Technology"
                description="Gives you the freedom to complete your payroll tasks securely and confidentially from home, interstate, overseas or wherever you may be."
              />
              <BusinessCard
                imageSrc={imageReport}
                imageAlt="Comprehensive Reporting in Solpay"
                header="Comprehensive Reporting"
                description="Solpay is able to generate a large range of easily produced, comprehensive reports which ensure that your business compiles with all current statutory obligations."
              />
              <BusinessCard
                imageSrc={imageNoContract}
                imageAlt="No contracts in Solpay"
                header="No Contracts"
                description="SolPay accounting software comes with a no obligation and no lock in contract arrangement. You can cancel/upgrade anytime as required."
              />
              <BusinessCard
                imageSrc={imageData}
                imageAlt="Australian Data center in Solpay"
                header="Australian Data center"
                description="Solpay uses and Australian Data Center which means that all data has maximum protection and confidentiality. as well as quick access and less downtime."
              />
              <BusinessCard
                imageSrc={imageSupport}
                imageAlt="Australian Support Team in Solpay"
                header="Australian Support Team"
                description="Our support centre is based in Australia and we ensure that our clients receive the best service possible."
              />
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default BusinessFit;
