import { Edit, Mail, Print } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import moment from "moment";

export const getBillListTableColumns = (props, extra = {}) => {
  const columns: GridColumns = [
    {
      headerName: "Bill No",
      field: "billNumber",
    },
    {
      headerName: "Supplier Name",
      field: "supplierName",
    },
    {
      headerName: "Bill Date",
      field: "purchaseDate",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Bill Amount",
      field: "totalPayment",
      sortable: false,
    },
    {
      headerName: "Paid Amount",
      field: "paidAmount",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      width: 120,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div key={"btn_panel" + params.row.id}>
            <IconButton
              size="small"
              title="Edit"
              onClick={() => {
                props.history.push(
                  "/accounting/purchase/bill/addedit/" + params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>
            <IconButton size="small" title="Print">
              <Print />
            </IconButton>
            <IconButton size="small" title="Mail">
              <Mail />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
