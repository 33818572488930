import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { IReduxState } from "@redux";
import { actions as paymentActions } from "@redux/pay/paymentRedux";
import paymentDataAccess from "dataAccess/pay/paymentDataAccess";
import useTrait from "libComp/common/syncUpdateState";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { change, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

const ChangePackage = (props) => {
  const [warning, setWarning] = React.useState(false);

  // console.log("_props", props.initialValues);
  const packageValue = useTrait("");
  const packages = useTrait("");
  const [billValue, setBillValue] = React.useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commonState = useSelector((state: IReduxState) => state.commonState);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props?.data?.monthFrequency) setBillValue(props?.data?.monthFrequency);
    //console.log("month_Frequency", billValue);
  }, [props?.data?.monthFrequency]);

  React.useEffect(() => {
    packages.set(props?.initialValues?.packages?.data);
    if (props?.initialValues?.currentTenantPackage?.length) {
      packageValue.set(props?.initialValues?.currentTenantPackage[0]?.id);
      //console.log(props?.initialValues?.currentTenantPackage[0]);
    }
    // if (props?.data?.packageId) packageValue.set(props?.data?.packageId);
    //console.log("month_Frequency", billValue);
  }, [
    props?.initialValues?.packages,
    props?.initialValues?.currentTenantPackage,
  ]);

  React.useEffect(() => {
    if (packageValue === null) setWarning(true);
    dispatch(paymentActions.getPackageData());

    // setPackageValue(props?.data?.packageId);
    // setBillValue(props?.data?.monthFrequency);
  }, [billValue]);

  const changePackeges = (e) => {
    // console.log("previous_value", billValue);
    //console.log(e.target.value);
    packageValue.set(e.target.value);
    dispatch(change("tabChangePackage", `packageId`, e.target.value));
  };

  const changeBills = (e) => {
    setBillValue(e.target.value);
    dispatch(change("tabChangePackage", `monthFrequency`, e.target.value));
  };

  const onFormSubmit = (model) => {
    return paymentDataAccess
      .updatePackage(model)(commonState.commonActions)
      .then((data) => {
        //console.log("res", data);
        if (data.success) {
          //console.log("success", data);
          enqueueSnackbar("Package has been changed!", { variant: "success" });
          dispatch(paymentActions.getPackgesForChange(0));
        }
      })
      .catch((error: Error) => {
        enqueueSnackbar("Package could not been saved!", {
          variant: "error",
        });
        throw new SubmissionError({ _error: error.message });
      });
  };

  // const packages = props?.initialValues?.packages?.data;

  const billingFreq = [
    { id: "1", name: "Monthly" },
    { id: "6", name: "Half Yearly" },
    { id: "12", name: "Yearly" },
  ];

  const style = useStyleClass();

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Settings",
      to: null,
    },
    {
      title: "Payment",
      to: "/settings/subscription/payments",
    },
    {
      title: "Packages",
      to: "/accounting/sales/invoices",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />

      <div className="page-container">
        <PageTitleHeader header="Change Package" />
        {warning && (
          <React.Fragment>
            <Alert
              variant="filled"
              severity="warning"
              onClose={() => {
                setWarning(false);
              }}
            >
              You Must Select a Package, Please Contact Us for a Quote!
            </Alert>
            <br />
          </React.Fragment>
        )}
        <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
          <form
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
            noValidate
          >
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                {/* <div>
                  <Field name="sex" component={solpayRadioField}>
                    <Radio value="male" />
                    <Radio value="female" />
                  </Field>
                </div> */}
                <FormControl>
                  <FormLabel
                    sx={{ fontSize: "1.3em", fontWeight: 700, borderBottom: 2 }}
                    id="demo-controlled-radio-buttons-group"
                  >
                    Packages
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={packageValue?.get()}
                    onChange={changePackeges}
                    // defaultValue={
                    //   packageValue ? packageValue : defaultCheckedPackage
                    // }
                  >
                    {packages?.get() &&
                      packages
                        ?.get()
                        .map((item, num) => (
                          <FormControlLabel
                            key={"package_" + num}
                            value={item.id}
                            control={<Radio />}
                            label={item.description}
                          />
                        ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <FormLabel
                    sx={{ fontSize: "1.3em", fontWeight: 700, borderBottom: 2 }}
                    id="demo-controlled-radio-buttons-group"
                  >
                    Billing Frequency
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    onChange={changeBills}
                    value={billValue}
                  >
                    {billingFreq.map((item, num) => (
                      <FormControlLabel
                        key={num}
                        value={item.id}
                        control={<Radio />}
                        label={item.name}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xl={12} md={12}>
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
};

const tabChangePackage = reduxForm<any>({
  form: "tabChangePackage",
  enableReinitialize: true,
})(ChangePackage);
const mapStateToProps = (state, ownProps) => ({
  data: { ...state.payment.packages },
  initialValues: state.payment.packages,
});

export default connect(mapStateToProps)(tabChangePackage);
