import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import { IReduxState } from "@redux";
import { fetchBilllookUpData } from "@redux/bill/billActions";
import { IBillState } from "@redux/bill/billReducer";
import {
  InputFieldWithoutLabelMui,
  InputHidden,
} from "libComp/common/inputRenderes";
import SolpayModal from "libComp/common/solpayModal";
import SolpayReactSelectIconWithoutLabel from "libComp/common/solpayModalWithoutLabel";
import SolpayReactSelectWithoutLabelForm from "libComp/common/SolpayReactSelectWithoutLabelForm";
import ProductFormView from "pages/inv/product/productFormView";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, Field } from "redux-form";
import { required } from "services/validators";

const BillDetalsCart = ({
  fields,
  field,
  index,
  discount,
  showProductModal,
  setProduectIndex,
}) => {
  const dispatch = useDispatch();

  //console.log("FIELDS", fields);
  //console.log("FIELD", field);

  const commonState = useSelector((state: IReduxState) => state.commonState);

  let subtotal = 0;
  let totalDiscount = 0;
  let totalTax = 0;
  let totalPayment = 0;
  let totalIngst = 0;

  let productDesctiption = fields.get(index)
    ? fields.get(index).productDesctiption
    : "";

  let rowProductId = fields.get(index) ? fields.get(index).productId : "";

  let rowSelectedAccuntId = fields.get(index)
    ? fields.get(index).accountId
    : "";

  let rowQuantity =
    fields.get(index) && fields.get(index).quantity
      ? parseFloat(fields.get(index).quantity)
      : 1;

  let rowUnitPrice = fields.get(index)
    ? parseFloat(fields.get(index).unitPrice)
    : 0;

  let rowTaxCodeId = fields.get(index) ? fields.get(index).taxCodeId : 0;

  let rowTaxRate = fields.get(index) ? fields.get(index).taxRate : 0;

  let rowDiscountAmount =
    fields.get(index) && fields.get(index).discountAmount
      ? fields.get(index).discountAmount
      : 0;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let productList = useSelector((state: IBillState) => state.bill.productList);

  const accountList = useSelector(
    (state: IBillState) => state.bill.accountList
  );

  const taxList = useSelector((state: IBillState) => state.bill.taxList);

  const selectedAccountModel = useSelector(
    (state: IBillState) => state.bill.selectedAccountModel
  );

  const accountListWithoutGroup = useSelector(
    (state: IBillState) => state.bill.accountListWithoutGroup
  );

  const resetRowAfterProductSelect = (data) => {
    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].itemDesctiption`,
        data.itemDescription
      )
    );

    dispatch(
      change("BillFormView", `purchaseBillDetails[${index}].productId`, data.id)
    );

    dispatch(
      change("BillFormView", `purchaseBillDetails[${index}].quantity`, 1)
    );

    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].unitPrice`,
        typeof data.purchaseUnitPrice === "undefined"
          ? 0.0
          : data.purchaseUnitPrice
      )
    );

    let accId =
      typeof data.purchaseAccountId === "undefined"
        ? selectedAccountModel.id
        : data.purchaseAccountId;

    dispatch(
      change("BillFormView", `purchaseBillDetails[${index}].accountId`, accId)
    );

    rowTaxCodeId =
      typeof data.purchaseTaxId === "undefined"
        ? setTaxIdByAccountId(accId)
        : data.purchaseTaxId;

    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].taxCodeId`,
        rowTaxCodeId
      )
    ); //need to add setTaxIdByAccountId()

    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].discountAmount`,
        0.0
      )
    );

    const initQuantity =
      typeof data.purchaseUnitPrice !== "undefined"
        ? data.purchaseUnitPrice * 1
        : 0.0;

    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].totalAmount`,
        initQuantity
      )
    );

    rowTaxRate = getTaxRate(rowTaxCodeId);
    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].taxRate`,
        rowTaxRate
      )
    );

    calculateAmout();

    //dispatch(change("BillFormView", `purchaseBillDetails[${index}].taxRate`, initQuantity));
  };
  const CalculateAmountAfterdelete = (index) => {
    fields.remove(index);
    if (fields.length > 0) calculateAmout(true, index);
    if (fields.length === 1) fields.push();
  };

  const setTaxIdByAccountId = (accId) => {
    const seletedAccountId = accId;
    const accountModel = accountListWithoutGroup.find(
      (o) => o.id === seletedAccountId
    );

    return accountModel.taxCodeId !== "undefined" ? accountModel.taxCodeId : 0;
  };

  const setRowQuantity = (val) => {
    dispatch(
      change("BillFormView", `purchaseBillDetails[${index}].quantity`, val)
    );
    calculateAmout();
  };

  const setRowDiscount = (val) => {
    dispatch(
      change(
        "BillFormView",
        `purchaseBillDetails[${index}].discountAmount`,
        val
      )
    );
    calculateAmout();
  };

  const getTaxRate = (id) => {
    let value;
    if (typeof id != "undefined") {
      value = taxList.find((o) => o.id === id);
      return value.rate;
    }
  };

  const calculateAmout = (
    afterDelete: boolean = false,
    deleteIndex: number = -1
  ) => {
    let rowSubtotal = 0.0;
    let rowTotalDiscount = 0.0;
    if (rowQuantity > 0 && rowUnitPrice > 0)
      rowSubtotal = rowQuantity * rowUnitPrice - rowDiscountAmount;
    // if (subtotal !== 0 && isGSTApplicable) gst = subtotal / 10;
    if (rowSubtotal !== 0) fields.get(index).totalAmount = rowSubtotal;
    // if (gst !== 0) fields.get(index).gst = gst;
    // if (subtotal !== 0 || gst !== 0) fields.get(index).amountIncludingGST = subtotal + gst;
    calculateTotalAmount(afterDelete, deleteIndex);
  };

  const calculateTotalAmount = (
    afterDelete: boolean = false,
    deleteIndex: number = -1
  ) => {
    if (fields.length > 0) {
      subtotal = 0;
      totalDiscount = 0;
      totalTax = 0;
      totalPayment = 0;

      fields.forEach((element, index) => {
        if (fields.get(index) && fields.get(index).productId) {
          if (!afterDelete || (afterDelete && deleteIndex !== index)) {
            if (fields.get(index) && fields.get(index).totalAmount)
              subtotal += Number(fields.get(index).totalAmount);
            if (fields.get(index) && fields.get(index).discountAmount)
              totalDiscount += Number(fields.get(index).discountAmount);
            if (
              fields.get(index) &&
              fields.get(index).totalAmount > 0 &&
              fields.get(index).taxRate
            )
              totalTax +=
                (fields.get(index).totalAmount * fields.get(index).taxRate) /
                100;
          } else {
          }
        }
      });

      // let totalDue = subtotal + totalTax;
      let totalDue = subtotal + totalTax - totalPayment;

      dispatch(
        change(
          "BillFormView",
          `discountAmount`,
          Number(totalDiscount).toFixed(2)
        )
      );

      dispatch(change("BillFormView", `subTotal`, subtotal.toFixed(2)));

      dispatch(change("BillFormView", `taxAmount`, totalTax.toFixed(2)));

      dispatch(
        change("BillFormView", `totalPayment`, Number(totalDue).toFixed(2))
      );

      dispatch(change("BillFormView", `totalDue`, Number(totalDue).toFixed(2)));

      dispatch(change("BillFormView", `paidAmount`, totalPayment.toFixed(2)));
    }
  };
  var updateProductList = () => {
    dispatch(fetchBilllookUpData());
    //productList=[...productList,data];
  };

  useEffect(() => {
    if (fields.get(index)) calculateAmout();
  }, [rowQuantity, rowUnitPrice, rowDiscountAmount, rowTaxCodeId]);

  const deleteDetail = (id, index) => {
    //  billDataAccess
    // .deleteInvoiceDetail(id)(commonState.commonActions)
    // .then((data) => {
    //    if (data.success) fields.remove(index);
    // });
  };

  return (
    <>
      <TableRow key={index}>
        <TableCell align="center" style={{ minWidth: 210 }}>
          <Field
            name={`${field}.productId`}
            options={productList}
            onDataSelect={(data) => {
              resetRowAfterProductSelect(data);
            }}
            component={SolpayReactSelectIconWithoutLabel}
            Addlabel="Add a new Product"
            iconEvent={handleShow}
          />
          <Field name={`${field}.productId`} component={InputHidden}></Field>
        </TableCell>
        <TableCell align="center" style={{ minWidth: 180 }}>
          <Field
            name={`${field}.productDesctiption`}
            component={InputFieldWithoutLabelMui}
            type="text"
            label="Description"
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field
            label="Quantity"
            name={`${field}.quantity`}
            component={InputFieldWithoutLabelMui}
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field
            label="Unit Price"
            name={`${field}.unitPrice`}
            component={InputFieldWithoutLabelMui}
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 170 }}>
          <Field
            name={`${field}.accountId`}
            component={SolpayReactSelectWithoutLabelForm}
            onDataSelect={(data) => {
              dispatch(
                change(
                  "BillFormView",
                  `purchaseBillDetails[${index}].accountId`,
                  data.value
                )
              );
            }}
            options={accountList}
            validate={!`${field}.productId` && [required]}
          />
          <Field component={InputHidden} name="accountId" />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 170 }}>
          <Field
            name={`${field}.taxCodeId`}
            component={SolpayReactSelectWithoutLabelForm}
            onDataSelect={(data) => {
              dispatch(
                change(
                  "BillFormView",
                  `purchaseBillDetails[${index}].taxCodeId`,
                  data.value
                )
              );
              dispatch(
                change(
                  "BillFormView",
                  `purchaseBillDetails[${index}].taxRate`,
                  data.rate
                )
              );
            }}
            options={taxList}
            validate={!`${field}.accountId` && [required]}
          />
          <Field component={InputHidden} name="taxCodeId" />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 70 }}>
          <Field
            label="Amount"
            name={`${field}.totalAmount`}
            component={InputFieldWithoutLabelMui}
          />
        </TableCell>
        <TableCell align="center">
          <Stack direction="row">
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={() => {
                fields.push();
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="remove"
              onClick={() => {
                let id = fields.get(index) ? fields.get(index).id : 0;

                CalculateAmountAfterdelete(index);
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>

      {show && (
        <SolpayModal
          onClose={handleClose}
          title="Add Product"
          data={
            <ProductFormView
              showInModal={show}
              productId="0"
              pageTitle="Add Supplier"
              setProduct={(data) => {
                //supplierAdd({label:data.data.fullName,id:data.data.supplierId,value:data.data.supplierId})
                //  dispatch(change("BillFormView", `supplierId`, data.data.supplierId));
                updateProductList();
                //dispatch(change("BillFormView", `purchaseBillDetails[${index}].productId`, data.data.id));
                dispatch(
                  change(
                    "BillFormView",
                    `purchaseBillDetails[${index}].productId`,
                    data.data.id
                  )
                );
                resetRowAfterProductSelect(data.data);
                handleClose();
              }}
            />
          }
        />
      )}
    </>
  );
};
export default BillDetalsCart;
