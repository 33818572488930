import * as React from "react";
// import userIcon from "media/images/blueImages/users.svg";
// import PageButtons from "libComp/sections/pageButtons";

function Contact() {
  // const Buttons = [
  //   {
  //     header: "Supplier",
  //     description: "Manage Suppliers",
  //     to: "/contact/suppliers",
  //     imageSrc: userIcon,
  //     imageAlt: "Suppliers",
  //   },
  //   {
  //     header: "Client",
  //     description: "Manage Client",
  //     to: "/contact/clients",
  //     imageSrc: userIcon,
  //     imageAlt: "Clients",
  //   },
  // ];
  return (
    <div>
      {/* <br></br>
      <PageButtons buttons={Buttons}></PageButtons>; */}
    </div>
  );
}

export default Contact;
