import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const getPayrollRegularPaymentTable = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "name",
      sortable: false,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      sortable: false,
      width: 85,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              title="Edit"
              size="small"
              onClick={() => {
                props.history.push(
                  "/payroll/payroll-settings/paymenthead/addedit/" +
                    params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ];
  return columns;
};

export const getPayrollOverTimePaymentTable = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "name",
      sortable: false,
    },
    {
      headerName: "Description",
      field: "description",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      sortable: false,
      width: 85,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              title="Edit"
              size="small"
              onClick={() => {
                props.history.push(
                  "/payroll/payroll-settings/paymenthead/addedit/" +
                    params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ];
  return columns;
};
