import { ICommonActions } from "@redux/commonRedux";
import { IFilter } from "@redux/listRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class AgentDataAccess {
    getAgentsList = 
    (pageNo, pageSize, filters: Array<IFilter>, sortPreference) =>
     (commonActions: ICommonActions) => {
            let _filters = {};
            filters.forEach((item) => {
                if (item.value) {
                  _filters["filter." + item.propertyName] =
                    item.value + "-" + item.type;
                }
              });
              return commonDataAccess.getData("Agent/agent-list", {
                pageNo,
                pageSize,
                sortPreference,
                ..._filters,
              })(commonActions);
        };
}

export default new AgentDataAccess();