import { DataGrid, DataGridProps, GridCallbackDetails } from "@mui/x-data-grid";
import { useAppSelector } from "@redux/hook.type";
import { actions as listActions } from "@redux/listRedux";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { useStyleClass } from "styles/theming/useStyleClass";
import "./SolpayTable.scss";

interface SolpayTableProps extends DataGridProps {
  triggerChange?: (
    pageNumber: number,
    pageSize: number,
    sort?: string,
    details?: GridCallbackDetails
  ) => void;
}

const DEFAULT_CONFIG: Partial<SolpayTableProps> = {
  autoHeight: true,
  disableColumnMenu: true,
  disableSelectionOnClick: true,
  rowsPerPageOptions: [25, 50, 100],
  sortingMode: "server",
};

export const SolpayTable = (
  props: Omit<SolpayTableProps, "onPageSizeChange" | "onPageChange">
) => {
  const style = useStyleClass();
  const listState = useAppSelector((state) => state.listState);
  const dispatch = useDispatch();

  let currentPageSize = useRef<number>();
  let currentPageNumber = useRef<number>();

  let config = {
    ...DEFAULT_CONFIG,
    ...props,
  };

  const { triggerChange, ...gridProps } = config;

  currentPageSize.current = listState.pageSize ?? config.rowsPerPageOptions[0];
  currentPageNumber.current = config.page ?? 1;

  let dataColumns = props.columns.map((col) => {
    if (col.width && col.width > 0) {
      return col;
    } else {
      return {
        ...col,
        flex: 1,
      };
    }
  });

  return (
    <div>
      <DataGrid
        {...gridProps}
        initialState={{
          //pagination: { pageSize: config.rowsPerPageOptions[0] },
          pagination: {
            pageSize: config.rowsPerPageOptions[0],
          },
        }}
        columns={dataColumns}
        paginationMode="server"
        onPageChange={(pageNumber, details) => {
          currentPageNumber.current = pageNumber + 1;
          dispatch(listActions.updatePageNo(pageNumber + 1));
          triggerChange(
            pageNumber + 1,
            currentPageSize.current,
            listState.sort,
            details
          );
        }}
        onPageSizeChange={(size, details) => {
          currentPageSize.current = size;
          dispatch(listActions.updatePageSize(size));
          triggerChange(1, size, listState.sort, details);
        }}
        // onSortModelChange={(model: GridSortModel, details) => {
        //   const sortValue =
        //     model.length > 0 ? model[0].field + "-" + model[0].sort : "";
        //   dispatch(listActions.updateSortString(sortValue));
        //   triggerChange(
        //     listState.pageNo,
        //     listState.pageSize,
        //     sortValue,
        //     details
        //   );
        // }}
        classes={{
          columnHeaders: style.tableHeader,
        }}
      />
    </div>
  );
};
