import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import userDataAccess from "dataAccess/user/userDataAccess";
import { Dispatch } from "redux";
import { actions as commonActions } from "../commonRedux";

const typesPrefix = "USERS_";
const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getUsers: typesPrefix + "GET_USERS",
  cleanUsers: typesPrefix + "CLEAN_USERS",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
  getUserDetail: typesPrefix + "GET_USER_DETAIL",
  auditLogs: typesPrefix + "AUDIT_LOGS",
};

export const actions = {
  getUsers:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "UserName-ASC",
      scope = "current"
    ) =>
    (dispatch, getState) => {
      dispatch(commonActions.loading());
      dispatch({ type: types.loading });
      let state: IReduxState = getState();
      userDataAccess
        .getAll(
          pageNo,
          pageSize,
          filters,
          sortPreference,
          scope
        )(state.commonState.commonActions)
        .then((userData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: userData.data.list,
              totalCount: userData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        })
        .finally(() => {
          dispatch(commonActions.loadingEnd());
        });
    },
  getUserDetail: (userId: number) => (dispatch, getState) => {
    let state: IReduxState = getState();
    userDataAccess
      .getUserDetail(userId)(state.commonState.commonActions)
      .then((res) => {
        dispatch({ type: types.getUserDetail, payload: res.data });
      });
  },
  cleanUsers: (userId: string) => (dispatch: Dispatch) => {
    dispatch({ type: types.cleanUsers, payload: userId });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
  auditLogs:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "CreatedOn-DESC",
      scope = "all"
    ) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      userDataAccess
        .getAuditLogs(
          pageNo,
          pageSize,
          filters,
          sortPreference,
          scope
        )(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.auditLogs,
            payload: {
              list: data.data.list,
              totalCount: data.data.totalCount,
            },
          });
        });
    },
};

export interface IUserListState {
  users: Array<any>;
  user: any;
  auditLogs: Array<any>;
  userDetail?: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: IUserListState = {
  users: [],
  user: {},
  auditLogs: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IUserListState = initialState,
  action: Action
): IUserListState => {
  switch (action.type) {
    case types.cleanUsers:
      if (
        state.user.id &&
        state.user.id.toString() === action.payload.toString()
      ) {
        return state;
      } else {
        return { ...state, uiMessages: [], user: {} };
      }
    case types.getUsers:
      return {
        ...state,
        isLoading: false,
        users: action.payload.list,
        uiMessages: action.payload.uiMessages,
      };
    case types.auditLogs:
      return {
        ...state,
        isLoading: false,
        auditLogs: action.payload.list,
        totalCount: action.payload.totalCount,
        uiMessages: action.payload.uiMessages,
      };
    case types.getUserDetail:
      return {
        ...state,
        userDetail: action.payload,
      };
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        users: action.payload.list,
        totalCount: action.payload.totalCount,
      };

    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    default:
      return state;
  }
};
