import * as React from "react";
// import saleIcon from "media/images/blueImages/users.svg";
// import PageButtons from "libComp/sections/pageButtons";

function Accounting() {
  // const Buttons = [
  //   {
  //     header: "Sales",
  //     description: "Manage Invoices",
  //     to: "/accounting/sales",
  //     imageSrc: saleIcon,
  //     imageAlt: "Sales",
  //   },
  //   {
  //     header: "Purchase",
  //     description: "View Purchase",
  //     to: "/accounting/purchases",
  //     imageSrc: saleIcon,
  //     imageAlt: "Purchase",
  //   },
  //   {
  //     header: "Banking",
  //     description: "View Purchase",
  //     to: "/accounting/banking",
  //     imageSrc: saleIcon,
  //     imageAlt: "View Banking",
  //   },
  //   {
  //     header: "Journals",
  //     description: "View Journals",
  //     to: "/accounting/journals",
  //     imageSrc: saleIcon,
  //     imageAlt: "Journals",
  //   },
  //   {
  //     header: "Reports",
  //     description: "View Reports",
  //     to: "/accounting/reports",
  //     imageSrc: saleIcon,
  //     imageAlt: "Reports",
  //   },
  //   {
  //     header: "Settings",
  //     description: "Manage Settings",
  //     to: "/accounting/settings",
  //     imageSrc: saleIcon,
  //     imageAlt: "Purchase",
  //   },
  //   {
  //     header: "Year Closing",
  //     description: "Close Accounts",
  //     to: "/accounting/yearclosing",
  //     imageSrc: saleIcon,
  //     imageAlt: "Year Closing",
  //   },
  // ];

  return (
    <div>
      {/* <br></br> */}
      {/* <PageButtons buttons={Buttons}></PageButtons>; */}
    </div>
  );
}

export default Accounting;
