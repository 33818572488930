import { Box, Stack } from "@mui/material";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { connect } from "react-redux";
import PayRunSummaryTableView from "./PayRunSummaryTableView";

function PayRunSummary(props) {
  const payRunId = props.payrunId || props.match.params.id;

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Previous Pays",
      to: "/payroll/pastpayrun",
    },
    {
      title: "Pay Summary",
      to: null,
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader
          header="Pay Summaries"
          buttons={[
            {
              title: "Back to Previous Pays List",
              onClick: (index) => {
                props.history.push("/payroll/pastpayrun");
              },
              iconName: "fa fa-long-arrow-left",
              id: "proadd1",
            },
          ]}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <PayRunSummaryTableView payRunId={payRunId} />
      </Box>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  payRun: state.payrun.payRun ?? [],
});
export default connect(mapStateToProps)(PayRunSummary);
