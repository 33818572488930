import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as employeeActions } from "@redux/emp/employeeRedux";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import { useSnackbar } from "notistack";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

interface Props {
  employeeId: string;
}
function TabLeaveInfo(props: InjectedFormProps<any, Props> & Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const employeeId = props.employeeId;
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (employeeId === "0") history.push("/payroll/employees");
  }, []);

  const onFormSubmit = (model) => {
    return employeeDataAccess
      .saveLeaveInfo(
        props.employeeId,
        model
      )(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Leave Info has been saved!", { variant: "success" });
        dispatch(employeeActions.getLeaveInfo(employeeId));
      })
      .catch((error: Error) => {
        enqueueSnackbar("Leave Info could not been saved!", {
          variant: "error",
        });
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <>
      <Box sx={{ flex: 1, width: "100%", mx: "auto" }}>
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Field name="id" component={InputHidden} />
          <Field name="tenantId" component={InputHidden} />
          <Field name="employeeId" component={InputHidden} />
          <Field name="createdBy" component={InputHidden} />
          <Field name="createdOn" component={InputHidden} />
          <Grid container spacing={6}>
            <Grid item xl={7} md={7} sm={12} xs={12}>
              <Field
                name="openingAnnualLeave"
                component={InputFieldOneRow}
                label="Opening Annual Leave"
                unit="hours"
                placeholder="Opening Annual Leave"
              />
              <Field
                name="annualEntitlement"
                component={InputFieldOneRow}
                label="Annual Entitlement"
                endAdornment={
                  <InputAdornment position="end">kg</InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                inputProps={{
                  "aria-label": "weight",
                }}
                placeholder="Annual Entitlement"
              />

              <Field
                name="openingPersonallLeave"
                component={InputFieldOneRow}
                label="Opening Personal Leave"
                unit="hours"
                placeholder="Opening Personal Leave"
              />
              <Field
                name="personalLeaveEntitlement"
                component={InputFieldOneRow}
                label="Personal Leave Entitlement"
                unit="hours"
                placeholder="Personal Leave Entitlement"
              />
              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      {/* <Container>
        <Row>
          <Col md={7} sm={7} xs={12}>
            <form onSubmit={props.handleSubmit((data) => onFormSubmit(data))}>
              <Field name="id" component={InputHidden} />
              <Field name="tenantId" component={InputHidden} />
              <Field name="employeeId" component={InputHidden} />
              <Field name="createdBy" component={InputHidden} />
              <Field name="createdOn" component={InputHidden} />
              <Field
                name="openingAnnualLeave"
                component={InputFieldOneRowWithLabel}
                label="Opening Annual Leave"
                unit="hours"
                placeholder="Opening Annual Leave"
              />
              <Field
                name="annualEntitlement"
                component={InputFieldOneRowWithLabel}
                label="Annual Entitlement"
                unit="hours"
                placeholder="Annual Entitlement"
              />

              <Field
                name="openingPersonallLeave"
                component={InputFieldOneRowWithLabel}
                label="Opening Personal Leave"
                unit="hours"
                placeholder="Opening Personal Leave"
              />
              <Field
                name="personalLeaveEntitlement"
                component={InputFieldOneRowWithLabel}
                label="Personal Leave Entitlement"
                unit="hours"
                placeholder="Personal Leave Entitlement"
              />
              <Row>
                <Col md={5}></Col>
                <Col md={7}>
                  {props.error && (
                    <div className="error-message">{props.error}</div>
                  )}
                  <Button type="submit" disabled={props.submitting}>
                    {props.submitting ? (
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                    ) : (
                      <i className="fa fa-check"></i>
                    )}{" "}
                    Save
                  </Button>
                </Col>
              </Row>
            </form>
            <br />
          </Col>
          <Col md={{ span: 5, offset: 0 }} sm={5} xs={12}></Col>
        </Row>
      </Container> */}
    </>
  );
}
const tabLeaveInfo = reduxForm<any, Props>({
  form: "TabLeaveInfo",
  enableReinitialize: true,
})(TabLeaveInfo);
const mapStateToProps = (state, ownProps) => ({
  initialValues: state.employee.leaveInfo,
});

export default connect(mapStateToProps)(tabLeaveInfo);
