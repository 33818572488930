import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as payRunActions, IPayRunState } from "@redux/pyr/payRunRedux";
import payRunDataAccess from "dataAccess/pyr/payrunDataAccess";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { change, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
import PayRunSummaryView from "./payRunSummaryView";
import ReviewPaySlip from "./paySlip";

function ReviewPayRun(props) {
  const style = useStyleClass();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const payrunState: IPayRunState = props.payRun;
  const payrunId = props.match.params.id;
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [paySlipIdList, setpaySlipIdList] = useState([]);
  const [isPrevious, setIsPrevious] = useState(false);
  const [isNext, setIsNext] = useState(true);
  const [isSendEmail, setIsSendEmail] = useState(false);
  const [paySlipId, setPaySlipId] = useState("0");
  const [uiMessage, setUiMessage] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    if (props.paySlips) {
      let splitlist = props.paySlips.split(",");
      setpaySlipIdList(splitlist);
      setCurrentIndex(0);
    }
  }, [props.paySlips]);

  useEffect(() => {
    if (currentIndex > -1) {
      if (currentIndex === 0) setIsPrevious(false);
      else if (currentIndex > 0) setIsPrevious(true);
      if (currentIndex === paySlipIdList.length - 1) setIsNext(false);
      else if (currentIndex < paySlipIdList.length - 1) setIsNext(true);
      let id = paySlipIdList[currentIndex];
      setPaySlipId(id);
    }
  }, [currentIndex]);
  const dispatch = useDispatch();
  useEffect(() => {
    if (payrunId !== "" && payrunId !== "0")
      dispatch(payRunActions.getPayRunToReview(payrunId));
  }, [payrunId]);

  const onFormSubmit = (model) => {
    return payRunDataAccess
      .completePayRunReview(model)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Payrun Review has been saved!", {
            variant: "success",
          });
          props.history.push("/payroll/payrun/summary/" + payrunId);
        } else
          enqueueSnackbar(data.message, {
            variant: "error",
          });
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Pay Run",
      to: "/payroll/payrun/startpay",
    },
    {
      title: "Review Pay Run",
      to: "null",
    },
  ];

  const handleIncrementIndex = (currentIndex) => {
    setCurrentIndex(currentIndex + 1);
  };
  const handledecrementIndex = (currentIndex) => {
    setCurrentIndex(currentIndex - 1);
  };

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header="Review Pay Run" />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          px: 2,
        }}
      >
        <Grid container spacing={10}>
          <Grid item xl={6} md={6}>
            <PayRunSummaryView currentIndex={currentIndex} />
          </Grid>
          <Grid item xl={6} md={6}>
            <Typography variant="subtitle1" gutterBottom component="div">
              Name of Payee:{" "}
              <span style={{ fontSize: "1rem", fontWeight: 450 }}>
                {payrunState.paySlip &&
                  payrunState.paySlip.employee &&
                  payrunState.paySlip.employee.contact.fullname}
              </span>
            </Typography>
            <ReviewPaySlip
              paySlipId={paySlipId}
              isPrevious={isPrevious}
              isNext={isNext}
              currentIndex={currentIndex}
              handleIncrementIndex={handleIncrementIndex}
              handledecrementIndex={handledecrementIndex}
            />
          </Grid>
        </Grid>
        <Grid>
          {props.error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {props.error}
            </Typography>
          )}
        </Grid>
        <Divider />
        <Grid container spacing={10}>
          <Grid item xl={12} md={12}>
            <Box>
              <form
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
                noValidate
              >
                <FormGroup>
                  <FormControlLabel
                    control={<Checkbox defaultChecked={isSendEmail} />}
                    label="Email payslip to employees?"
                    onChange={() => {
                      setIsSendEmail(!isSendEmail);
                      dispatch(
                        change("ReviewPayRun", "isSendEmail", !isSendEmail)
                      );
                    }}
                  />
                </FormGroup>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2, mr: 0 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                  Complete Review &amp; Save
                </Button>
              </form>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
const reviewPayRun = reduxForm<any>({
  form: "ReviewPayRun",
  enableReinitialize: true,
})(ReviewPayRun);
const mapStateToProps = (state: IReduxState, ownProps) => ({
  paySlips:
    state.payrun.payRun && state.payrun.payRun.paySlipIdList
      ? state.payrun.payRun.paySlipIdList
      : "",
  payRun: state.payrun,
  initialValues: {
    ...state.payrun.payRun,
    isSendEmail: false,
  },
});

export default connect(mapStateToProps)(reviewPayRun);
