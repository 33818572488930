import { IReduxState } from "@redux";
import { actions as listActions } from "@redux/listRedux";
import { actions as tenantActions, ITenantState } from "@redux/tnt/tenantRedux";
import { actions as userActions } from "@redux/user/userRedux";
import { defaultPageSize, filterOptions } from "common/constants";
import { default as Filter } from "libComp/sections/filter";
import { PageTabsMuiForList } from "libComp/sections/pageTabsMui";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import React, { useCallback, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getOrgTableColumns } from "./organization-list.config";
import { IListState } from "@redux/listRedux";

function Organizations(props) {
  const [selection, setSelection] = useState(null);
  const dispatch = useDispatch();
  const tenantState: ITenantState = props.tenant;
  const listState: IListState = props.listState;
  const [type, setType] = useState("active");
  const [pageNum, setpageNum] = useState(0);
  const [name, setName] = useState("");
  const [abn, setABN] = useState("");
  const [acn, setACN] = useState("");
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const title = "Organisation List";

  const userRedux = useSelector((state: IReduxState) => state.user);

  useEffect(() => {
    dispatch(listActions.updateSortString("Name ASC"));
    dispatch(tenantActions.getAll(type));
    //console.log(type);
  }, [type, name]);

  const getTenantList = useCallback(
    (
      orgType = type,
      pageNo = pageNum,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "Name-ASC"
    ) => {
      //console.log(orgType);
      dispatch(
        tenantActions.getAll(
          orgType,
          filters,
          pageNo + 1,
          pageSize,
          sortPreference
        )
      );
    },
    [selection]
  );

  let switchTenant = {
    onSwitchTenantAction: (id) => {
      return dispatch(userActions.alterOrg(id));
    },
  };

  const tableColumns = getOrgTableColumns(props, switchTenant);
  const orgButtons = [
    {
      title: "Add Organization",
      onClick: (index) => {
        props.history.push("/admin/organization/addedit/0");
      },
      iconName: "fa fa-plus",
      id: "orgadd1",
    },
  ];
  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
      setType("active");
      setpageNum(0);
    }
    if (newValue === 1) {
      setType("unsubscribed");
      setpageNum(0);
    }
    if (newValue === 2) {
      setType("inactive");
      setpageNum(0);
    }
    if (newValue === 3) {
      setType("incompletereg");
      setpageNum(0);
    }
    if (newValue === 4) {
      setType("neworganization");
      setpageNum(0);
    }
    if (newValue === 5) {
      setType("donothavecard");
      setpageNum(0);
    }
  };

  const tabItems = [
    {
      title: "Active",
      value: 0,
    },
    {
      title: "Unsubscribed",
      value: 1,
    },
    {
      title: "Inactive",
      value: 2,
    },
    {
      title: "Incomplete Reg",
      value: 3,
    },
    {
      title: "New Organizations",
      value: 4,
    },
    {
      title: "Don't have card",
      value: 5,
    },
  ];

  return (
    <div className="page-container">
      <PageTitleHeader header={title} buttons={orgButtons} />

      <Filter
        filterOptions={[
          {
            propertyName: "Name",
            placeholder: "Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ABN",
            placeholder: "ABN",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ACN",
            placeholder: "ACN",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            tenantActions.getAll(type, filters, pageNo, pageSize, sortData)
          );
        }}
      />
      <PageTabsMuiForList
        tabItems={tabItems}
        handleTabChange={handleTabChange}
      />
      {/* <Container fluid className="inner-container form-element">
        <span className="single-filter-container">
          <Fragment>
            <Form.Control
              className="filter-form-control"
              onChange={(event) => {
                setName(event.target.value);
              }}
              placeholder="Name"
            />
          </Fragment>
        </span>
      </Container> */}
      {/* <Container fluid className="inner-container form-element">
        {props.listState.filters.map((item, index) => (
          <span className="single-filter-container" key={index}>
            <Form.Control
              className="filter-form-control"
              value={item.value}
              onChange={(event) => {
                setName(event.target.value);
              }}
              placeholder="Name"
            />
          </span>
        ))}
      </Container> */}

      <SolpayGrid
        columns={tableColumns}
        rows={tenantState.organizations}
        rowCount={tenantState.totalCount}
        loading={tenantState.isLoading}
        loadData={getTenantList}
        pageNo={pageNum}
        triggerChange={(pageNumber, pageSize, sort) => {
          setpageNum(pageNumber);
          //console.log(pageNumber, pageSize, sort);
          getTenantList(type, pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}
const mapStateToProps = ({ tenant, listState }) => ({
  tenant,
  listState,
});

export default connect(mapStateToProps)(Organizations);
