import { IReduxState } from "@redux";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "services/validators";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import { useStyleClass } from "styles/theming/useStyleClass";
import { Alert, Box, Grid, Button, CircularProgress } from "@mui/material";
import DetailedReportDataAccess from "dataAccess/pyr/detailedReportDataAccess";
import { SavePPFFromBiteArray } from "services/commonService";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useState, useRef } from "react";

function DetailedReport(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [uiMessage, setUiMessage] = useState([]);
  const style = useStyleClass();
  const formRef = useRef<HTMLFormElement>(null);
  const onFormSubmit = async (model) => {
    try {
      const reportResponse =
        await DetailedReportDataAccess.detailsReportGenerate(model)(
          commonState.commonActions
        );
      SavePPFFromBiteArray("PayrollDetails.pdf", reportResponse);
      formRef.current?.reset();
    } catch (error) {
      //console.log(error);
    } 
  };

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Reports",
      to: "/payroll/Report",
    },
    {
      title: "Details Report",
      to: "/payroll/Report/DetailedReport",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="Details Report" />
        <Box
          sx={{
            flex: 1,
            width: "96%",
            mx: "auto",
            borderColor: "grey.200",
            p: 2,
            mt: 2,
          }}
        >
          <form
            ref={formRef} 
            noValidate
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            {uiMessage.map((item, index) => (
              <>
                <Alert
                  key={index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    setUiMessage([]);
                  }}
                >
                  {item.messageText}
                </Alert>
                <br />
              </>
            ))}
            <Grid container spacing={6}>
              <Grid item sm={12} md={6}>
                <Field
                  name="startDate"
                  component={DatePickerFieldOneRow}
                  label="From"
                  validate={required}
                />
                <Field
                  name="endDate"
                  component={DatePickerFieldOneRow}
                  label="To"
                  validate={required}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classes={{ root: style.button }}
                    sx={{ mt: 1 }}
                    variant="contained"
                    type="submit"
                    disabled={props.submitting}
                  >
                    {props.submitting ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    ) : (
                      <></>
                    )}{" "}
                    &nbsp;Generate Report
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
}
const detailedReport = reduxForm<any>({
  form: "DetailedReport",
  enableReinitialize: true,
  asyncChangeFields: ["startDate", "endDate"],
})(DetailedReport);
const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});
export default connect(mapStateToProps)(detailedReport);
