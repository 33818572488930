import { Person, AttachMoney, FormatListBulleted } from "@mui/icons-material";
import { useAppSelector } from "@redux/hook.type";
import { actions, IPaymentState } from "@redux/pay/paymentRedux";
import { filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTabsMui, { PageTabsMuiForList } from "libComp/sections/pageTabsMui";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import PaidSubscriptions from "./paidSubscriptions";
import { getPaymentListTableColumns } from "./payment-list.config";
import ReceivedPayment from "./receivedPayment";
import UnpaidSubscriptions from "./unpaidSubscriptions";

function PaymentList(props) {
  //console.log(props);
  // const [selection, setSelection] = useState(null);
  // const paymentState: IPaymentState = props.payment;
  const title = "Bill Payment List";
  // const dispatch = useDispatch();
  // const listState = useAppSelector((state) => state.listState);

  // const getPaymentList = useCallback(
  //   (
  //     pageNo = 1,
  //     pageSize = 10,
  //     filters = [],
  //     sortPreference = "TriggeredOn-desc"
  //   ) => {
  //     console.log("list");
  //     dispatch(
  //       actions.getPayments(pageNo + 1, pageSize, filters, sortPreference)
  //     );
  //   },
  //   [selection]
  // );

  const [value, setValue] = useState("receivedPayment");
  //const tableColumns = getPaymentListTableColumns(props);

  const handleTabChange = (event, newValue) => {
    //console.log(newValue);
    setValue(newValue);
  };

  const tabItems = [
    {
      title: (
        <>
          <Person />
          <span>Received Payment</span>
        </>
      ),
      value: 0, //"receivedPayment",
      component: <ReceivedPayment props={props} />,
    },
    {
      title: (
        <>
          <AttachMoney />
          <span>All Subscriptions</span>
        </>
      ),
      value: 1, // "allSubscriptions",
      component: <PaidSubscriptions props={props} />,
    },
    {
      title: (
        <>
          <FormatListBulleted />
          <span>Unpaid Subscriptions</span>
        </>
      ),
      value: 2, //"unpaidSubscriptions",
      component: <UnpaidSubscriptions props={props} />,
    },
  ];
  return (
    <div className="page-container">
      <PageTitleHeader header={title} />
      <PageTabsMui tabItems={tabItems} handleTabChange={handleTabChange} />
      {/* {value === "receivedPayment" && <ReceivedPayment props={props} />}
      {value === "allSubscriptions" && <ReceivedPayment props={props} />}
      {value === "unpaidSubscriptions" && <ReceivedPayment props={props} />} */}
      {/* <SolpayGrid
        columns={tableColumns}
        rows={paymentState.payments}
        rowCount={paymentState.totalCount}
        loading={paymentState.isLoading}
        loadData={getPaymentList}
        triggerChange={(pageNumber, pageSize, sort) => {
          //console.log("page number", pageNumber);
          getPaymentList(pageNumber, pageSize, listState.filters, sort);
        }}
      /> */}
    </div>
  );
}
const mapStateToProps = ({ payment, listState }) => ({
  payment,
  listState,
});
export default connect(mapStateToProps)(PaymentList);
