import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class SummaryReportDataAccess {
  summaryReportGenerate = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postDataForPdf(
      "Payroll/summaryReport",
      data
    )(commonActions);
  };
}

export default new SummaryReportDataAccess();
