import { IReduxState } from "@redux";
import React from "react";
import { connect } from "react-redux";
import Header from "../newLayout/header/header";
// import PageMenuTitle from "../newLayout/pageTitleHeaders/pageMenuTitle";
import Footer from "./footer";

function Layout(props) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: "1 0 auto",
        overflow: "hidden",
        height: "100%",
      }}
    >
      <Header />

      <div style={{ height: "100%", overflow: "hidden" }}>
        <section
          className="wrapper"
          style={{
            height: "100%",
            display: "flex",
            flex: "1 1 auto",
            overflow: "hidden",
          }}
        >
          <div
            className="spaceCreator"
            style={{
              display: "flex",
              flex: "1 0 auto",
              overflowY: "auto",
              flexDirection: "column",
            }}
          >
            {/* <PageMenuTitle /> */}
            <div style={{ flex: "1 0 auto" }}>
              {props.isLoading && <div className="loader"></div>}
              {props.children}
            </div>
            <Footer />
          </div>
        </section>
      </div>
    </div>
  );
}

const mapStatetoProps = (state: IReduxState, ownProps) => ({
  isLoading: state.commonState.isLoading,
});
export default connect(mapStatetoProps)(Layout);
