import { Box, Stack } from "@mui/material";
import { actions as employeeActions } from "@redux/emp/employeeRedux";
import PageTabsMui from "libComp/sections/pageTabsMui";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import React, {useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import TabAdditionDeduction from "./additionDeduction";
import TabUpsertEmployee from "./employee";
import TabLeaveInfo from "./leaveInfo";
import TabPaySlips from "./paySlips";
import TabPaySuperInfo from "./paySuperInfo";
import TabTaxInfo from "./taxInfo";
import { defaultPageSize } from "common/constants";


function AddEditEmployee(props) {
  const [selection, setSelection] = useState(null);
  const employeeId = props.match.params.employeeId;
  const dispatch = useDispatch();
  let employee = props.employee;

  const history = useHistory();
  const exceeded = employee?.employeeType?.message;

  useEffect(() => {
    dispatch(employeeActions.getEmployeeType());
    dispatch(employeeActions.cleanEmployee(employeeId));
    if (employeeId !== "0") {
      dispatch(employeeActions.getEmployee(employeeId));
      dispatch(employeeActions.getPayAndSuperInfo(employeeId));
      dispatch(employeeActions.getTaxInfo(employeeId));
      dispatch(employeeActions.getLeaveInfo(employeeId));
      dispatch(employeeActions.getAdditionDeduction(employeeId));
      dispatch(employeeActions.getPaySlips(employeeId));
    }
  }, [employeeId]);

  useEffect(() => {
    if (exceeded && employeeId === "0") history.push("/payroll/employees");
  });

  const [value, setValue] = React.useState(0);
  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const getPaySlipLists = useCallback(
    (pageNo = 1, pageSize = defaultPageSize, filters = [], sortPreference = "") => {
      dispatch(
        employeeActions.getPaySlips(
          employeeId,
          pageNo + 1,
          pageSize,
          filters,
          sortPreference
        )
      );
    },
    [selection]
  );

  // Breadcrumb
  let MenuItems = [];

  MenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Employees",
      to: "/payroll/employees",
    },
  ];

  if (employeeId > 0) {
    MenuItems.push({
      title: "Edit Employee",
      to: "/payroll/employee/addedit/{employeeId}",
    });
  } else {
    MenuItems.push({
      title: "Add Employee",
      to: "/payroll/employee/addedit/0",
    });
  }

  let tabItems;

  if (employeeId == 0) {
    tabItems = [
      {
        title: "Employee",
        component: (
          <TabUpsertEmployee employeeId={employeeId} history={props.history} />
        ),
        value: 0,
        disabled: false,
      },
      {
        title: "Pay & Super Info",
        component: <TabPaySuperInfo employeeId={employeeId} />,
        value: 1,
        disabled: true,
      },
      {
        title: "Tax Info",
        component: <TabTaxInfo employeeId={employeeId} />,
        value: 2,
        disabled: true,
      },
      {
        title: "Leave Info",
        component: <TabLeaveInfo employeeId={employeeId} />,
        value: 3,
        disabled: true,
      },
      {
        title: "Additions / Deductions",
        component: <TabAdditionDeduction employeeId={employeeId} />,
        value: 4,
        disabled: true,
      },
      {
        title: "Pay Slips",
        component: <TabPaySlips employeeId={employeeId} />,
        value: 5,
        disabled: true,
      },
    ];
  } else {
    tabItems = [
      {
        title: "Employee",
        component: (
          <TabUpsertEmployee employeeId={employeeId} history={props.history} />
        ),
        value: 0,
        disabled: false,
      },
      {
        title: "Pay & Super Info",
        component: <TabPaySuperInfo employeeId={employeeId} />,
        value: 1,
        disabled: false,
      },
      {
        title: "Tax Info",
        component: <TabTaxInfo employeeId={employeeId} />,
        value: 2,
        disabled: false,
      },
      {
        title: "Leave Info",
        component: <TabLeaveInfo employeeId={employeeId} />,
        value: 3,
        disabled: false,
      },
      {
        title: "Additions / Deductions",
        component: <TabAdditionDeduction employeeId={employeeId} />,
        value: 4,
        disabled: false,
      },
      {
        title: "Pay Slips",
        component: <TabPaySlips employeeId={employeeId} />,
        value: 5,
        disabled: false,
      },
    ];
  }

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} />
      <Box
        sx={{
          flex: 1,
          width: "100%",
          mx: "auto",
          padding: "1em 0",
          minHeight: "75%",
        }}
      >
        <Stack sx={{ width: "96%", mx: "auto" }}>
          <PageTitleHeader
            header={
              employeeId === "0"
                ? "Add Employee"
                : typeof employee.employee.contact === "undefined"
                ? "Edit Employee"
                : `Edit Employee: ${employee.employee.contact.fullname}`
            }
          />
        </Stack>

        <PageTabsMui
          employeeId={employeeId}
          tabItems={tabItems}
          handleTabChange={handleTabChange}
        />
      </Box>
      <br />
    </>
  );
}

const mapStateToProps = (state) => ({
  employee: state.employee,
});

export default connect(mapStateToProps)(AddEditEmployee);
