import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const productListTable = (props, extra: any) => {
  const columns: GridColumns = [
    {
      headerName: "Product Name",
      field: "productName",
    },
    {
      headerName: "Product Code",
      field: "productCode",
    },
    {
      headerName: "Service?",
      field: "service",
      sortable: false,
    },
    {
      headerName: "Price",
      field: "price",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      width: 90,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="">
            <IconButton
              size="small"
              onClick={() => {
                props.history.push(
                  "/accounting/purchase/product/addedit/" + params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>

            <IconButton
              size="small"
              onClick={() => {
                extra.onDeleteAction(params.row.id);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
