import { Dispatch } from '@reduxjs/toolkit';
import productDataAccess from 'dataAccess/inv/productDataAccess';
import { IReduxState } from '@redux';
import products from 'pages/inv/product/products';
const typesPrefix = 'PRODUCT_';
const types = {
  addToList: typesPrefix + 'ADD_TO_LIST',
  getProduct: typesPrefix + 'GET_PRODUCT',
  failed: typesPrefix + 'FAILED',
  loading: typesPrefix + 'LOADING',
  removeUiMessage: typesPrefix + 'REMOVE_UI_MESSAGE',
};

export class Ptoduct {
  Id: number;
  TenantId: number;
  ProductName: string;
  ProductCode: string;
  Price: number;
  IsGSTApplicable: boolean;
  IsService: boolean;
  PurchaseUnitPrice: number;
  PurchaseAccountId: number | null;
  PurchaseTaxId: number | null;
  PurchaseDescription: string;
  SalesAccountId: number | null;
  SaleTaxId: number | null;
  SalesDescription: string;
  ProductImage: string;
  IsActive: boolean;
  IsDeleted: boolean;
  QuantityOnHand: number;
  IsTraceable: boolean;
}

export const actions = {
  getProducts:
    (pageNo = 1, pageSize = 10, filters = [], sortPreference = 'id-desc') =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      productDataAccess
        .getProductList(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((productData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: productData.data.list,
              totalCount: productData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },
  getProduct: (id: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    productDataAccess
      .getProduct(id)(state.commonState.commonActions)
      .then((productData) => {
        dispatch({
          type: types.getProduct,
          payload: productData.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};
export interface IProductState {
  products: Array<any>;
  product: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: IProductState = {
  products: [],
  product: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IProductState = initialState,
  action: Action
): IProductState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        products: action.payload.list,
        product: [],
        totalCount: action.payload.totalCount,
      };
    case types.getProduct:
      return {
        ...state,
        isLoading: false,
        product: action.payload,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
