import { defaultPageSize } from 'common/constants';
const typesPrefix = 'LIST_';
const types = {
  reinitialize: typesPrefix + 'REINITIALIZE',
  updatePageNo: typesPrefix + 'UPDATE_PAGE_NO',
  updatePageSize: typesPrefix + 'UPDATE_PAGE_SIZE',
  updateSortString: typesPrefix + 'UPDATE_SORT_STRING',
  initializeFilter: typesPrefix + 'INITIALIZE_FILTER',
  updateFilter: typesPrefix + 'UPDATE_FILTER',
};

export const actions = {
  reinitialize: () => (dispatch) => {
    dispatch({ type: types.reinitialize });
  },
  updatePageNo: (pageNo) => (dispatch) => {
    dispatch({ type: types.updatePageNo, payload: pageNo });
  },
  updatePageSize: (pageSize) => (dispatch) => {
    dispatch({ type: types.updatePageSize, payload: pageSize });
  },
  updateSortString: (sortValue) => (dispatch) => {
    dispatch({ type: types.updateSortString, payload: sortValue });
  },
  initializeFilter: (filterOptions) => (dispatch) => {
    dispatch({ type: types.initializeFilter, payload: filterOptions });
  },
  updateFilter: (filters) => (dispatch) => {
    dispatch({
      type: types.updateFilter,
      payload: filters,
    });
  },
};

export interface IFilter {
  propertyName: string;
  value: string;
  type: string;
  placeholder: string;
}
export interface IListState {
  isLoading: boolean;
  pageNo: number;
  pageSize: number;
  sort: string;
  filters: Array<IFilter>;
}

const initialState = {
  isLoading: true,
  pageNo: 1,
  pageSize: defaultPageSize,
  sort: '',
  filters: [],
};

export const reducer = (
  state: IListState = initialState,
  action: any
): IListState => {
  const { type, payload } = action;

  switch (type) {
    case types.reinitialize: {
      return Object.assign({}, state, initialState);
    }
    case types.updatePageNo:
      return { ...state, pageNo: payload };
    case types.updatePageSize:
      return { ...state, pageSize: payload };
    case types.updateSortString:
      return { ...state, sort: payload };
    case types.initializeFilter: {
      return {
        ...state,
        filters: payload.map((item) => ({
          propertyName: item.propertyName,
          value: '',
          type: item.typeOptions[0].value,
          placeholder: item.placeholder,
        })),
        isLoading: false,
      };
    }
    case types.updateFilter: {
      return { ...state, filters: payload };
    }
    default:
      return state;
  }
};
