import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { IReduxState } from "@redux";
import { actions as employeeActions } from "@redux/emp/employeeRedux";
import { filterOptions } from "common/constants";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import paymentDataAccess from "dataAccess/pyr/payrunDataAccess";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayTable } from "libComp/SolpayTable/SolpayTable";
import SpinnerLocal from "libComp/spinner";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { reduxForm } from "redux-form";
import { getPaySlipTableColumns } from "./payslip-list.config";

interface Props {
  employeeId: string;
}

function TabPaySlips(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showConfirm, setShowConfirm] = useState(false);
  const [paySlipId, setPaySlip] = useState("");
  const history = useHistory();
  const dispatch = useDispatch();
  const employeeId = props.employeeId;
  const commonState = useSelector((state: IReduxState) => state?.commonState);

  const paySlips = useSelector(
    (state: IReduxState) => state?.employee?.paySlips
  );

  const listState = useSelector((state: IReduxState) => state?.listState);

  const paySlipsLoading = useSelector(
    (state: IReduxState) => state?.employee?.paySlipsIsLoading
  );

  const paySlipsError = useSelector(
    (state: IReduxState) => state?.employee?.paySlipsIsError
  );

  const paySlipsErrorMessage = useSelector(
    (state: IReduxState) => state?.employee?.paySlipsErrorMessage
  );

  React.useEffect(() => {
    if (employeeId === "0") history.push("/payroll/employees");
  }, []);

  const onClickPrint = (id) => {
    return paymentDataAccess
      .printPaySlip(id)(commonState?.commonActions)
      .then((response) => {
        enqueueSnackbar("Printed successfully!", { variant: "success" });
        SavePPFFromBiteArray("payslip.pdf", response);
        //FileDownload(response , "payslip.pdf");
      })
      .catch((error) => {
        //console.log(error);
      });
  };
  /**
   * Creates and save a file
   * @param {string} fileName name of the file
   * @param {string} fileContent content of the file
   */
  const SavePPFFromBiteArray = (fileName, fileContent) => {
    const element = document.createElement("a");
    const file = new Blob([fileContent], { type: "application/pdf" });
    if (window.navigator && (window.navigator as any).msSaveBlob) {
      //workaround for ie11
      (window.navigator as any).msSaveBlob(file, fileName);
    } else {
      element.href = URL.createObjectURL(file);
      element.download = fileName; //"myFile.xml"
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
    }
  };

  const deletePaySlip = (id) => {
    employeeDataAccess
      .deletePaySlip(id)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Delete has been saved!", { variant: "success" });
          dispatch(employeeActions.getPaySlips(props.employeeId));
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setShowConfirm(false));
  };

  const mailPaySlip = (id) => {
    employeeDataAccess
      .mailPaySlip(id)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Mail has been sent succssfully!", {
            variant: "success",
          });
          dispatch(employeeActions.getPaySlips(props.employeeId));
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setShowConfirm(false));
  };

  if (!paySlips?.list) {
    return (
      <div>
        <PageTitleHeader header="Pay Slips Here" />
        <SpinnerLocal />
      </div>
    );
  }

  if (paySlipsError) {
    return (
      <div>
        <PageTitleHeader header="Pay Slips Here" />
        <div style={{ padding: 40, color: "red" }}>{paySlipsErrorMessage}</div>
      </div>
    );
  }

  let extraProps = {
    onDeleteAction: (id) => {
      setPaySlip(id);
      setShowConfirm(true);
    },

    onMailAction: (id) => {
      setPaySlip(id);
      mailPaySlip(paySlipId);
    },

    onPrintAction: (id) => {
      setPaySlip(id);
      onClickPrint(id);
    },
  };

  const tableColumns = getPaySlipTableColumns(props, extraProps);

  return (
    <div>
      <PageTitleHeader header="Pay Slips Here" />
      <Filter
        filterOptions={[
          {
            propertyName: "FromDate",
            placeholder: "Pay From Date",
            inputType: "date",
            typeOptions: [filterOptions.greaterThanEqual],
          },
          {
            propertyName: "ToDate",
            placeholder: "Pay To Date",
            inputType: "date",
            typeOptions: [filterOptions.lessThanEqual],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            employeeActions?.getPaySlips(
              props.employeeId,
              pageNo,
              pageSize,
              filters,
              sortData
            )
          );
        }}
      />

      <Dialog
        fullScreen={fullScreen}
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Do you want to delete this pay slip?"}
        </DialogTitle>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)}>No</Button>
          <Button
            onClick={() => {
              deletePaySlip(paySlipId);
              setShowConfirm(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <SolpayTable
        columns={tableColumns}
        rows={paySlips?.list}
        rowCount={paySlips?.totalCount}
        loading={paySlipsLoading}
        triggerChange={(pageNo, pageSize, sort) => {
          dispatch(
            employeeActions.getPaySlips(
              props?.employeeId,
              pageNo,
              pageSize,
              listState?.filters,
              sort
            )
          );
        }}
      />
    </div>
  );
}
const tabPaySlips = reduxForm<any, Props>({
  form: "TabPaySlips",
  enableReinitialize: true,
})(TabPaySlips);
const mapStateToProps = (state, ownProps) => ({
  initialValues: state?.employee?.paySlips,
});

export default connect(mapStateToProps)(tabPaySlips);
