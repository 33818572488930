import groupBy from '@redux/bkp/transactionRedux';
import {
  CLEAN_QUOTATION,
  GET_LOOKUPDATA,
  GET_QUOTATION,
  QUOTATION_ADD_TO_LIST,
  QUOTATION_FAILED,
  QUOTATION_LOADING,
  QUOTATION_REMOVE_UI_MESSAGE,
} from './quotationTypes';

export interface IQuotationState {
  quotations: Array<any>;
  clientQuotationDetails: Array<any>;
  bankAccountList: Array<any>;
  quotation: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  clientList: Array<any>;
  productList: Array<any>;
  accountList: Array<any>;
  taxList: Array<any>;
  selectedAccountModel: any;
  accountListWithoutGroup: Array<any>;
  currentQuotation: string;
}

const initialState: IQuotationState = {
  quotations: [],
  clientQuotationDetails: [],
  quotation: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
  clientList: [],
  productList: [],
  accountList: [],
  taxList: [],
  selectedAccountModel: {},
  currentQuotation: '',
  accountListWithoutGroup: [],
  bankAccountList: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IQuotationState = initialState,
  action: Action
): IQuotationState => {
  switch (action.type) {
    case CLEAN_QUOTATION:
      if (
        state.quotation.id &&
        state.quotation.id.toString() === action.payload.toString()
      ) {
        return state;
      } else {
        return { ...state, uiMessages: [], quotation: {} };
      }
    case QUOTATION_ADD_TO_LIST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        quotations: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case GET_LOOKUPDATA:
      return {
        ...state,
        clientList: action.payload.lookUpData.clientListmodels,
        productList: action.payload.lookUpData.productListmodels,
        // accountList: Object.entries(
        //   groupBy(action.payload.lookUpData.accountListmodels, (t) => t.type)
        // ).map(([label, options]) => ({ label, options })),
        taxList: action.payload.lookUpData.taxListmodels,
        // bankAccountList: Object.entries(
        //   groupBy(
        //     action.payload.lookUpData.bankAccountListmodels,
        //     (t) => t.type
        //   )
        // ).map(([label, options]) => ({ label, options })),
        currentQuotation: action.payload.lookUpData.currentQuotation,
        // selectedAccountModel: action.payload.lookUpData.selectedAccountModel,
        // accountListWithoutGroup: action.payload.lookUpData.accountListmodels,
      };
    case GET_QUOTATION:
      return {
        ...state,
        isLoading: false,
        quotation: action.payload,
      };
    case QUOTATION_REMOVE_UI_MESSAGE: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case QUOTATION_LOADING:
      return { ...state, isLoading: true, isError: false };

    case QUOTATION_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
