import { IReduxState } from "@redux";
import { connect, useSelector } from "react-redux";
import { reduxForm } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { Box, Grid, Button, CircularProgress } from "@mui/material";
import EmployeeLeaveBalanceReportDataAccess from "dataAccess/pyr/employeeLeaveBalanceReportDataAccess";
import { SavePPFFromBiteArray } from "services/commonService";

function EmployeeLeaveBalanceReport(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const style = useStyleClass();

  const onFormSubmit = async (model) => {
    try {
      const reportResponse =
        await EmployeeLeaveBalanceReportDataAccess.employeeLeaveBalanceReportGenerate(model)(
          commonState.commonActions
        );
      SavePPFFromBiteArray("Employee_Leave_Balance.pdf", reportResponse);
    } catch (error) {
      //console.log(error);
    }
  };

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Reports",
      to: "/payroll/Report",
    },
    {
      title: "Employee Leave Balance Report",
      to: "/payroll/Report/EmployeeLeaveBalanceReport",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="Employee Leave Balance Report" />
        <Box
          sx={{
            flex: 1,
            width: "96%",
            mx: "auto",
            borderColor: "grey.200",
            p: 2,
            mt: 2,
          }}
        >
          <form
            noValidate
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            <Grid container spacing={6}>
              <Grid item sm={12} md={6}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classes={{ root: style.button }}
                    sx={{ mt: 1 }}
                    variant="contained"
                    type="submit"
                    disabled={props.submitting}
                  >
                    {props.submitting ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    ) : (
                      <></>
                    )}{" "}
                    &nbsp;Download Report
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
}
const employeeLeaveBalanceReport = reduxForm<any>({
  form: "EmployeeLeaveBalanceReport",
  enableReinitialize: true,
})(EmployeeLeaveBalanceReport);
const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});
export default connect(mapStateToProps)(employeeLeaveBalanceReport);
