const typesSupplierPrefix = 'Supplier_';
export const SUPPLIER_ADD_TO_LIST = typesSupplierPrefix + 'ADD_TO_LIST';
export const GET_SUPPLIER = typesSupplierPrefix + 'GET';
export const CLEAN_SUPPLIER = typesSupplierPrefix + 'CLEAN';
export const SUPPLIER_FAILED = typesSupplierPrefix + 'FAILED';
export const SUPPLIER_LOADING = typesSupplierPrefix + 'LOADING';
export const SUPPLIER_REMOVE_UI_MESSAGE =
  typesSupplierPrefix + 'REMOVE_UI_MESSAGE';
export const SUPPLIER_LOOKUPDATA = typesSupplierPrefix + 'GET_LOOKUPDATA';
export const SUPPLIER_SELECTED = typesSupplierPrefix + 'SELECTED';
