import PageButtons from "libComp/sections/pageButtons";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import imageDollar from "media/images/blueImages/doller.svg";

function Payroll() {
  return (
    <div>
      <PageTitleHeader header="Payroll" />
      <PageButtons
        buttons={[
          {
            header: "Employees",
            description: "Start a payrun now",
            to: "/payroll/employees",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
          {
            header: "Pay",
            description: "Pay the Employees",
            to: "/payroll/payrun/startpay",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
          {
            header: "Previous Pays",
            description: "Previous Payslips",
            to: "/payroll/pastpayrun",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
          {
            header: "Payroll Settings",
            description: "Additional payroll settings",
            to: "/payroll/payroll-settings",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
          {
            header: "Reports",
            description: "View, Print or Email Reports",
            to: "/",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
          {
            header: "Superannuation",
            description: "Superannuation Registration, Payment",
            to: "/payroll/superannuation",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
          {
            header: "Time Sheet & Rostering",
            description: "Time Sheet & Rostering",
            to: "/",
            imageSrc: imageDollar,
            imageAlt: "icon",
          },
        ]}
      />
    </div>
  );
}

export default Payroll;
