import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class PAYGPaymentSummaryReportAccess {
    getAllEmployeesDropdown = () => (commonActions: ICommonActions) => {
      return commonDataAccess.getData('Payroll/prepareEmployeeDropdown')(
        commonActions
      );
    };

    getPrepareYearDropdown = () => (commonActions: ICommonActions) => {
        return commonDataAccess.getData('Payroll/prepareYearDropdown')(
          commonActions
        );
      };

      paygPaymentReportGenerate = (data) => (commonActions: ICommonActions) => {
        return commonDataAccess.postDataForPdf(
          "Payroll/paygPaymentSummaryReport",
          data
        )(commonActions);
      };
  }
  
  export default new PAYGPaymentSummaryReportAccess();