import PageButtons from "libComp/sections/pageButtons";
import payicon from "media/images/blueImages/payroll.svg";
import saleIcon from "media/images/blueImages/users.svg";

const pyrReport = () => {
  const Buttons = [
    {
      header: "Summary Report",
      description: "Generate Payroll Summary Report",
      to: "/payroll/Report/SummaryReport",
      imageSrc: saleIcon,
      imageAlt: "Summary Report",
    },
    {
      header: "Details Report",
      description: "Generate Payroll Details Report for each Employee",
      to: "/payroll/Report/DetailedReport",
      imageSrc: payicon,
      imageAlt: "Details Report",
    },
    {
      header: "PAYG Payment Summary Report",
      description: "Generate PAYG Payment Summary Report for all/individual Employee",
      to: "/payroll/Report/PAYGPaymentSummaryReport",
      imageSrc: saleIcon,
      imageAlt: "PAYG Payment Summary Report",
    },
    {
      header: "Leave Balance Report",
      description: "Generate Leave Balance Report for all/individual Employee",
      to: "/payroll/Report/EmployeeLeaveBalanceReport",
      imageSrc: payicon,
      imageAlt: "Leave Balance Report",
    },
    {
      header: "ATO Report",
      description: "Generate ATO Report (Electronic File)",
      to: "/payroll/Report/ATOReport",
      imageSrc: saleIcon,
      imageAlt: "ATO Report",
    },
    {
      header: "SuperStream Alternative File",
      description: "Generate SuperStream Alternative File",
      to: "/payroll/super-payments",
      imageSrc: payicon,
      imageAlt: "SuperStream Alternative File",
    },
  ];
  return (
    <div>
      <PageButtons buttons={Buttons}></PageButtons>
    </div>
  );
};

export default pyrReport;
