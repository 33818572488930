import PageButtons from "libComp/sections/pageButtons";
import imageUser from "media/images/blueImages/users.svg";

function Admin() {
  return ( 
    <div>
      <PageButtons
        buttons={[
          {
            header: "Organization",
            description: "Manage Organizations / Clients",
            to: "/admin/organizations",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Users",
            description: "Manage Users",
            to: "/admin/users/current",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Agents",
            description: "Manage Agents",
            to: "/admin/agent",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "View Tickets",
            description: "View Tickets",
            to: "#",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "View Payment",
            description: "View Payment List",
            to: "#",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Unsubscribe Requests",
            description: "Unsubscribe Requests List",
            to: "#",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Super Funds",
            description: "Manage Super Funds",
            to: "/admin/superfunds",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Audit Logs (Activity)",
            description: "Audit Logs (Activity)",
            to: "/",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Audit Logs (Data Change)",
            description: "Audit Logs (Data Change)",
            to: "/",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "All Organization's Users ",
            description: "Manage Users ",
            to: "/admin/users/all",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
        ]}
      />
    </div>
  );
}

export default Admin;
