import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";

const UnpaidReports = () => {
  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Reports",
      to: null,
    },
    {
      title: "Sales",
      to: "/reports/sales",
    },
    {
      title: "Unpaid Reports",
      to: "/reports/sales/unpaid-reports",
    },
  ];
  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
    </>
  );
};

export default UnpaidReports;
