import { ExpandLess, ExpandMore } from "@mui/icons-material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Avatar from "@mui/material/Avatar";
import Badge from "@mui/material/Badge";
import Stack from "@mui/material/Stack";
import { styled } from "@mui/material/styles";

import MenuIcon from "@mui/icons-material/Menu";
import {
  Collapse,
  Divider,
  FormControl,
  List,
  ListItemButton,
  Modal,
  Select,
} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { IReduxState } from "@redux";
import { actions as userActions } from "@redux/user/userRedux";
import { localStorageKeys } from "common/constants";
import logo from "media/images/solpay-logo-white.png";
import * as React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { PAGES } from "../Pages";
import {
  MODALSTYLE,
  StyledList,
  useDisableColorStyle,
} from "./customHeaderStyle";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "left",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(-1.4),
    marginLeft: theme.spacing(1.5),
    width: 200,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

type Anchor = "top" | "left" | "bottom" | "right";

const Header = () => {
  const commonState = useSelector((state: IReduxState) => state.commonState);

  // Constants
  const classes = useDisableColorStyle();

  const location = useLocation();
  const history = useHistory();

  const pages = PAGES;
  const modalStyle = MODALSTYLE;

  const userRedux = useSelector((state: IReduxState) => state.user);

  const isSuperAdmin =
    userRedux?.user != null
      ? userRedux?.user?.isSuperAdmin
      : history.push("/login");

  const [tenant, setTenant] = React.useState("");

  const dispatch = useDispatch();

  // States
  const [selectedIndexMenu, setSelectedIndexMenu] = React.useState(-1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorDropDown, setAnchorDropDown] =
    React.useState<null | HTMLElement>(null);
  const [openNestedMenu, setOpenNestedMenu] = React.useState(false);

  const open = Boolean(anchorDropDown);
  const [selectedId, setSelectedId] = React.useState(null);
  const [selecteddrawer, setSelecteddrawer] = React.useState(null);
  const anchorRef = React.useRef(null);
  // Handling Events

  const handleClickNestedMenu = (id) => {
    setSelecteddrawer(id);
    // setOpenNestedMenu(openNestedMenu ? false : true);
    setOpenNestedMenu(true);
  };

  const handleClickDropDown = (event: React.MouseEvent<HTMLElement>, link) => {
    if (link != null) history.push(link);
    setSelectedId(event.currentTarget.id);
    setAnchorDropDown(event.currentTarget);
  };
  const handleCloseDropDown = () => {
    if (anchorElUser != null) {
      setAnchorElUser(null);
    }
    setAnchorDropDown(null);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleChangeTenant = (event) => {
    return dispatch(userActions.alterOrg(event.target.value));
  };

  const handleRouteChange = (link) => {
    if (link != null) {
      history.push(link);
    }
  };

  React.useEffect(() => {
    if (typeof userRedux?.user?.tenantId !== "undefined")
      setTenant(userRedux?.user?.tenantId.toString());
    var user = JSON.parse(localStorage.getItem(localStorageKeys.user));
    if (user != null) {
      user["tenantName"] = userRedux?.user?.tenantName;
      user["tenantId"] = userRedux?.user?.tenantId;
    }

    localStorage.setItem(localStorageKeys.user, JSON.stringify(user));
    history.push(window.location);
  }, [userRedux?.user?.tenantId]);

  // const handleCloseNavMenu = () => {
  //   setAnchorElNav(null);
  // };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleListItemClick = (index) => {
    if (pages[index].to !== null) setSelectedIndexMenu(index);
  };

  // UseEffect

  useEffect(() => {
    if (selectedIndexMenu === -1) setSelectedIndexMenu(0);
    else {
      const pageLength = pages.length;
      for (var i = 0; i < pageLength; i++) {
        if (pages[i].to === location.pathname) {
          setSelectedIndexMenu(i);
          break;
        }
        if (pages[i].to === null) {
          var childrenLength = pages[i].children.length;
          for (var j = 0; j < childrenLength; j++) {
            if (pages[i].children[j].childTo === location.pathname) {
              setSelectedIndexMenu(pages[i].children[j].selectedIndex);
              break;
            } else if (pages[i].children[j].childTo === null) {
              var childSubmenuLength = pages[i].children[j].childSubmenu.length;
              for (var k = 0; k < childSubmenuLength; k++) {
                if (
                  pages[i].children[j].childSubmenu[k].childSubmenuTo ===
                  location.pathname
                ) {
                  setSelectedIndexMenu(
                    pages[i].children[j].childSubmenu[k].selectedIndex
                  );
                  break;
                } else if (
                  typeof pages[i].children[j].childSubmenu[k]
                    .secondChildSubMenu != "undefined"
                ) {
                  for (
                    var l = 0;
                    l <
                    pages[i].children[j].childSubmenu[k].secondChildSubMenu
                      .length;
                    l++
                  ) {
                    if (
                      location.pathname.includes(
                        pages[i].children[j].childSubmenu[k].secondChildSubMenu[
                          l
                        ].secondChildSubMenuPath
                      )
                    ) {
                      setSelectedIndexMenu(
                        pages[i].children[j].childSubmenu[k].secondChildSubMenu[
                          l
                        ].selectedIndex
                      );
                      break;
                    }
                  }
                }
              }
            } else if (
              pages[i].children[j].childTo != null &&
              pages[i].children[j].hasFormView
            ) {
              var hfLength = pages[i].children[j].hasFormView.length;
              for (var f = 0; f < hfLength; f++) {
                if (
                  location.pathname.includes(
                    pages[i].children[j].hasFormView[f].formViewMenuPath
                  )
                ) {
                  setSelectedIndexMenu(
                    pages[i].children[j].hasFormView[f].selectedIndex
                  );
                  break;
                }
              }
            }
          }
        }
      }
    }
  }, [selectedIndexMenu, pages, location]);

  // Responsive Drawer Starts
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
      setOpenNestedMenu(false);
    };

  const handleTest = (link) => {
    setOpenNestedMenu(false);
    history.push(link);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: 250, display: { md: "none" } }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)} // Backdrop Issue
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {userRedux?.user?.tenantName && (
              <Typography
                variant="subtitle1"
                textAlign="center"
                sx={{ m: 2, fontWeight: 700 }}
              >
                {userRedux?.user?.tenantName}
              </Typography>
            )}
            <Divider />
          </ListSubheader>
        }
      >
        {pages.map((menu, index) => (
          <div key={menu.menuItem + "div_1" + index}>
            <div
              // to={menu.to != null ? menu.to : ""}
              id={menu.menuItem}
              style={{ textDecoration: "none" }}
              key={"link_" + menu.menuItem + index}
              onClick={() =>
                menu.children.length
                  ? handleRouteChange(menu.to)
                  : handleTest(menu.to)
              }
            >
              <>
                <ListItem
                  button
                  key={menu.menuItem + index}
                  id={menu.menuItem}
                  onClick={
                    menu.children.length > 0
                      ? () => handleClickNestedMenu(menu.menuItem)
                      : undefined
                  }
                >
                  <ListItemText
                    disableTypography
                    primary={
                      <Typography
                        variant="body2"
                        gutterBottom
                        component="div"
                        sx={{ ml: 2, color: "#000" }}
                        id={menu.menuItem}
                      >
                        {menu.menuItem}
                      </Typography>
                    }
                  />
                  {menu.children.length > 0 &&
                    (menu.menuItem === selecteddrawer && openNestedMenu ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItem>
              </>
            </div>

            {menu.children.map((sub, i) => (
              <Collapse
                in={
                  menu.menuItem === selecteddrawer && openNestedMenu
                    ? true
                    : null
                }
                id={sub.subMenu}
                timeout="auto"
                unmountOnExit
                key={"cola" + menu.menuItem + sub.subMenu + i}
              >
                <div
                  // to={sub.childTo != null ? sub.childTo : ""}
                  style={
                    sub.childSubmenu.length > 0
                      ? {
                          textDecoration: "none",
                          pointerEvents: "none",
                          cursor: "auto",
                        }
                      : { textDecoration: "none" }
                  }
                  onClick={() =>
                    sub.childTo.length
                      ? handleRouteChange(sub.childTo)
                      : history.push(sub.childTo)
                  }
                >
                  <List
                    component="div"
                    disablePadding
                    key={"menuList_" + menu.menuItem + i}
                  >
                    <ListItemButton
                      sx={{ pl: 4 }}
                      className={
                        sub.childSubmenu.length > 0 ? classes.text : ""
                      }
                      disabled={sub.childSubmenu.length > 0 ? true : false}
                      classes={{
                        disabled:
                          classes.root &&
                          (sub.childSubmenu.length > 0 ? classes.iconMenu : ""),
                      }}
                    >
                      {sub.childSubmenu.length > 0 && (
                        <ListItemIcon
                          style={{
                            minWidth: "30px",
                            marginRight: -20,
                          }}
                        >
                          <KeyboardArrowRightIcon fontSize="small" />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={
                          <Typography
                            variant="body2"
                            gutterBottom
                            component="div"
                            sx={{
                              ml: 2,
                              color: "#000",
                              mb: 0,
                            }}
                            className={
                              sub.childSubmenu.length > 0 ? classes.text : ""
                            }
                          >
                            {sub.subMenu}
                          </Typography>
                        }
                      />
                    </ListItemButton>
                  </List>
                </div>
                {sub.childSubmenu.map((childSub, i) => (
                  <div
                    // to={
                    //   childSub.childSubmenuTo != null
                    //     ? childSub.childSubmenuTo
                    //     : ""
                    // }
                    style={{ textDecoration: "none" }}
                    key={"li_" + sub.subMenu + childSub.childMenu + i}
                    onClick={() =>
                      childSub.childSubmenuTo.length
                        ? handleRouteChange(childSub.childSubmenuTo)
                        : history.push(childSub.childSubmenuTo)
                    }
                  >
                    <List
                      component="div"
                      disablePadding
                      key={"list_" + sub.subMenu + childSub.childMenu + i}
                    >
                      <ListItemButton sx={{ pl: 4 }}>
                        <ListItemText
                          primary={
                            <Typography
                              variant="body2"
                              gutterBottom
                              component="div"
                              sx={{
                                ml: 4,
                                color: "#000",
                              }}
                            >
                              {childSub.childMenu}
                            </Typography>
                          }
                        />
                      </ListItemButton>
                    </List>
                  </div>
                ))}
                {sub.childSubmenu.length > 0 && <Divider sx={{ m: 1 }} />}
              </Collapse>
            ))}
          </div>
        ))}
        <Divider />
      </List>
    </Box>
  );
  // Responsive Drawer Ends

  return (
    <div>
      <AppBar
        position="static"
        sx={{ height: "45px", width: 1, px: 3 }}
        style={{ zIndex: 4, backgroundColor: "#1976d2" }} // previous "#354aa5"
      >
        <Toolbar disableGutters variant="dense" sx={{ my: 0, mx: 0 }}>
          <Box>
            <Typography
              variant="h6"
              noWrap
              component="div"
              sx={{
                mr: 2,
                ml: 0,
                display: { xs: "none", md: "flex" },
              }}
            >
              <Link to="/tenant">
                <div>
                  <img
                    style={{ width: "105px", marginTop: "-10px" }}
                    src={logo}
                    alt="SolPay Software"
                  />
                </div>
              </Link>
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            {(["left"] as const).map((anchor, i) => (
              <div key={"di" + i}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={toggleDrawer(anchor, true)}
                  color="inherit"
                  key={anchor}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  sx={{ display: { xs: "flex", md: "none" } }}
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                >
                  {list(anchor)}
                </Drawer>
              </div>
            ))}
          </Box>
          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}
          >
            <Link to="/tenant">
              <img style={{ width: "85px" }} src={logo} alt="SolPay Software" />
            </Link>
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", md: "flex" },
              mx: "auto",
            }}
          >
            <StyledList style={{ zIndex: 3 }}>
              <Stack direction="row">
                <>
                  {pages.map((item, index) => (
                    <div key={item.menuItem + "dmain_" + index}>
                      {item?.isSuperAdmin ? (
                        <>
                          {userRedux?.user?.isSuperAdmin ? (
                            <>
                              <div key={item.menuItem + "sa_" + index}>
                                <div
                                  // to={item.to != null ? item.to : ""}
                                  key={item.menuItem + "lsa_" + index}
                                  id={item.menuItem}
                                  onClick={(e) =>
                                    item.children.length
                                      ? handleClickDropDown(e, item.to)
                                      : history.push(item.to)
                                  }
                                  style={{
                                    textDecoration: "none",
                                  }}
                                >
                                  <ListItemButton
                                    ref={anchorRef}
                                    id={item.menuItem}
                                    selected={selectedIndexMenu === index}
                                    onClick={() => handleListItemClick(index)}
                                    aria-controls={
                                      open ? "fade-menu" : undefined
                                    }
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                    sx={{
                                      my: 0,
                                      mx: 0,
                                      display: "block",
                                    }}
                                  >
                                    <Typography
                                      color="#fff"
                                      id={item.menuItem}
                                      m={0}
                                      p={0}
                                      style={{
                                        fontWeight: 500,
                                        fontSize: 15,
                                      }}
                                      onClick={
                                        item.children.length
                                          ? handleCloseDropDown
                                          : undefined
                                      }
                                    >
                                      {item.menuItem}
                                    </Typography>
                                  </ListItemButton>
                                </div>

                                {item.children.length > 0 && (
                                  <StyledMenu
                                    MenuListProps={{
                                      "aria-labelledby": "fade-button",
                                    }}
                                    anchorEl={anchorDropDown}
                                    open={
                                      item.menuItem === selectedId
                                        ? open
                                        : false
                                    }
                                    onClose={handleCloseDropDown}
                                    TransitionComponent={Fade}
                                    sx={{
                                      mt: "15px",
                                      flexGrow: 1,
                                      display: {
                                        xs: "none",
                                        sm: "none",
                                        md: "flex",
                                      },
                                    }}
                                    PaperProps={{
                                      style: {
                                        // transform: "translateX(130px)",
                                        borderRadius: "3px",
                                        boxShadow:
                                          "0 0 0 1px rgb(0 10 30 / 20%), 0 3px 6px 0 rgb(0 10 30 / 20%)",
                                      },
                                    }}
                                  >
                                    {item.children.map(
                                      (subItem, indexOfSubItem) => (
                                        <div
                                          key={
                                            subItem.subMenu +
                                            "dsa" +
                                            indexOfSubItem
                                          }
                                        >
                                          <div
                                            key={
                                              subItem.subMenu +
                                              "lsa" +
                                              indexOfSubItem
                                            }
                                            style={
                                              subItem.childSubmenu.length > 0
                                                ? {
                                                    textDecoration: "none",
                                                    pointerEvents: "none",
                                                    cursor: "auto",
                                                  }
                                                : {
                                                    textDecoration: "none",
                                                  }
                                            }
                                            // to={
                                            //   subItem.childTo != null
                                            //     ? subItem.childTo
                                            //     : ""
                                            // }
                                            onClick={() =>
                                              subItem?.childTo?.length
                                                ? handleRouteChange(
                                                    subItem?.childTo
                                                  )
                                                : history.push(subItem?.childTo)
                                            }
                                          >
                                            <MenuItem
                                              key={
                                                subItem.subMenu +
                                                "lsa" +
                                                indexOfSubItem
                                              }
                                              onClick={
                                                !subItem.childSubmenu.length
                                                  ? handleCloseDropDown
                                                  : undefined
                                              }
                                              disabled={
                                                subItem.childSubmenu.length > 0
                                                  ? true
                                                  : false
                                              }
                                              classes={{
                                                disabled:
                                                  classes.root &&
                                                  (subItem.childSubmenu.length >
                                                  0
                                                    ? classes.iconMenu
                                                    : ""),
                                              }}
                                              disableRipple
                                              // classes={{ disabled: classes.root }}
                                            >
                                              {subItem.childSubmenu.length >
                                                0 && (
                                                <ListItemIcon
                                                  style={{
                                                    minWidth: "30px",
                                                    marginRight: -8,
                                                  }}
                                                >
                                                  <KeyboardArrowRightIcon fontSize="small" />
                                                </ListItemIcon>
                                              )}
                                              <Typography
                                                textAlign="left"
                                                style={{
                                                  fontSize: 16,
                                                  color: "#000a1e",
                                                }}
                                              >
                                                {subItem.subMenu}
                                              </Typography>
                                            </MenuItem>
                                          </div>

                                          {subItem.childSubmenu.map(
                                            (subChild, index) => (
                                              <div>
                                                <div
                                                  key={
                                                    subChild.childMenu +
                                                    "lsa" +
                                                    index
                                                  }
                                                  // to={subChild.childSubmenuTo}
                                                  onClick={() =>
                                                    subChild.childSubmenuTo
                                                      .length
                                                      ? handleRouteChange(
                                                          subChild.childSubmenuTo
                                                        )
                                                      : history.push(
                                                          subChild.childSubmenuTo
                                                        )
                                                  }
                                                  style={{
                                                    textDecoration: "none",
                                                  }}
                                                >
                                                  <MenuItem
                                                    key={
                                                      subChild.childMenu +
                                                      "msa" +
                                                      index
                                                    }
                                                    onClick={
                                                      handleCloseDropDown
                                                    }
                                                  >
                                                    <Typography
                                                      textAlign="left"
                                                      style={{
                                                        fontSize: 16,
                                                        color: "#000a1e",
                                                        marginLeft: 4,
                                                      }}
                                                    >
                                                      {subChild.childMenu}
                                                    </Typography>
                                                  </MenuItem>
                                                </div>
                                              </div>
                                            )
                                          )}
                                          {subItem.showDivider && (
                                            <Divider
                                              sx={{
                                                m: 1,
                                              }}
                                            />
                                          )}
                                        </div>
                                      )
                                    )}
                                  </StyledMenu>
                                )}
                              </div>
                            </>
                          ) : null}
                        </>
                      ) : (
                        <>
                          <div key={item.menuItem + "dnsa_" + index}>
                            <div
                              key={item.menuItem + "lnsa_" + index}
                              // to={item.to != null ? item.to : ""}
                              id={item.menuItem}
                              onClick={(e) =>
                                item.children.length
                                  ? handleClickDropDown(e, item.to)
                                  : history.push(item.to)
                              }
                              style={{
                                textDecoration: "none",
                              }}
                            >
                              <ListItemButton
                                ref={anchorRef}
                                id={item.menuItem}
                                selected={selectedIndexMenu === index}
                                onClick={() => handleListItemClick(index)}
                                aria-controls={open ? "fade-menu" : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? "true" : undefined}
                                sx={{
                                  my: 0,
                                  mx: 0,
                                  display: "block",
                                }}
                              >
                                <Typography
                                  color="#fff"
                                  id={item.menuItem}
                                  m={0}
                                  p={0}
                                  style={{
                                    fontWeight: 500,
                                    fontSize: 15,
                                  }}
                                  onClick={
                                    item.children.length
                                      ? handleCloseDropDown
                                      : undefined
                                  }
                                >
                                  {item.menuItem}
                                </Typography>
                              </ListItemButton>
                            </div>

                            {item.children.length > 0 && (
                              <StyledMenu
                                MenuListProps={{
                                  "aria-labelledby": "fade-button",
                                }}
                                anchorEl={anchorDropDown}
                                open={
                                  item.menuItem === selectedId ? open : false
                                }
                                onClose={handleCloseDropDown}
                                TransitionComponent={Fade}
                                sx={{
                                  mt: "15px",
                                  flexGrow: 1,
                                  display: {
                                    xs: "none",
                                    sm: "none",
                                    md: "flex",
                                  },
                                }}
                                PaperProps={{
                                  style: {
                                    // transform: "translateX(130px)",
                                    borderRadius: "3px",
                                    boxShadow:
                                      "0 0 0 1px rgb(0 10 30 / 20%), 0 3px 6px 0 rgb(0 10 30 / 20%)",
                                  },
                                }}
                              >
                                {item.children.map(
                                  (subItem, indexOfSubItem) => (
                                    <div
                                      key={
                                        subItem.subMenu +
                                        "dnsa" +
                                        indexOfSubItem
                                      }
                                    >
                                      <div
                                        key={
                                          subItem.subMenu +
                                          "lnsa" +
                                          indexOfSubItem
                                        }
                                        style={
                                          subItem.childSubmenu.length > 0
                                            ? {
                                                textDecoration: "none",
                                                pointerEvents: "none",
                                                cursor: "auto",
                                              }
                                            : {
                                                textDecoration: "none",
                                              }
                                        }
                                        // to={
                                        //   subItem.childTo != null
                                        //     ? subItem.childTo
                                        //     : ""
                                        // }
                                        onClick={() =>
                                          subItem.childTo.length
                                            ? handleRouteChange(subItem.childTo)
                                            : history.push(subItem.childTo)
                                        }
                                      >
                                        <MenuItem
                                          key={
                                            subItem.subMenu +
                                            "mnsa" +
                                            indexOfSubItem
                                          }
                                          onClick={
                                            !subItem.childSubmenu.length
                                              ? handleCloseDropDown
                                              : undefined
                                          }
                                          disabled={
                                            subItem.childSubmenu.length > 0
                                              ? true
                                              : false
                                          }
                                          classes={{
                                            disabled:
                                              classes.root &&
                                              (subItem.childSubmenu.length > 0
                                                ? classes.iconMenu
                                                : ""),
                                          }}
                                          disableRipple
                                          // classes={{ disabled: classes.root }}
                                        >
                                          {subItem.childSubmenu.length > 0 && (
                                            <ListItemIcon
                                              style={{
                                                minWidth: "30px",
                                                marginRight: -8,
                                              }}
                                            >
                                              <KeyboardArrowRightIcon fontSize="small" />
                                            </ListItemIcon>
                                          )}
                                          <Typography
                                            textAlign="left"
                                            style={{
                                              fontSize: 16,
                                              color: "#000a1e",
                                            }}
                                          >
                                            {subItem.subMenu}
                                          </Typography>
                                        </MenuItem>
                                      </div>

                                      {subItem.childSubmenu.map(
                                        (subChild, index) => (
                                          <div
                                            key={
                                              subChild.childMenu +
                                              "dnsa" +
                                              index
                                            }
                                          >
                                            <div
                                              key={
                                                subChild.childMenu +
                                                "lnsa" +
                                                index
                                              }
                                              // to={
                                              //   subChild.childSubmenuTo != null
                                              //     ? subChild.childSubmenuTo
                                              //     : ""
                                              // }
                                              onClick={() =>
                                                subChild?.childSubmenuTo?.length
                                                  ? handleRouteChange(
                                                      subChild.childSubmenuTo
                                                    )
                                                  : history.push(
                                                      subChild.childSubmenuTo
                                                    )
                                              }
                                              style={{
                                                textDecoration: "none",
                                              }}
                                            >
                                              <MenuItem
                                                key={
                                                  subChild.childMenu +
                                                  "mnsa" +
                                                  index
                                                }
                                                onClick={handleCloseDropDown}
                                              >
                                                <Typography
                                                  textAlign="left"
                                                  style={{
                                                    fontSize: 16,
                                                    color: "#000a1e",
                                                    marginLeft: 4,
                                                  }}
                                                >
                                                  {subChild.childMenu}
                                                </Typography>
                                              </MenuItem>
                                            </div>
                                          </div>
                                        )
                                      )}
                                      {subItem.showDivider && (
                                        <Divider
                                          sx={{
                                            m: 1,
                                          }}
                                        />
                                      )}
                                    </div>
                                  )
                                )}
                              </StyledMenu>
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  ))}
                </>
              </Stack>
            </StyledList>
          </Box>
          {/* Without bug start */}
          <Box
            sx={{
              flexGrow: 0,
              mx: 2,
              display: { sm: "none", md: "none", lg: "flex" },
            }}
          >
            {userRedux?.user?.isSuperAdmin ? (
              <Box sx={{ minWidth: 150 }}>
                <FormControl fullWidth>
                  <Select
                    sx={{
                      border: "none",
                      background: "rgb(25, 118, 210)",
                      color: "#fff",
                      fontWeight: "bold",
                      "& .MuiOutlinedInput-notchedOutline": { display: "none" },
                      height: "42px",
                    }}
                    labelId="demo-simple-select-label"
                    value={tenant}
                    onChange={handleChangeTenant}
                    inputProps={{
                      sx: {
                        "&::placeholder": {
                          fontSize: "16px",
                        },
                      },
                    }}
                  >
                    {userRedux?.user?.userAllTenantList?.map((item, index) => (
                      <MenuItem key={index} value={item.value}>
                        {item.text}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            ) : (
              <Typography
                textAlign="center"
                color="#fff"
                m={0}
                p={0}
                style={{
                  fontWeight: 500,
                  fontSize: 14,
                }}
              >
                {userRedux?.user?.tenantName}
              </Typography>
            )}
          </Box>

          <Box sx={{ flexGrow: 0, mt: "-7px" }}>
            <div>
              <Tooltip title="Open settings">
                <IconButton
                  id="user"
                  onClick={handleOpenUserMenu}
                  sx={{ p: 0 }}
                >
                  {userRedux.user && userRedux.user.userModulePermissions ? (
                    <StyledBadge
                      overlap="circular"
                      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                      variant="dot"
                    >
                      <Avatar
                        alt={userRedux?.user?.tenantName}
                        src="/static/images/avatar/1.jpg"
                        sx={{ width: 30, height: 30 }}
                      />
                    </StyledBadge>
                  ) : (
                    <Avatar
                      alt={userRedux?.user?.tenantName}
                      src="/static/images/avatar/2.jpg"
                      sx={{ width: 30, height: 30 }}
                    />
                  )}
                </IconButton>
              </Tooltip>
              {/* Without bug start */}
              <StyledMenu
                MenuListProps={{
                  "aria-labelledby": "user",
                }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                sx={{ mt: 3 }}
              >
                {userRedux.user && userRedux.user.userModulePermissions ? (
                  <div>
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        dispatch(userActions.logout(history));
                      }}
                    >
                      <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem
                      onClick={() => {
                        handleCloseUserMenu();
                        history.push("/change-password");
                      }}
                    >
                      <Typography
                        textAlign="center"
                        style={{
                          fontSize: 16,
                          color: "#000a1e",
                        }}
                      >
                        Change Password
                      </Typography>
                    </MenuItem>
                  </div>
                ) : (
                  <MenuItem
                    onClick={() => {
                      handleCloseUserMenu();
                      history.push("/login");
                    }}
                  >
                    <Typography textAlign="center">Login</Typography>
                  </MenuItem>
                )}
              </StyledMenu>
            </div>
          </Box>
          <Modal
            open={userRedux.isLoggingOut}
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalStyle}>
              <Typography
                id="modal-modal-description"
                variant="h6"
                component="h2"
              >
                Logging out, please wait.
              </Typography>
            </Box>
          </Modal>
          {/* Without bug end */}
        </Toolbar>
      </AppBar>
    </div>
  );
};
export default Header;
