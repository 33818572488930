import { actions as commonActions } from "@redux/commonRedux";
import { SnackbarProvider } from "notistack";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Router from "router";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();

  dispatch(commonActions.setHistory(history));
  dispatch(commonActions.setCommonActions(history));

  useEffect(() => {
    dispatch(commonActions.setHistory(history));
    dispatch(commonActions.setCommonActions(history));
  }, [history]);

  return (
    <>
      <SnackbarProvider autoHideDuration={3000}>
        <Router />
      </SnackbarProvider>
    </>
  );
}

export default App;
