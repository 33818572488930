import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as superActions,
  ISuperAnnuationState,
} from "@redux/pyr/superAnnuationRedux";
import superAnnuationDataAccess from "dataAccess/pyr/superAnnuationDataAccess";
import { InputFieldOneRowReadOnly } from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
// import PayrollPageMenuHeader from "libComp/pageMenuHeaders/payrollPageMenuHeader";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

function ReviewBillingContact(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const superannuation: ISuperAnnuationState = props.superannuation;
  const superregistrationId = props.match.params.id;
  const hasToUpdate: boolean = props.match.params.hastoupdate == "true";
  const [uiMessage, setUiMessage] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (superregistrationId) {
      dispatch(
        superActions.getBillingContact(superregistrationId, hasToUpdate)
      );
    }
  }, [superregistrationId, hasToUpdate]);

  useEffect(() => {
    setUiMessage(props.uiMessages);
  }, [props.uiMessages]);

  const returnUrl = window.location.origin + "/payroll/registrationComplete/";

  const reviewBillingContact = (model) => {
    superAnnuationDataAccess
      .reviewBillingContact(model)(commonState.commonActions)
      .then((data) => {
        //console.log(data);
        if (data.success) {
          if (!hasToUpdate) window.location.href = data.data;
          else
            props.history.push(
              "/payroll/registrationComplete/" + superregistrationId
            );
        }
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // const reviewBillingContact = (model) => {
  //   superAnnuationDataAccess
  //     .reviewBillingContact(model)(commonState.commonActions)
  //     .then((data) => {
  //       if (data.success) {
  //         if (!hasToUpdate)
  //           superAnnuationDataAccess
  //             .getPDSApproval(
  //               superregistrationId,
  //               hasToUpdate,
  //               returnUrl + superregistrationId
  //             )(commonState.commonActions)
  //             .then((data) => {
  //               window.location.href = data;
  //             });
  //         else
  //           props.history.push(
  //             "/payroll/registrationComplete/" + superregistrationId
  //           );
  //       } else toast.error(data.message);
  //     })
  //     .catch((error: Error) => {
  //       throw new SubmissionError({ _error: error.message });
  //     });
  // };

  const steps = ["Organization", "Authoriser", "Bank", "Billing Contact"];

  const style = useStyleClass();

  return (
    <React.Fragment>
      <div className="page-container">
        <PageTitleHeader header="Set Up Superannuation"></PageTitleHeader>
        {uiMessage &&
          uiMessage.map((item, index) => (
            <>
              <>
                <Alert
                  key={index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    setUiMessage([]);
                  }}
                >
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: item.messageText,
                    }}
                  />
                </Alert>
                <br />
              </>
            </>
          ))}

        <Box sx={{ width: "100%" }}>
          <Stepper alternativeLabel activeStep={3}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />

          <form
            noValidate
            onSubmit={props.handleSubmit((model) =>
              reviewBillingContact(model)
            )}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Who will be charge of approving the payments
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
                mb: 4,
              }}
            >
              This person must be a Payroll Admin and have permission to make
              banking decisions on behalf of the business
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  name="billingContactEmail"
                  label="Billing Contact Email"
                  disabled={true}
                  component={InputFieldOneRowReadOnly}
                />
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    pt: 2,
                    justifyContent: "space-between",
                  }}
                >
                  <Button
                    variant="contained"
                    classes={{ root: style.button }}
                    sx={{ mx: 2 }}
                    onClick={() => {
                      props.history.push(
                        "/payroll/reviewbankdetails/" +
                          superregistrationId +
                          "/" +
                          hasToUpdate
                      );
                    }}
                  >
                    Back
                  </Button>
                  {props.billingContact.billingContactEmail && (
                    <Button
                      classes={{ root: style.button }}
                      sx={{ mx: 2 }}
                      variant="contained"
                      type="submit"
                      disabled={props.submitting}
                    >
                      Register
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </React.Fragment>
  );
}

const reviewSuperAuthorizer = reduxForm<any>({
  form: "ReviewBillingContact",
  enableReinitialize: true,
})(ReviewBillingContact);

const mapStatetoProps = (state: IReduxState, ownProops) => ({
  superannuation: state.superannuation,
  billingContact: state.superannuation.billingContact,
  uiMessages: state.superannuation.uiMessages,
  initialValues: { ...state.superannuation.billingContact },
});

export default connect(mapStatetoProps)(reviewSuperAuthorizer);
