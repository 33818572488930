import clientDataAccess from "dataAccess/contact/clientDataAccess";
import { APIResponse } from "models/response";
import { addValidator } from "redux-form-validators";

//required validator for form input
// export const required = (value) =>
// //   value || typeof value === "number" ? undefined : "This field is required";
export const required = (value) =>
  value ? undefined : "This field is required";

export const notEmpty = (value) =>
  value || value === "" ? undefined : "Please select a value";

export const minLength = (min: number) => (value) => {
  return value && value.length < min
    ? `Must be ${min} characters or more`
    : undefined;
};

export const maxLength = (max: number) => (value) => {
  return value && value.length > max
    ? `Cannot be more than ${max} characters in length.`
    : undefined;
};

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "Invalid email address"
    : undefined;

// export const phone = (value) =>
//   value && !/^[0]{1}(2|4|3|7|8){1}(\s){0,1}[0-9]{4}(\s)?[0-9]{4}$/i.test(value)
//     ? "Phone Number is not in correct format. Correct format e.g. 07 3454 6789 or 0734546789"
//     : undefined;

 export const phone = (value) =>
  value && (!/^[0]{1}(2|4|3|7|8){1}(\s){0,1}[0-9]{4}(\s)?[0-9]{4}$/i.test(value) &&
  !/^1300(\s){0,1}[0-9]{3}(\s){0,1}[0-9]{3}$/i.test(value))
    ? "Phone Number is not in correct format. Correct format e.g. 07 3454 6789 or 0734546789"
    : undefined;

export const abn = addValidator({
  defaultMessage:
    "ABN Number is not in correct format. Correct format e.g. 012 345 678 90 or 01234567890",
  validator: (options, value, allValues) => {
    return value && /^(\d *?){11}$/i.test(value);
  },
});
export const bsb = addValidator({
  defaultMessage:
    "BSB Number is not in correct format. Correct format e.g. 123-456 or 123456",
  validator: (options, value, allValues) => {
    return value && /^[0-9]{3}-?[0-9]{3}$/i.test(value);
  },
});

export const userName = (value) =>
  value && !/^[a-zA-Z0-9]{1}[a-zA-Z0-9._]+$/i.test(value)
    ? "Only character, letter dot(.) and underscore(_) are allowed & dot(.) and underscore(_) are not allowed at first position."
    : undefined;
export const password = (value) =>
  value && !/^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).*$/i.test(value)
    ? "Password must contain combination of uppercase letter, lowarcase letter, number and special character(! @ # $ & *)"
    : undefined;

export const mobile = (value) =>
  value && !/^04(\s)?[0-9]{4}(\s)?[0-9]{4}$/i.test(value)
    ? "Invalid Mobile Number"
    : undefined;

export const postCode = (value) =>
  value && !/^[0-9]{4}$/i.test(value) ? "Invalid Postcode" : undefined;

export const clientCode = (value, client) => {
  value &&
    clientDataAccess
      .isClientCodeAvaiable(value, client.id)
      .then((data) => {
        const response: APIResponse = data;
        return !response.data ? `${value} is not available.` : undefined;
      })
      .catch((error: Error) => {});
};

export const passwordsMustMatch = (value, allValues) => {
  return value !== allValues.password ? "Passwords do not match" : undefined;
};

export const passwordsMustMatch2 = (value, allValues) => {
  return value !== allValues.newPassword ? "Passwords do not match" : undefined;
};
