import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as payRunActions } from "@redux/pyr/payRunRedux";
import {
  default as payRunDataAccess,
  default as payrunDataAccess,
} from "dataAccess/pyr/payrunDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, FieldArray, reduxForm, SubmissionError } from "redux-form";
import { asyncValidatePayrunFrequency } from "services/asyncValidate";
import { useStyleClass } from "styles/theming/useStyleClass";

function PayRun(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const payRunState = props.payRun;
  const [freequency, setFreequency] = useState([]);
  var selected = [];
  const [selectedEmp, setSelectedEmp] = useState();
  const [employeeId, setEmployeeId] = useState([]);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(payRunActions.getListForStartPayRun());

    let isActive = true;

    payrunDataAccess
      .getPayrollFreequency()(commonState.commonActions)
      .then((data) => {
        if (isActive) setFreequency(data.data);
      });

    return () => {
      isActive = false;
    };
  }, []);

  useEffect(() => {
    var filteredEmployee = [];
    var selected = [];

    if (
      payRunState.pyrPayRun.employees &&
      payRunState.pyrPayRun.employees.length > 0
    )
      payRunState.pyrPayRun.employees.map((item, index) => {
        if (item.hasPayInfo && item.hasTaxInfo) {
          filteredEmployee.push(item.id);
          selected.push(true);
        }
      });

    setEmployeeId(filteredEmployee);
  }, [payRunState.pyrPayRun]);

  const onFormSubmit = (model) => {
    model.selected = [...selected];
    model.employeeId = [...employeeId];
    model.employees = [];
    return payRunDataAccess
      .startPayRun(model)(commonState.commonActions)
      .then((data) => {
        if (data.success && data.data > 0) {
          // enqueueSnackbar("Payroll has been saved!", { variant: "success" });
          props.history.push("/payroll/payrun/reviewpay/" + data.data);
        } else
          enqueueSnackbar(data.message, {
            variant: "error",
          });
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <div className="page-container">
      <PageTitleHeader header="Start Pay" />
      <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
        <form
          className="form-horizontal form-element"
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          {payRunState.uiMessages &&
            payRunState.uiMessages.map((item, index) => (
              <React.Fragment key={"alertKey_" + index}>
                <Alert
                  key={"alertComp_" + index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    dispatch(payRunActions.removeUiMessage(index));
                  }}
                >
                  {item.messageText}
                </Alert>
                <br />
              </React.Fragment>
            ))}

          <Grid container spacing={10}>
            <Grid item xl={6} md={6} xs={12} sm={12}>
              <Typography variant="h6" component="h6">
                Select Pay Frequency and dates
              </Typography>
              <br />
              <Field
                name="frequency"
                options={freequency}
                component={solpayReactSelect}
                label="Frequency"
              />
              <Field
                name="payFrom"
                component={DatePickerFieldOneRow}
                label="From"
              />
              <Field
                name="payTo"
                component={DatePickerFieldOneRow}
                label="To"
              />
              <Field
                name="payDate"
                component={DatePickerFieldOneRow}
                label="Pay On"
              />
              <Field
                name="lastPayRunTime"
                component={DatePickerFieldOneRow}
                label="Last Pay Run"
              />
              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-usd"></i>
                  )}{" "}
                  &nbsp;Start Pay
                </Button>
              </Box>
            </Grid>
            <Grid item xl={6} md={6} xs={12} sm={12}>
              <Typography variant="h6" component="h6">
                Select Employees you would like to pay
              </Typography>
              <br />
              <FieldArray
                name="employees"
                component={Employees}
                props={{ employeeId, selected }}
              />
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
}

const payRun = reduxForm<any>({
  form: "PayRun",
  enableReinitialize: true,
  asyncValidate: asyncValidatePayrunFrequency,
  asyncChangeFields: ["frequency", "payTo", "payFrom"],
})(PayRun);

const mapStateToProps = (state, ownProps) => ({
  payRun: state.payrun,
  pyrPayRun: state.payrun.pyrPayRun,
  initialValues: state.payrun.pyrPayRun ?? {},
});

export default connect(mapStateToProps)(payRun);

const Employees = ({ fields, meta, employeeId, selected }) => {
  const [selectAll, setSelectAll] = useState(true);
  const [checkedState, setCheckedState] = useState([]);

  useEffect(() => {
    const collection = [];

    fields.map((item, index) => {
      console.timeStamp(item);
      if (fields.get(index).hasPayInfo && fields.get(index).hasTaxInfo) {
        collection[index] = true;
        selected[index] = true;
      } else {
        collection[index] = false;
        selected[index] = false;
      }
    });

    setCheckedState(collection);
  }, [fields]);

  const selectItem = (e) => {
    const { checked } = e.target;
    const collection = [];

    if (checked) {
      fields.map((item, index) => {
        // console.timeStamp(item);
        if (fields.get(index).hasPayInfo && fields.get(index).hasTaxInfo) {
          collection[index] = true;
          selected[index] = true;
        } else {
          collection[index] = false;
          selected[index] = false;
        }
      });
    } else {
      fields.map((item, index) => {
        collection[index] = false;
        selected[index] = false;
      });
    }
    setCheckedState(collection);
    setSelectAll(checked);
  };

  const handleChild = (position) => {
    let allSelected = true;
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );
    selected[position] = !selected[position];
    const upLength = updatedCheckedState.length;

    for (let i = 0; i < upLength; i++) {
      if (fields.get(i).hasPayInfo && fields.get(i).hasTaxInfo) {
        if (updatedCheckedState[i]) {
          allSelected = true;
        } else {
          allSelected = false;
          break;
        }
      }
    }
    setSelectAll(allSelected);
    setCheckedState(updatedCheckedState);
  };

  const style = useStyleClass();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              <TableCell>
                <Typography
                  variant="subtitle1"
                  align="left"
                  sx={{ fontWeight: "bold", color: "#fff" }}
                >
                  <label htmlFor="chkSelectAll" style={{ marginBottom: 0 }}>
                    <Checkbox
                      checked={selectAll}
                      id="chkSelectAll"
                      onClick={selectItem.bind(this)}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  </label>
                  Employee Name
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={"tRow_" + index}>
                <TableCell sx={{ py: 0 }} key={"tCell_" + index}>
                  <Box>
                    <Typography variant="subtitle1" align="left">
                      <label
                        htmlFor={`chkSelected${index}`}
                        style={{
                          marginBottom: 0,
                          visibility:
                            !fields.get(index).hasPayInfo ||
                            !fields.get(index).hasTaxInfo
                              ? "hidden"
                              : "visible",
                        }}
                      >
                        <Checkbox
                          disabled={
                            !fields.get(index).hasPayInfo ||
                            !fields.get(index).hasTaxInfo
                          }
                          checked={checkedState[index] === true}
                          id={`chkSelected${index}`}
                          onChange={() => handleChild(index)}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      </label>
                      {fields.get(index).contact.fullname}
                      <Divider />
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
