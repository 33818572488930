import {
  CLEAN_SUPPLIER,
  GET_SUPPLIER,
  SUPPLIER_ADD_TO_LIST,
  SUPPLIER_FAILED,
  SUPPLIER_LOADING,
  SUPPLIER_REMOVE_UI_MESSAGE,
  SUPPLIER_SELECTED,
} from "./supplierTypes";

export interface ISupplierState {
  suppliers: Array<any>;
  purchaseBillDetails: Array<any>;
  supplier: any;
  selectedSupplier: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  accountList: Array<any>;
  taxList: Array<any>;
  designationList: Array<any>;
  selectedAccountModel: any;
}

const initialState: ISupplierState = {
  suppliers: [],
  purchaseBillDetails: [],
  supplier: {},
  selectedSupplier: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
  accountList: [],
  taxList: [],
  selectedAccountModel: {},
  designationList: [
    { label: "Customer", value: "1" },
    { label: "Individual", value: "2" },
  ],
};

interface Action {
  type: string;
  payload: any;
}
export const reducer = (
  state: ISupplierState = initialState,
  action: Action
): ISupplierState => {
  switch (action.type) {
    case CLEAN_SUPPLIER:
      if (
        state.supplier.id &&
        state.supplier.id.toString() === action.payload.toString()
      ) {
        return state;
      } else {
        return { ...state, uiMessages: [], supplier: [] };
      }
    case SUPPLIER_ADD_TO_LIST:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        suppliers: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case GET_SUPPLIER:
      return {
        ...state,
        isLoading: false,
        supplier: action.payload,
      };
    case SUPPLIER_SELECTED:
      return {
        ...state,
        selectedSupplier: action.payload,
      };
    case SUPPLIER_REMOVE_UI_MESSAGE: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case SUPPLIER_LOADING:
      return { ...state, isLoading: true, isError: false };

    case SUPPLIER_FAILED:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
