import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";
import { ProductModel } from "models/ProductsModel";

class ProductDataAccess {
  getProductList =
    (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      return commonDataAccess.getData("Product/getall", {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };
  saveProduct = (product: ProductModel) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData("Product/save", product)(commonActions);
  };
  getProduct = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Product/get", { id })(commonActions);
  };

  getProductByName = (name) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Product/getproductlist?term=" + name)(
      commonActions
    );
  };
  isProductCodeExist = (code: string, productId) => {
    return commonDataAccess.getData(
      "Product/IsProductCodeExist?productCode=" +
        code +
        "&&productId=" +
        productId
    )(null);
  };
  deleteProduct = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Product/delete",
      {},
      { id: id }
    )(commonActions);
  };
}

export default new ProductDataAccess();
