// import AccountingPageMenuHeader from "libComp/pageMenuHeaders/accountingPageMenuHeader";

function Purchases() {
  // const MenuItems = [
  //   {
  //     title: "Sales",
  //     to: "/accounting/sales",
  //     imageSrc: imageUser,
  //     imageAlt: "icon",
  //   },
  //   {
  //     title: "Purchase",
  //     to: "/purchases",
  //     imageSrc: imageUser,
  //     imageAlt: "Purchase",
  //     isActive: true,
  //   },
  //   {
  //     title: "Banking",
  //     to: "/banking",
  //     imageSrc: imageUser,
  //     imageAlt: "View Banking",
  //   },
  //   {
  //     title: "Journals",
  //     to: "/journals",
  //     imageSrc: imageUser,
  //     imageAlt: "Journals",
  //   },
  //   {
  //     title: "Reports",
  //     to: "/reports",
  //     imageSrc: imageUser,
  //     imageAlt: "Reports",
  //   },
  //   {
  //     title: "Settings",
  //     to: "/settings",
  //     imageSrc: imageSetting,
  //     imageAlt: "Purchase",
  //   },
  //   {
  //     title: "Year Closing",
  //     to: "/accounting/yearclosing",
  //     imageSrc: imageSetting,
  //     imageAlt: "Year Closing",
  //   },
  // ];

  // const Buttons = [
  //   {
  //     header: "Business & Individual Supplier",
  //     description: "Add or Edit Business & Individual Clients",
  //     to: "/contact/suppliers",
  //     imageSrc: imageUser,
  //     imageAlt: "Business & Individual Supplier",
  //   },
  //   {
  //     header: "Products / Services",
  //     description: "Add or Edit Products / Services",
  //     to: "/accounting/sales/product",
  //     imageSrc: imageUser,
  //     imageAlt: "Products / Services",
  //   },
  //   {
  //     header: "Bills",
  //     description: "Bill List",
  //     to: "/purchase/bill/list",
  //     imageSrc: imageUser,
  //     imageAlt: "Quotations",
  //   },
  // ];

  return (
    <div>
      {/* <AccountingPageMenuHeader />
      <PageTitleHeader header="Purchases" />
      <PageButtons buttons={Buttons}></PageButtons>; */}
    </div>
  );
}

export default Purchases;
