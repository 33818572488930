import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class BillDataAccess {
  getList = (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters['filter.' + item.propertyName] =
            item.value + '-' + item.type;
        }
      });
      return commonDataAccess.getData('PurchaseBill/getall', {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };

    
  getClientBillList = (clientId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('PurchaseBill/getclientbills', {
      clientId,
    })(commonActions);
  };
  getPurchaseBillLookupData = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'PurchaseBill/billlookup',
      {}
    )(commonActions);
  };
  saveBill = (bill) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'PurchaseBill/savebill',
      bill
    )(commonActions);
  };
  saveBillPayment = (billPayment) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'PurchaseBill/savebillpayment',
      billPayment
    )(commonActions);
  };
  getBill = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('PurchaseBill/getbyid', { id })(
      commonActions
    );
  };
  deleteBillDetail = (invoiceDetailId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'PurchaseBill/deleteclientbilldetail?invoiceDetailId=' + invoiceDetailId
    )(commonActions);
  };
  revertBillPayment = (paymentId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData('PurchaseBill/revertbill?id=' + paymentId)(
      commonActions
    );
  };
  getAllBillPaymentMethod = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('PurchaseBill/getallpaymentmethod')(
      commonActions
    );
  };
}

export default new BillDataAccess();
