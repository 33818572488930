import { Grid, TextField, ThemeOptions } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { actions as listActions, IListState } from "@redux/listRedux";
import CustomDatePicker from "libComp/common/dataPicker";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";

const useInputClass = makeStyles((theme: ThemeOptions) => ({
  input: {
    "& .MuiInputBase-root": {
      width: "170px",
    },
  },
  // inputStyle: {
  //   "& label.Mui-focused": {
  //     display: "none",
  //   },
  //   "& legend": {
  //     display: "none",
  //   },
  //   "& .MuiInputLabel-root": {
  //     display: "none",
  //   },
  //   "& .MuiOutlinedInput-notchedOutline": {
  //     top: 0,
  //   },
  // },
}));
interface IFilterOption {
  /**
   * name of the property
   */
  propertyName: string;
  /**
   * placeholder text for the field
   */
  placeholder: string;
  /**
   * type of the input eg. text/date
   */
  inputType: string;
  /**
   * options will be provided from filterOptions declared in constants file
   */
  typeOptions: Array<any>;
}
interface IProps {
  /**
   * filters for the data list
   */
  filterOptions: Array<IFilterOption>;
  /**
   * will come from redux
   */
  listState: IListState;
  /**
   * callback for when a filter is updated
   */
  onFilterChange: Function;
}
let timer;
function Filter(props: IProps) {
  const style = useInputClass();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(listActions.initializeFilter(props.filterOptions));
    return () => {
      dispatch(listActions.initializeFilter([]));
    };
  }, []);

  if (props.listState.isLoading) return <div>Loadinng</div>;

  return (
    <Grid>
      {props.listState.filters.map((item, index) => (
        <span className="single-filter-container" key={index}>
          {/* type dropdown if multiple type */}
          {/* {props.filterOptions[index] &&
            props.filterOptions[index].typeOptions.length > 1 && (
              <>
                <Form.Control
                  className="filter-form-control"
                  value={item.value}
                  onChange={(event) => {
                    let filters = props.listState.filters;
                    filters[index].type = event.target.value;
                    dispatch(listActions.updateFilter(filters));
                    if (
                      props.listState.filters[index].value &&
                      props.listState.filters[index].value.length > 0
                    ) {
                      dispatch(listActions.updatePageNo(1));
                      props.onFilterChange(
                        1,
                        props.listState.pageSize,
                        filters,
                        props.listState.sort
                      );
                    }
                  }}
                  as="select"
                  custom
                >
                  {props.filterOptions[index].typeOptions.map((item, index) => (
                    <option value={item.value} key={index}>
                      {item.label}
                    </option>
                  ))}
                </Form.Control>
              </>
            )} */}
          {/* handle text */}
          {props.filterOptions[index] &&
            props.filterOptions[index].inputType === "text" && (
              // <form>
              <TextField
                className={`${style.input}`}
                inputProps={{
                  autoComplete: "none",
                  form: {
                    autocomplete: "off",
                  },
                }}
                size="small"
                value={item.value}
                onChange={(event) => {
                  let filters = props.listState.filters;
                  filters[index].value = event.target.value;
                  dispatch(listActions.updateFilter(filters));
                  dispatch(listActions.updatePageNo(1));
                  //props.onFilterChange(1, props.listState.pageSize, filters, props.listState.sort);
                  clearTimeout(timer);
                  timer = setTimeout(() => {
                    props.onFilterChange(
                      1,
                      props.listState.pageSize,
                      filters,
                      props.listState.sort
                    );
                  }, 500);
                }}
                label={item.placeholder}
              />
              // </form>
            )}
          {/* handle date */}
          {props.filterOptions[index] &&
            props.filterOptions[index].inputType === "date" && (
              // <form autoComplete="off">
              <CustomDatePicker
                className={`${style.input}`}
                placeholder={item.placeholder}
                value={item.value}
                onChange={(value) => {
                  let filters = props.listState.filters;
                  //console.log(value);
                  filters[index].value = value;
                  //console.log(filters);
                  item.value = filters[index].value;
                  dispatch(listActions.updateFilter(filters));
                  dispatch(listActions.updatePageNo(1));
                  props.onFilterChange(
                    1,
                    props.listState.pageSize,
                    filters,
                    props.listState.sort
                  );
                }}
              />
              // </form>
            )}
        </span>
      ))}
    </Grid>
  );
}
const mapStateToProps = ({ listState }) => ({
  listState,
});

export default connect(mapStateToProps)(Filter);




