import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { FormControl, TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      //     // padding: 0,
      // padding: "7.5px 14px",
      //     "& .MuiButtonBase-root": {
      //       // padding: 0,
      //       padding: "8.5px 14px",
      //       // paddingLeft: 10
      //     },
      "& .MuiInputBase-input": {
        padding: "7.5px 14px",
        //       // padding: 15,
        //       // paddingLeft: 0
      },
    },
    "& .MuiInputLabel-formControl ": {
      top: "-8px",
    },
    "& .Mui-focused": {
      top: "0px",
    },
    "& .MuiInputLabel-shrink": {
      top: "0px",
    },
  },
});

interface Props {
  value: Date | string;
  onChange: any;
  className?: string;
  placeholder?: string;
}
const CustomDatePicker = (props) => {
  const classes = useStyles();

  // const onChange = (date) => {
  //   Date.parse(date)
  //     ? inputProps.onChange(
  //         moment.utc(date.toLocaleString()).format("MM/DD/YYYY")
  //       )
  //     : inputProps.onChange(null);
  // };

  return (
    <FormControl style={{ width: "98%" }} className={classes.root}>
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        className={classes.root}
      >
        <DatePicker
          value={props.value}
          onChange={props.onChange}
          label={props.placeholder}
          inputFormat="dd/MM/yyyy"
          renderInput={(params) => (
            <TextField {...params} {...props} error={false} />
          )}
        />
      </LocalizationProvider>
    </FormControl>
  );
};

export default CustomDatePicker;

export const CustomDatePickerReadonly = (props: Props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        value={props.value ? new Date(props.value) : props.value}
        onChange={props.onChange}
        readOnly
        label={props.placeholder}
        inputFormat="dd/MM/yyyy"
        renderInput={(params) => <TextField {...params} />}
      />
    </LocalizationProvider>
  );
};

// export const DatePickerFieldOneRow = (props: ReduxFieldProps) => {
//   let config = { layout: "horizontal", ...props };
//   let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
//   const [value, setValue] = useState(props.input?.value);
//   return (
//     <Grid
//       container
//       spacing={1}
//       style={{
//         display: "flex",
//         marginBottom: "21px",
//         alignItems: layout === "horizontal" ? "center" : "start",
//         flexDirection: layout === "horizontal" ? "row" : "column",
//       }}
//     >
//       <Grid
//         item
//         md={props.labelCol ? props.labelCol : 4}
//         xl={
//           props.labelCol
//             ? props.labelCol > 11
//               ? props.labelCol
//               : props.labelCol + 1
//             : 4
//         }
//       >
//         <h2
//           style={{
//             fontSize: "14px",
//             textAlign: layout === "horizontal" ? "right" : "left",
//             marginBottom: "0px",
//           }}
//         >
//           {props.label}
//         </h2>
//       </Grid>
//       <Grid
//         item
//         style={{
//           flex: "1 1 auto",
//           width: layout === "horizontal" ? "auto" : "100%",
//         }}
//         md={props.fieldColumn ? props.fieldColumn : 8}
//         xl={
//           props.fieldColumn
//             ? props.fieldColumn > 11
//               ? props.fieldColumn
//               : props.fieldColumn + 1
//             : 8
//         }
//       >
//         <FormControl {...props.input} style={{ width: "100%" }}>

//         {/* <DatePicker
//         error={props.meta.error && props.meta.touched && !props.meta.valid}
//         helperText={props.meta.touched ? props.meta.error : ""}
//         label={props.label}
//         size="small"
//         value={props.input?.value}
//         onChange={(newValue) => {
//           setValue(newValue);
//         }}
//         renderInput={(params) => <TextField {...params} />}
//       /> */}
//           {/* <TextField
//             error={props.meta.error && props.meta.touched && !props.meta.valid}
//             helperText={props.meta.touched ? props.meta.error : ""}
//             label={props.label}
//             size="small"
//             type={props.type}
//             required={props.required}
//             value={props.input?.value}
//           /> */}
//         </FormControl>
//       </Grid>
//     </Grid>
// };
