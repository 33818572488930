import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Container,
  createTheme,
  CssBaseline,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { actions } from "@redux/user/userRedux";
import { localStorageKeys } from "common/constants";
import userDataAccess from "dataAccess/emp/userDataAccess";
import { InputFieldWithoutLabelMui } from "libComp/common/inputRenderes";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import { useSnackbar } from "notistack";
import { connect, useDispatch } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const Login = (props: any) => {
  const theme = createTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const login = (model) => {
    return userDataAccess
      .login(model)
      .then((data) => {
        //Cookies.set("access_token", response.headers["x-access-token"]);

        localStorage.setItem(localStorageKeys.user, JSON.stringify(data.data));
        localStorage.setItem(localStorageKeys.authorization, data.data.token);
        enqueueSnackbar("Logged in!", { variant: "info" });
        props.history.push("/tenant");
        dispatch(actions.login(data.data));
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <>
      <ThemeProvider theme={theme}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{ minHeight: "60vh", mt: 20 }}
        >
          <CssBaseline />
          <Box
            sx={{
              marginTop: 12,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              border: 1,
              borderRadius: 1,
              borderColor: "grey.300",
              p: 2,
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in to continue
            </Typography>
            <Box
              component="div"
              sx={{
                mt: 2,
              }}
            >
              <form
                onSubmit={props.handleSubmit((data) => login(data))}
                noValidate
              >
                <Field
                  name="userName"
                  component={InputFieldWithoutLabelMui}
                  label="Username"
                  placeholder="Username"
                  validate={required}
                />
                <br />
                <Field
                  name="password"
                  type="password"
                  component={InputFieldWithoutLabelMui}
                  label="Password"
                  placeholder="Password"
                  validate={required}
                />
                {/* <Field
                  name="rememberMe"
                  layout="horizontal"
                  component={SolpayCheckBox}
                  label="Remember Me"
                /> */}
                {props.error && (
                  <Typography
                    variant="subtitle2"
                    component="div"
                    classes={{ root: style.error }}
                  >
                    {props.error}
                  </Typography>
                )}
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classes={{ root: style.button }}
                    sx={{ my: 2 }}
                    variant="contained"
                    type="submit"
                    disabled={props.submitting}
                  >
                    {props.submitting && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    )}
                    Sign In
                  </Button>
                </Box>
              </form>
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    </>
  );
};

//   return (
//     <div className="login-form-container">
//       <div className="login-form">
//         <h5>Please login to continue</h5>
//         <hr />
//         <form onSubmit={props.handleSubmit((data) => login(data))} noValidate>
//           <Field
//             name="userName"
//             component={InputField}
//             label="Username"
//             validate={required}
//           />
//           <Field
//             name="password"
//             type="password"
//             component={InputField}
//             label="Password"
//             validate={required}
//           />
//           {props.error && (
//             <Typography
//               variant="subtitle2"
//               component="div"
//               classes={{ root: style.error }}
//             >
//               {props.error}
//             </Typography>
//           )}
//           <Button disabled={props.submitting} type="submit">
//             {props.submitting && (
//               <Spinner
//                 as="span"
//                 animation="border"
//                 size="sm"
//                 role="status"
//                 aria-hidden="true"
//               />
//             )}
//             Login
//           </Button>
//         </form>
//       </div>
//     </div>
// );
// };

const mapStateToProps = (state) => ({});

const LoginForm = reduxForm({
  form: "LoginForm",
})(Login);

export default connect(mapStateToProps)(LoginForm);
