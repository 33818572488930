import BusinessFit from "libComp/main/sections/businessFit";
import HomeVideo from "libComp/main/sections/homeVideo";
import React from "react";

export default function Home() {
  return (
    <div>
      <HomeVideo />
      <BusinessFit />
    </div>
  );
}
