import PageButtons from "libComp/sections/pageButtons";
import imageUser from "media/images/blueImages/users.svg";
function Settings() {
  return (
    <div>
      <PageButtons 
        buttons={[
          {
            header: "Business Settings",
            description: "Manage Business Settings",
            to: "/settings/business",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Payroll Settings",
            description: "Additional Payroll Settings",
            to: "/settings/payroll",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Subscription",
            description: "Add / Edit Subscription",
            to: "/settings/subscription",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Accounts",
            description: "Account List",
            to: "/settings/accounts",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Bank Feeds",
            description: "Bank Feeds Settings",
            to: "/settings/bankfeeds",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Invoices",
            description: "Add or Edit Invoices",
            to: "/settings/invoices",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Purchase",
            description: "Add or Edit Purchase",
            to: "/settings/purchase",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
          {
            header: "Users",
            description: "Manage Users",
            to: "/settings/users",
            imageSrc: imageUser,
            imageAlt: "icon",
          },
        ]}
      />
    </div>
  );
}

export default Settings;
