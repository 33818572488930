import { quatationFormUrl, quatationListUrl } from "common/config";
import { modules } from "common/enums";
import Auth from "hoc/auth2";
import ParentLayout from "libComp/common/layout/parentLayout";
import PublicLayout from "libComp/common/layout/publicLayout";
import SecondaryLayout from "libComp/common/layout/secondaryLayout";
import Accounting from "pages/accounting/accounting";
import Purchases from "pages/accounting/purchases/purchases";
import Sales from "pages/accounting/sales/sales";
import Admin from "pages/admin/admin";
import AuditLogs from "pages/admin/audit_logs/auditLogs";
import AddEditOrganization from "pages/admin/config/addEditOrganization";
import Organizations from "pages/admin/config/organizations";
import addEditSupportTicket from "pages/admin/tickets/supportTicketFormView";
import Tickets from "pages/admin/tickets/tickets";
import Unsubscription from "pages/admin/unsubscribe_request/unsubscription";
import agentList from "pages/Agent/agentList";
import addEditBill from "pages/bill/addEditBill";
import BillList from "pages/bill/billList";
import AddEditJournal from "pages/bkp/journals/addEditJournal";
import Journal from "pages/bkp/journals/journals";
import YearClosing from "pages/bkp/yearClosing";
import changePassword from "pages/changePassword";
import clientFormView from "pages/contacts/client/clientFormView";
import ClientList from "pages/contacts/client/clientList";
import Contact from "pages/contacts/contact";
import SupplierFormView from "pages/contacts/supplier/supplierForm";
import SupplierList from "pages/contacts/supplier/supplierList";
//#endregion
//#region tenant
import Tenant from "pages/dashboard";
import Forbidden from "pages/forbidden";
import Help from "pages/help/help";
import HelpPostTicket from "pages/help/helpPostTicket";
//#region main
import Home from "pages/home";
import AddEditInvoice from "pages/inv/invoice/addEditInvoice/addEditInvoice";
import Invoice from "pages/inv/invoice/invoices";
import upsertProduct from "pages/inv/product/productFormView";
import Products from "pages/inv/product/products";
import Login from "pages/login";
import NotAuthorize from "pages/notAuthorize";
import paymentList from "pages/payment/paymentList";
import addEditEmployee from "pages/payroll/emp/employees/addEditEmployee/addEditEmployee";
import Employees from "pages/payroll/emp/employees/employees";
import TenantPayroll from "pages/payroll/payroll";
import PayrollSettings from "pages/payroll/pyr/payrollSettings/payrollSettings";
import UpsertPaymentHead from "pages/payroll/pyr/payrollSettings/upsertPaymentHead";
import PayRun from "pages/payroll/pyr/payRun/payRun";
import PayRunSummary from "pages/payroll/pyr/payRun/payRunSummary";
import PreviousPay from "pages/payroll/pyr/payRun/previousPay";
import ReviewPayRun from "pages/payroll/pyr/payRun/reviewPayRun";
import atoReport from "pages/payroll/pyr/report/atoReport";
import detailedReport from "pages/payroll/pyr/report/detailedReport";
import employeeLeaveBalanceReport from "pages/payroll/pyr/report/employeeLeaveBalanceReport";
import paygPaymentSummaryReport from "pages/payroll/pyr/report/paygPaymentSummaryReport";
import pyrReport from "pages/payroll/pyr/report/pyrReport";
import summaryReport from "pages/payroll/pyr/report/summaryReport";
import RegistrationComplete from "pages/payroll/pyr/superAnnuation/registrationComplete";
import ReviewBankDetails from "pages/payroll/pyr/superAnnuation/reviewBankDetails";
import ReviewBillingContact from "pages/payroll/pyr/superAnnuation/reviewBillingContact";
import ReviewBusiness from "pages/payroll/pyr/superAnnuation/reviewBusiness";
import ReviewSuperAuthorizer from "pages/payroll/pyr/superAnnuation/reviewSuperAuthorizer";
import SuperAnnuation from "pages/payroll/pyr/superAnnuation/superAnnuation";
import SuperRegistration from "pages/payroll/pyr/superAnnuation/superRegistration";
import SuperPayments from "pages/payroll/pyr/superPayment/superPayments";
import SuperStreamReport from "pages/payroll/pyr/superPayment/superStreamReport";
import ViewSuperPayment from "pages/payroll/pyr/superPayment/viewSuperPayment";
import SuperFunds from "pages/payroll/superFund/superFunds";
import SuperFundsDetails from "pages/payroll/superFund/superFundsDetails";
import addEditQuotation from "pages/quotation/addEditQuotation/addEditQuotation";
import Quotation from "pages/quotation/quotation";
import CustomerStatement from "pages/report/customerStatement";
import Report from "pages/report/report";
import SalesReport from "pages/report/salesReport";
import SalesStatement from "pages/report/salesStatement";
import UnpaidReports from "pages/report/unpaidReports";
import Business from "pages/settings/config/business/addEditBussiness";
import Settings from "pages/settings/settings";
import ChangePackage from "pages/settings/subscriptions/changePackage/changePackage";
import CreditCard from "pages/settings/subscriptions/creditCard/creditCard";
import SubscriptionLayout from "pages/settings/subscriptions/subscriptionLayout";
import Subscription from "pages/settings/subscriptions/unsubscriptionFeedback";
import Register from "pages/signup";
import addEditUser from "pages/user/addeditUser";
import ResetPassword from "pages/user/resetPassword";
import userList from "pages/user/userList";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

//#endregion

const Router = () => {
  return (
    <ParentLayout>
      <Switch>
        <Route path={["/index", "/login", "/signup"]} exact>
          <PublicLayout>
            <Switch>
              <Route path="/index" exact component={Auth(Home, [], false)} />
              <Route path="/login" exact component={Auth(Login, [], false)} />
              <Route
                path="/signup"
                exact
                component={Auth(Register, [], false)}
              />
            </Switch>
          </PublicLayout>
        </Route>
        <Route path={"/*"}>
          <SecondaryLayout>
            <Switch>
              <Route path="/not-authorize" component={NotAuthorize} />
              <Route path="/forbidden" component={Forbidden} />
              <Route
                path={["/", "/tenant"]}
                exact
                component={Auth(Tenant, [], false)}
              />
              <Route
                path="/tenant/payroll"
                exact
                component={Auth(TenantPayroll, [modules.payroll])}
              />
              <Route
                path="/accounting"
                exact
                component={Auth(Accounting, [modules.payroll])}
              />
              <Route
                path="/contact"
                exact
                component={Auth(Contact, [modules.payroll])}
              />
              <Route
                path="/reports"
                exact
                component={Auth(Report, [modules.payroll])}
              />
              <Route
                path="/reports/sales"
                exact
                component={Auth(SalesReport, [modules.purchaseBill])}
              />
              <Route
                path="/reports/sales/sales-statement"
                exact
                component={Auth(SalesStatement, [modules.payroll])}
              />
              <Route
                path="/reports/sales/customer-statement"
                exact
                component={Auth(CustomerStatement, [modules.payroll])}
              />
              <Route
                path="/reports/sales/unpaid-reports"
                exact
                component={Auth(UnpaidReports, [modules.payroll])}
              />
              <Route
                path="/accounting/sales"
                exact
                component={Auth(Sales, [modules.payroll])}
              />
              <Route
                path="/accounting/purchases"
                exact
                component={Auth(Purchases, [modules.purchaseBill])}
              />
              <Route
                path="/accounting/journals"
                exact
                component={Auth(Journal, [modules.payroll])}
              />
              <Route
                path="/accounting/journal/addedit/:id"
                exact
                component={Auth(AddEditJournal, [modules.payroll])}
              />
              <Route
                path="/accounting/sales/invoices"
                exact
                component={Auth(Invoice, [modules.payroll])}
              />
              <Route
                path="/accounting/sales/invoice/addedit/:id"
                exact
                component={Auth(AddEditInvoice, [modules.payroll])}
              />
              {/* <Route
                path={quatationFormUrl + ":id"}
                exact
                component={Auth(AddEditInvoice, [modules.payroll])}
              /> */}
              <Route
                path="/accounting/purchase/bills"
                exact
                component={Auth(BillList, [modules.purchaseBill])}
              />
              <Route
                path="/accounting/purchase/bill/addedit/:id"
                exact
                component={Auth(addEditBill, [modules.purchaseBill])}
              />
              <Route
                path={quatationListUrl}
                exact
                component={Auth(Quotation, [modules.payroll])}
              />
              <Route
                path={quatationFormUrl + ":id"}
                exact
                component={Auth(addEditQuotation, [modules.payroll])}
              />
              <Route
                path="/accounting/yearclosing"
                exact
                component={Auth(YearClosing, [modules.payroll])}
              />
              <Route
                path="/contact/clients"
                exact
                component={Auth(ClientList, [modules.payroll])}
              />
              <Route
                path="/contact/client/Individual/new/:clientId"
                exact
                component={Auth(clientFormView, [modules.payroll])}
              />
              <Route
                path="/contact/client/Individual/edit/:clientId"
                exact
                component={Auth(clientFormView, [modules.payroll])}
              />
              <Route
                path="/contact/client/Business/new/:clientId"
                exact
                component={Auth(clientFormView, [modules.payroll])}
              />
              <Route
                path="/contact/client/Business/edit/:clientId"
                exact
                component={Auth(clientFormView, [modules.payroll])}
              />
              <Route
                path="/accounting/purchase/products"
                exact
                component={Auth(Products, [modules.payroll])}
              />
              <Route
                path="/accounting/purchase/product/addedit/:productId"
                exact
                component={Auth(upsertProduct, [modules.payroll])}
              />
              <Route
                path="/accounting/contacts/suppliers"
                exact
                component={Auth(SupplierList, [modules.payroll])}
              />
              <Route
                path="/accounting/contacts/supplier/new/:supplierId"
                exact
                component={Auth(SupplierFormView, [modules.payroll])}
              />
              <Route
                path="/accounting/contacts/supplier/edit/:supplierId"
                exact
                component={Auth(SupplierFormView, [modules.payroll])}
              />
              <Route
                path="/payroll/employees"
                exact
                component={Auth(Employees, [modules.payroll])}
              />
              <Route
                path="/payroll/superannuation"
                exact
                component={Auth(SuperAnnuation, [modules.payroll])}
              />
              <Route
                path="/payroll/superregistration"
                exact
                component={Auth(SuperRegistration, [modules.payroll])}
              />
              <Route
                path="/payroll/super-payments"
                exact
                component={Auth(SuperPayments, [modules.payroll])}
              />
              <Route
                path="/payroll/super-payment/view/:id"
                exact
                component={Auth(ViewSuperPayment, [modules.payroll])}
              />
              <Route
                path="/payroll/super-payment/super-stream-report"
                exact
                component={Auth(SuperStreamReport, [modules.payroll])}
              />
              <Route
                path="/payroll/reviewbusiness"
                exact
                component={Auth(ReviewBusiness, [modules.payroll])}
              />
              <Route
                path="/payroll/reviewsuperauthorizer/:id/:hastoupdate"
                exact
                component={Auth(ReviewSuperAuthorizer, [modules.payroll])}
              />
              <Route
                path="/payroll/reviewbankdetails/:id/:hastoupdate"
                exact
                component={Auth(ReviewBankDetails, [modules.payroll])}
              />
              <Route
                path="/payroll/reviewbillingcontact/:id/:hastoupdate"
                exact
                component={Auth(ReviewBillingContact, [modules.payroll])}
              />
              <Route
                path="/payroll/registrationComplete/:id/"
                exact
                component={Auth(RegistrationComplete, [modules.payroll])}
              />
              <Route
                path="/payroll/payrun/startpay"
                exact
                component={Auth(PayRun, [modules.payroll])}
              />
              <Route
                path="/payroll/payrun/reviewpay/:id"
                exact
                component={Auth(ReviewPayRun, [modules.payroll])}
              />
              <Route
                path="/payroll/pastpayrun"
                exact
                component={Auth(PreviousPay, [modules.payroll])}
              />
              <Route
                path="/payroll/payrun/summary/:id"
                exact
                component={Auth(PayRunSummary, [modules.payroll])}
              />
              <Route
                path="/payroll/employee/addedit/:employeeId"
                exact
                component={Auth(addEditEmployee, [modules.payroll])}
              />
              <Route
                path="/payroll/payroll-settings"
                exact
                component={Auth(PayrollSettings, [modules.payroll])}
              />
              <Route
                path="/payroll/payroll-settings/paymenthead/addedit/:headId"
                exact
                component={Auth(UpsertPaymentHead, [modules.payroll])}
              />
              <Route
                path="/payroll/payroll-settings/paymenthead/addedit/:headId/:isOvertimePay"
                exact
                component={Auth(UpsertPaymentHead, [modules.payroll])}
              />
              <Route
                path="/payroll/Report"
                exact
                component={Auth(pyrReport, [modules.payroll])}
              />
              <Route
                path="/payroll/Report/PAYGPaymentSummaryReport"
                exact
                component={Auth(paygPaymentSummaryReport, [modules.payroll])}
              />
              <Route
                path="/payroll/Report/SummaryReport"
                exact
                component={Auth(summaryReport, [modules.payroll])}
              />
              <Route
                path="/payroll/Report/DetailedReport"
                exact
                component={Auth(detailedReport, [modules.payroll])}
              />
              <Route
                path="/payroll/Report/ATOReport"
                exact
                component={Auth(atoReport, [modules.payroll])}
              />
              <Route
                path="/payroll/Report/EmployeeLeaveBalanceReport"
                exact
                component={Auth(employeeLeaveBalanceReport, [modules.payroll])}
              />
              <Route
                path="/admin"
                exact
                component={Auth(Admin, [modules.payroll, modules.invoicing])}
              />
              <Route
                path="/admin/organizations"
                exact
                component={Auth(Organizations, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/organization/addedit/:id"
                exact
                component={Auth(
                  AddEditOrganization,
                  [modules.payroll, modules.invoicing],
                  false
                )}
              />
              <Route
                path="/admin/users/:scope"
                exact
                component={Auth(userList, [modules.payroll, modules.invoicing])}
              />
              <Route
                path="/admin/user/:id"
                exact
                component={Auth(addEditUser, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/reset-password/:id"
                exact
                component={Auth(ResetPassword, [])}
              />
              <Route
                path="/admin/org_user/:id"
                exact
                component={Auth(addEditUser, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/superfunds"
                exact
                component={Auth(SuperFunds, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/superfund/addEdit/:id"
                exact
                component={Auth(SuperFundsDetails, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/tickets"
                exact
                component={Auth(Tickets, [modules.payroll, modules.invoicing])}
              />
              <Route
                path="/admin/ticket/:id"
                exact
                component={Auth(addEditSupportTicket, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/audit-activity"
                exact
                component={Auth(AuditLogs, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/paymentlist"
                exact
                component={Auth(paymentList, [modules.admin])}
              />
              <Route
                path="/admin/agent-lists"
                exact
                component={Auth(agentList, [modules.admin])}
              />
              {/* <Route
                path="/admin/subscription/payments/:tenantId"
                exact
                component={Auth(SubscriptionLayout, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              /> */}
              <Route
                path="/settings"
                exact
                component={Auth(Settings, [modules.payroll, modules.invoicing])}
              />
              <Route
                path="/help"
                exact
                component={Auth(Help, [modules.payroll, modules.invoicing])}
              />
              <Route
                path="/help/post-ticket"
                exact
                component={Auth(HelpPostTicket, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/settings/business"
                exact
                component={Auth(Business, [modules.payroll, modules.invoicing])}
              />
              <Route
                path="/settings/subscription/unsubscribe"
                exact
                component={Auth(Subscription, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/settings/subscription/payments"
                exact
                component={Auth(SubscriptionLayout, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/settings/subscription/card"
                exact
                component={Auth(CreditCard, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/settings/subscription/packages"
                exact
                component={Auth(ChangePackage, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/admin/unsubscriptions"
                exact
                component={Auth(Unsubscription, [
                  modules.payroll,
                  modules.invoicing,
                ])}
              />
              <Route
                path="/change-password"
                exact
                component={Auth(changePassword, [])}
              />
              <Route path="/*">
                <Redirect to="/" />
              </Route>
            </Switch>
          </SecondaryLayout>
        </Route>
      </Switch>
    </ParentLayout>
  );
};
export default withRouter(Router);
