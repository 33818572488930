import { Button } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { formatDate } from "libComp/utility.service";

export const getPreviousPayTableColumns = (props, extra: any) => {
  const columns: GridColumns = [
    {
      headerName: "Date",
      field: "duration",
      sortable: false,
    },
    {
      headerName: "Pay Date",
      field: "payDate",
      sortable: false,
      renderCell: (params) => formatDate(params.row?.payDate),
    },
    {
      headerName: "Actions", //delete,view,complete buttons...
      field: "progress",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.progressStatus !== 3 && (
              <Button
                sx={{ m: 2 }}
                variant="contained"
                size="small"
                onClick={() => {
                  props.history.push(
                    "/payroll/payrun/reviewpay/" + params.row.id
                  );
                }}
              >
                Complete Now
              </Button>
            )}

            {params.row.progressStatus === 3 && (
              <Button
                sx={{ m: 2 }}
                variant="contained"
                size="small"
                onClick={() => {
                  props.history.push(
                    "/payroll/payrun/summary/" + params.row.id
                  );
                }}
              >
                View
              </Button>
            )}

            {params.row.allowDelete && (
              <Button
                sx={{ m: 2 }}
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  extra.onDeleteAction(params.row.id);
                }}
              >
                Delete
              </Button>
            )}
          </>
        );
      },
    },
  ];
  return columns;
};
