import FooterCopyright from "libComp/common/footerCopyright";

function Footer() {
  return (
    <>
      <FooterCopyright />
    </>
  );
}

export default Footer;
