import { IReduxState } from '@redux';
import superDataAccess from 'dataAccess/pyr/superAnnuationDataAccess';
import { actions as commonActions } from '../commonRedux';

const typesPrefix = 'SUPER_ANNUATION_';
const types = {
  isRegistered: typesPrefix + 'GET_ORGANIZATION_IS_REGISTERED',
  getOrganization: typesPrefix + 'GET_ORGANIZATION',
  getSuperAuthoriser: typesPrefix + 'GET_SUPER_AUTHORISER',
  getBankingDetails: typesPrefix + 'GET_BANKING_DETAILS',
  getBillingContact: typesPrefix + 'GET_BILLING_CONTACT',
  getSuperPayment: typesPrefix + 'GET_SUPER_PAYMENTS',
  getSuperPaymentById: typesPrefix + 'GET_SUPER_PAYMENTS__BY_ID',
  getSuperStreamReport: typesPrefix + 'GET_SUPER_STREAM_REPORT',
  failed: typesPrefix + 'FAILED',
  loading: typesPrefix + 'LOADING',
  removeUiMessage: typesPrefix + 'REMOVE_UI_MESSAGE',
};
export const actions = {
  getOrganizationIsRegistered: () => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getOrganizationIsRegistered()(state.commonState.commonActions)
      .then((orgData) => {
        dispatch({
          type: types.isRegistered,
          payload: {
            isRegistered: orgData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },
  getOrganization: () => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getOrganizationToReview()(state.commonState.commonActions)
      .then((orgData) => {
        dispatch({
          type: types.getOrganization,
          payload: {
            organization: orgData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },

  getSuperAuthoriser: (id, hasToUpdate) => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getSuperAuthoriser(
        id,
        hasToUpdate
      )(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getSuperAuthoriser,
          payload: {
            superAuthoriser: data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },
  getBankDetails: (id, hasToUpdate) => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getBankDetails(
        id,
        hasToUpdate
      )(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getBankingDetails,
          payload: {
            bankingDetails: data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },
  getBillingContact: (id, hasToUpdate) => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getBillingContact(
        id,
        hasToUpdate
      )(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getBillingContact,
          payload: {
            billingContact: data.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },

  getSuperStreamReport: () => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getSuperStreamReport()(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getSuperStreamReport,
          payload: {
            superStreamReport: data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },

  getSuperPaymentList: () => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getSuperPaymentList()(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getSuperPayment,
          payload: {
            superPayment: data.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },
  getSuperPaymentById: (id) => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    superDataAccess
      .getSuperPaymentById(id)(state.commonState.commonActions)
      .then((Data) => {
        dispatch({
          type: types.getSuperPaymentById,
          payload: {
            superPaymentId: Data
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
};

export interface ISuperAnnuationState {
  isRegistered: boolean;
  organization: any;
  superAuthoriser: any;
  superStreamReport: Array<any>;
  superPayment: Array<any>;
  superPaymentId: any
  bankingDetails: any;
  billingContact: any;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: ISuperAnnuationState = {
  isRegistered: false,
  organization: {},
  bankingDetails: {},
  superPayment: [],
  superStreamReport: [],
  superPaymentId: {},
  billingContact: {},
  superAuthoriser: {},
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: ISuperAnnuationState = initialState,
  action: Action
) => {
  switch (action.type) {
    case types.isRegistered: {
      return {
        ...state,
        isRegistered: action.payload.isRegistered,
        isLoading: false,
        isError: false,
      };
    }
    case types.getOrganization: {
      return {
        ...state,
        organization: action.payload.organization,
        isLoading: false,
        isError: false,
      };
    }
    case types.getSuperAuthoriser: {
      return {
        ...state,
        superAuthoriser: action.payload.superAuthoriser.data,
        uiMessages: action.payload.superAuthoriser.uiMessages,
        isLoading: false,
        isError: false,
      };
    }
    case types.getBankingDetails: {
      return {
        ...state,
        bankingDetails: action.payload.bankingDetails.data,
        uiMessages: action.payload.bankingDetails.uiMessages,
        isLoading: false,
        isError: false,
      };
    }
    case types.getBillingContact: {
      return {
        ...state,
        billingContact: action.payload.billingContact,
        isLoading: false,
        isError: false,
      };
    }
    case types.getSuperStreamReport: {
      return {
        ...state,
        superStreamReport: action.payload.superStreamReport.data,
        uiMessages: action.payload.superStreamReport.uiMessages,
        isLoading: false,
        isError: false,
      };
    }
    case types.getSuperPayment: {
      return {
        ...state,
        superPayment: action.payload.superPayment,
        uiMessages: action.payload.superPayment.uiMessages,
        isLoading: false,
        isError: false,
      };
    }
    case types.getSuperPaymentById:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        superPaymentId: action.payload.superPaymentId.data,
        totalCount: action.payload.totalCount,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
