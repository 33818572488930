import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as paymentActions } from "@redux/pay/paymentRedux";
import paymentDataAccess from "dataAccess/pay/paymentDataAccess";
import {
  InputFieldOneRow,
  InputFieldTwoRow,
} from "libComp/common/inputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const CreditCard = (props) => {
  const style = useStyleClass();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropdownData, setDropdownData] = useState([]);
  const commonState = useSelector((state: IReduxState) => state.commonState);


  useEffect(() => {
    dispatch(paymentActions.getDropDownData());
  }, []);

  const  paymentData = useSelector((state: any) => state?.payment);
  //console.log(paymentData);
  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Settings",
      to: null,
    },
    {
      title: "Payment",
      to: "/settings/subscription/payments",
    },
    {
      title: "Credit Card",
      to: "/settings/subscription/card",
    },
  ];

  const onFormSubmit = (model) => {
    return paymentDataAccess
      .updateCreditCard(model)(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Credit Card has been updated!", {
          variant: "success",
        });
        history.push("/settings/subscription/payments");
      })
      .catch((error: Error) => {
        enqueueSnackbar("Credit Card could not been saved!", {
          variant: "error",
        });
        throw new SubmissionError({ _error: error.message });
      });
  };

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="Update Credit Card" />

        <Box sx={{ flex: 1, width: "75", mx: "auto" }}>
          <form
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
            noValidate
          >
            <Grid container spacing={10}>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Field
                  name="name"
                  component={InputFieldTwoRow}
                  label="Name (as it appears on your card)"
                />
                <Field
                  name="cardNumber"
                  component={InputFieldOneRow}
                  label="Card number (no dashes or spaces)"
                  validate={required}
                />
                <Field
                  name="expMonth"
                  component={solpayReactSelect}
                  options={paymentData.dropdowns.expirationDate ? paymentData.dropdowns.expirationDate : []}
                  label="Expiration Date"
                  validate={required}
                />
                <Field
                  name="expYear"
                  component={solpayReactSelect}
                  options={paymentData.dropdowns.expirationYear ? paymentData.dropdowns.expirationYear : []}
                  label="Expiration Year"
                  validate={required}
                />
                <Stack
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={4}
                >
                  <Field
                    name="cardCCV"
                    component={InputFieldOneRow}
                    label="Security code (3 on back, Amex: 4 on front)"
                    validate={required}
                  />
                </Stack>

                <Divider />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classes={{ root: style.button }}
                    sx={{ m: 2 }}
                    variant="contained"
                    type="submit"
                    disabled={props.submitting}
                  >
                    {props.submitting ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    ) : (
                      <i className="fa fa-check"></i>
                    )}{" "}
                    Save
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
};

const creditCardForm = reduxForm<any>({
  form: "CreditCard",
  enableReinitialize: true,
})(CreditCard);

const mapStateToProps = ({ payment }) => ({
  payment,
});
export default connect(mapStateToProps)(creditCardForm);
