import { Cameraswitch } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import moment from "moment";

export const getOrgTableColumns = (props, extra) => {
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "ABN",
      field: "abn",
    },
    {
      headerName: "ACN",
      field: "acn",
    },
    {
      headerName: "Active Since",
      field: "createdOn",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Agent",
      field: "isAgent",
      sortable: false,
      renderCell: (params) => {
        return <>{params.row.isAgent ? "Agent" : ""}</>;
      },
    },
    {
      headerName: "Switch Tenant",
      field: "tenant",
      width: 120,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {extra ? (
              <IconButton
                title="Edit"
                onClick={() => {
                  extra.onSwitchTenantAction(params.row.id);
                }}
              >
                <Cameraswitch />
              </IconButton>
            ) : null}
          </>
        );
      },
    },
    {
      headerName: "Edit",
      field: "progress",
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              title="Edit"
              onClick={() => {
                props.history.push(
                  "/admin/organization/addedit/" + params.row.id
                );
              }}
            >
              <EditIcon />
            </IconButton>
          </div>
        );
      },
    },
    // {
    //   headerName: "Go",
    //   field: "progress",
    //   width: 120,
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       params.row.isActive && (
    //         <div>
    //           <IconButton
    //             size="small"
    //             title="Edit"
    //             onClick={() => {
    //               dispatch(userActions.alterOrg(params.row.id));
    //               props.history.push("/tenant");
    //             }}
    //           >
    //             <EditIcon />
    //           </IconButton>
    //           <IconButton size="small" title="Print" onClick={() => {}}>
    //             <Print />
    //           </IconButton>
    //           <IconButton size="small" title="Mail" onClick={() => {}}>
    //             <Mail />
    //           </IconButton>
    //         </div>
    //       )
    //     );
    //   },
    // },
  ];
  return columns;
};
