import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as superActions,
  ISuperAnnuationState,
} from "@redux/pyr/superAnnuationRedux";
import superAnnuationDataAccess from "dataAccess/pyr/superAnnuationDataAccess";
// import PayrollPageMenuHeader from "libComp/pageMenuHeaders/payrollPageMenuHeader";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

function ReviewBankDetails(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const superannuation: ISuperAnnuationState = props.superannuation;
  const superregistrationId = props.match.params.id;
  const hasToUpdate = props.match.params.hastoupdate;
  const dispatch = useDispatch();
  const [uiMessage, setUiMessage] = useState([]);

  useEffect(() => {
    if (superregistrationId) {
      dispatch(superActions.getBankDetails(superregistrationId, hasToUpdate));
    }
  }, [superregistrationId, hasToUpdate]);
  useEffect(() => {
    setUiMessage(props.uiMessages);
  }, [props.uiMessages]);

  const reviewBankDetails = (model) => {
    superAnnuationDataAccess
      .reviewBankDetails(model)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          props.history.push(
            "/payroll/reviewbillingcontact/" + data.data.id + "/" + hasToUpdate
          );
        } else toast.error(data.message);
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const steps = ["Organization", "Authoriser", "Bank", "Billing Contact"];

  const style = useStyleClass();

  return (
    <React.Fragment>
      <div className="page-container">
        <PageTitleHeader header="Set Up Superannuation" />
        {uiMessage &&
          uiMessage.map((item, index) => (
            <>
              <>
                <Alert
                  key={index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    setUiMessage([]);
                  }}
                >
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: item.messageText,
                    }}
                  />
                </Alert>
                <br />
              </>
            </>
          ))}

        <Box sx={{ width: "100%" }}>
          <Stepper alternativeLabel activeStep={2}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />

          <form
            noValidate
            onSubmit={props.handleSubmit((model) => reviewBankDetails(model))}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Select the banking details
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
                mb: 4,
              }}
            >
              Choose a bank account used for direct debits of superannuation
              payments
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "100%" }}
            >
              <Grid item xs={12} sm={12} md={12} lg={6}>
                <Stack
                  sx={{
                    border: 1,
                    p: 2,
                    borderColor: "#e3e3e3",
                    borderRadius: 2,
                  }}
                  direction="row"
                  spacing={6}
                  divider={<Divider orientation="vertical" flexItem />}
                >
                  <input
                    type="radio"
                    readOnly
                    checked={props.bankingDetails.accountName ? true : false}
                  ></input>
                  <Typography>{props.bankingDetails.accountName}</Typography>
                  <Typography>{props.bankingDetails.bsb}</Typography>
                  <Typography>{props.bankingDetails.accountNumber}</Typography>
                </Stack>
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    classes={{ root: style.button }}
                    variant="contained"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      props.history.push(
                        "/payroll/reviewsuperauthorizer/" +
                          superregistrationId +
                          "/" +
                          hasToUpdate
                      );
                    }}
                  >
                    Back
                  </Button>
                  {props.bankingDetails.accountName && (
                    <>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        sx={{ mr: 1 }}
                        classes={{ root: style.button }}
                        variant="contained"
                        type="submit"
                      >
                        Next
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </React.Fragment>
  );
}

const reviewSuperAuthorizer = reduxForm<any>({
  form: "ReviewBankDetails",
  enableReinitialize: true,
})(ReviewBankDetails);

const mapStatetoProps = (state: IReduxState, ownProops) => ({
  superannuation: state.superannuation,
  uiMessages: state.superannuation.uiMessages,
  bankingDetails: state.superannuation.bankingDetails,
  initialValues: { ...state.superannuation.bankingDetails },
});

export default connect(mapStatetoProps)(reviewSuperAuthorizer);
