import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IReduxState } from "@redux";
import {
  actions as unsubscriptionActions,
  IUnsubscritionState,
} from "@redux/unsubscription/unsubscriptionRedux";
import unsubscriptionDataAccess from "dataAccess/unsubscription/unsubscriptionDataAccess";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getUnsubscriptionTableColumns } from "./unsubscription-list.config";
import { defaultPageSize } from "common/constants";

function Unsubscription(props) {
  const [selection, setSelection] = useState(null);
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showConfirm, setShowConfirm] = useState(false);
  const [tenantId, setTenantId] = useState("");
  const [id, setId] = useState("");

  const dispatch = useDispatch();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  //const unsubscriptionState: IUnsubscritionState = props.unsubscription;
  const unsubscriptionState = useSelector((state: IReduxState) => state?.unsubscription);
  const listState = useSelector((state: IReduxState) => state?.listState);
  const title = "Unsubscribe Request Lists";

  const getUnsubscriptionList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "purchaseDate-desc"
    ) => {
      dispatch(unsubscriptionActions.getAll(pageNo + 1, pageSize, filters, sortPreference));
    },
    [selection]
  );

  const deleteTenant = (id, tenantId) => {
    unsubscriptionDataAccess
      .deleteTenant(
        id,
        tenantId
      )(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Tenant has been removed!", { variant: "success" });
          dispatch(unsubscriptionActions.getAll());
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => setShowConfirm(false));
  };

  let extraProps = {
    onDeleteAction: (id, tenantId) => {
      setId(id);
      setTenantId(tenantId);
      setShowConfirm(true);
    },
  };

  const tableColumns = getUnsubscriptionTableColumns(props, extraProps);
  return (
    <div className="page-container">
      <PageTitleHeader header={title} />

      <Dialog
        fullScreen={fullScreen}
        open={showConfirm}
        onClose={() => setShowConfirm(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {"Approve Un-Subscription Request"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Do you want to Approve the Un-Subscription Request for this Organization?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowConfirm(false)}>No</Button>
          <Button
            onClick={() => {
              deleteTenant(id, tenantId);
              setShowConfirm(false);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>

      <SolpayGrid
        columns={tableColumns}
        rows={unsubscriptionState.unsubscriptions}
        rowCount={unsubscriptionState.totalCount}
        loading={unsubscriptionState.isLoading}
        loadData={getUnsubscriptionList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getUnsubscriptionList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}
const mapStateToProps = ({ unsubscription }) => ({
  unsubscription,
});
export default connect(mapStateToProps)(Unsubscription);
