import { Box } from "@mui/material";
import { IReduxState } from "@redux";
import { IListState } from "@redux/listRedux";
import {
  actions as ticketActions,
  ITicketState,
} from "@redux/ticket/ticketRedux";
import { defaultPageSize } from "common/constants";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { getTicketTableColumns } from "pages/admin/tickets/ticket-list.config";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";

const Help = (props) => {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const ticketState: ITicketState = props.ticket;
  const title = "Previous Ticket";
  const listState: IListState = props.listState;

  const ticketButtons = [
    {
      title: "Post New Ticket",
      onClick: (index) => {
        props.history.push("/help/post-ticket");
      },
      iconName: "fa fa-phone",
      id: "phone",
    },
  ];

  const getTicketList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "createdOn-desc"
    ) => {
      dispatch(
        ticketActions.getAll(pageNo + 1, pageSize, filters, sortPreference)
      );
    },
    [selection]
  );

  const tableColumns = getTicketTableColumns(props);

  return (
    <div className="page-container">
      <PageTitleHeader header={title} buttons={ticketButtons} />

      <Box
        sx={{
          "& .super-app-theme--resolved": {
            bgcolor: (theme) => "#f3e08e",

            "&:hover": {
              bgcolor: (theme) => "#fd9191",
            },
          },
        }}
      >
        <SolpayGrid
          columns={tableColumns}
          rows={ticketState.tickets}
          rowCount={ticketState.totalCount}
          loading={ticketState.isLoading}
          loadData={getTicketList}
          triggerChange={(pageNumber, pageSize, sort) => {
            getTicketList(pageNumber, pageSize, listState.filters, sort);
          }}
        />
      </Box>
    </div>
  );
};


const mapStateToProps = ({ ticket, listState }) => ({ticket,listState});
export default connect(mapStateToProps)(Help);
