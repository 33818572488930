import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { useStyleClass } from "styles/theming/useStyleClass";
import { ReduxFieldProps } from "./fied-prop.type";

export const SolpayTextArea = (props: ReduxFieldProps) => {
  const style = useStyleClass();
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            classes={{ root: style.inputStyle }}
            required={props.required}
            value={props.input?.value}
            multiline
            maxRows={4}
            label={props.label}
            placeholder={props.label}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
