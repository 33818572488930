import PageMenuTitle, {
  IBreadcrumbs,
} from 'libComp/common/layout/newLayout/pageTitleHeaders/pageMenuTitle';
import React from 'react';

const PageBreadcrumb = (props) => {
  const MenuItems: IBreadcrumbs[] = props.menuItem;
  return (
    <div style={{ marginTop: '45px' }}>
      <PageMenuTitle menuItems={MenuItems} />
    </div>
  );
};

export default PageBreadcrumb;
