import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';
class clientDataAccess {
  saveIndividualClient = (client) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData('Individual/save', client)(commonActions);
  };
  saveCompanyClient = (client) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData('Company/save', client)(commonActions);
  };
  getClientList =
    (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters['filter.' + item.propertyName] =
            item.value + '-' + item.type;
        }
      });
      return commonDataAccess.getData('Client/getall', {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };
  getClient = (clientId, clientType) => (commonActions: ICommonActions) => {
    switch (clientType) {
      case '1':
        return commonDataAccess.getData('Company/get', {
          id: clientId,
        })(commonActions);
      case '2':
        return commonDataAccess.getData('Individual/get', {
          id: clientId,
        })(commonActions);
    }
  };
  isClientCodeAvaiable = (clientCode, clientId) => {
    return commonDataAccess.getData('Client/isclientcodeavailable', {
      ClientCode: clientCode,
      ClientId: clientId,
    })(null);
  };
  getClientByName = (client) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'ClientInvoice/getclientlist?term=' + client
    )(commonActions);
  };
  getSupplierByName = (supplier) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'ClientInvoice/getclientlist?term=' + supplier
    )(commonActions);
  };
}
export default new clientDataAccess();
