import { useAppSelector } from "@redux/hook.type";
import { actions, IUserListState } from "@redux/user/userListRedux";
import { defaultPageSize, filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { getUserListTableColumns } from "./userList.config";

function UserList(props) {
  console.log(props);
  const userState: IUserListState = props.userList;
  const [selection, setSelection] = React.useState(null);
  const title = "User List";

  let urlString = props.location.pathname;

  const isOrgUser = urlString.includes("/admin/users/all");

  let scope = props.match.params.scope;

  const listState = useAppSelector((state) => state.listState);

  const dispatch = useDispatch();

  const getUserList = useCallback(
    (pageNo = 0, pageSize = defaultPageSize, filters = [], sortPreference = "") => {
      dispatch(actions.getUsers(pageNo + 1, pageSize, filters, sortPreference));
    },
    [selection]
  );

  // useEffect(() => {
  //   dispatch(listActions.updateSortString("UserName-ASC"));

  //   dispatch(
  //     actions.getUsers(pageNo, pageSize, filters, sortPreference, scope)
  //   );
  // }, []);

  const userButtons = [
    {
      title: "Add User",
      onClick: (index) => {
        isOrgUser
          ? props.history.push("/admin/agent/create")
          : props.history.push("/admin/user/create");
      },
      iconName: "fa fa-plus",
      id: "useradd1",
    },
  ];

  //props.IsOrgUSer = isOrgUser;
  const tableColumns = getUserListTableColumns(props, isOrgUser);

  return (
    <div className="page-container">
      <PageTitleHeader
        header={title}
        buttons={isOrgUser ? null : userButtons}
      />
      <Filter
        filterOptions={[
          {
            propertyName: "UserName",
            placeholder: "Username",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "Email",
            placeholder: "Email",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "TenantName",
            placeholder: "Tenant Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            actions.getUsers(pageNo, pageSize, filters, sortData, scope)
          );
        }}
      />

      <SolpayGrid
        columns={tableColumns}
        rows={userState.users}
        rowCount={userState.totalCount}
        loading={userState.isLoading}
        loadData={getUserList}
        triggerChange={(pageNumber, pageSize, sort) => {
          //console.log("page number", pageNumber);
          getUserList(pageNumber, pageSize, listState.filters, sort);
        }}
        // onPageChange={(pageNumber, details) => {

        //   console.log("page Number",pageNumber);
        //   dispatch( getBillList(pageNumber, 10, listState.filters, "purchaseDate-desc"));

        // }}
      />
    </div>
  );
}
const mapStateToProps = ({ userList, listState }) => ({
  userList,
  listState,
});
export default connect(mapStateToProps)(UserList);
