import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { fetchBilllookUpData, GetBillById } from "@redux/bill/billActions";
import billDataAccess from "dataAccess/bill/billDataAccess";
import invoiceDataAccess from "dataAccess/inv/invoiceDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  InputFieldOneRow,
  InputFieldOneRowReadOnly,
  InputHidden,
} from "libComp/common/inputRenderes";
import SolpayModal from "libComp/common/solpayModal";
import SolpayReactSelectIcon from "libComp/common/solpaySelectRendersWithIcon";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { change, Field, FieldArray, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import SupplierFormView from "../contacts/supplier/supplierForm";
import AddEditOrderPayment from "../payment/addEditOrderPayment";
import BillDetail from "./billDetails";
import BillPayment from "./billPayment";

function BillFormView(props) {
  const style = useStyleClass();
  // const invoiceState: IInvoiceState = props.invoice;

  // const supplierList= props.supplierList;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let supplierList = [];
  const error = props.error;
  let isShowPaymentButton = true;

  if (props.supplierList.length > 0) supplierList = props.supplierList;

  const [suppliers, setSuppliers] = useState([]);
  const [isAddPaymentrow, setIsAddPaymentrow] = useState(false);
  const [isAddPaymentModel, setIsAddPaymentModel] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  const dispatch = useDispatch();
  const billId = props.match.params.id;

  const [showClientModal, setShowClientModal] = useState(false);

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const discountAmount = props.discountAmount ? props.discountAmount : 0.0;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddPaymentModelClose = () => setIsAddPaymentModel(false);
  const handleAddPaymentModelShow = () => setIsAddPaymentModel(true);

  const supplierAdd = (data) => {
    supplierList.push(data);
    //  setSuppliers([...suppliers,{label:data.data.fullName,id:data.data.supplierId,value:data.data.supplierId}]);
  };

  useEffect(() => {
    if (billId > 0) {
      dispatch(GetBillById(billId));
      invoiceDataAccess
        .getAllPaymentMethod()(commonState.commonActions)
        .then((data) => {
          setPaymentMethodOptions(
            data.data.map((item, index) => ({
              ...item,
              value: item.id,
            }))
          );
        });
    }
    dispatch(fetchBilllookUpData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBillFormSubmit = (model) => {
    return billDataAccess
      .saveBill(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;

        if (response.success) {
          enqueueSnackbar("Bill has been saved!", { variant: "success" });
          props.history.push("/accounting/purchase/bills");
        } else
          enqueueSnackbar("Bill could not been saved!", { variant: "error" });
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };
  const billButtons = [
    {
      title: "Add Bill",
      onClick: (index) => {
        props.history.push("/accounting/purchase/bill/addedit/0");
      },
      iconName: "fa fa-plus",
    },
  ];

  // Breadcrumb
  let MenuItems = [];
  if (billId > 0) {
    MenuItems = [
      {
        title: "Bill",
        to: "/accounting/purchase/bills",
      },
      {
        title: "Edit",
        to: "/accounting/purchase/bill/addedit/{billId}",
      },
    ];
  } else {
    MenuItems = [
      {
        title: "Bill",
        to: "/accounting/purchase/bills",
      },
      {
        title: "New",
        to: "/accounting/purchase/bill/addedit/0",
      },
    ];
  }

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header={billId === "0" ? "New Bill" : "Edit Bill"} />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => {
            onBillFormSubmit(data);
          })}
          noValidate
        >
          <Grid container spacing={10} sx={{ width: "90%" }}>
            <Grid item xl={6} md={6}>
              <Field
                name="billNumber"
                label="Bill Number"
                component={InputFieldOneRowReadOnly}
              />
              <Field
                name="supplierId"
                label="Supplier"
                validate={[required]}
                options={supplierList}
                component={SolpayReactSelectIcon}
                Addlabel="Add a new Supplier"
                iconEvent={handleShow}
              />
              <Field component={InputHidden} name="supplierList" />
              <Field
                name="reference"
                component={InputFieldOneRow}
                label="Reference"
              />
            </Grid>

            <Grid item xl={6} md={6}>
              <Field
                name="purchaseDate"
                md={5}
                sm={6}
                component={DatePickerFieldOneRow}
                label="Bill Date"
                validate={required({
                  msg: "This is a required field",
                })}
              />
              <Field
                name="deliveryDate"
                md={5}
                sm={6}
                component={DatePickerFieldOneRow}
                label="Delivery Date"
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xl={12} md={12} sx={{ overflow: "hidden" }}>
              <PageTitleHeader header="Products / Services" />
              <FieldArray
                name="purchaseBillDetails"
                component={BillDetail}
                props={{ dispatch, discountAmount }}
              />
            </Grid>
          </Grid>
          {props.error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {props.error}
            </Typography>
          )}

          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
            &nbsp;
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save and Send email
            </Button>
          </Box>
        </form>
      </Box>
      <Box sx={{ flex: 1, width: "95%", mx: "auto", mt: 2, mb: 2 }}>
        {props.initialValues.totalPayment > 0 && (
          <Grid container spacing={10}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <PageTitleHeader
                header="Add Bill Payment"
                buttons={
                  isShowPaymentButton
                    ? [
                        {
                          title: "Add Bill Payment",
                          onClick: (index) => {
                            handleAddPaymentModelShow();
                            setTimeout(() => {
                              setIsAddPaymentrow(false);
                            }, 500);
                          },
                          iconName: "fa fa-plus",
                          id: "ipadd1",
                        },
                      ]
                    : []
                }
              />
              <Grid>
                <FieldArray
                  name="supplierBillPayments"
                  component={BillPayment}
                  props={{
                    dispatch,
                    discountAmount,
                    paymentMethodOptions,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      {show && (
        <SolpayModal
          onClose={handleClose}
          title="Add Supplier"
          data={
            <SupplierFormView
              showInModal={show}
              supplierId="0"
              pageTitle="Add Supplier"
              setSupplier={(data) => {
                supplierAdd({
                  label: data.data.fullName,
                  id: data.data.supplierId,
                  value: data.data.supplierId,
                });
                dispatch(
                  change("BillFormView", `supplierId`, data.data.supplierId)
                );
                handleClose();
              }}
            />
          }
        />
      )}
      {isAddPaymentModel && (
        <SolpayModal
          onClose={handleAddPaymentModelClose}
          title="Add Payment"
          data={
            <AddEditOrderPayment
              setPayment={(data) => {
                // supplierAdd({ label: data.data.fullName, id: data.data.supplierId, value: data.data.supplierId })
                // dispatch(change("BillFormView", `supplierId`, data.data.supplierId));
                handleAddPaymentModelClose();
              }}
              // showInModal={show}
              //supplierId="0"
              // pageTitle="Add Supplier"
              // setSupplier={(data) => {
              //    supplierAdd({ label: data.data.fullName, id: data.data.supplierId, value: data.data.supplierId })
              //    dispatch(change("BillFormView", `supplierId`, data.data.supplierId));
              //    handleClose();
              // }}
            />
          }
        />
      )}
    </>
  );
}
export default BillFormView;
