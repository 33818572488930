import { Button } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { formatDate } from "../../../libComp/utility.service";
export const getUnsubscriptionTableColumns = (props, extra: any) => {
  const columns: GridColumns = [
    {
      headerName: "Company",
      field: "businessName",
    },
    {
      headerName: "Requested Time",
      field: "requestedTime",
      renderCell: (params) => {
        return formatDate(params.row?.requestedTime);
      },
    },
    {
      headerName: "Processed Time",
      field: "processedTime",
      renderCell: (params) => {
        return formatDate(params.row?.processedTime);
      },
    },
    {
      headerName: "Comment",
      field: "comment",
      
    },
    {
      headerName: "Action",
      field: "progress",
      
      sortable: false,
      renderCell: (params) => {
        return (
          <>
            {params.row.isProcessed === false && (
              <div>
                <Button sx={{ m: 1 }} variant="contained" onClick={() => {
                    extra.onDeleteAction(params.row.id, params.row.tenantId);
                  }}>
                  Approve
                </Button>
                <Button variant="contained"
                  onClick={() => {
                    props.history.push(window.location);
                  }}
                >
                  Cancel
                </Button>
              </div>
            )}
          </>
        );
      },
    },
  ];
  return columns;
};
