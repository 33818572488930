// import SalePageMenuHeader from "libComp/pageMenuHeaders/salePageMenuHeader";
import { IReduxState } from "@redux";
import { useAppSelector } from "@redux/hook.type";
import {
  actions as invoiceActions,
  IInvoiceState,
} from "@redux/inv/invoiceRedux";
import { defaultPageSize, filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import { getInvoiceTableColumns } from "./invoices.config";

function Invoice(props) {
  const [selection, setSelection] = React.useState(null);
  const invoiceState: IInvoiceState = props.invoice.invoice;
  const title = "Invoice List";
  const dispatch = useDispatch();
  const listState = useAppSelector((state) => state.listState);

  const getInvoiceList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "invoiceDate-desc"
    ) => {
      dispatch(
        invoiceActions.getInvoices(
          pageNo + 1,
          pageSize,
          filters,
          sortPreference
        )
      );
    },
    [selection]
  );

  const invoiceButtons = [
    {
      title: "Add Invoice",
      onClick: (index) => {
        props.history.push("/accounting/sales/invoice/addedit/0");
      },
      iconName: "fa fa-plus",
      id: "aiadd1",
    },
  ];

  let tableColumns = getInvoiceTableColumns(props);

  // Breadcrumb
  const MenuItems = [
    {
      title: "Invoice",
      to: "/accounting/sales/invoices",
    },
  ];

  return (
    <div className="page-container">
      <PageTitleHeader header={title} buttons={invoiceButtons} />
      <Filter
        filterOptions={[
          {
            propertyName: "InvoiceNo",
            placeholder: "Invoice Number",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ClientName",
            placeholder: "Client Name",
            inputType: "text",

            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            invoiceActions.getInvoices(pageNo, pageSize, filters, sortData)
          );
        }}
      />

      <SolpayGrid
        columns={tableColumns}
        rows={invoiceState.invoices}
        rowCount={invoiceState.totalCount}
        loading={invoiceState.isLoading}
        loadData={getInvoiceList}
        triggerChange={(pageNumber, pageSize, sort) => {
          //console.log("page number", pageNumber);
          getInvoiceList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}

const mapStateToProps = (invoice: IReduxState, listState) => ({
  invoice,
  listState,
});
export default connect(mapStateToProps)(Invoice);
