import React from 'react';
import { Col } from 'react-bootstrap';
interface Props {
  imageSrc: any;
  imageAlt;
  header: string;
  description: string;
}
function BusinessCard(props: Props) {
  return (
    <Col md={4} className="mb-5">
      <div className="text-center how-solpay">
        <img src={props.imageSrc} alt={props.imageAlt} />
        <h4 className="font-20 bold mb-20">{props.header}</h4>
        <p>{props.description}</p>
      </div>
    </Col>
  );
}

export default BusinessCard;
