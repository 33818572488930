import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import paymentDataAccess from "dataAccess/pay/paymentDataAccess";
import { actions as commonActions } from "@redux/commonRedux";
import { Dispatch } from "redux";
const typesPrefix = "PAYMENT_";

const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getpackagesForchange: typesPrefix + "GET_PACKAGES_FOR_CHANGE",
  getAllSubscriptions: typesPrefix + "GET_ALL_SUBSCRIPTIONS",
  getAllBillPayments: typesPrefix + "GET_ALL_BILL_PAYMENTS",
  getAllCreditCards: typesPrefix + "GET_ALL_CREDIT_CARDS",
  getPackageData: typesPrefix + "GET_PACKAGE_DATA",
  getDropDownData: typesPrefix + "GET_DROPDOWN_DATA",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
  getPaymentList: typesPrefix + "GET_PAYMENT_LIST",
  getSubscriptionPaymentList: typesPrefix + "GET_SUBSCRIPTIONS_PAYMENT_LIST",
  getUnpaidSubscriptionPaymentList:
    typesPrefix + "GET_UNPAID_SUBSCRIPTIONS_PAYMENT_LIST",
};

export const actions = {
  getPackgesForChange: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getPackgesForChange(id)(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getpackagesForchange,
          payload: {
            packageModules: data.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getAllSubscriptions: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getAllSubscriptions()(state.commonState.commonActions)
      .then((Data) => {
        dispatch({
          type: types.getAllSubscriptions,
          payload: {
            data: Data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getAllCreditCards: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getAllCreditCards()(state.commonState.commonActions)
      .then((Data) => {
        dispatch({
          type: types.getAllCreditCards,
          payload: {
            data: Data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  }, 
  getDropDownData: () => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getDropDownData()(state.commonState.commonActions)
      .then((Data) => {
        //console.log(Data)
        dispatch({
          type: types.getDropDownData,
          payload: {
            data: Data.data,
            
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getAllBillPayments: (
    pageNo = 1,
    pageSize = defaultPageSize,
    filters = [],
    sortPreference = ""
  ) => 
  (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getAllBillPayments(
        pageNo,
        pageSize,
        filters,
        sortPreference
      )(state.commonState.commonActions)
      .then((billPaymentData) => {
        dispatch({
          type: types.addToList,
          payload: {
            list: billPaymentData.data.list,
            totalCount: billPaymentData.data.totalCount,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(()=>{
        dispatch(commonActions.loadingEnd());
      });
  },
  getPackageData: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getPackageData()(state.commonState.commonActions)
      .then((Data) => {
        dispatch({
          type: types.getPackageData,
          payload: {
            data: Data.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
  getPayments:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "",
      scope = "all"
    ) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      paymentDataAccess
        .getPayments(
          pageNo,
          pageSize,
          filters,
          sortPreference,
          scope
        )(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.getPaymentList,
            payload: {
              list: data.data.list,
              totalCount: data.data.totalCount,
            },
          });
        });
    },
  getAllPaidSubscriptions:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "",
      scope = "all"
    ) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      paymentDataAccess
        .getAllPaidSubscriptions(
          pageNo,
          pageSize,
          filters,
          sortPreference,
          scope
        )(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.getSubscriptionPaymentList,
            payload: {
              list: data.data.list,
              totalCount: data.data.totalCount,
            },
          });
        });
    },
  getAllUnpaidSubscriptions:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "",
      scope = "all"
    ) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      paymentDataAccess
        .getAllUnpaidSubscriptions(
          pageNo,
          pageSize,
          filters,
          sortPreference,
          scope
        )(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.getUnpaidSubscriptionPaymentList,
            payload: {
              list: data.data.list,
              totalCount: data.data.totalCount,
            },
          });
        });
    },
};

export interface IPaymentState {
  packageModules: Array<any>;
  subscriptions: Array<any>;
  billPayments: Array<any>;
  packages: Array<any>;
  payments: Array<any>;
  dropdowns: Array<any>;
  creditCards: Array<any>;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: IPaymentState = {
  packageModules: [],
  subscriptions: [],
  billPayments: [],
  dropdowns: [],
  creditCards: [],
  packages: [],
  payments: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IPaymentState = initialState,
  action: Action
): IPaymentState => {
  switch (action.type) {
    case types.getAllSubscriptions:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        subscriptions: action.payload.data,
      };
    case types.getAllCreditCards:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        creditCards: action.payload.data,
      };
    case types.getDropDownData:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        dropdowns: action.payload.data,
      };
    case types.getpackagesForchange:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        packageModules: action.payload.packageModules,
      };
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        billPayments: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.getPackageData:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        packages: action.payload.data,
      };
    case types.getPaymentList:
      return {
        ...state,
        isLoading: false,
        payments: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.getSubscriptionPaymentList:
      return {
        ...state,
        isLoading: false,
        payments: action.payload.list,
        totalCount: action.payload.totalCount,
      };

    case types.getUnpaidSubscriptionPaymentList:
      return {
        ...state,
        isLoading: false,
        payments: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };
    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
