import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useAppSelector } from "@redux/hook.type";
import userDataAccess from "dataAccess/user/userDataAccess";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm } from "redux-form";
import { password, passwordsMustMatch2, required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

function ResetPassword(props) {
  const userId = props.match.params.id;

  const commonActions = useAppSelector(
    (state) => state.commonState.commonActions
  );
  const onSubmit = (data) => {
    userDataAccess
      .resetPassword(data)(commonActions)
      .then((res) => {
        toast.success("Password Reset Successfully Done!");
        props.history.push("/admin/users/current");
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const style = useStyleClass();

  let MenuItems = [];
  MenuItems = [
    {
      title: "Admin",
      to: null,
    },
    {
      title: "Users",
      to: "/admin/users/all",
    },
    {
      title: "Edit",
      to: null,
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} /> <br />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header={"Reset Password"} />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onSubmit(data))}
        >
          <Grid container spacing={10}>
            <Grid item xl={6} md={6} sm={12} xs={12}>
              <Field name="id" component={InputHidden} />

              <Field
                name="newPassword"
                type="password"
                component={InputFieldOneRow}
                validate={[required, password]}
                label="New Password"
              />

              <Field
                name="confirmPassword"
                type="password"
                validate={[passwordsMustMatch2, required]}
                component={InputFieldOneRow}
                label="Confirm Password"
              />

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              type="submit"
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}

const resetPassword = reduxForm<any, any>({
  form: "resetPassword",
  enableReinitialize: true,
})(ResetPassword);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {
    id: ownProps.match.params.id,
  },
});

export default connect(mapStateToProps)(resetPassword);
