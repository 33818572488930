import { IReduxState } from "@redux";
import DashBoardButton from "libComp/sections/dashBoardButton";
import imageDollar from "media/images/blueImages/doller.svg";
import imagePrevPay from "media/images/blueImages/prev-pay.svg";
import { Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useStyleClass } from "styles/theming/useStyleClass";
import "../styles/styles.scss";

function Tenant() {

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const classes = useStyleClass();
  return (
    <div>
      <div className="btn-wraper">
        <div className="container-fluid" style={{ marginTop: "20px" }}>
          <Row>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div>
                <h2 className={classes.primaryColor + " title-header"}>
                  Quick Access
                </h2>
              </div>

              <DashBoardButton
                buttons={[
                  {
                    header: "Start Payrun",
                    description: null,
                    to: "/payroll/payrun/startpay",
                    imageSrc: imageDollar,
                    imageAlt: "icon",
                  },
                  {
                    header: "Generate Invoice",
                    description: null,
                    to: "/accounting/sales/invoice/addedit/0",
                    imageSrc: imagePrevPay,
                    imageAlt: "icon",
                  },
                ]}
              />
            </div>
            <div className="col-xs-12 col-sm-6 col-md-2"></div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="heading ">
                <h2 className={classes.primaryColor + " title-header"}>
                  Reminders
                </h2>
              </div>

              <DashBoardButton
                buttons={[
                  {
                    header: "28/09/2021",
                    description:
                      "Run Payroll By 4:00 PM for 13/09/2021 to 25/09/2021",
                    to: "/tenant",
                    imageSrc: null,
                    imageAlt: "icon",
                  },
                ]}
              />
            </div>
          </Row>

          {/* <Button onClick={commonState.commonActions.onUnauthorize as MouseEventHandler}>Go To Unauthorize</Button>
         <Button onClick={commonState.commonActions.onForbidden as MouseEventHandler}>Go To Forbidden</Button> */}
        </div>{" "}
      </div>
    </div>
  );
}

export default Tenant;
