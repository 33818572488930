import { FormControl, Grid, InputLabel } from "@mui/material";
import CustomLabel from "libComp/custom/customRequired";
import PropTypes from "prop-types";
import React from "react";
import Select from "react-select";

const SolpayReactSelectWithoutLabel = ({
  label,
  input,
  options,
  multi,
  group,
  child,
  meta: { touched, error, warning },
  labelCol,
  fieldColumn,
  iconEvent,
  required,
}) => {
  const { name, value, onBlur, onChange, onFocus } = input;
  const transformedValue = transformValue(value, options, multi);
  let config = { layout: "horizontal" };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";

  return (
    <Grid
      item
      style={{
        flex: "1 1 auto",
        width: "100%",
      }}
      md={fieldColumn ? fieldColumn : 8.08}
      // xl={
      //   fieldColumn ? (fieldColumn > 11 ? fieldColumn : fieldColumn + 1) : 7
      // }
    >
      <FormControl size="small" style={{ width: 168, zIndex: 0 }}>
        <Select
          iconEvent
          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
          menuPortalTarget={document.body}
          closeMenuOnScroll={(e: any) =>
            !e.target.classList.contains("css-4ljt47-MenuList")
          }
          placeholder={required === true ? <CustomLabel name={label} /> : label}
          menuPosition={"fixed"}
          valueKey="value"
          name={name}
          value={transformedValue}
          multi={multi}
          options={options}
          onChange={
            multi ? multiChangeHandler(onChange) : singleChangeHandler(onChange)
          }
          onBlur={() => onBlur(value)}
          onFocus={onFocus}
        />
      </FormControl>
      {touched && error && (
        <InputLabel
          style={{
            display: "block",
            color: "red",
            fontSize: "12px",
            margin: "2px",
          }}
        >
          {error}
        </InputLabel>
      )}
    </Grid>
  );
};

SolpayReactSelectWithoutLabel.defaultProps = {
  multi: false,
};

SolpayReactSelectWithoutLabel.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    //value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  className: PropTypes.string,
};

export default SolpayReactSelectWithoutLabel;

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "");
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map((value) => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === "string") return [];

  const filteredOptions = options.filter((option) => {
    return multi ? value.indexOf(option.value) !== -1 : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0];
}
