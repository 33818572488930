import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as journalActions } from "@redux/bkp/transactionRedux";
import { accountTypes } from "common/enums";
import journalDataAcess from "dataAccess/bkp/journalDataAcess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  InputFieldOneRow,
  InputFieldRowReadOnlyMui,
  InputFieldWithoutLabelMui,
} from "libComp/common/inputRenderes";
import SolpayReactSelectIconWithoutLabel from "libComp/common/solpayModalWithoutLabel";
import SolpayReactSelectWithoutLabelForm from "libComp/common/SolpayReactSelectWithoutLabelForm";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  change,
  Field,
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
interface Props {
  journalId: bigint;
  accountHeads: Array<any>;
  taxCodes: Array<any>;
  journal: any;
  history: any;
  totalDebit: number;
  totalCredit: number;
}
const customStyle = {
  paddingRight: 0,
};
function AddEditJournal(props: InjectedFormProps<any, Props> & Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [accountHeads, setAccountHeads] = useState([]);
  const dispatch = useDispatch();
  let isNotValid = props.totalDebit !== props.totalCredit;
  const [isAddrow, setIsAddrow] = useState(false);
  useEffect(() => {
    if (props.journalId > 0)
      dispatch(journalActions.getJournal(props.journalId));
  }, [props.journalId]);
  useEffect(() => {
    dispatch(journalActions.getAllAccountHead());
    dispatch(journalActions.getAllTaxCode());
  }, []);
  useEffect(() => {
    if (props.accountHeads && props.accountHeads.length > 0) {
      groupAccountHead();
    }
  }, [props.accountHeads]);

  const groupAccountHead = () => {
    var allHeads = [...props.accountHeads];
    let _accountHeads = {};
    let __accountHeads = [];
    allHeads.forEach((item, index) => {
      if (_accountHeads[item.accountType] === undefined) {
        _accountHeads[item.accountType] = [];
        _accountHeads[item.accountType].push({
          ...item,
          label: "(" + item.accountNo + ") " + item.name,
          value: item.id,
        });
      } else {
        _accountHeads[item.accountType].push({
          ...item,
          label: "(" + item.accountNo + ") " + item.name,
          value: item.id,
        });
      }
    });
    __accountHeads.push({
      label: accountTypes.income,
      options: _accountHeads[accountTypes.income],
    });
    __accountHeads.push({
      label: accountTypes.expense,
      options: _accountHeads[accountTypes.expense],
    });
    __accountHeads.push({
      label: accountTypes.asset,
      options: _accountHeads[accountTypes.asset],
    });
    __accountHeads.push({
      label: accountTypes.liability,
      options: _accountHeads[accountTypes.liability],
    });
    __accountHeads.push({
      label: accountTypes.equity,
      options: _accountHeads[accountTypes.equity],
    });

    setAccountHeads(__accountHeads);
  };

  const onFormSubmit = (model) => {
    return journalDataAcess
      .saveJournal(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success)
          enqueueSnackbar("Client has been saved!", { variant: "success" });
        else
          enqueueSnackbar("Client could not been saved!", { variant: "error" });
        props.history.push("/accounting/journals");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // Breadcrumb
  let MenuItems = [];
  if (props.journalId > 0) {
    MenuItems = [
      {
        title: "Journals",
        to: "/accounting/journals",
      },
      {
        title: "Edit",
        to: "/accounting/journal/addedit/{props.journalId}",
      },
    ];
  } else {
    MenuItems = [
      {
        title: "Journals",
        to: "/accounting/journals",
      },
      {
        title: "New",
        to: "/accounting/journal/addedit/0",
      },
    ];
  }

  const error = props.error;

  const style = useStyleClass();

  return (
    <React.Fragment>
      <PageBreadcrumb menuItem={MenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header="New Journal" />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        >
          <Grid container spacing={10} sx={{ width: "90%" }}>
            <Grid item xl={6} md={6}>
              <Field
                name="transactionDate"
                label="Date"
                validate={required}
                component={DatePickerFieldOneRow}
              />
              <Field
                name="transactionNo"
                placeholder="Transaction Number"
                label="Transaction Number"
                component={InputFieldOneRow}
              />
            </Grid>
            <Grid item xl={6} md={6}>
              <Field
                name="narration"
                placeholder="Notes"
                label="Notes"
                component={InputFieldOneRow}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={12} md={12} sx={{ overflow: "hidden" }}>
              <PageTitleHeader header="Transaction Details" />
              <FieldArray
                props={{
                  allAccountHeads: props.accountHeads,
                  accountHeads,
                  allTaxCodes: props.taxCodes,
                  taxCodes: props.taxCodes,
                  totalDebit: props.totalDebit,
                  totalCredit: props.totalCredit,
                  isAddrow: isAddrow,
                }}
                name="transactionDetails"
                component={TransactionDetail}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {error}
            </Typography>
          )}

          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </React.Fragment>
  );
}

const tabUpsertCompanyClient = reduxForm<any, Props>({
  form: "TabAddEditJournal",
  enableReinitialize: true,
})(AddEditJournal);
const selector = formValueSelector("TabAddEditJournal");

const mapStateToProps = (state: IReduxState, ownProps) => ({
  journal: state.journal,
  journalId: ownProps.match.params.id,
  accountHeads: state.journal.accountHeads ?? [],
  transactionDetails: selector(state, "transactionDetails"),
  totalDebit: selector(state, "sumOfDebit"),
  totalCredit: selector(state, "sumOfCredit"),
  taxCodes: state.journal.taxCodes ?? [],
  initialValues:
    ownProps.match.params.id === "0"
      ? {
          transactionDetails: [
            {
              accountId: "",
              note: "",
              debit: "",
              credit: "",
              isDebit: false,
              taxCode: "",
              tax: "",
            },
            {
              accountId: "",
              note: "",
              debit: "",
              credit: "",
              isDebit: false,
              taxCode: "",
              tax: "",
            },
          ],
          journal: {},
          isActive: true,
        }
      : state.journal.journal,
});
export default connect(mapStateToProps)(tabUpsertCompanyClient);

const TransactionDetail = ({
  fields,
  meta,
  allAccountHeads,
  accountHeads,
  allTaxCodes,
  taxCodes,
  totalDebit,
  totalCredit,
  isAddrow,
}) => {
  useEffect(() => {
    if (isAddrow) fields.push();
  }, [isAddrow]);
  let isNotValid = totalDebit !== totalCredit;

  const tableHeaders = [
    "Account",
    "Description",
    "Debit",
    "Credit",
    "Tax Code",
    "Tax",
    "+/-",
  ];

  const style = useStyleClass();

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <EachRow
                key={index}
                field={field}
                index={index}
                fields={fields}
                allAccountHeads={allAccountHeads}
                accountHeads={accountHeads}
                allTaxCodes={allTaxCodes}
                taxCodes={taxCodes}
              />
            ))}

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
              >
                TOTAL
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  placeholder="Total Debit"
                  name="sumOfDebit"
                  style={isNotValid ? { border: "1px solid red" } : {}}
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 0 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
              >
                Total Credit
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  name="sumOfCredit"
                  style={isNotValid ? { border: "1px solid red" } : {}}
                  placeholder="Total Credit"
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 0 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
              >
                Total Tax
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  placeholder="Total Tax"
                  name="sumOfTax"
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 0 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const EachRow = ({
  fields,
  field,
  index,
  allAccountHeads,
  accountHeads,
  allTaxCodes,
  taxCodes,
}) => {
  const dispatch = useDispatch();

  let accountId = fields.get(index) ? +fields.get(index).accountId : 0;
  let isDebit = fields.get(index) && fields.get(index).debit ? true : false;
  let isCredit = fields.get(index) && fields.get(index).credit ? true : false;
  let debitAmount = fields.get(index) ? +fields.get(index).debit : 0;
  let creditAmount = fields.get(index) ? +fields.get(index).credit : 0;
  let taxCode = fields.get(index) ? fields.get(index).taxCode : null;
  let taxValue =
    fields.get(index) && fields.get(index).taxValue
      ? +fields.get(index).taxValue
      : 0;
  let taxAmount =
    fields.get(index) && fields.get(index).tax ? +fields.get(index).tax : 0;
  useEffect(() => {
    if (accountId) {
      let account = { mappingTaxCode: null };
      for (let index = 0; index < allAccountHeads.length; index++) {
        const element = allAccountHeads[index];
        if (element.id === accountId) {
          account = element;
          break;
        }
      }
      dispatch(
        change(
          "TabAddEditJournal",
          `[${field}].taxCode`,
          account.mappingTaxCode
        )
      );
    }
  }, [accountId, allTaxCodes, allAccountHeads]);

  useEffect(() => {
    setTaxAmount();
  }, [allTaxCodes, taxCode]);
  useEffect(() => {
    calculateTax();
  }, [debitAmount, creditAmount, taxValue, allTaxCodes]);

  useEffect(() => {
    calculateAmount();
  }, [debitAmount, creditAmount, taxAmount]);
  const setTaxAmount = () => {
    for (let index = 0; index < allTaxCodes.length; index++) {
      const element = allTaxCodes[index];
      if (element.value === taxCode) {
        let taxvalue = +element.description;
        dispatch(change("TabAddEditJournal", `[${field}].taxValue`, taxvalue));
        break;
      }
    }
  };
  const calculateTax = () => {
    dispatch(change("TabAddEditJournal", `[${field}].tax`, "0.00"));

    if (isDebit && debitAmount > 0) {
      const taxAmount = (taxValue / (taxValue + 100)) * debitAmount;
      dispatch(
        change("TabAddEditJournal", `[${field}].tax`, taxAmount.toFixed(2))
      );
    } else if (isCredit && creditAmount > 0) {
      const taxAmount = (taxValue / (taxValue + 100)) * creditAmount;
      dispatch(
        change("TabAddEditJournal", `[${field}].tax`, taxAmount.toFixed(2))
      );
    }
    dispatch(change("TabAddEditJournal", `[${field}].isDebit`, isDebit));
  };

  const calculateAmount = () => {
    let sumOfDebit = 0;
    let sumOfCredit = 0;
    let sumOfTax = 0;
    fields.forEach((element, index) => {
      if (fields.get(index) && fields.get(index).debit)
        sumOfDebit += +fields.get(index).debit;
      if (fields.get(index) && fields.get(index).credit)
        sumOfCredit += +fields.get(index).credit;
      if (fields.get(index) && fields.get(index).tax)
        sumOfTax += +fields.get(index).tax;
    });
    dispatch(change("TabAddEditJournal", `sumOfDebit`, sumOfDebit.toFixed(2)));
    dispatch(
      change("TabAddEditJournal", `sumOfCredit`, sumOfCredit.toFixed(2))
    );
    dispatch(change("TabAddEditJournal", `sumOfTax`, sumOfTax.toFixed(2)));
  };
  return (
    <React.Fragment key={"idc_" + index}>
      <TableRow>
        <TableCell align="center" style={{ minWidth: 210 }}>
          {accountHeads.length > 0 && (
            <>
              <Field
                name={`${field}.accountId`}
                options={accountHeads}
                placeholder="Select a account head"
                onDataSelect={(data) => {
                  dispatch(
                    change(
                      "TabAddEditJournal",
                      `[${field}].accountId`,
                      data.value
                    )
                  );
                }}
                child={
                  <Button
                    className="btn-sm"
                    style={{ height: 38 }}
                    onClick={() => {}}
                  >
                    +
                  </Button>
                }
                component={SolpayReactSelectIconWithoutLabel}
              />
            </>
          )}
        </TableCell>
        <TableCell align="center" style={{ minWidth: 180 }}>
          <Field name={`${field}.note`} component={InputFieldWithoutLabelMui} />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field
            name={`${field}.debit`}
            disabled={isCredit}
            component={InputFieldWithoutLabelMui}
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field
            name={`${field}.credit`}
            disabled={isDebit}
            component={InputFieldWithoutLabelMui}
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 170 }}>
          <Field
            name={`${field}.taxCode`}
            options={[{ label: "-- Choose --", value: null }, ...taxCodes]}
            onDataSelect={(data) => {
              dispatch(
                change("TabAddEditJournal", `[${field}].taxCode`, data.value)
              );
            }}
            component={SolpayReactSelectWithoutLabelForm}
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field name={`${field}.tax`} component={InputFieldWithoutLabelMui} />
        </TableCell>

        <TableCell align="center">
          <Stack direction="row">
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={() => {
                fields.push();
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="remove"
              onClick={() => {
                fields.remove(index);
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};
