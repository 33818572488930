import { Edit, Print } from "@mui/icons-material";
import LockResetIcon from "@mui/icons-material/LockReset";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const getUserListTableColumns = (props, extra) => {
  const columns: GridColumns = [
    {
      headerName: "Username",
      field: "userName",
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Tenant Name",
      field: "tenantName",
    },
    {
      headerName: "Actions",
      field: "",
      width: 120,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              title="Edit"
              onClick={() => {
                if (extra)
                  props.history.push("/admin/org_user/" + params.row.id);
                else props.history.push("/admin/user/" + params.row.id);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              size="small"
              title="Reset Password"
              onClick={() => {
                props.history.push("/admin/reset-password/" + params.row.id);
              }}
            >
              <LockResetIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
