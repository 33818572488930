import { useAppSelector } from "@redux/hook.type";
import { actions, IUserListState } from "@redux/user/userListRedux";
import { defaultPageSize, filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import React, { useCallback } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getAuditListTableColumns,
  getFilterOptions,
} from "./audit-list.config";

function AuditLogs(props) {
  const userState: IUserListState = props.userList;
  const [selection, setSelection] = React.useState(null);
  const title = "Audit Log (Activity)";
  const listState = useAppSelector((state) => state.listState);

  const dispatch = useDispatch();

  const getAuditLogs = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "CreatedOn-desc"
    ) => {
      dispatch(
        actions.auditLogs(pageNo + 1, pageSize, filters, sortPreference)
      );
      //console.log("user", userState.users);
    },
    []
  );

  const tableColumns = getAuditListTableColumns(props);
  const filterOptions = getFilterOptions(props);

  return (
    <>
      <div className="page-container">
        <PageTitleHeader header={title} />
        <Filter
          filterOptions={filterOptions}
          onFilterChange={(pageNo, pageSize, filters) => {
            //console.log(filters);
            //dispatch(actions.auditLogs(pageNo, pageSize, filters));
            getAuditLogs(pageNo, pageSize, filters);
          }}
        />
        <SolpayGrid
          columns={tableColumns}
          rows={userState.auditLogs}
          rowCount={userState.totalCount}
          loading={userState.isLoading}
          loadData={getAuditLogs}
          triggerChange={(pageNumber, pageSize, sort) => {
            getAuditLogs(pageNumber, pageSize, listState.filters, sort);
          }}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ userList, listState }) => ({ userList, listState });
export default connect(mapStateToProps)(AuditLogs);
