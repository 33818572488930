import { IReduxState } from "@redux";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import { Alert, Box, Grid, Button, CircularProgress } from "@mui/material";
import PAYGPaymentSummaryReportAccess from "dataAccess/pyr/paygPaymentSummaryReportAccess";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { SavePPFFromBiteArray } from "services/commonService";
import { useEffect, useState } from "react";

function PAYGPaymentSummaryReport(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [dropdownEmployees, setDropdownEmployees] = useState([]);
  const [dropdownYears, setDropdownYears] = useState([]);
  const [uiMessage, setUiMessage] = useState([]);
  const style = useStyleClass();

  useEffect(() => {
    PAYGPaymentSummaryReportAccess.getPrepareYearDropdown()(
      commonState.commonActions
    ).then((data) => setDropdownYears(data.data));
    PAYGPaymentSummaryReportAccess.getAllEmployeesDropdown()(
      commonState.commonActions
    ).then((data) => setDropdownEmployees(data.data));
  }, []);

  const onFormSubmit = async (model) => {
    try {
      const reportResponse =
        await PAYGPaymentSummaryReportAccess.paygPaymentReportGenerate(model)(
          commonState.commonActions
        );
      SavePPFFromBiteArray("PAYGPaymentSummary.pdf", reportResponse);
    } catch (error) {
      //console.log(error);
    }
  };

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Reports",
      to: "/payroll/Report",
    },
    {
      title: "PAYG Payment Summary Report",
      to: "/payroll/Report/PAYGPaymentSummaryReport",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="PAYG Payment Summary Report" />
        <Box
          sx={{
            flex: 1,
            width: "96%",
            mx: "auto",
            borderColor: "grey.200",
            p: 2,
            mt: 2,
          }}
        >
          <form
            noValidate
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            {uiMessage.map((item, index) => (
              <>
                <Alert
                  key={index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    setUiMessage([]);
                  }}
                >
                  {item.messageText}
                </Alert>
                <br />
              </>
            ))}
            <Grid container spacing={6}>
              <Grid item sm={12} md={6}>
                <Field
                  name="employee"
                  component={solpayReactSelect}
                  options={dropdownEmployees}
                  label="Employee"
                  validate={required}
                />
                <Field
                  name="year"
                  component={solpayReactSelect}
                  options={dropdownYears}
                  label="Year"
                  validate={required}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classes={{ root: style.button }}
                    sx={{ mt: 1 }}
                    variant="contained"
                    type="submit"
                    disabled={props.submitting}
                  >
                    {props.submitting ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    ) : (
                      <></>
                    )}{" "}
                    &nbsp;Generate Report
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
}
const payGPaymentSummaryReport = reduxForm<any>({
  form: "PAYGPaymentSummaryReport",
  enableReinitialize: true,
  asyncChangeFields: ["employee", "year"],
})(PAYGPaymentSummaryReport);
const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});
export default connect(mapStateToProps)(payGPaymentSummaryReport);
