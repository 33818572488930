import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import { useSnackbar } from "notistack";
import { useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { maxLength, required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { CRMEntityTypes } from "common/constants";
import bankDataAccess from "dataAccess/crm/bankDataAccess";
import { actions as tenantActions } from "@redux/tnt/tenantRedux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const maxLength100 = maxLength(100);
interface IProps {
  entityId?: string;
  bankAccount: any;
}

function AddEditBussinessBankInfo(
  props: InjectedFormProps<any, IProps> & IProps
) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const style = useStyleClass();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = (model) => {
    bankDataAccess
      .saveBankInfo(
        model,
        props.entityId,
        CRMEntityTypes.Company
      )(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Bank info has been saved!", { variant: "success" });
        dispatch(tenantActions.getBusinessInfo());
        handleClose();
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const BankInfoButton = [
    {
      id: "button1",
      title: "Edit",
      iconName: "fa fa-edit",
      onClick: (index) => {
        handleClickOpen();
      },
    },
  ];
  return (
    <>
      <PageTitleHeader header="Bank info" buttons={BankInfoButton} />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Typography>Edit Bank Info</Typography>
            </Grid>
            <Grid item>
              <IconButton aria-label="cancel" color="primary">
                <HighlightOffIcon onClick={() => handleClose()} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <form
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
            noValidate
          >
            <TableContainer component={Paper} sx={{ p: 1 }}>
              <Table
                sx={{
                  [`& .${tableCellClasses.root}`]: {
                    borderBottom: "none",
                    padding:0,
                  },
                }}
              >
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Field name="id" component={InputHidden} />
                      <Field name="tenantId" component={InputHidden} />
                      <Field
                        name="accountName"
                        component={InputFieldOneRow}
                        label="Account Name"
                        placeholder="Account name"
                        validate={required}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Field
                        name="bsb"
                        component={InputFieldOneRow}
                        label="BSB"
                        placeholder="BSB"
                        validate={required}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Field
                        name="accountNumber"
                        component={InputFieldOneRow}
                        label="Account Number"
                        placeholder="Account Number"
                        validate={[required, maxLength100]}
                      />
                    </TableCell>
                  </TableRow>

                  {props.error && (
                    <Typography
                      variant="subtitle2"
                      component="div"
                      classes={{ root: style.error }}
                    >
                      {props.error}
                    </Typography>
                  )}
                  <TableRow>
                    <TableCell>
                      <Divider />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                        <Button
                          classes={{ root: style.button }}
                          sx={{ m: 2 }}
                          variant="contained"
                          type="submit"
                          disabled={props.submitting}
                        >
                          {props.submitting ? (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: "blue",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          ) : (
                            <i className="fa fa-check"></i>
                          )}{" "}
                          Save
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </form>
        </DialogContent>
      </Dialog>
      <Box>
        <TableContainer component={Paper}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            aria-label="caption table"
          >
            <TableBody>
              <TableRow>
                <TableCell style={{ fontWeight: 500 }} align="left">
                  Account Name
                </TableCell>
                <TableCell>{props.bankAccount.accountName ?? ""}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ fontWeight: 500 }} align="left">
                  BSB
                </TableCell>
                <TableCell>{props.bankAccount.bsb ?? ""}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell style={{ fontWeight: 500 }} align="left">
                  Account Number
                </TableCell>
                <TableCell>{props.bankAccount.accountNumber ?? ""}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
}

const addEditBussinessBankInfo = reduxForm<any, IProps>({
  form: "AddEditBussinessBankInfo",
  enableReinitialize: true,
})(AddEditBussinessBankInfo);
const mapStateToProps = (state) => ({
  bankAccount: state.tenant.businessInfo.crmBankAccount ?? {},
  initialValues: state.tenant.businessInfo.crmBankAccount ?? {},
});

export default connect(mapStateToProps)(addEditBussinessBankInfo);
