import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as employeeActions } from "@redux/emp/employeeRedux";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import {
  InputFieldRowReadOnlyMui,
  InputFieldWithoutLabelMui,
} from "libComp/common/inputRenderes";
import SolpayReactSelectWithoutLabel from "libComp/common/solpaySelctRendersWithoutLabel";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Field,
  FieldArray,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
} from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

interface Props {
  employeeId: string;
  additions: any;
  deductions: any;
}
function TabAdditionDeduction(props: InjectedFormProps<any, Props> & Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const dispatch = useDispatch();
  const employeeId = props.employeeId;
  const [paymentHeads, setPaymentHeads] = useState([]);
  const [paymentHeadsForSelect, setPaymentHeadsSelect] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (employeeId === "0") {
      history.push("/payroll/employees");
    } else {
      employeeDataAccess
        .getPaymentHeadList()(commonState.commonActions)
        .then((data) => {
          setPaymentHeads(data.data);
          setPaymentHeadsSelect(
            data.data.map((item) => ({
              label: item.name,
              value: item.id,
              allowanceType: item.allowance ? item.allowance.allowanceType : "",
            }))
          );
        })
        .catch((error) => {
          //console.log(error);
        });
    }
  }, []);

  const onFormSubmit = (model) => {
    let requestData = { ...model };

    requestData.pyrEmployeeAdditions = requestData.pyrEmployeeAdditions.filter(
      (item) => item && item.amount !== null && item.amount !== undefined
    );

    requestData.pyrEmployeeAdditions = requestData.pyrEmployeeAdditions.map(
      (item) => ({
        id: item.id,
        employeeId: props.employeeId,
        paymentHeadId: item.paymentHead.id,
        isBeforeTax: item.isBeforeTax,
        amount: item.amount,
      })
    );

    requestData.pyrEmployeeDeductions =
      requestData.pyrEmployeeDeductions.filter(
        (item) => item && item.amount !== null && item.amount !== undefined
      );

    requestData.pyrEmployeeDeductions = requestData.pyrEmployeeDeductions.map(
      (item) => ({
        id: item.id,
        employeeId: props.employeeId,
        paymentHeadId: item.paymentHead.id,
        isBeforeTax: item.isBeforeTax,
        amount: item.amount,
      })
    );

    employeeDataAccess
      .saveAdditionDeduction(
        requestData,
        employeeId
      )(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Addition Deduction has been saved!", {
          variant: "success",
        });
        dispatch(employeeActions.getAdditionDeduction(employeeId));
      })
      .catch((error) => {
        enqueueSnackbar("Addition Deduction could not been saved!", {
          variant: "error",
        });
        //console.log(error);
      });
  };

  const style = useStyleClass();

  // console.log("HEAD", paymentHeads);
  // console.log("HEADSELECT", paymentHeadsForSelect);

  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "100%",
          mx: "auto",
        }}
      >
        <form
          className="form-horizontal form-element"
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid container spacing={2}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <PageTitleHeader header="Additions Here" />
              <FieldArray
                name="pyrEmployeeAdditions"
                component={Additions}
                props={{ paymentHeadsForSelect, additions: props.additions }}
              />
            </Grid>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <PageTitleHeader header="Deductions Here" />
              <FieldArray
                name="pyrEmployeeDeductions"
                component={Deductions}
                props={{ paymentHeadsForSelect }}
              />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
const tabAdditionDeduction = reduxForm<any, Props>({
  form: "TabAdditionDeduction",
  enableReinitialize: true,
})(TabAdditionDeduction);

const selector = formValueSelector("TabAdditionDeduction");

const mapStateToProps = (state: IReduxState, ownProps) => ({
  additions: selector(state, "pyrEmployeeAdditions"),
  deduction: selector(state, "pyrEmployeeDeductions"),
  initialValues: state.employee.additionDeduction,
});

export default connect(mapStateToProps)(tabAdditionDeduction);

const Additions = ({ fields, meta, paymentHeadsForSelect, additions }) => {
  const style = useStyleClass();

  const additionsHeaders = [
    "Before Tax?",
    "Description",
    "Allowance Type",
    "Amount",
    "+/-",
  ];

  if (fields.length < 1) {
    fields.push();
  }
  const getAllowanceType = (addition) => {
    let allowanceType = "";

    if (!addition) return allowanceType;

    if (addition.paymentHead) {
      paymentHeadsForSelect.forEach((element) => {
        if (element.value.toString() === addition.paymentHead.id.toString())
          allowanceType = element.allowanceType;
      });
    }
    return allowanceType;
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          minHeight: "auto",
        }}
      >
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {additionsHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={index}>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  <Field
                    name={`${field}.isBeforeTax`}
                    component={SolpayReactSelectWithoutLabel}
                    options={[
                      { label: "Select a option" },
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    label="Before Tax"
                  />
                </TableCell>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  <Field
                    name={`${field}.paymentHead.id`}
                    component={SolpayReactSelectWithoutLabel}
                    type="number"
                    options={[
                      { label: "Select a option" },
                      ...paymentHeadsForSelect,
                    ]}
                    label="Description"
                  />
                </TableCell>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  {additions ? getAllowanceType(additions[index]) : ""}
                  <Field
                    name={additions ? getAllowanceType(additions[index]) : ""}
                    component={InputFieldRowReadOnlyMui}
                    label="Allowance"
                  />
                </TableCell>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  <Field
                    name={`${field}.amount`}
                    type="number"
                    component={InputFieldWithoutLabelMui}
                    label="Amount"
                  />
                </TableCell>
                <TableCell align="center"
                  style={{  textAlign: "right" }}>
                  <Stack direction="row"
                    justifyContent="center"
                    alignItems="center">
                    {index + 1 === fields.length && (
                      <IconButton
                        color="secondary"
                        aria-label="add"
                        onClick={() => {
                          fields.push();
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    <IconButton
                      color="secondary"
                      aria-label="remove"
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const Deductions = ({ fields, meta, paymentHeadsForSelect }) => {
  const style = useStyleClass();

  const deductionsHeaders = ["Before Tax?", "Description", " ", "Amount", "+/-"];

  if (fields.length < 1) fields.push();

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          minHeight: "auto",
        }}
      >
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {deductionsHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <TableRow key={index}>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  <Field
                    name={`${field}.isBeforeTax`}
                    component={SolpayReactSelectWithoutLabel}
                    options={[
                      { label: "Select a option" },
                      { label: "Yes", value: true },
                      { label: "No", value: false },
                    ]}
                    label="Before Tax"
                  />
                </TableCell>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  <Field
                    name={`${field}.paymentHead.id`}
                    type="number"
                    component={SolpayReactSelectWithoutLabel}
                    options={[
                      { label: "Select a option" },
                      ...paymentHeadsForSelect,
                    ]}
                    label="Description"
                  />
                </TableCell>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}></TableCell>
                <TableCell align="center" style={{ minWidth: 220, width: 260 }}>
                  <Field
                    name={`${field}.amount`}
                    type="number"
                    component={InputFieldWithoutLabelMui}
                    label="Amount"
                  />
                </TableCell>
                <TableCell
                  align="center"
                  style={{  textAlign: "right" }}
                >
                  <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {index + 1 === fields.length && (
                      <IconButton
                        color="secondary"
                        aria-label="add"
                        onClick={() => {
                          fields.push();
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                    <IconButton
                      color="secondary"
                      aria-label="remove"
                      onClick={() => {
                        fields.remove(index);
                      }}
                    >
                      <RemoveIcon />
                    </IconButton>
                  </Stack>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
