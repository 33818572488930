import { Mail, Print } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";

export const getPaySlipTableColumns = (props, extra: any) => {
  const columns: GridColumns = [
    {
      headerName: "Duration",
      field: "duration",
    },
    {
      headerName: "Pay Date",
      field: "payDate",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Amount Gross",
      field: "amountGross",
      sortable: false,
    },
    {
      headerName: "PAYG",
      field: "taxPaid",
      sortable: false,
    },
    {
      headerName: "Super",
      field: "superContribution",
      sortable: false,
    },
    {
      headerName: "Payment Type",
      field: "paymentMethodText",
      sortable: false,
    },
    {
      headerName: "Hour Worked",
      field: "hourWorked",
      sortable: false,
    },
    {
      headerName: "Annual Leave Taken",
      field: "annualLeaveTaken",
      sortable: false,
    },
    {
      headerName: "Personal Leave Taken",
      field: "personalLeaveTaken",
      sortable: false,
    },

    {
      headerName: "Actions",
      field: "",
      width: 120,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              title="Remove"
              onClick={() => {
                extra.onDeleteAction(params.row.id);
              }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              size="small"
              title="Print"
              onClick={() => {
                extra.onPrintAction(params.row.id);
              }}
            >
              <Print />
            </IconButton>
            <IconButton
              size="small"
              title="Mail"
              onClick={() => {
                extra.onMailAction(params.row.id);
              }}
            >
              <Mail />
            </IconButton>
          </div>
        );
      },
    },
    // {
    //   headerName: "Go",
    //   field: "progress",
    //   width: 120,
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       params.row.isActive && (
    //         <div>
    //           <IconButton
    //             size="small"
    //             title="Edit"
    //             onClick={() => {
    //               dispatch(userActions.alterOrg(params.row.id));
    //               props.history.push("/tenant");
    //             }}
    //           >
    //             <EditIcon />
    //           </IconButton>
    //           <IconButton size="small" title="Print" onClick={() => {}}>
    //             <Print />
    //           </IconButton>
    //           <IconButton size="small" title="Mail" onClick={() => {}}>
    //             <Mail />
    //           </IconButton>
    //         </div>
    //       )
    //     );
    //   },
    // },
  ];
  return columns;
};
