import MyCreditCard from "./myCreditCard";
import MySubscriptions from "./mySubscriptions";
import SubscriptionPaymentList from "./subsPaymentList";

import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Divider, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import * as React from "react";

const SubscriptionLayout = (props) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
          my: 2,
        }}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <Tabs value={value} onChange={handleChange}>
                <Tab label="Subscription Info" value="1" />
                <Tab label="Bill Payments" value="2" />
                {/* <Tab label="My Credit Cards" value="3" /> */}
              </Tabs>
            </Box>
            <TabPanel sx={{ p: 0 }} value="1">
              <MySubscriptions />
              <br />
              <Divider />
              <MyCreditCard />
            </TabPanel>
            <TabPanel sx={{ p: 0 }} value="2">
              <SubscriptionPaymentList />
            </TabPanel>
            {/* <TabPanel sx={{ p: 0 }} value="3">
              <MyCreditCard />
            </TabPanel> */}
          </TabContext>
        </Box>
      </Box>
    </>
  );
};

export default SubscriptionLayout;
