import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import ticketDataAccess from "dataAccess/ticket/ticketDataAccess";
import { InputFieldOneRow } from "libComp/common/inputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import { SolpayTextArea } from "libComp/common/solpayTextArea";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "redux-form-validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const HelpPostTicket = (props) => {
  const commonState = useSelector((state: IReduxState) => state.commonState);

  let MenuItems = [];
  MenuItems = [
    {
      title: "Help",
      to: "/help",
    },
    {
      title: "New Ticket",
      to: "/post-ticket",
    },
  ];
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const style = useStyleClass();

  const onFormSubmit = (model) => {
    ticketDataAccess
      .saveTicket(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) {
          enqueueSnackbar("Ticket has been posted!", { variant: "success" });
          props.history.push("/help");
        }
      })
      .catch((error: Error) => {
        enqueueSnackbar("there have been a problem posting the ticket!", {
          variant: "error",
        });
        throw new SubmissionError({ _error: error.message });
      });
  };

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header={"Support Ticket"} />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              px: { xs: 2, sm: 2, md: 4, lg: 6, xl: 6 },
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Field
                name="type"
                component={solpayReactSelect}
                options={[
                  { value: "Accounts", label: "Accounts" },
                  { value: "Sales", label: "Sales" },
                  { value: "Billing", label: "Billing" },
                  { value: "Technical", label: "Technical" },
                  { value: "Others", label: "Others" },
                ]}
                label="Type"
                placeholder=""
                required={true}
                validate={required({
                  msg: "Type is a required field",
                })}
              />
              <Field
                name="subject"
                placeholder="Subject"
                label="Subject"
                component={InputFieldOneRow}
              />
              <Field
                name="body"
                placeholder="Body"
                label="Body"
                component={SolpayTextArea}
              />
              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Submit Ticket
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
};

const addPostSupportTicket = reduxForm({
  form: "HelpPostTicket",
  enableReinitialize: true,
})(HelpPostTicket);

const mapStateToProps = (state, ownProps) => ({
  ...state.ticket,
  initialValues: {
    ...state.ticket.ticket,
  },
});

export default connect(mapStateToProps)(addPostSupportTicket);
