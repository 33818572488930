import { ICommonActions } from "@redux/commonRedux";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { apiPrefix, apiUrl } from "common/config";
import { localStorageKeys } from "common/constants";

class commonDataAccess {
  getData =
    (url: string, params: Object = {}) =>
      (commonActions: ICommonActions) => {
        return Axios.get(apiUrl + apiPrefix + url, {
          headers: {
            Authorization: `bearer ${localStorage.getItem(
              localStorageKeys.authorization
            )}`,
          },
          params: params,
        })
          .then((response: AxiosResponse) => {
            if (response.data.success) {
              return response.data;
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch((error: AxiosError) => {
            if (error.response.status.toString() === "401") {
              commonActions.onUnauthorize();
            } else if (error.response.status.toString() === "403") {
              commonActions.onForbidden();
            } else {
              throw new Error(error.message);
            }
          });
      };

  postData =
    (url: string, data: Object = {}, params: Object = {}) =>
      (commonActions: ICommonActions) => {
        return Axios.post(apiUrl + apiPrefix + url, data, {
          headers: {
            Authorization: `bearer ${localStorage.getItem(
              localStorageKeys.authorization
            )}`,
          },
          params: params,
        })
          .then((response: AxiosResponse) => {
            if (response.data.success) {
              return response.data;
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch((error: AxiosError) => {
            if (!error.response) {
              throw new Error(error.message);
            }
            if (error.response.status.toString() === "401") {
              commonActions.onUnauthorize();
            } else if (error.response.status.toString() === "403") {
              commonActions.onForbidden();
            } else {
              throw new Error(error.message);
            }
          });
      };
  postDataForPdf =
    (url: string, data: Object = {}, params: Object = {}) =>
      (commonActions: ICommonActions) => {
        return Axios.post(apiUrl + apiPrefix + url, data, {
          headers: {
            Authorization: `bearer ${localStorage.getItem(
              localStorageKeys.authorization
            )}`,
          },
          responseType: "blob",
          params: params,
        })
          .then((response: AxiosResponse) => {
            if (response.data) {
              return response.data;
            } else {
              throw new Error(response.data.message);
            }
          })
          .catch((error: AxiosError) => {
            if (error.response.status.toString() === "401") {
              commonActions.onUnauthorize();
            } else if (error.response.status.toString() === "403") {
              commonActions.onForbidden();
            } else {
              throw new Error(error.message);
            }
          });
      };

      postDataForATOReportFile =
      (url: string, data: Object = {}, params: Object = {}) =>
        (commonActions: ICommonActions) => {
          return Axios.post(apiUrl + apiPrefix + url, data, {
            headers: {
              Authorization: `bearer ${localStorage.getItem(
                localStorageKeys.authorization
              )}`,
            },
            //responseType: "blob",
            params: params,
          })
            .then((response: AxiosResponse) => {
              if (response) {
                //console.log(response)
                return response.data;
              } else {
                throw new Error(response.data.message);
              }
            })
            .catch((error: AxiosError) => {
              if (error.response.status.toString() === "401") {
                commonActions.onUnauthorize();
              } else if (error.response.status.toString() === "403") {
                commonActions.onForbidden();
              } else {
                throw new Error(error.message);
              }
            });
        };

  postDataNoResponse =
    (url: string, data: Object = {}, params: Object = {}) =>
      (commonActions: ICommonActions) => {
        return Axios.post(apiUrl + apiPrefix + url, data, {
          headers: {
            Authorization: `bearer ${localStorage.getItem(
              localStorageKeys.authorization
            )}`,
          },
          params: params,
        })
          .then((response: AxiosResponse) => {
            if (response.status === 200) {
              return response.data;
            } else {
              throw new Error(response.data);
            }
          })
          .catch((error: AxiosError) => {
            if (error.response.status.toString() === "401") {
              commonActions.onUnauthorize();
            } else if (error.response.status.toString() === "403") {
              commonActions.onForbidden();
            } else {
              throw new Error(error.message);
            }
          });
      };
}
export default new commonDataAccess();
