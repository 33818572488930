import userDataAccess from "dataAccess/emp/userDataAccess";
import { InputFieldWithoutLabel } from "libComp/common/inputRenderes";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { Button, Col, Container, Row, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import {
  maxLength,
  minLength,
  password,
  required,
  userName,
} from "services/validators";
const maxLength100 = maxLength(100);
const maxLength32 = maxLength(32);
const minLength8 = minLength(8);
const minLength4 = minLength(4);
const validate = (values) => {
  const errors = { confirmPassWord: "" };
  if (values.passWord !== values.confirmPassWord) {
    errors.confirmPassWord =
      "The password and confirmation password do not match.";
  }
  return errors;
};
export const usernameAsyncValidate = (values) => {
  return userDataAccess
    .isUserNameAvaiable(values.userName)
    .then((data) => {
      if (!data.data) {
        throw { userName: data.message };
      }
    })
    .catch((error: Error) => {
      //console.log(error);
      throw { userName: values.userName + " not available" };
    });
};
const Signup = (props: any) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isAgreedWithTerms, setisAgreedWithTerms] = useState(
    props.setisAgreedWithTerms
  );
  const signup = (model) => {
    return userDataAccess
      .signup(model)
      .then((data) => {
        enqueueSnackbar(data.message, { variant: "success" });
        props.history.push("/login");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  return (
    <React.Fragment>
      <Container>
        <Row>
          <Col md={6} sm={6} xs={12}>
            <Col
              md={8}
              sm={8}
              xs={10}
              className="col-sm-offset-2 col-md-offset-2 col-lg-offset-2 col-xs-offset-1 text-center"
            >
              <h1>30 day free trial</h1>
              <p>No credit card required for trial</p>
            </Col>
          </Col>
        </Row>
        <hr />
      </Container>
      <Container className="mv-50">
        <form noValidate onSubmit={props.handleSubmit((data) => signup(data))}>
          <Col
            sm={10}
            md={10}
            lg={10}
            className="col-sm-offset-1 col-md-offset-1 col-xs-offset-1"
          >
            <Row>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xs-12">
                <Field
                  name="firstName"
                  component={InputFieldWithoutLabel}
                  placeholder="First Name"
                  validate={required}
                />
                <br></br>
                <Field
                  name="lastName"
                  component={InputFieldWithoutLabel}
                  placeholder="Last Name"
                  validate={required}
                />
                <br></br>
                <Field
                  name="userName"
                  validate={[required, userName, minLength4, maxLength32]}
                  component={InputFieldWithoutLabel}
                  placeholder="User Name"
                />
                <br></br>
                <Field
                  name="passWord"
                  component={InputFieldWithoutLabel}
                  placeholder="Password"
                  validate={[required, password, minLength8, maxLength100]}
                />
                <br></br>
                <Field
                  name="confirmPassWord"
                  component={InputFieldWithoutLabel}
                  placeholder="Confirm Password"
                  validate={[required]}
                />
                <br></br>
                <Field
                  name="email"
                  component={InputFieldWithoutLabel}
                  placeholder="Email"
                  validate={[required]}
                />
                <br></br>
                <Field
                  name="phoneNo"
                  component={InputFieldWithoutLabel}
                  placeholder="Phone No"
                />
                <br></br>
                <div className="form-group">
                  <p>
                    <input
                      type="checkbox"
                      onChange={() => setisAgreedWithTerms(!isAgreedWithTerms)}
                    ></input>
                    &nbsp;I have read and I agree to the{" "}
                    <a target="_blank" href="#">
                      terms of use
                    </a>{" "}
                    and{" "}
                    <a target="_blank" href="#">
                      privacy policy
                    </a>
                  </p>
                </div>
                <div className="col-sm-8 col-md-8 col-lg-8 col-xs-10 col-sm-offset-2 col-md-offset-2 col-lg-offset-2 col-xs-offset-1 text-center">
                  <div className="form-group">
                    <Button
                      disabled={!isAgreedWithTerms || props.submitting}
                      type="submit"
                    >
                      {props.submitting ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        ""
                      )}{" "}
                      Get Started
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-6 col-lg-6 col-xs-12">
                <div className="signup-qoute-part col-sm-10 col-md-10 col-lg-10 col-xs-12 mt-20 col-sm-offset-1 col-md-offset-1 col-lg-offset-1">
                  <h4 className=" bold text-left">
                    Trusted by many small businesses across Australia{" "}
                  </h4>
                  <div className="text-right">
                    <span>
                      <i
                        className="fa fa-quote-left fa-3"
                        style={{ background: "#ccc", color: "black" }}
                      ></i>
                      I love how simple it is to use. Also, the value that I get
                      for that price point is just fantastic.
                      <br />
                      <b className="text-black">
                        Suits my small business perfectly.
                      </b>
                      .
                    </span>
                    <address>-Sam J, SJ Legals</address>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
        </form>
      </Container>
    </React.Fragment>
  );
};

const mapStateToProps = (state, ownProps) => ({
  initialValues: { isAgreedWithTerms: false },
});

const SignupForm = reduxForm({
  form: "SignupForm",
  validate,
  asyncValidate: usernameAsyncValidate,
  asyncChangeFields: ["userName"],
})(Signup);

export default connect(mapStateToProps)(SignupForm);
