import { useAppSelector } from "@redux/hook.type";
import { actions, IPaymentState } from "@redux/pay/paymentRedux";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getPaymentListTableColumns } from "./payment-list.config";
import { defaultPageSize } from "common/constants";

function ReceivedPaymentList(props) {
  const [selection, setSelection] = useState(null);
  const paymentState: IPaymentState = props.payment;

  const dispatch = useDispatch();
  const listState = useAppSelector((state) => state.listState);

  const getPaymentList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "TriggeredOn-desc"
    ) => {
      //console.log("list");
      dispatch(
        actions.getPayments(pageNo + 1, pageSize, filters, sortPreference)
      );
    },
    [selection]
  );

  const tableColumns = getPaymentListTableColumns(props);

  return (
    <SolpayGrid
      columns={tableColumns}
      rows={paymentState.payments}
      rowCount={paymentState.totalCount}
      loading={paymentState.isLoading}
      loadData={getPaymentList}
      triggerChange={(pageNumber, pageSize, sort) => {
        //console.log("page number", pageNumber);
        getPaymentList(pageNumber, pageSize, listState.filters, sort);
      }}
    />
  );
}
const mapStateToProps = ({ payment, listState }) => ({
  payment,
  listState,
});
export default connect(mapStateToProps)(ReceivedPaymentList);
