import {
  Box,
  Button,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as superActions,
  ISuperAnnuationState,
} from "@redux/pyr/superAnnuationRedux";
import superAnnuationDataAccess from "dataAccess/pyr/superAnnuationDataAccess";
import { InputFieldOneRowReadOnly } from "libComp/common/inputRenderes";
// import PayrollPageMenuHeader from "libComp/pageMenuHeaders/payrollPageMenuHeader";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

function ReviewOrganization(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const superannuation: ISuperAnnuationState = props.superannuation;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(superActions.getOrganization());
  }, []);

  const reviewOrganization = (model) => {
    superAnnuationDataAccess
      .reviewOrganization(model)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          props.history.push(
            "/payroll/reviewsuperauthorizer/" +
              data.data.id +
              "/" +
              superannuation.organization.hasToUpdate
          );
        } else toast.error(data.message);
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const steps = ["Organization", "Authoriser", "Bank", "Billing Contact"];

  const style = useStyleClass();

  return (
    <React.Fragment>
      <div className="page-container">
        <PageTitleHeader header="Set Up Superannuation" />
        <Box sx={{ width: "100%" }}>
          <Stepper alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />
          <form
            noValidate
            onSubmit={props.handleSubmit((model) => reviewOrganization(model))}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Review the organization details
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
                mb: 4,
              }}
            >
              This detail will be sent to the superannuation funds
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "90%" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  name="abn"
                  label="ABN"
                  disabled={true}
                  component={InputFieldOneRowReadOnly}
                />
                <Field
                  disabled={true}
                  name="businessTradeName"
                  label="Legal/Trading Name"
                  component={InputFieldOneRowReadOnly}
                />
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    classes={{ root: style.button }}
                    variant="contained"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      props.history.push("/payroll/superregistration");
                    }}
                  >
                    Back
                  </Button>
                  {props.organization.abn && (
                    <>
                      <Box sx={{ flex: "1 1 auto" }} />
                      <Button
                        sx={{ mr: 1 }}
                        classes={{ root: style.button }}
                        variant="contained"
                        type="submit"
                      >
                        Next
                      </Button>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </React.Fragment>
  );
}

const reviewOrganization = reduxForm<any>({
  form: "reviewOrganization",
  enableReinitialize: true,
})(ReviewOrganization);
const mapStatetoProps = (state: IReduxState, ownProps) => ({
  superannuation: state.superannuation,
  organization: state.superannuation.organization,
  initialValues: { ...state.superannuation.organization },
});

export default connect(mapStatetoProps)(reviewOrganization);
