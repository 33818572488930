import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as paymentAction,
  IPaymentHeadState,
} from "@redux/pyr/paymentHeadRedux";
import paymentHeadDataAccess from "dataAccess/pyr/paymentHeadDataAccess";
import {
  InputFieldHiddenRow,
  InputFieldOneRow,
  InputSimpleRadio,
} from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

function UpsertPaymentHead(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const headId = props.match.params.headId;
  const isOvertimePay = props.initialValues.isOvertimePay;
  const paymentState: IPaymentHeadState = props.paymenthead;
  const allowanceTypes = paymentState.allowanceTypes;
  const dispatch = useDispatch();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(paymentAction.getAllAllowanceTypes());
    if (headId !== "0") dispatch(paymentAction.getPaymentHead(headId));
  }, []);

  const onFormSubmit = (model) => {
    //model.isOvertimePay = isOvertimePay == "true";

    return paymentHeadDataAccess
      .savePaymentHead(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success)
          enqueueSnackbar("Addition / Deduction Payment has been saved!", {
            variant: "success",
          });
        else
          enqueueSnackbar(
            "Addition / Deduction Payment could not been saved!",
            {
              variant: "error",
            }
          );
        if (response.success) props.history.push("/payroll/payroll-settings");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Addition/Deduction Payment List",
      to: "/payroll/payroll-settings",
    },
    {
      title: "Addition/Deduction Payment",
      to: null,
    },
  ];

  return (
    <Box
      sx={{
        flex: 1,
        width: "96%",
        mx: "auto",
        border: 1,
        borderRadius: 1,
        borderColor: "grey.200",
        p: 2,
      }}
    >
      <PageBreadcrumb menuItem={BCMenuItems} />

      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header="Addition/Deduction Payment" />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          className="form-horizontal form-element"
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid container spacing={10}>
            <Grid item md={6} sm={12}>
              <Field name="name" label="Name" component={InputFieldOneRow} />
              <Field
                name="description"
                label="Description"
                component={InputFieldOneRow}
              />
              <Field
                name=""
                label="Allowance Type :"
                component={InputFieldHiddenRow}
              />
            </Grid>
          </Grid>
          
          <Grid container spacing={10}>
            <Grid item md={12} sx={{ textAlign: "center" }}>
              {allowanceTypes.map((item, index) => (
                <React.Fragment key={"allowance_" + index}>
                  <Field
                    name="allowanceType"
                    component={InputSimpleRadio}
                    type="radio"
                    label={item.label}
                    value={item.value.toString()}
                  />
                  <Grid item md={12} sx={{ mx: 2, ml: 6 }}>
                    <div
                      style={{ textAlign: "left" }}
                      key={index + "_des"}
                      dangerouslySetInnerHTML={{
                        __html: item.description,
                      }}
                    ></div>
                  </Grid>
                  
                </React.Fragment>
              ))}
            </Grid>
          </Grid>

          {/* <Row>
            <div className="col-md-8">
              {allowanceTypes.map((item, index) => (
                <React.Fragment key={index}>
                  <Field
                    mdL={2}
                    name="allowanceType"
                    component={InputSimpleRadio}
                    type="radio"
                    label={item.label}
                    value={item.value.toString()}
                  />
                  <div
                    key={index + "_des"}
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  ></div>
                  <br></br>
                  <br></br>
                </React.Fragment>
              ))}
            </div>
          </Row> */}
          {props.error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {props.error}
            </Typography>
          )}
          <Divider />

          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
        <br />
      </Box>
    </Box>
  );
}

const upsertPaymentHead = reduxForm<any>({
  form: "UpsertPaymentHead",
  enableReinitialize: true,
})(UpsertPaymentHead);

const mapStateToProps = (state, ownProps) => ({
  paymenthead: state.paymenthead,
  allowanceTypes: state.allowanceTypes,
  initialValues:
    ownProps.match.params.headId === "0"
      ? { isOvertimePay: ownProps.match.params.isOvertimePay === "true" }
      : {
          ...state.paymenthead.paymenthead,
          allowanceType: state.paymenthead.paymenthead.allowanceType
            ? state.paymenthead.paymenthead.allowanceType.toString()
            : "",
        },
});
export default connect(mapStateToProps)(upsertPaymentHead);
