export enum modules {
  payroll = "Payroll",
  invoicing = "Invoicing",
  admin = "Admin",
  purchaseBill = "PurchaseBill",
}

export enum userTypes {
  admin = "Admin",
  superAdmin = "SuperAdmin",
  userGeneral = "User-General",
}

export enum accountTypes {
  income = "Income",
  expense = "Expense",
  asset = "Asset",
  liability = "Liability",
  equity = "Equity",
}
