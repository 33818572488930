import { Box, Button, Divider, Grid } from "@mui/material";
import { IReduxState } from "@redux";
import subscriptionDataAccess from "dataAccess/subscription/subscriptionDataAccess";
import { InputHidden } from "libComp/common/inputRenderes";
import { SolpayTextArea } from "libComp/common/solpayTextArea";
import { APIResponse } from "models/response";
import { connect, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Field,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

const UnSubscriptionView = (props) => {
  const commonState = useSelector((state: IReduxState) => state.commonState);

  const onFormSubmit = (e) => {
    subscriptionDataAccess
      .saveSubscription(e)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) {
          props.history.push(window.location);
        } else toast.error("Saved UnSuccessful");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();
  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
          my: 2,
          height: "90vh",
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid container spacing={6}>
            <Grid item sm={12} md={6}>
              <Field name="userId" component={InputHidden} />
              <Field name="tenantId" component={InputHidden} />
              <Field name="IsProcessed" component={InputHidden} />
              <Field name="createdBy" component={InputHidden} />
              <Field name="createdOn" component={InputHidden} />

              <Field
                name="comment"
                component={SolpayTextArea}
                label="Feedback"
                required
              />

              <Divider />

              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                >
                  Send Request
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

const Subscription = reduxForm<any>({
  form: "SubscriptionView",
  enableReinitialize: true,
})(UnSubscriptionView);

const mapStateToProps = (state: IReduxState, ownProps) => {
  const userRedux = state.user;

  return {
    initialValues: {
      userId: userRedux.user.id,
      tenantId: userRedux.user.tenantId,
      IsProcessed: true,
      createdBy: userRedux.user.id,
      createdOn: new Date().toLocaleDateString(),
    },
  };
};

const selector = formValueSelector("UnSubscriptionView");

export default connect(mapStateToProps)(Subscription);
