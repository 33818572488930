import { IReduxState } from "@redux";
import { actions as invoiceAction } from "@redux/inv/invoiceRedux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import OrderPaymentFormViewInvoice from "./orderPaymentFormView";

const AddEditInvoicePayment = reduxForm<any>({
  form: "OrderPaymentFormView",
  enableReinitialize: true,
})(OrderPaymentFormViewInvoice);

const selector = formValueSelector("PaymentFormView");
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInvoice: () =>
      dispatch(invoiceAction.getInvoice(ownProps.match.params.id)),
  };
};

const initialValues = {
  paymentDate: new Date(),
};

const mapStateToProps = (state: IReduxState, ownProps) => ({
  invoiceId: state.invoice.invoice.id,
  initialValues: {
    ...initialValues,
    ...state.invoice.invoice,
    ...state.invoice.invoice.bankAccountList,
    payAmount: state.invoice.invoice.amountDue,
    accountList: state.invoice.accountList,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddEditInvoicePayment);
