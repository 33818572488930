import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class subscriptionDataAccess {
  saveSubscription = (subscription) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Unsubscribe/saveUnsubscribeRequest",
      subscription
    )(commonActions);
  };
}

export default new subscriptionDataAccess();
