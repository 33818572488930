import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { IReduxState } from "@redux";
// import ContactPageMenuHeader from "libComp/pageMenuHeaders/contactPageMenuHeader";
import {
  getSuppliers,
  setSelectedSupplier,
} from "@redux/contact/supplierAction";
import { ISupplierState } from "@redux/contact/supplierReducer";
import { IListState } from "@redux/listRedux";
// import { Row } from "react-bootstrap";
import Bootbox from "bootbox-react";
import { defaultPageSize, filterOptions } from "common/constants";
import supplierDataAccess from "dataAccess/contact/supplierDataAccess";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";

function SupplierList(props) {
  const [selection, setSelection] = useState(null);
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const supplierState: ISupplierState = props.supplier;
  const listState: IListState = props.listState;

  const getSupplierList = useCallback(
    (pageNo = 0, pageSize = defaultPageSize, filters = [], sortPreference = "id-desc") => {
      dispatch(getSuppliers(pageNo + 1, pageSize, filters, sortPreference));
    },
    [selection]
  );

  let deleteSupplier = (supplierId) => {
    supplierDataAccess
      .deleteSupplier(supplierId)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Supplier has been removed!", { variant: "success" });
          dispatch(
            getSuppliers(
              listState.pageNo,
              listState.pageSize,
              listState.filters,
              listState.sort
            )
          );
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      })
      .finally(() => setShowConfirm(false));
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const [supplierId, setsupplierId] = useState("");

  const columns: GridColumns = [
    {
      headerName: "Supplier Name",
      field: "name",
    },
    {
      headerName: "Supplier Code",
      field: "supplierCode",
      sortable: false,
    },
    {
      headerName: "Supplier Type",
      field: "supplierTypeName",
      sortable: false,
    },

    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Phone",
      field: "phone",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      width: 90,
      align: "center",
      sortable: false,
      renderCell: (params) => (
        <div className="">
          <IconButton
            size="small"
            onClick={() => {
              dispatch(setSelectedSupplier(params.row));
              props.history.push(
                "/accounting/contacts/supplier/edit/" + params.row.supplierId
              );
            }}
          >
            <Edit />
          </IconButton>

          <IconButton
            size="small"
            onClick={() => {
              setsupplierId(params.row.id);
              setShowConfirm(true);
            }}
          >
            <Delete />
          </IconButton>
        </div>
      ),
    },
  ];

  return (
    <div className="page-container">
      <PageTitleHeader
        header="Suppliers List"
        buttons={[
          {
            title: "Add Supplier",
            onClick: (index) => {
              props.history.push("/accounting/contacts/supplier/new/0");
            },
            iconName: "fa fa-plus",
            id: "sadd1",
          },
        ]}
      />
      <Bootbox
        show={showConfirm}
        type={"confirm"}
        message={"Do you want to delete this payment?"}
        onSuccess={() => {
          deleteSupplier(supplierId);
          setShowConfirm(false);
        }}
        onCancel={() => setShowConfirm(false)}
        onClose={() => setShowConfirm(false)}
      />
      <Filter
        filterOptions={[
          {
            propertyName: "Name",
            placeholder: "Supplier Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "SupplierCode",
            placeholder: "Supplier Code",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },

          {
            propertyName: "Phone",
            placeholder: "Phone",
            inputType: "text",
            typeOptions: [filterOptions.equals],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(getSuppliers(pageNo, pageSize, filters, sortData));
        }}
      />

      <SolpayGrid
        columns={columns}
        rows={supplierState.suppliers}
        rowCount={supplierState.totalCount}
        loading={supplierState.isLoading}
        loadData={getSupplierList}
        triggerChange={(pageNumber, pageSize, sort) => {
          //console.log("page number", pageNumber);
          getSupplierList(pageNumber, pageSize, listState.filters, sort);
        }}
        // onPageChange={(pageNumber, details) => {

        //   console.log("page Number",pageNumber);
        //   dispatch( getBillList(pageNumber, 10, listState.filters, "purchaseDate-desc"));

        // }}
      />
    </div>
  );
}

const mapStateToProps = ({ supplier, listState }) => ({ supplier, listState });
export default connect(mapStateToProps)(SupplierList);
