import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "../commonDataAccess";
class companyDataAccess {
  getPackgesForChange = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Payment/getpackageforchange", { id })(
      commonActions
    );
  };
  changePackage = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Payment/changepackage",
      data
    )(commonActions);
  };
  getAllSubscriptions = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Payment/subscriptions")(commonActions);
  };
  getAllBillPayments = 
  (pageNo, pageSize, filters, sortPreference) => 
  (commonActions: ICommonActions) => {
    let _filters = {};
    filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters['filter.' + item.propertyName] =
          item.value + '-' + item.type;
      }
    });
    return commonDataAccess.getData("Payment/bill-payments",{
      pageNo,
      pageSize,
      sortPreference,
      ..._filters,
    })(commonActions);
  };
  getAllCreditCards = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Payment/credit-cards")(commonActions);
  };
  getPackageData = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Payment/change-package")(commonActions);
  };
  updatePackage = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Payment/update-package",
      data
    )(commonActions);
  };
  getDropDownData = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Payment/get-creditcardexpiry-dropdowndata")(commonActions);
  };
  updateCreditCard = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Payment/update-credit-card",
      data
    )(commonActions);
  };
  getPayments =
    (pageNo, pageSize, filters, sortPreference, scope) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      //console.log(filters);
      filters.forEach((item) => {
        if (item.value) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      //console.log(_filters);
      return commonDataAccess.getData("Payment/get-payment-list", {
        pageNo,
        pageSize,
        sortPreference,
        scope,
        ..._filters,
      })(commonActions);
    };
  getAllPaidSubscriptions =
    (pageNo, pageSize, filters, sortPreference, scope) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      return commonDataAccess.getData("Payment/get-all-subscriptions-list", {
        pageNo,
        pageSize,
        sortPreference,
        scope,
        ..._filters,
      })(commonActions);
    };

  getAllUnpaidSubscriptions =
    (pageNo, pageSize, filters, sortPreference, scope) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      return commonDataAccess.getData("Payment/get-unpaid-subscriptions-list", {
        pageNo,
        pageSize,
        sortPreference,
        scope,
        ..._filters,
      })(commonActions);
    };
}
export default new companyDataAccess();
