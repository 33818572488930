import { IReduxState } from '@redux';
import { Dispatch } from '@reduxjs/toolkit';
import billDataAccess from 'dataAccess/bill/billDataAccess';
import {
  BILL_ADD_TO_LIST,
  BILL_FAILED,
  BILL_LOADING,
  GET_BILL,
  GET_LOOKUPDATA,
  PRODUCT_LIST_FOR_BILL,
} from './billTypes';

export const getBills =
  (
    pageNo = 1,
    pageSize = 10,
    filters = [],
    sortPreference = 'purchaseDate-desc'
  ) =>
  (dispatch: Dispatch, getState) => {
    dispatch(fetchBillLoading());
    const state: IReduxState = getState();
    billDataAccess
      .getList(
        pageNo,
        pageSize,
        filters,
        sortPreference
      )(state.commonState.commonActions)
      .then((productData) => {
        dispatch(fetchBillAddToList(productData));
      })
      .catch((error: Error) => {
        dispatch(fetchBillError(error));
      });
  };
export const fetchBilllookUpData = () => (dispatch, getState) => {
  dispatch(fetchBillLoading());
  const state: IReduxState = getState();
  billDataAccess
    .getPurchaseBillLookupData()(state.commonState.commonActions)
    .then((response) => {
      // response.data is the users
      const lookUpdata = response.data;
      dispatch(fetchPurchaseBillLookUpData(lookUpdata));
    })
    .catch((error) => {
      // error.message is the error message
      dispatch(fetchBillError(error.message))(
        state.commonState.commonActions
      ).then((response) => {
        // response.data is the users
        const lookUpdata = response.data;
        dispatch(fetchPurchaseBillLookUpData(lookUpdata));
      });
    });
};
export const GetBillById = (id) => (dispatch, getState) => {
  dispatch(fetchBillLoading());

  const state: IReduxState = getState();
  billDataAccess
    .getBill(id)(state.commonState.commonActions)
    .then((Data) => {
      dispatch(FetchBillById(Data));
    })
    .catch((error: Error) => {
      dispatch(fetchBillError(error));
    });
};
const fetchPurchaseBillLookUpData = (lookUpData) => {
  return {
    type: GET_LOOKUPDATA,
    payload: {
      lookUpData: lookUpData,
    },
  };
};
const fetchProductList = () => {
  return {
    type: PRODUCT_LIST_FOR_BILL,
  };
};
const fetchBillLoading = () => {
  return { type: BILL_LOADING };
};
const fetchBillError = (error) => {
  return {
    type: BILL_FAILED,
    payload: error.message,
  };
};
const fetchBillAddToList = (productData) => {
  return {
    type: BILL_ADD_TO_LIST,
    payload: {
      list: productData.data.list,
      totalCount: productData.data.totalCount,
    },
  };
};
const FetchBillById = (Data) => {
  return {
    type: GET_BILL,
    payload: {
      bill: Data.data,
    },
  };
};
