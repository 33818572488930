import { List } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

export const useDisableColorStyle = makeStyles((theme) => ({
  root: {
    "&.Mui-disabled": {
      opacity: 1,
      cursor: "auto",
      pointerEvents: "none !important",
    },
    "&.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root:hover": {
      backgroundColor: "transparent",
    },
  },
  text: {
    "&.css-c6ruct-MuiTypography-root": {
      fontWeight: "500",
      cursor: "auto",
      pointerEvents: "all !important",
    },
    "&.css-ishquk-MuiButtonBase-root-MuiListItemButton-root:hover": {
      backgroundColor: "transparent",
      cursor: "auto",
    },
  },
  iconMenu: {
    fontSize: "13px",
  },
}));

export const StyledList = styled(List)({
  // selected and (selected + hover) states
  "&& .Mui-selected, && .Mui-selected:hover": {
    color: "#fff",
    opacity: 1,
    // backgroundColor: "#354aa5",
    backgroundColor: "transparent",
    borderBottom: "3px solid #FFF",
    "&, & .MuiListItemIcon-root": {
      color: "black",
    },
  },
  // hover states
  "& .MuiListItemButton-root:hover": {
    backgroundColor: "transparent",
    "&, & .MuiListItemIcon-root": {
      color: "white",
    },
  },
});

export const MODALSTYLE = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 300,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
