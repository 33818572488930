import { useAppSelector } from "@redux/hook.type";
import { getQuotations } from "@redux/quotation/quotationActions";
import { IQuotationState } from "@redux/quotation/quotationReducer";
import { quatationFormUrl } from "common/config";
import { defaultPageSize, filterOptions } from "common/constants";
// import SalePageMenuHeader from "libComp/pageMenuHeaders/salePageMenuHeader";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getQuotationTableColumns } from "./quotation.config";

const Quotation = (props) => {
  const quotationState: IQuotationState = props.quotation;
  const [selection, setSelection] = useState(null);
  const title = "Quotation List";
  const dispatch = useDispatch();
  const listState = useAppSelector((state) => state.listState);

  const getQuotationList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      //sortPreference = "quotationDate-desc"
      sortPreference = ""
    ) => {
      dispatch(getQuotations(pageNo + 1, pageSize, filters, sortPreference));
    },
    [selection]
  );

  const quotationButtons = [
    {
      title: "Add Quotation",
      onClick: (index) => {
        props.history.push(quatationFormUrl + "0");
      },
      iconName: "fa fa-plus",
      id: "quatadd1",
    },
  ];

  const tableColumns = getQuotationTableColumns(props);

  return (
    <div className="page-container">
      <PageTitleHeader
        header={title}
        buttons={quotationButtons}
      ></PageTitleHeader>

      <Filter
        filterOptions={[
          {
            propertyName: "QuotationNo",
            placeholder: "Quotation Number",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ClientName",
            placeholder: "Client Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(getQuotations(pageNo, pageSize, filters, sortData));
        }}
      />

      <SolpayGrid
        columns={tableColumns}
        rows={quotationState.quotations}
        rowCount={quotationState.totalCount}
        loading={quotationState.isLoading}
        loadData={getQuotationList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getQuotationList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ quotation, listState }) => ({
  quotation,
  listState,
});
export default connect(mapStateToProps)(Quotation);
