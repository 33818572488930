import Bootbox from "bootbox-react";
import { ToastContainer } from "react-toastify";
// import "styles/FlatBlueTheme/css/style.css";
// import "styles/FlatBlueTheme/css/override-style.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.min.css";
import "styles/main.css";
import "styles/tenant.css";
import "sweetalert/dist/sweetalert.css";

export default function Layout(props) {
  return (
    <div
      style={{
        overflow: "hidden",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Bootbox
        show={false}
        type={"confirm"}
        message={""}
        onSuccess={() => {}}
        onCancel={() => {}}
        onClose={() => {}}
      />
      {props.children}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}
