import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { actions as paymentActions } from "@redux/pay/paymentRedux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

const MySubscriptions = ({ data }) => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(paymentActions.getAllSubscriptions());
  }, []);

  const title = "My Subscriptions";

  const history = useHistory();

  const subscriptionsButtons = [
    {
      title: "Change Package",
      onClick: (index) => {
        history.push("/settings/subscription/packages");
      },
      iconName: "fa fa-arrow-up",
      id: "billadd1",
    },
  ];

  return (
    <>
      <Box sx={{ px: 4, py: 2 }}>
        <PageTitleHeader header={title} buttons={subscriptionsButtons} />

        <Box sx={{ minWidth: 275 }}>
          <Card variant="outlined">
            {data?.data?.map((item, index) => (
              <CardContent sx={{ backgroundColor: "#d9edf7" }} key={index}>
                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Currently activated software (Name): {item?.module}
                </Typography>

                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  package: {item?.description}
                </Typography>

                <Typography
                  sx={{ fontSize: 14 }}
                  color="text.secondary"
                  gutterBottom
                >
                  Price: {item?.price} $
                </Typography>
              </CardContent>
            ))}
          </Card>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  data: { ...state.payment.subscriptions },
});
export default connect(mapStateToProps)(MySubscriptions);
