import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  clearSupplireList,
  getSupplierById,
  suppliserUiMessage,
} from "@redux/contact/supplierAction";
import { ISupplierState } from "@redux/contact/supplierReducer";
import { stateList } from "common/constants";
import supplierDataAccess from "dataAccess/contact/supplierDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "redux-form-validators";
import { supplierCodeAsyncValidate } from "services/asyncValidate";
import {
  bsb,
  email,
  maxLength,
  mobile,
  phone,
  postCode,
} from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const maxLength30 = maxLength(30);
const maxLength25 = maxLength(25);
const maxLength20 = maxLength(20);
// const maxLength11 = maxLength(11);
// const maxLength7 = maxLength(7);
function SupplierForm(props) {
  const style = useStyleClass();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let selectedDes;

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const supplierId =
    props.formShownInModal || typeof props.match == "undefined"
      ? "0"
      : props.match.params.supplierId;
  selectedDes = props.formType;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const supplierState: ISupplierState = props.supplier;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [selectedDesignation, setDesignation] = useState(selectedDes);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [radioDisable, setDisable] = useState(supplierId > 0 ? true : false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (supplierId > 0) {
      dispatch(getSupplierById(supplierId, selectedDesignation));
    } else {
      localStorage.removeItem("selectedDs");
      dispatch(clearSupplireList(supplierId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFormSubmit = (model) => {
    if (supplierId !== "0") {
      model.id = supplierId;
    }

    return selectedDesignation === "1"
      ? companyDataSave(model)
      : individualDataSave(model);
  };

  useEffect(() => {
    return () => {};
  }, [selectedDesignation]);

  const companyDataSave = (model) => {
    supplierDataAccess
      .saveCompanySupplier(model)(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Supplier has been saved!", { variant: "success" });
        props.formShownInModal
          ? modalClose(data)
          : props.history.push("/accounting/contacts/suppliers");
      })
      .catch((error: Error) => {
        suppliserUiMessage(error.message);
        // throw new SubmissionError({ _error: error.message });
      });
  };

  const individualDataSave = (model) => {
    supplierDataAccess
      .saveIndividualSupplier(model)(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Supplier has been saved!", { variant: "success" });

        props.formShownInModal
          ? modalClose(data)
          : props.history.push("/accounting/contacts/suppliers");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const modalClose = (data) => {
    props.setSupplier(data);
    // props.hideModal();
  };

  // Breadcrumb
  let MenuItems = [];
  if (supplierId > 0) {
    MenuItems = [
      {
        title: "Supplier",
        to: "/accounting/contacts/suppliers",
      },
      {
        title: "Edit",
        to: "/accounting/contacts/supplier/new/{supplierId}",
      },
    ];
  } else {
    MenuItems = [
      {
        title: "Supplier",
        to: "/accounting/contacts/suppliers",
      },
      {
        title: "New",
        to: "/accounting/contacts/supplier/new/0",
      },
    ];
  }

  return (
    <div>
      {!props.formShownInModal && <PageBreadcrumb menuItem={MenuItems} />}
      <Stack sx={{ width: "96%", mx: "auto" }}>
        {!props.formShownInModal && supplierId === "0" && (
          <>
            <PageTitleHeader header="Add Supplier" />
          </>
        )}
        {!props.formShownInModal && supplierId !== "0" && (
          <>
            <PageTitleHeader header="Edit Supplier" />
          </>
        )}
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid container spacing={10}>
            <Grid item xl={6} md={6}>
              <Field name="id" component={InputHidden} />
              <Field name="addressId" component={InputHidden} />
              <Field name="supplierId" component={InputHidden} />
              <Field name="companyId" component={InputHidden} />
              <Field name="clientId" component={InputHidden} />
              <Field name="bankAccountId" component={InputHidden} />

              <Grid>
                <Field
                  name="selectedDesignation"
                  key="selectedDesignation"
                  component={(props) => {
                    return (
                      <Grid container>
                        <Grid item md={4} xl={4}></Grid>
                        <Grid item md={8} xl={8}>
                          <FormControl>
                            <div>
                              <label
                                style={{
                                  textAlign: "right",
                                }}
                              ></label>
                            </div>
                            <div>
                              {radioDisable && (
                                <div>
                                  <label>
                                    <input
                                      name="selectedDesignation"
                                      type="radio"
                                      value="1"
                                      checked={selectedDesignation === "1"}
                                      onChange={() => setDesignation("1")}
                                      disabled
                                    />
                                    <strong>&nbsp; &nbsp;Company</strong>
                                  </label>
                                  <br />
                                  <label>
                                    <input
                                      name="selectedDesignation"
                                      type="radio"
                                      value="2"
                                      checked={selectedDesignation === "2"}
                                      onChange={() => setDesignation("2")}
                                      disabled
                                    />
                                    <strong>&nbsp; &nbsp;Individual</strong>
                                  </label>
                                </div>
                              )}
                              {!radioDisable && (
                                <div>
                                  <label>
                                    <input
                                      name="selectedDesignation"
                                      type="radio"
                                      value="1"
                                      checked={selectedDesignation === "1"}
                                      onChange={() => setDesignation("1")}
                                    />
                                    <strong>&nbsp; &nbsp;Company</strong>
                                  </label>
                                  <br />
                                  <label>
                                    <input
                                      name="selectedDesignation"
                                      type="radio"
                                      value="2"
                                      checked={selectedDesignation === "2"}
                                      onChange={() => setDesignation("2")}
                                    />
                                    <strong>&nbsp; &nbsp;Individual</strong>
                                  </label>
                                </div>
                              )}
                            </div>
                          </FormControl>
                        </Grid>
                      </Grid>
                    );
                  }}
                />
              </Grid>

              <Field
                name="supplierCode"
                label="Supplier Code"
                placeholder="Supplier Code"
                component={InputFieldOneRow}
                validate={[maxLength20]}
              />
              {selectedDesignation === "2" && (
                <div>
                  <Field
                    name="title"
                    placeholder="Title"
                    label="Title"
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="firstName"
                    placeholder="First Name"
                    label="First Name"
                    required={true}
                    validate={required({
                      msg: "First Name is a required field",
                    })}
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="middleName"
                    placeholder="Middle Name"
                    label="Middle Name"
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="lastName"
                    label="Last Name"
                    placeholder="Last Name"
                    required={true}
                    validate={required({
                      msg: "Last Name is a required field",
                    })}
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="dob"
                    component={DatePickerFieldOneRow}
                    label="Date of Birth"
                    validate={required({
                      msg: "Date of Birth is a required field",
                    })}
                  />
                  <Field
                    name="gender"
                    component={solpayReactSelect}
                    options={[
                      { value: 1, label: "Male" },
                      { value: 2, label: "Female" },
                    ]}
                    label="Gender"
                    placeholder=""
                    required={true}
                    validate={required({
                      msg: "Gender is a required field",
                    })}
                  />
                </div>
              )}
              {selectedDesignation === "1" && (
                <div>
                  <Field
                    name="companyName"
                    label="Company Name"
                    placeholder="Company Name"
                    required={true}
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="acn"
                    label="ACN"
                    placeholder="ACN"
                    component={InputFieldOneRow}
                  />
                </div>
              )}
              <Field
                name="abn"
                label="ABN"
                placeholder="ABN"
                required={true}
                validate={[
                  required({
                    msg: "ABN is a required field",
                  }),
                  // abn({ lowerCase: true, allowBlank: true }),
                ]}
                component={InputFieldOneRow}
              />

              <Field
                name="taxFileNo"
                label="Tax File No"
                placeholder="Tax File No"
                component={InputFieldOneRow}
              />
              <Field
                name="email"
                label="Email"
                placeholder="Email"
                required={true}
                validate={[
                  required({
                    msg: "Email is a required field",
                  }),
                  email,
                ]}
                component={InputFieldOneRow}
              />
              {selectedDesignation === "2" && (
                <div>
                  <Field
                    name="homePhone"
                    label="Home Phone"
                    placeholder="Home Phone"
                    required={true}
                    validate={[
                      required({
                        msg: "Home Phone is a required field",
                      }),
                      phone,
                      maxLength25,
                    ]}
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="workPhone"
                    label="Work Phone"
                    placeholder="Work Phone"
                    validate={[phone, maxLength25]}
                    component={InputFieldOneRow}
                  />
                </div>
              )}

              {selectedDesignation === "1" && (
                <div>
                  <Field
                    name="mainPhone"
                    label="Main Phone"
                    placeholder="Main Phone"
                    required={true}
                    validate={[
                      required({
                        msg: "Main Phone is a required field",
                      }),
                      phone,
                      maxLength25,
                    ]}
                    component={InputFieldOneRow}
                  />
                  <Field
                    name="otherPhone"
                    label="Other Phone"
                    placeholder="Other Phone"
                    validate={[phone, maxLength25]}
                    component={InputFieldOneRow}
                  />
                </div>
              )}

              {selectedDesignation === "2" && (
                <Field
                  name="mobile"
                  label="Mobile Number"
                  placeholder="Mobile Number"
                  validate={[mobile, maxLength30]}
                  component={InputFieldOneRow}
                />
              )}
              <Field
                name="fax"
                label="Fax"
                placeholder="Fax"
                component={InputFieldOneRow}
              />
              {selectedDesignation === "1" && (
                <Field
                  name="website"
                  label="Website"
                  placeholder="Website"
                  component={InputFieldOneRow}
                />
              )}
              <Field
                name="isActive"
                layout="horizontal"
                // labelCol={3}
                // fieldColumn={9}
                component={SolpayCheckBox}
                label="Active"
              />
              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
            </Grid>
            <Grid item xl={6} md={6}>
              <PageTitleHeader header="Address" />

              <Field
                name="street1"
                component={InputFieldOneRow}
                label="Street Address"
                placeholder="Street Line 1"
              />
              <Field
                name="street2"
                component={InputFieldOneRow}
                label="Street Line 2"
                placeholder="Street Line 2"
              />
              <Field
                name="suburb"
                component={InputFieldOneRow}
                label="Suburb"
                placeholder="Suburb"
              />
              <Field
                name="state"
                component={solpayReactSelect}
                label="State"
                options={stateList}
                placeholder="State"
                required={true}
                validate={[
                  required({
                    msg: "State is a required field",
                  }),
                ]}
              />
              <Field
                name="postCode"
                component={InputFieldOneRow}
                label="Post Code"
                validate={[postCode]}
                placeholder="Post Code"
              />

              <Field
                name="country"
                component={InputFieldOneRow}
                label="Country"
                placeholder="Country"
              />
              <PageTitleHeader header="Bank Info" />

              <Field
                name="accountName"
                component={InputFieldOneRow}
                label="Account Name"
                placeholder="Account Name"
                // required={true}
                // validate={[required]}
              />
              <Field
                name="bsb"
                component={InputFieldOneRow}
                label="BSB"
                placeholder="BSB"
                validate={[bsb({ allowBlank: true })]}
              />
              <Field
                name="accountNumber"
                component={InputFieldOneRow}
                label="Account Number"
                placeholder="Account Number"
              />
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
      </Box>
      <br />
    </div>
  );
}
const SupplierFormView = reduxForm({
  form: "SupplierFormView",
  enableReinitialize: true,
  asyncValidate: supplierCodeAsyncValidate,
  asyncChangeFields: ["supplierCode", "supplierId"],
})(SupplierForm);
const mapStateToProps = (state: ISupplierState, ownProps) => {
  const supplier = state.supplier.supplier;
  let supplierType = "Company";
  // eslint-disable-next-line eqeqeq
  if (
    typeof ownProps.showInModal === "undefined" ||
    // eslint-disable-next-line eqeqeq
    ownProps.showInModal == false
  ) {
    if (typeof ownProps.match != "undefined") {
      if (ownProps.match.params.supplierId > 0) {
        if (
          typeof state.supplier.selectedSupplier.supplierId !== "undefined" ||
          state.supplier.selectedSupplier.supplierId ===
            ownProps.match.params.supplierId
        ) {
          localStorage.setItem(
            "supplier",
            state.supplier.selectedSupplier.supplierTypeName
          );
          supplierType = state.supplier.selectedSupplier.supplierTypeName;
        } else {
          let selSupplier = localStorage.getItem("supplier");
          supplierType = selSupplier;
        }
        return {
          designationList: state.supplier.designationList,
          selectedDesignation: ownProps.match.params.supplierType,
          suppliers: state.suppliers,
          formType: supplierType === "Individual" ? "2" : "1",
          initialValues: {
            ...supplier,
            country:
              supplier.country === "" || typeof supplier.country === "undefined"
                ? "Australia"
                : supplier.country,
          },
        };
      } else {
        return {
          supplier: state.supplier,
          designationList: state.supplier.designationList,
          selectedDesignation: ownProps.match.params.supplierType,
          formType: "1",
          suppliers: state.suppliers,
          initialValues: {
            gender: 1,
            ...state.supplier.supplier,
            country: "Australia",
            isActive: true,
          },
        };
      }
    }
  } else {
    return {
      supplier: state.supplier,
      designationList: state.supplier.designationList,
      formShownInModal: ownProps.showInModal,
      formType: "1",
      initialValues: {
        gender: 1,
        ...state.supplier.supplier,
        country: "Australia",
        isActive: true,
      },
    };
  }
};

export default connect(mapStateToProps)(SupplierFormView);
