import AddIcon from "@mui/icons-material/Add";
import {
  BottomNavigation,
  BottomNavigationAction,
  Box,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import Select, { components } from "react-select";

const SelectMenuButton = (props) => {
  return (
    <components.MenuList {...props}>
      <Box>
        <List>
          <ListItem>
            <ListItemText>{props.children}</ListItemText>
          </ListItem>
        </List>

        <Box sx={{ position: "sticky", bottom: -4 }} onClick={props.onClick}>
          <Divider />
          <BottomNavigation showLabels>
            <BottomNavigationAction
              style={{ cursor: "pointer" }}
              label={props.label}
              icon={<AddIcon fontSize="inherit" />}
            ></BottomNavigationAction>
          </BottomNavigation>
        </Box>
      </Box>
    </components.MenuList>
  );
};

const SolpayReactSelectIcon = ({
  label,
  input,
  options,
  multi,
  group,
  child,
  onDataSelect,
  meta: { touched, error, warning },
  labelCol,
  fieldColumn,
  iconEvent,
  Addlabel,
}) => {
  const { name, value, onBlur, onChange, onFocus } = input;
  const transformedValue = transformValue(value, options, multi);
  let config = { layout: "horizontal" };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";

  return (
    <Grid container>
      <Grid
        item
        style={{
          width: "100%",
        }}
      >
        <FormControl size="small" style={{ width: "98%", zIndex: 0 }}>
          <Select
            components={{
              MenuList: (menuProps) => (
                <SelectMenuButton
                  {...menuProps}
                  onClick={iconEvent}
                  label={Addlabel}
                />
              ),
            }}
            menuPortalTarget={document.body}
            // closeMenuOnScroll={(e: any) =>
            //   !e.target.classList.contains("css-4ljt47-MenuList")
            // }
            menuPosition={"fixed"}
            valueKey="value"
            name={name}
            value={transformedValue}
            multi={multi}
            options={options}
            onChange={(e) => {
              onDataSelect(e);
            }}
            onBlur={() => onBlur(value)}
            onFocus={onFocus}
            placeholder={Addlabel}
          />
        </FormControl>
        {touched && error && (
          <InputLabel
            style={{
              display: "block",
              color: "red",
              fontSize: "12px",
              margin: "2px",
            }}
          >
            {error}
          </InputLabel>
        )}
      </Grid>
    </Grid>
  );
};

SolpayReactSelectIcon.defaultProps = {
  multi: false,
};

SolpayReactSelectIcon.propTypes = {
  input: PropTypes.shape({
    name: PropTypes.string.isRequired,
    //value: PropTypes.string.isRequired,
    onBlur: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onFocus: PropTypes.func.isRequired,
  }).isRequired,
  options: PropTypes.array.isRequired,
  multi: PropTypes.bool,
  className: PropTypes.string,
};

export default SolpayReactSelectIcon;

/**
 * onChange from Redux Form Field has to be called explicity.
 */
function singleChangeHandler(func) {
  return function handleSingleChange(value) {
    func(value ? value.value : "");
  };
}

/**
 * onBlur from Redux Form Field has to be called explicity.
 */
function multiChangeHandler(func) {
  return function handleMultiHandler(values) {
    func(values.map((value) => value.value));
  };
}

/**
 * For single select, Redux Form keeps the value as a string, while React Select
 * wants the value in the form { value: "grape", label: "Grape" }
 *
 * * For multi select, Redux Form keeps the value as array of strings, while React Select
 * wants the array of values in the form [{ value: "grape", label: "Grape" }]
 */
function transformValue(value, options, multi) {
  if (multi && typeof value === "string") return [];

  const filteredOptions = options.filter((option) => {
    return multi ? value.indexOf(option.value) !== -1 : option.value === value;
  });

  return multi ? filteredOptions : filteredOptions[0];
}
