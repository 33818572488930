import DashboardButtonCard from 'libComp/cards/dashboardButtonCard';
import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ButtonCard from '../cards/buttonCard';

interface button {
  imageSrc: any;
  imageAlt: string;
  header: string;
  description: string;
  to: string;
}
interface Props {
  buttons: Array<button>;
}
function dashBoardButton(props: Props) {
  return (
    <div className="">
      {props.buttons.map((button, index) => (
        <div className="btn-box text-center  dashboard-icon-heading" key={"div_dbc_"+index}>
          <Col md={12} sm={12} xs={12}>
            <DashboardButtonCard key={"dbc_"+index} {...button} />
          </Col>
        </div>
      ))}
    </div>
  );
}

export default dashBoardButton;
