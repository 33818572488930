import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";
class userDataAccess {
  getAll =
    (pageNo, pageSize, filters, sortPreference, scope) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      return commonDataAccess.getData("User/getuserslist", {
        pageNo,
        pageSize,
        sortPreference,
        scope,
        ..._filters,
      })(commonActions);
    };

  getAllForAllTenant =
    (pageNo, pageSize, sortPreference) => (commonActions: ICommonActions) => {
      return commonDataAccess.getData("User/getusersforalltenant", {
        pageNo,
        pageSize,
        sortPreference,
      })(commonActions);
    };

  getUserDetail = (userId: number) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("user/getuserinfo", {
      id: userId,
    })(commonActions);
  };

  saveUserInfo = (payload: any) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "user/saveuserinfo",
      payload
    )(commonActions);
  };

  changeUserPassword = (payload: any) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "user/change-password",
      payload
    )(commonActions);
  };

  resetPassword = (payload: any) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData("user/resetpassword", {
      id: payload.id,
      confirmPassword: payload.confirmPassword,
      newPassWord: payload.newPassword,
    })(commonActions);
  };
  // getAuditLogs = () => (commonActions: ICommonActions) => {
  //   console.log("getAuditLogs");
  //   return commonDataAccess.getData("user/get-activity-logs")(commonActions);
  // };
  getAuditLogs =
    (pageNo, pageSize, filters, sortPreference, scope) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      //console.log(filters);
      filters.forEach((item) => {
        if (item.value) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      //console.log(_filters);
      return commonDataAccess.getData("User/get-activity-logs", {
        pageNo,
        pageSize,
        sortPreference,
        scope,
        ..._filters,
      })(commonActions);
    };
  getUserList = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("user/get-user-list")(commonActions);
  };
}

export default new userDataAccess();
