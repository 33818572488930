import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import tenantDataAccess from "dataAccess/tnt/tenantDataAccess";
import { actions as commonActions } from "../commonRedux";
import { Dispatch } from "redux";
const typesPrefix = "TENANT_";

const types = {
  getAll: typesPrefix + "GET_ALL",
  getById: typesPrefix + "GET_ORGANIZATION_BY_ID",
  getBunisessInfo: typesPrefix + "GET_BUSINESS_INFO",
  getPayerInfo: typesPrefix + "GET_PAYER_INFO",
  getSysAdministratorInfo: typesPrefix + "GET_SYS_ADMINS_INFO",
  getBillingContactInfo: typesPrefix + "GET_BILLING_CONTACT_INFO",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
};

export const actions = {
  getAll:
    (
      type = "active",
      filters = [],
      pageNo = 1,
      pageSize = defaultPageSize,
      sortPreference = "Name ASC"
    ) =>
    (dispatch, getState) => {
      dispatch(commonActions.loading());
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      tenantDataAccess
        .getAll(
          type,
          filters,
          pageNo,
          pageSize,
          sortPreference
        )(state.commonState.commonActions)
        .then((tenantData) => {
          dispatch({
            type: types.getAll,
            payload: {
              list: tenantData.data.list,
              totalCount: tenantData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },
  getById: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    tenantDataAccess
      .getById(id)(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getById,
          payload: {
            organization: data.data,
            uiMessages: data.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getBusinessInfo: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    tenantDataAccess
      .getBusinessInfo()(state.commonState.commonActions)
      .then((businessData) => {
        dispatch({
          type: types.getBunisessInfo,
          payload: {
            businessInfo: businessData.data,
            uiMessages: businessData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getPayerInfo: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    tenantDataAccess
      .getPayerInfo()(state.commonState.commonActions)
      .then((businessData) => {
        dispatch({
          type: types.getPayerInfo,
          payload: {
            payerInfo: businessData.data,
            uiMessages: businessData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getSysAdminInfo: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    tenantDataAccess
      .getSysAdminsInfo()(state.commonState.commonActions)
      .then((businessData) => {
        dispatch({
          type: types.getSysAdministratorInfo,
          payload: {
            sysAdminsInfo: businessData.data,
            uiMessages: businessData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getBillingConactInfo: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    tenantDataAccess
      .getBillingContactInfo()(state.commonState.commonActions)
      .then((businessData) => {
        dispatch({
          type: types.getBillingContactInfo,
          payload: {
            billingContactInfo: businessData.data,
            uiMessages: businessData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};

export interface ITenantState {
  organization: any;
  organizations: Array<any>;
  businessInfo: any;
  payerInfo: any;
  sysAdminsInfo: any;
  billingContactInfo: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: ITenantState = {
  organization: {},
  organizations: [],
  businessInfo: {},
  payerInfo: {},
  sysAdminsInfo: {},
  billingContactInfo: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: ITenantState = initialState,
  action: Action
): ITenantState => {
  switch (action.type) {
    case types.getAll:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        organizations: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.getById:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        organization: action.payload.organization,
      };
    case types.getBunisessInfo:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        businessInfo: action.payload.businessInfo,
      };
    case types.getPayerInfo:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        payerInfo: action.payload.payerInfo,
      };
    case types.getSysAdministratorInfo:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        sysAdminsInfo: action.payload.sysAdminsInfo,
      };
    case types.getBillingContactInfo:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        billingContactInfo: action.payload.billingContactInfo,
      };
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    default:
      return state;
  }
};
