import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const getSuperFundsTable = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Fund Name",
      field: "fundName",
    },
    {
      headerName: "ABN",
      field: "abn",
    },
    {
      headerName: "USI",
      field: "usi",
      sortable: false,
    },
    {
      headerName: "BSB Number",
      field: "bsbNumber",
      sortable: false,
    },
    {
      headerName: "Account Name",
      field: "accountName",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "progress",
      sortable: false,
      width: 85,
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              title="Edit"
              size="small"
              onClick={() => {
                props.history.push("/admin/superfund/addEdit/" + params.row.id);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
