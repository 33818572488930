import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { FormControl, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useStyleClass } from "styles/theming/useStyleClass";

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "7.5px 14px",
      },
    },
    "& .MuiInputLabel-formControl ": {
      top: "-8px",
    },
    "& .Mui-focused": {
      top: "0px",
    },
    "& .MuiInputLabel-shrink": {
      top: "0px",
    },
  },
});

export const DatePickerFieldOneRow = (props) => {
 // console.log("date pickers props__", props);
  const {
    meta: { touched, error, submitting },
    input: { onBlur, label, value, ...inputProps },
  } = props;
  let config = { layout: "horizontal" };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";

  const onChange = (date) => {
    props.input.value = Date.parse(date)
      ? inputProps.onChange(
          moment.utc(date.toLocaleString()).format("MM/DD/YYYY")
        )
      : inputProps.onChange(null);
  };

  const classes = useStyles();
  const style = useStyleClass();

  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl
          style={{ width: "98%" }}
          className={classes.root}
          classes={{ root: style.inputStyle }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={onChange}
              value={value}
              disabled={submitting}
              inputFormat="dd/MM/yyyy"
              label={props.label}
              renderInput={(params) => (
                <TextField
                  {...params}
                  {...props}
                  error={touched && !!error}
                  helperText={touched && error}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const DatePickerFieldOneRowWithoutLabel = (props) => {
  const {
    meta: { touched, error, submitting },
    input: { onBlur, value, ...inputProps },
  } = props;
  let config = { layout: "horizontal" };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";

  const classes = useStyles();
  const style = useStyleClass();

  const onChange = (date) => {
    Date.parse(date)
      ? inputProps.onChange(
          moment.utc(date.toLocaleString()).format("MM/DD/YYYY")
        )
      : inputProps.onChange(null);
  };

  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
      }}
    >
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl
          style={{ width: "98%" }}
          className={classes.root}
          classes={{ root: style.inputStyle }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={onChange}
              value={value}
              disabled={submitting}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => (
                <TextField
                  required
                  {...params}
                  {...props}
                  error={touched && !!error}
                  helperText={touched && error}
                />
              )}
            />
          </LocalizationProvider>
        </FormControl>
      </Grid>
    </Grid>
  );
};
