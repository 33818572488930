import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class PaymentHeadDataAccess {
  getPaymentHeadList = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('EmployeePaymentHead/getall')(
      commonActions
    );
  };
  getOvertimePaymentHeadList = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'EmployeePaymentHead/getallovertimepayhead'
    )(commonActions);
  };
  savePaymentHead = (payHead) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'EmployeePaymentHead/save',
      payHead
    )(commonActions);
  };
  getPaymentHead = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('EmployeePaymentHead/get', { id })(
      commonActions
    );
  };
  getAllAllowanceTypes = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('EmployeePaymentHead/getallallowancetype')(
      commonActions
    );
  };
}

export default new PaymentHeadDataAccess();
