import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import { IReduxState } from "@redux";
import yearClosingAccess from "dataAccess/bkp/yearClosingDataAcess";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
// import SalePageMenuHeader from "libComp/pageMenuHeaders/salePageMenuHeader";
import { APIResponse } from "models/response";
import { useState } from "react";
import {} from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

function YearClosing(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [closingYears, setClosingYears] = useState([]);
  const [uiMessage, setUiMessage] = useState([]);
  yearClosingAccess
    .getClosingYears()(commonState.commonActions)
    .then((data) => setClosingYears(data.data));
  const onFormSubmit = (model) => {
    return yearClosingAccess
      .closeYear(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) setUiMessage(response.uiMessages);
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <>
      <br />
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
          height: "90vh",
        }}
      >
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        >
          {uiMessage.map((item, index) => (
            <>
              <Alert
                key={index}
                variant="filled"
                severity="warning"
                onClose={() => {
                  setUiMessage([]);
                }}
              >
                {item.messageText}
              </Alert>
              <br />
            </>
          ))}

          <Grid container spacing={6}>
            <Grid item sm={12} md={6}>
              <Field
                name="dateFilterOption"
                component={solpayReactSelect}
                options={closingYears}
                label="Year End For"
                validate={required}
              />
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <></>
                  )}{" "}
                  &nbsp;End Year
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      <br />
    </>
  );
}
const yearClosing = reduxForm<any>({
  form: "YearClosing",
  enableReinitialize: true,
})(YearClosing);
const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});
export default connect(mapStateToProps)(yearClosing);
