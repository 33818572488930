import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useAppSelector } from "@redux/hook.type";
import userDataAccess from "dataAccess/user/userDataAccess";
import { InputFieldOneRow } from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { useSnackbar } from "notistack";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { password, passwordsMustMatch, required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

export const ChangePassword = (props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commonActions = useAppSelector(
    (state) => state.commonState.commonActions
  );
  const onSubmit = (data) => {
    userDataAccess
      .changeUserPassword(data)(commonActions)
      .then((res) => {
        enqueueSnackbar("Password has been changed!", { variant: "success" });
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };
  const style = useStyleClass();

  return (
    <>
      <br />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header={"Change Password"} />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onSubmit(data))}
        >
          <Grid container spacing={10} sx={{ width: "90%" }}>
            <Grid item xl={6} md={6}>
              <Field
                name="currentPassword"
                type="password"
                mdL={3}
                mdI={7}
                component={InputFieldOneRow}
                validate={[required]}
                label="Current password"
              />
              <Field
                name="password"
                type="password"
                mdL={3}
                mdI={7}
                component={InputFieldOneRow}
                validate={[required, password]}
                label="Password"
              />
              <Field
                name="confirmPassword"
                type="password"
                mdL={3}
                mdI={7}
                component={InputFieldOneRow}
                validate={[passwordsMustMatch, required]}
                label="Confirm Password"
              />

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
};

const changePassword = reduxForm<any, any>({
  form: "changePassword",
  enableReinitialize: true,
})(ChangePassword);

const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});

export default connect(mapStateToProps)(changePassword);
