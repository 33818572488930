import { InputFieldOneRow } from "libComp/common/inputRenderes";
import { Field } from "redux-form";

const employeeOvertimePayFields = ({
  fields,
  meta,
  dispatch,
  discountAmount,
}) => {
  // console.log("F", fields.getAll());
  return (
    <>
      {fields?.getAll()?.map((field, index) => (
        <Field
          key={`${field?.paymentHead}_${index}`}
          label={`${field?.paymentHead}`}
          // name={`${field}.amount`}
          name={"pyrEmployeeOvertimePayments[" + index + "]?.amount"}
          component={InputFieldOneRow}
        />
      ))}
    </>
  );
};
export default employeeOvertimePayFields;
