import { IClientState } from "@redux/crm/clientRedux";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import { clientCodeAsyncValidate } from "services/asyncValidate";
import clientForm from "./clientForm";

const ClientFormView = reduxForm({
  form: "ClientFormView",
  enableReinitialize: true,
  asyncValidate: clientCodeAsyncValidate,
  asyncChangeFields: ["clientCode", "clientId"],
})(clientForm);

const mapStateToProps = (state: IClientState, ownProps) => {
  const client = state.client.client;
  let clientType = "Company";
  if (
    typeof ownProps.showInModal === "undefined" ||
    // eslint-disable-next-line eqeqeq
    ownProps.showInModal == false
  ) {
    if (typeof ownProps.match != "undefined") {
      if (ownProps.match.params.clientId > 0) {
        if (
          typeof state.client.client.clientId !== "undefined" ||
          state.client.client.clientId === ownProps.match.params.clientId
        ) {
          clientType =
            state.client.client.entityType === "1"
              ? "Company"
              : "individualClient";
          localStorage.setItem("client", clientType);
          //supplierType = state.supplier.selectedSupplier.supplierTypeName;
        } else {
          let selClient = localStorage.getItem("client");
          clientType = selClient;
        }
        return {
          designationList: state.client.designationList,
          selectedDesignation: ownProps.match.params.supplierType,
          clients: state.clients,
          formType: clientType === "Individual" ? "2" : "1",
          initialValues: {
            ...client,
            country:
              client.country === "" || typeof client.country === "undefined"
                ? "Australia"
                : client.country,
          },
        };
      } else {
        return {
          supplier: [],
          designationList: state.client.designationList,
          selectedDesignation: ownProps.match.params.supplierType,
          formType: "1",
          clients: state.clients,

          initialValues: {
            gender: 1,
            ...state.client.client,
            country: "Australia",
            isActive: true,
          },
        };
      }
    }
  } else {
    return {
      supplier: state.client,
      designationList: state.client.designationList,
      formShownInModal: ownProps.showInModal,
      // selectedDesignation: ownProps.match.params.supplierType,
      formType: "1",
      //  suppliers: state.suppliers,
      initialValues: {
        gender: 1,
        ...state.client.client,
        country: "Australia",
        isActive: true,
      },
    };
  }
};

export default connect(mapStateToProps)(ClientFormView);
