import { Box, Stack } from "@mui/material";
import { actions as tenantActions } from "@redux/tnt/tenantRedux";
import PageTabsMui from "libComp/sections/pageTabsMui";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import TabBillingConactInfo from "pages/settings/config/business/billingContactInfo";
import TabBusinessInfo from "pages/settings/config/business/businessInfo";
import TabPayerInfo from "pages/settings/config/business/payerInfo";
import TabSysAdminInfo from "pages/settings/config/business/sysAdminInfo";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";

function Business(props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const [value, setValue] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(tenantActions.getBusinessInfo());
    dispatch(tenantActions.getPayerInfo());
    dispatch(tenantActions.getSysAdminInfo());
    dispatch(tenantActions.getBillingConactInfo());
  }, []);

  const tabItems = [
    {
      title: "Business Info",
      component: <TabBusinessInfo history={props.history} />,
      value: 0,
    },
    {
      title: "Payer Info",
      component: <TabPayerInfo history={props.history} />,
      value: 1,
    },
    {
      title: "System Administrator",
      component: <TabSysAdminInfo history={props.history} />,
      value: 2,
    },
    {
      title: "Billing Contact",
      component: <TabBillingConactInfo history={props.history} />,
      value: 3,
    },
  ];

  return (
    <>
      <br />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header="Business Settings" />
      </Stack>

      <Box
        sx={{
          flex: 1,
          width: "100%",
          mx: "auto",
          minHeight: "75%",
        }}
      >
        <PageTabsMui tabItems={tabItems} handleTabChange={handleTabChange} />
      </Box>
    </>
  );
} 
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Business);
