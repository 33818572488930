// import PayrollPageMenuHeader from "libComp/pageMenuHeaders/payrollPageMenuHeader";
import { Box, Button, Typography } from "@mui/material";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { actions as superActions } from "@redux/pyr/superAnnuationRedux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import React from "react";
import { useDispatch } from "react-redux";
import { useStyleClass } from "styles/theming/useStyleClass";
import "./super.css";
function SuperRegistration(params) {
  const dispatch = useDispatch();
  const reviewOrganization = () => {
    dispatch(superActions.getOrganization());
    params.history.push("/payroll/reviewbusiness");
  };

  const style = useStyleClass();

  const steps = [
    "Review organization details",
    "Review Assignee who will be approved payments",
    "Review bank account",
  ];

  return (
    <React.Fragment>
      <div className="page-container">
        <PageTitleHeader header="Set Up Superannuation" />
        <Box sx={{ width: "100%" }}>
          <Typography
            variant="h6"
            component="div"
            sx={{
              textAlign: "center",
              margin: 4,
            }}
          >
            Set Up Automtic Superannuation
          </Typography>
          <Stepper alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />
          <Box textAlign="center">
            <Button
              classes={{ root: style.button }}
              variant="contained"
              size="large"
              onClick={reviewOrganization}
            >
              Get Started
            </Button>
          </Box>
        </Box>
      </div>
    </React.Fragment>
  );
}
export default SuperRegistration;
