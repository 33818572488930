import { StyledEngineProvider } from "@mui/material/styles";
import ThemeProvider from "@mui/styles/ThemeProvider";
import reducers from "@redux";
import App from "app";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, createStore } from "redux";
import reduxPromise from "redux-promise";
import reduxThunk from "redux-thunk";
import "styles/styles.scss";
import { theme } from "styles/theming/theme.conifig";

const createStoreWithMiddleware = applyMiddleware(
  reduxPromise,
  reduxThunk
)(createStore);
const store = createStoreWithMiddleware(
  reducers,
  window["__REDUX_DEVTOOLS_EXTENSION__"] &&
    window["__REDUX_DEVTOOLS_EXTENSION__"]()
);

ReactDOM.render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </StyledEngineProvider>,
  document.getElementById("root")
);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
