import { Alert, Box } from "@mui/material";
import { IReduxState } from "@redux";
import superAnnuationDataAccess from "dataAccess/pyr/superAnnuationDataAccess";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

function RegistrationComplete(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const superregistrationId = props.match.params.id;
  const [uiMessage, setUiMessage] = useState([]);

  useEffect(() => {
    if (superregistrationId)
      superAnnuationDataAccess
        .updateSuperOrganization(superregistrationId)(commonState.commonActions)
        .then((data) => {
          setUiMessage(data.uiMessages);
        });
  }, [superregistrationId]);

  return (
    <>
      <br />
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
          height: "90vh",
        }}
      >
        <Alert
          variant="filled"
          severity="warning"
          onClose={() => {
            setUiMessage([]);
          }}
        >
          Superannuation registration has been completed!!!
        </Alert>
      </Box>
      <br />
    </>
    // <React.Fragment>
    //   <br />
    //   <div id="mainBody">
    //     <div className="container">
    //       {/* {uiMessage &&
    //         uiMessage.map((item, index) => ( */}
    //       <Alert
    //         // key={index}
    //         variant="success"
    //         onClose={() => {
    //           setUiMessage([]);
    //         }}
    //         dismissible
    //       >
    //         Superannuation registration has been completed
    //       </Alert>
    //       {/* ))} */}
    //     </div>
    //   </div>
    // </React.Fragment>
  );
}

export default RegistrationComplete;
