import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class TaxCodeDataAccess {
  getAll = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('TaxCode/getall')(commonActions);
  };
}

export default new TaxCodeDataAccess();
