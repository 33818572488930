import { IReduxState } from "@redux";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { actions as superFundActions } from "@redux/pyr/superFundRedux";
import superFundDataAccess from "dataAccess/pyr/superFundDataAccess";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useStyleClass } from "styles/theming/useStyleClass";

function SuperFundsDetails(props) {
  const dispatch = useDispatch();
  const fundId = props.match.params.id;

  const commonState = useSelector((state: IReduxState) => state.commonState);

  React.useEffect(() => {
    if (fundId > 0) dispatch(superFundActions.getSuperFundById(fundId));
  }, []);

  // Breadcrumb
  let MenuItems = [];
  if (fundId > 0) {
    MenuItems = [
      {
        title: "Admin",
        to: null,
      },
      {
        title: "Super Funds",
        to: "/admin/superfunds",
      },
      {
        title: "Edit Super Fund",
        to: "/admin/superfund/addEdit/{fundId}",
      },
    ];
  } else {
    MenuItems = [
      {
        title: "Admin",
        to: null,
      },
      {
        title: "Super Funds",
        to: "/admin/superfunds",
      },
      {
        title: "New Fund",
        to: "/admin/superfund/addEdit/0",
      },
    ];
  }

  const style = useStyleClass();

  const onFormSubmit = (model) => {
    superFundDataAccess
      .superFundSave(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) props.history.push("/admin/superfunds");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} /> <br />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader
          header={
            fundId === "0" ? "New Fund" : "Edit : Advance Retirement Suite"
          }
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            sx={{
              px: { xs: 2, sm: 2, md: 4, lg: 6, xl: 6 },
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
              <Field name="id" component={InputHidden} />

              <Field
                name="fundName"
                placeholder="Fund Name"
                label="Fund Name"
                component={InputFieldOneRow}
              />
              <Field
                name="abn"
                placeholder="ABN"
                label="ABN"
                component={InputFieldOneRow}
              />
              <Field
                name="usi"
                placeholder="USI"
                label="USI"
                component={InputFieldOneRow}
              />
              <Field
                name="accountName"
                placeholder="Account Name"
                label="Account Name"
                component={InputFieldOneRow}
              />
              <Field
                name="bsbNumber"
                placeholder="BSB Number"
                label="BSB Number"
                component={InputFieldOneRow}
              />
              <Field
                name="accountNumber"
                placeholder="Account Number"
                label="Account Number"
                component={InputFieldOneRow}
              />

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              Save
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}

const addEditSuperFund = reduxForm({
  form: "SuperFundsDetails",
  enableReinitialize: true,
})(SuperFundsDetails);

// selector = formValueSelector("SupportTicketFormView");

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getSuperFund: () =>
      dispatch(superFundActions.getSuperFundById(ownProps.match.params.id)),
  };
};

const mapStateToProps = (state, ownProps) => ({
  ...state.superFund,
  initialValues: {
    ...state.superFund.superFund,
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(addEditSuperFund);
