import { Edit, Mail } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { formatDate } from "libComp/utility.service";

export const getInvoiceTableColumns = (props, extra = {}) => {
  const columns: GridColumns = [
    {
      headerName: "Invoice No",
      field: "invoiceNo",
      sortable: true,
    },
    {
      headerName: "Client Name",
      field: "clientName",
      sortable: true,
    },
    {
      headerName: "Invoice Date",
      field: "invoiceDate",
      sortable: true,
      renderCell: (params) => {
        return formatDate(params.row?.invoiceDate);
      },
    },
    {
      headerName: "Invoice Amount",
      field: "totalAmountIncGST",
      sortable: true,
    },
    {
      headerName: "Paid Amount",
      field: "totalPayment",
      sortable: true,
    },
    {
      headerName: "Actions",
      field: "",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              onClick={() => {
                props.history.push(
                  "/accounting/sales/invoice/addedit/" + params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>
            <IconButton size="small" title="Mail">
              <Mail />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
