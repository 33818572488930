import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as employeeActions,
  IEmployeeState,
} from "@redux/emp/employeeRedux";
import { stateList } from "common/constants";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Field,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { email, maxLength, mobile, phone, required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

const maxLength100 = maxLength(100);
const maxLength25 = maxLength(25);
const maxLength4 = maxLength(4);
const maxLength6 = maxLength(6);

const validate = (values) => {
  const errors = {
    street1: "",
    suburb: "",
    postCode: "",
    country: "",
    accountNumber: "",
    bsb: "",
    accountName: "",
  };
  if ((values.suburb || values.postCode) && !values.street1) {
    errors.street1 = "Street1 is required";
  } else {
    errors.street1 = undefined;
  }
  if ((values.street1 || values.postCode) && !values.suburb) {
    errors.suburb = "suburb is required";
  } else {
    errors.suburb = undefined;
  }
  if ((values.street1 || values.suburb) && !values.postCode) {
    errors.postCode = "postCode is required";
  } else {
    errors.postCode = undefined;
  }

  if ((values.street1 || values.suburb || values.postCode) && !values.country) {
    errors.country = "country is required";
  } else {
    errors.country = undefined;
  }
  if ((values.accountName || values.bsb) && !values.accountNumber) {
    errors.accountNumber = "account number is required";
  } else {
    errors.accountNumber = undefined;
  }
  if ((values.accountNumber || values.bsb) && !values.accountName) {
    errors.accountNumber = "account number is required";
  } else {
    errors.accountNumber = undefined;
  }
  if ((values.accountName || values.accountNumber) && !values.bsb) {
    errors.bsb = "bsb number is required";
  } else {
    errors.accountNumber = undefined;
  }
  return errors;
};

interface Props {
  employeeId: string;
  employee: IEmployeeState;
  history: any;
}

function TabEmployee(props: InjectedFormProps<any, Props> & Props) {
  const style = useStyleClass();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const employeeId = props.employeeId;
  const employeeState: IEmployeeState = props.employee;
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onFormSubmit = async (model) => {
    try {
      const data = await employeeDataAccess.saveEmployee(model)(
        commonState.commonActions
      );
      const response = data;

      if (response.success) {
        //console.log(response);
        if (employeeId === "0") {
          enqueueSnackbar("Employee has been saved!", {
            variant: "success",
          });
          props.history.push("/payroll/employees");
        } else if (employeeId == response.data) {
          enqueueSnackbar("Employee has been updated!", {
            variant: "success",
          });
        }
      } else {
        enqueueSnackbar("Employee could not be saved!", {
          variant: "error",
        });
      }
    } catch (error) {
      throw new SubmissionError({ _error: error.message });
    }
  };

  return (
    <>
      <Box
        key="box"
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          px: 2,
        }}
      >
        <form
          onSubmit={props?.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          {employeeState?.uiMessages?.map((item, index) => (
            <div key={"_div" + index}>
              <Alert
                key={"_" + index}
                variant="filled"
                severity="warning"
                onClose={() => {
                  dispatch(employeeActions?.removeUiMessage(index));
                }}
              >
                {item?.messageText}
              </Alert>
              <br />
            </div>
          ))}

          <Grid container spacing={6}>
            <Grid item xl={6} md={6} sx={{ mt: 4 }}>
              <Field
                name="contact.title"
                component={InputFieldOneRow}
                label="Title"
                placeholder=""
                validate={required}
              />
              <Field
                name="contact.firstName"
                component={InputFieldOneRow}
                label="First Name*"
                placeholder=""
                validate={required}
              />
              <Field
                name="contact.lastName"
                component={InputFieldOneRow}
                label="Last Name"
                placeholder=""
                validate={required}
              />
              <Field
                name="joiningDate"
                component={DatePickerFieldOneRow}
                label="Joining Date"
                placeholder=""
              />
              <Field
                name="terminationDate"
                component={DatePickerFieldOneRow}
                label="Termination Date"
                placeholder=""
              />
              <Field
                name="employmentType"
                component={solpayReactSelect}
                label="Employee Type"
                options={[
                  { value: 1, label: "Full-Time" },
                  { value: 2, label: "Part-time" },
                  { value: 3, label: "Labour hire" },
                  { value: 4, label: "Superannuation" },
                  { value: 5, label: "Casual employment" },
                  { value: 6, label: "Contractor" },
                ]}
                placeholder=""
              />
              <Field
                name="currentPosition"
                component={InputFieldOneRow}
                label="Job Title"
                placeholder=""
                validate={required}
              />
              <Field
                name="contact.gender"
                component={solpayReactSelect}
                options={[
                  { value: 1, label: "Male" },
                  { value: 2, label: "Female" },
                ]}
                label="Gender"
                placeholder=""
              />
              <Field
                name="contact.dob"
                component={DatePickerFieldOneRow}
                label="DOB"
                placeholder=""
              />
              <Field
                name="contact.email"
                component={InputFieldOneRow}
                label="Email"
                placeholder="example@example.com"
                validate={[required, email, maxLength100]}
              />
              <Field
                name="contact.homePhone"
                component={InputFieldOneRow}
                label="Phone"
                placeholder="e.g. 07 3454 6789 or 0734546789"
                validate={[required, phone, maxLength25]}
              />
              <Field
                name="contact.mobile"
                component={InputFieldOneRow}
                label="Mobile"
                placeholder="Mobile No"
                validate={[mobile, maxLength25]}
              />
              <Field
                name="isActive"
                label="Active?"
                component={SolpayCheckBox}
              />
            </Grid>
            <Grid item xl={6} md={6}>
              <PageTitleHeader header="Address" />
              <Field name="id" component={InputHidden} />
              <Field name="tenantId" component={InputHidden} />
              <Field
                name="street1"
                component={InputFieldOneRow}
                label="Street Address"
                placeholder="Street Line 1"
              />
              <Field
                name="street2"
                component={InputFieldOneRow}
                label=" "
                placeholder="Street Line 2"
              />
              <Field
                name="suburb"
                component={InputFieldOneRow}
                label="Suburb"
                placeholder="Suburb"
              />
              <Field
                name="state"
                component={solpayReactSelect}
                label="State"
                options={stateList}
                placeholder=""
              />
              <Field
                name="postCode"
                component={InputFieldOneRow}
                label="Post Code"
                placeholder="Post Code"
                validate={[maxLength4]}
              />
              <Field
                name="country"
                component={InputFieldOneRow}
                label="Country"
                placeholder="Country"
              />
              <PageTitleHeader header="Bank info" />

              <Field name="id" component={InputHidden} />
              <Field name="tenantId" component={InputHidden} />
              <Field
                name="accountName"
                component={InputFieldOneRow}
                label="Account Name"
                placeholder="Account name"
              />
              <Field
                name="bsb"
                component={InputFieldOneRow}
                label="BSB"
                placeholder="BSB"
                validate={maxLength6}
              />
              <Field
                name="accountNumber"
                component={InputFieldOneRow}
                label="Account Number"
                placeholder="Account Number"
                validate={maxLength100}
              />
            </Grid>
          </Grid>

          {props.error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {props.error}
            </Typography>
          )}
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              {props.submitting ? (
                <CircularProgress
                  size={24}
                  sx={{
                    color: "blue",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              ) : (
                <i className="fa fa-check"></i>
              )}{" "}
              {employeeId > "0" ? "Update" : "Save"}
            </Button>
          </Box>
        </form>
      </Box>
    </>
  );
}
const tabUpsertEmployee = reduxForm<any, Props>({
  form: "TabUpsertEmployee",
  validate,
  enableReinitialize: true,
})(TabEmployee);
const mapStateToProps = (state, ownProps) => ({
  employee: state.employee,
  initialValues:
    ownProps.employeeId === "0"
      ? {
          Contact: { Gender: 1 },
          EmploymentType: 1,
          country: "Australia",
          IsActive: true,
          state: "ACT",
        }
      : state.employee.employee,
});

export default connect(mapStateToProps)(tabUpsertEmployee);
