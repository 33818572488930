import { Box, Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { IReduxState } from "@redux";
import { actions as invoiceAction } from "@redux/inv/invoiceRedux";
import invoiceDataAccess from "dataAccess/inv/invoiceDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  InputFieldOneRow,
  InputFieldOneRowReadOnly,
  InputHidden,
} from "libComp/common/inputRenderes";
import SolpayModal from "libComp/common/solpayModal";
import SolpayReactSelectIcon from "libComp/common/solpaySelectRendersWithIcon";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import AddEditInvoicePayment from "pages/payment/invoicePayment/addEditInvoicePayment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { change, Field, FieldArray, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import ClientFormView from "../../../contacts/client/clientFormView";
import InvoiceDetails from "./invoiceDetails";
import PaymentDetail from "./paymentDetails";

function InvoiceFormView(props) {
  let clientList = [];
  const style = useStyleClass();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  let isShowPaymentButton = true;

  const error = props.error;

  if (props.clientList.length > 0) clientList = props.clientList;

  const [clients, setClients] = useState([]);
  const [isAddPaymentrow, setIsAddPaymentrow] = useState(false);
  const [isAddPaymentModel, setIsAddPaymentModel] = useState(false);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState([]);

  const dispatch = useDispatch();
  const invoiceId = props.match.params.id;

  const [showClientModal, setShowClientModal] = useState(false);
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const discountAmount = props.discountAmount ? props.discountAmount : 0.0;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleAddPaymentModelClose = () => setIsAddPaymentModel(false);
  const handleAddPaymentModelShow = () => setIsAddPaymentModel(true);

  const clientAdd = (data) => {
    clientList.push(data);
  };

  useEffect(() => {
    if (invoiceId > 0) {
      dispatch(invoiceAction.getInvoice(invoiceId));
      invoiceDataAccess
        .getAllPaymentMethod()(commonState.commonActions)
        .then((data) => {
          setPaymentMethodOptions(
            data.data.map((item, index) => ({
              ...item,
              value: item.id,
            }))
          );
        });
    }
    dispatch(invoiceAction.fetchInvoicelookUpData());
  }, []);

  let history = useHistory();

  const onFormSubmit = (model) => {
    return invoiceDataAccess
      .saveInvoice(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) {
          enqueueSnackbar("Invoice has been saved!", { variant: "success" });
          props.history.push("/accounting/sales/invoices");
        } else
          enqueueSnackbar("Invoice could not been saved!", {
            variant: "error",
          });
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Accounting",
      to: null,
    },
    {
      title: "Sales",
      to: null,
    },
    {
      title: "Invoices",
      to: "/accounting/sales/invoices",
    },
  ];

  if (invoiceId > 0) {
    BCMenuItems.push({
      title: "Edit Invoice",
      to: null,
    });
  } else {
    BCMenuItems.push({
      title: "New Invoice",
      to: null,
    });
  }

  return (
    <React.Fragment>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader
          header={invoiceId === "0" ? "New Invoice" : "Edit Invoice"}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => {
            onFormSubmit(data);
          })}
          noValidate
        >
          <Grid container spacing={10} sx={{ width: "90%" }}>
            <Grid item xl={6} md={6}>
              <Field
                name="invoiceNo"
                label="Invoice Number"
                component={InputFieldOneRowReadOnly}
              />
              <Field
                name="clientId"
                label="Client"
                validate={required}
                options={clientList}
                component={SolpayReactSelectIcon}
                Addlabel="Add a new Client"
                iconEvent={handleShow}
              />
              <Field component={InputHidden} name="clientList" />
              <Field
                name="reference"
                component={InputFieldOneRow}
                label="Reference"
              />
            </Grid>
            <Grid item xl={6} md={6}>
              <Field
                name="invoiceDate"
                md={5}
                sm={6}
                component={DatePickerFieldOneRow}
                label="Invoice Date"
                validate={required}
                // validate={required({
                //   msg: "This is a required field",
                // })}
              />
              <Field
                name="deliveredOn"
                md={5}
                sm={6}
                component={DatePickerFieldOneRow}
                label="Delivery Date"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={12} md={12} sx={{ overflow: "hidden" }}>
              <PageTitleHeader header="Products / Services" />
              <FieldArray
                name="clientInvoiceDetails"
                component={InvoiceDetails}
                props={{ dispatch, discountAmount }}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {error}
            </Typography>
          )}

          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              Save
            </Button>
            &nbsp;
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              Save and Send email
            </Button>
          </Box>
        </form>
      </Box>
      <Box sx={{ flex: 1, width: "95%", mx: "auto", mt: 2, mb: 2 }}>
        {props.initialValues.totalPayment > 0 && (
          <Grid container spacing={10}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <PageTitleHeader
                header="Add Invoice Payment"
                buttons={
                  isShowPaymentButton
                    ? [
                        {
                          title: "Add Invoice Payment",
                          onClick: (index) => {
                            handleAddPaymentModelShow();
                            setTimeout(() => {
                              setIsAddPaymentrow(false);
                            }, 500);
                          },
                          iconName: "fa fa-plus",
                          id: "ipadd1",
                        },
                      ]
                    : []
                }
              />
              <Grid>
                <FieldArray
                  name="clientInvoicePayments"
                  component={PaymentDetail}
                  props={{
                    dispatch,
                    discountAmount,
                    paymentMethodOptions,
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Box>
      {show && (
        <SolpayModal
          onClose={handleClose}
          title="Add Client"
          data={
            <ClientFormView
              formShownInModal={show}
              clientId="0"
              pageTitle="Add Clent"
              setClients={(data) => {
                clientAdd({
                  label: data.data.fullName,
                  id: data.data.clientId,
                  value: data.data.clientId,
                });
                dispatch(
                  change("InvoiceFormView", `clientId`, data.data.clientId)
                );
                handleClose();
              }}
            />
          }
        />
      )}
      {isAddPaymentModel && (
        <SolpayModal
          onClose={handleAddPaymentModelClose}
          title="Add Payment"
          data={
            <AddEditInvoicePayment
              setPayment={(data) => {
                handleAddPaymentModelClose();
              }}
            />
          }
        />
      )}
    </React.Fragment>
  );
}

export default InvoiceFormView;
