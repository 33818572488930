import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";
class addressDataAccess {
  saveAddress =
    (address, entityId, entityTypeId) => (commonActions: ICommonActions) => {
      console.log(address, entityId, entityTypeId);
      return commonDataAccess.postData("Address/save",  
      address, {
        entityId,
        entityTypeId,
      })(commonActions);
    };
  getAddresses =
    (entityTypeId, entityId) => (commonActions: ICommonActions) => {
      return commonDataAccess.getData("Address/entityaddresslist", {
        entityTypeId,
        entityId,
      })(commonActions);
    };
  getAddress = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Address/getbyid", { id })(commonActions);
  };
  getAllStates = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Address/getallstates")(commonActions);
  };
}
export default new addressDataAccess();
