import { IReduxState } from "@redux";
import payementDataAccess from "dataAccess/pyr/payrunDataAccess";
import { Dispatch } from "redux";
import { actions as commonActions } from "../commonRedux";
import { defaultPageSize } from "common/constants";
const typesPrefix = "PAYRUN_";

const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getListForPayRun: typesPrefix + "GET_LIST_TO_PAY",
  getHead: typesPrefix + "GET_PAYMENT",
  getPayRun: typesPrefix + "GET_PAYRUN",
  getPaySlip: typesPrefix + "GET_PAYSLIP",
  getPayRunToReview: typesPrefix + "GET_PAYRUN_TO_REVIEW",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
  getSelectedChecked: typesPrefix + "SELECTED_CHECKED",
};

export const actions = {
  getPreviuosPays:
    (
      pageNo = 1, 
      pageSize = defaultPageSize, 
      filters = [], 
      sortPreference = ""
    ) =>
    (dispatch, getState) => {
      dispatch(commonActions.loading());
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      payementDataAccess
        .getPreviuosPayList(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((payData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: payData.data.list,
              totalCount: payData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        })
        .finally(() => {
          dispatch(commonActions.loadingEnd());
        });
    },
  getListForStartPayRun: () => (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    payementDataAccess
      .getListForStartPayRun()(state.commonState.commonActions)
      .then((payData) => {
        dispatch({
          type: types.getListForPayRun,
          payload: {
            pyrPayRun: payData.data,
            uiMessages: payData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => {
        dispatch(commonActions.loadingEnd());
      });
  },
  getPayRunToReview: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    payementDataAccess
      .getPayRunToReview(id)(state.commonState.commonActions)
      .then((payData) => {
        dispatch({
          type: types.getPayRunToReview,
          payload: {
            payRun: payData.data,
            uiMessages: payData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getPaySlipToReview: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    payementDataAccess
      .getPaySlipToReview(id)(state.commonState.commonActions)
      .then((payData) => {
        dispatch({
          type: types.getPaySlip,
          payload: {
            paySlip: payData.data,
            uiMessages: payData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getPayrunSummary: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    payementDataAccess
      .getPayrunSummary(id)(state.commonState.commonActions)
      .then((payData) => {
        dispatch({
          type: types.getPayRun,
          payload: {
            payRun: payData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getSelectedChecked: (value) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();

    dispatch({
      type: types.getSelectedChecked,
      payload: {
        getSelectedChecked: value,
      },
    });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};

export interface IPayRunState {
  previousPayments: Array<any>;
  pyrPayRun: any;
  payRun: any;
  paySlip: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  getSelectedChecked: Array<boolean>;
}

const initialState: IPayRunState = {
  previousPayments: [],
  pyrPayRun: {},
  payRun: {},
  paySlip: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
  getSelectedChecked: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IPayRunState = initialState,
  action: Action
): IPayRunState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        previousPayments: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.getListForPayRun:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        uiMessages: action.payload.uiMessages,
        pyrPayRun: action.payload.pyrPayRun,
      };
    case types.getPayRunToReview:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        payRun: action.payload.payRun,
      };
    case types.getPayRun:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        payRun: action.payload.payRun,
      };
    case types.getPaySlip:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        paySlip: action.payload.paySlip,
      };
    case types.getSelectedChecked:
      return {
        ...state,
        getSelectedChecked: action.payload.getSelectedChecked,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };
    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};
