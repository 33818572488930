import PageButtons from "libComp/sections/pageButtons";
import payicon from "media/images/blueImages/payroll.svg";
import saleIcon from "media/images/blueImages/users.svg";
import { connect, useDispatch, useSelector } from "react-redux";

function Report() {
  const Buttons = [
    {
      header: "Sales",
      description: "Sales Reports",
      to: "/reports/sales",
      imageSrc: saleIcon,
      imageAlt: "Sales",
    },
    {
      header: "Payroll",
      description: "Payroll Reports",
      to: "/reports/payroll",
      imageSrc: payicon,
      imageAlt: "Purchase",
    },
    {
      header: "Business",
      description: "Business Reports",
      to: "/reports/business",
      imageSrc: saleIcon,
      imageAlt: "Business",
    },
    {
      header: "GST",
      description: "GST Reports",
      to: "/reports/gst",
      imageSrc: saleIcon,
      imageAlt: "GST",
    },
  ];
  return (
    <div>
      <PageButtons buttons={Buttons}></PageButtons>
    </div>
  );
}
const mapStateToProps = ({ employee, listState }) => ({ employee, listState });
export default connect(mapStateToProps)(Report);
