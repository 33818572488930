import { IReduxState } from "@redux";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import { Alert, Box, Grid, Button, CircularProgress } from "@mui/material";
import ATOReportDataAccess from "dataAccess/pyr/atoReportDataAccess";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { SavePPFFromBiteArray } from "services/commonService";
import { useEffect, useState } from "react";

function ATOReport(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [dropdownYears, setDropdownYears] = useState([]);
  const [uiMessage, setUiMessage] = useState([]);
  const [warningMessage, setwarningMessage] = useState([]);
  const [open, setOpen] = useState(false);
  const style = useStyleClass();
  useEffect(() => {
    ATOReportDataAccess.getPrepareYearDropdown()(
      commonState.commonActions
    ).then((data) => setDropdownYears(data.data));
  }, [commonState.commonActions]);
  const handleClick = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const onFormSubmit = async (model) => {
    try {
      const reportResponse = await ATOReportDataAccess.atoReportGenerate(model)(
        commonState.commonActions
      );
      if (reportResponse.uiMessages) {
        handleClick();
        setwarningMessage(reportResponse.uiMessages);
      }
      if (!reportResponse.uiMessages) {
        SavePPFFromBiteArray("EMPDUPE.A01", reportResponse);
      }
    } catch (error) {
      //console.log(error);
    }
  };

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Reports",
      to: "/payroll/Report",
    },
    {
      title: "ATO Report",
      to: "/payroll/Report/ATOReport",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="ATO Report" />
        <Box
          sx={{
            flex: 1,
            width: "96%",
            mx: "auto",
            borderColor: "grey.200",
            p: 2,
            mt: 2,
          }}
        >
          <form
            noValidate
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            {open &&
              warningMessage?.map((item, index) => (
                <div key={"_div" + index}>
                  <Alert
                    key={"_" + index}
                    variant="filled"
                    severity="warning"
                    onClose={() => {
                      handleClose();
                    }}
                  >
                    {item?.messageText}
                  </Alert>
                  <br />
                </div>
              ))}

            {uiMessage.map((item, index) => (
              <>
                <Alert
                  key={index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    setUiMessage([]);
                  }}
                >
                  {item.messageText}
                </Alert>
                <br />
              </>
            ))}
            <Grid container spacing={6}>
              <Grid item sm={12} md={6}>
                <Field
                  name="year"
                  component={solpayReactSelect}
                  options={dropdownYears}
                  label="Year"
                  validate={required}
                />
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    classes={{ root: style.button }}
                    sx={{ mt: 1 }}
                    variant="contained"
                    type="submit"
                    disabled={props.submitting}
                  >
                    {props.submitting ? (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: "blue",
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          marginTop: "-12px",
                          marginLeft: "-12px",
                        }}
                      />
                    ) : (
                      <></>
                    )}{" "}
                    &nbsp;Generate Electronic Lodgment File
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </>
  );
}
const atoReport = reduxForm<any>({
  form: "ATOReport",
  enableReinitialize: true,
  asyncChangeFields: ["year"],
})(ATOReport);
const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});
export default connect(mapStateToProps)(atoReport);
