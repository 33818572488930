import { IReduxState } from "@redux";
import {
  actions as productActions,
  IProductState,
} from "@redux/inv/productRedux";
import { IListState } from "@redux/listRedux";
import Bootbox from "bootbox-react";
import { defaultPageSize, filterOptions } from "common/constants";
import productDataAccess from "dataAccess/inv/productDataAccess";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { productListTable } from "./products.config";
function Products(props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const commonState = useSelector((state: IReduxState) => state.commonState);

  const productState: IProductState = props.product;
  const listState: IListState = props.listState;
  // useEffect(() => {
  //   getProductList();
  // }, []);

  const getProductList = useCallback(
    (pageNo = 0, pageSize = defaultPageSize, filters = [], sortPreference = "id-desc") => {
      dispatch(
        productActions.getProducts(
          pageNo + 1,
          pageSize,
          filters,
          sortPreference
        )
      );
    },
    [selection]
  );

  const [showConfirm, setShowConfirm] = useState(false);
  const [productId, setproductId] = useState("");

  const deleteProduct = (id) => {
    productDataAccess
      .deleteProduct(id)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          toast.success("Deleted successfully");
          dispatch(
            productActions.getProducts(
              listState.pageNo,
              listState.pageSize,
              listState.filters,
              listState.sort
            )
          );
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setShowConfirm(false));
  };

  let extraProps = {
    onDeleteAction: (id) => {
      setproductId(id);
      setShowConfirm(true);
    },
  };

  const tableColumns = productListTable(props, extraProps);

  return (
    <div className="page-container">
      <PageTitleHeader
        header="Product / Service List"
        buttons={[
          {
            title: "Add Product / Service",
            onClick: (index) => {
              props.history.push("/accounting/purchase/product/addedit/0");
            },
            iconName: "fa fa-plus",
            id: "proadd1",
          },
        ]}
      />
      <Bootbox
        show={showConfirm}
        type={"confirm"}
        message={"Do you want to delete this payment?"}
        onSuccess={() => {
          deleteProduct(productId);
          setShowConfirm(false);
        }}
        onCancel={() => setShowConfirm(false)}
        onClose={() => setShowConfirm(false)}
      />
      <Filter
        filterOptions={[
          {
            propertyName: "ProductName",
            placeholder: "Product Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ProductCode",
            placeholder: "Product Code",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            productActions.getProducts(pageNo, pageSize, filters, sortData)
          );
        }}
      />
      <SolpayGrid
        columns={tableColumns}
        rows={productState.products}
        rowCount={productState.totalCount}
        loading={productState.isLoading}
        loadData={getProductList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getProductList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ product, listState }) => ({ product, listState });
export default connect(mapStateToProps)(Products);
