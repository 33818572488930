import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import superFundDataAccess from "dataAccess/pyr/superFundDataAccess";
import { Dispatch } from "redux";
const typesPrefix = "SUPERFUND_";

const types = {
  getList: typesPrefix + "GET_LIST",
  getSuperFunds: typesPrefix + "GET_SUPERFUND",
  getSuperFundById: typesPrefix + "GET_SUPERFUND__BY_ID",
  cleanSuperFunds: typesPrefix + "CLEAN_SUPERFUND",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
};

export const actions = {
  getSuperFunds:
    (pageNo = 1, pageSize = defaultPageSize, filters = [], sortPreference = "") =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      superFundDataAccess
        .getAllSuperFundList(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((superData) => {
          dispatch({
            type: types.getList,
            payload: {
              list: superData.data.list,
              totalCount: superData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },

  getSuperFundById: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });

    const state: IReduxState = getState();
    superFundDataAccess
      .getFundById(id)(state.commonState.commonActions)
      .then((Data) => {
        dispatch({
          type: types.getSuperFundById,
          payload: Data.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },

  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};

export interface ISuperFundState {
  superFunds: Array<any>;
  superFund: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: ISuperFundState = {
  superFunds: [],
  superFund: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: ISuperFundState = initialState,
  action: Action
): ISuperFundState => {
  switch (action.type) {
    case types.getList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        superFunds: action.payload.list,
        superFund: [],
        totalCount: action.payload.totalCount,
      };

    case types.getSuperFundById:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        superFund: action.payload,
        totalCount: action.payload.totalCount,
      };

    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    default:
      return state;
  }
};
