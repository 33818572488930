import { Edit, Mail, Receipt } from "@mui/icons-material";
import PrintIcon from '@mui/icons-material/Print';
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { quatationFormUrl } from "common/config";
import { formatDate } from "libComp/utility.service";

export const getQuotationTableColumns = (props, extra = {}) => {
  const columns: GridColumns = [
    {
      headerName: "Quotation No",
      field: "quotationNo",
    },
    {
      headerName: "Client Name",
      field: "clientName",
    },
    {
      headerName: "Quotation Date",
      field: "quotationDate",
      renderCell: (params) => {
        return formatDate(params.row?.quotationDate);
      },
    },
    {
      headerName: "Quotation Amount",
      field: "totalAmount",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      width: 150,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              title="Edit"
              size="small"
              onClick={() => {
                props.history.push(quatationFormUrl + params.row.id);
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              title="Convert To Invoice"
              size="small"
              onClick={() => {}}
            >
              <Receipt />
            </IconButton>
            <IconButton size="small" title="Print" onClick={() => {}}>
              <PrintIcon />
            </IconButton>
            <IconButton size="small" title="Mail" onClick={() => {}}>
              <Mail />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
