import SearchIcon from "@mui/icons-material/Search";
import { Box, Button, Divider, Grid } from "@mui/material";
import {
  actions as superStreamReportActions,
  ISuperAnnuationState,
} from "@redux/pyr/superAnnuationRedux";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
// Import Worker
import { Worker } from "@react-pdf-viewer/core";
// Import the main Viewer component
import { TextDirection, Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// default layout plugin
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

const SuperStreamReport = (props) => {
  const superPaymentState: ISuperAnnuationState = props.superannuation;

  const [reportdata, setreportdata] = useState();
  const [customDate, setCustomDate] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  const style = useStyleClass();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(superStreamReportActions.getSuperStreamReport());
  }, []);

  const onFormSubmit = (model) => {
    //console.log(model);
  };

  const handleCustomDate = (e) => {
    if (e === "5") setCustomDate(true);
    else setCustomDate(false);
  };

  const createMarkup = () => {
    return { __html: reportdata };
  };

  let quarterList = useSelector(
    (state: any) => state.superannuation.superStreamReport
  );

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Payroll",
      to: null,
    },
    {
      title: "Super Payment List",
      to: "/payroll/super-payments",
    },
    {
      title: "Super Payment",
      to: "/payroll/super-payment/super-stream-report",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />

      <div className="page-container">
        <PageTitleHeader header="Super Payment" />
        <br />

        <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
          <form
            noValidate
            // onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Field
                  name="quarter"
                  component={solpayReactSelect}
                  options={quarterList}
                  label="Pay Period"
                  validate={required}
                  onChange={handleCustomDate}
                />

                {customDate && (
                  <>
                    <Field
                      name="startDate"
                      md={5}
                      sm={6}
                      component={DatePickerFieldOneRow}
                      label="Start Date"
                    />

                    <Field
                      name="endDate"
                      md={5}
                      sm={6}
                      component={DatePickerFieldOneRow}
                      label="End Date"
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Divider />

            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                classes={{ root: style.button }}
                sx={{ m: 2 }}
                variant="contained"
                type="submit"
                disabled={props.submitting}
              >
                <SearchIcon />
                &nbsp;Search
              </Button>
            </Box>
          </form>
          <Grid item xs={12} md={12}>
            {reportdata ? (
              <div className="viewer">
                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js">
                  <Viewer
                    fileUrl={reportdata}
                    theme={{
                      theme: "auto",
                      direction: TextDirection.RightToLeft,
                    }}
                    plugins={[defaultLayoutPluginInstance]}
                    // renderLoader={(percentages: number) => (
                    //   <div style={{ width: '240px' }}>
                    //     <ProgressBar progress={Math.round(percentages)} />
                    //   </div>
                    // )}
                  />
                </Worker>
              </div>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  border: "2px dashed rgba(0, 0, 0, .3)",
                  display: "flex",
                  fontSize: "2rem",
                  height: "100%",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Preview area
              </div>
            )}

            <br></br>

            {/* <div dangerouslySetInnerHTML={createMarkup()}></div> */}
          </Grid>
        </Box>
      </div>

      <br></br>
      <div dangerouslySetInnerHTML={createMarkup()}></div>
    </>
  );
};

const salesStreamReport = reduxForm<any>({
  form: "SuperStreamReport",
  enableReinitialize: true,
})(SuperStreamReport);

const mapStateToProps = ({ superannuation }) => ({
  superannuation,
});
export default connect(mapStateToProps)(salesStreamReport);
