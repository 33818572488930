import React from "react";

function NotAuthorize() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "30vh",
      }}
    >
      <div style={{ fontSize: "40px", fontWeight: "bold", color: "black" }}>
        UNAUTHORIZED
      </div>

      <div>
        <div
          style={{ borderBottom: "1px solid gray", marginTop: "10px" }}
        ></div>
        <div style={{ fontSize: "20px" }}>
          Sorry, You are not authorized to access this page
        </div>
      </div>
    </div>
  );
}

export default NotAuthorize;
