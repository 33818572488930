import { IReduxState } from "@redux";
import groupBy from "@redux/bkp/transactionRedux";
import { Dispatch } from "@reduxjs/toolkit";
import { defaultPageSize } from "common/constants";
import invoiceDataAccess from "dataAccess/inv/invoiceDataAccess";
const typesPrefix = "INVOICE_";
export const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getInvoice: typesPrefix + "GET_INVOICE",
  cleanInvoice: typesPrefix + "CLEAN_INVOICE",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
  getLookupData: typesPrefix + "Get_LOOK_UP_DATA",
  productListforInvoice: "PRODUCT_LIST_FOR_INVOICE",
};

export const actions = {
  getInvoices:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "invoiceDate-desc"
    ) =>
      (dispatch: Dispatch, getState) => {
        dispatch({ type: types.loading });

        const state: IReduxState = getState();
        invoiceDataAccess
          .getInvoiceList(
            pageNo,
            pageSize,
            filters,
            sortPreference
          )(state.commonState.commonActions)
          .then((productData) => {
            dispatch({
              type: types.addToList,
              payload: {
                list: productData.data.list,
                totalCount: productData.data.totalCount,
              },
            });
          })
          .catch((error: Error) => {
            dispatch({
              type: types.failed,
              payload: error.message,
            });
          });
      },

  fetchInvoicelookUpData: () => (dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    invoiceDataAccess
      .getInvoiceLookupData()(state.commonState.commonActions)
      .then((response) => {
        // response.data is the users
        const lookUpdata = response.data;
        dispatch({
          type: types.getLookupData,
          payload: {
            lookUpData: lookUpdata,
          },
        });
      })
      .catch((error) => {
        // error.message is the error message
        dispatch({
          type: types.failed,
          payload: error.message,
        })(state.commonState.commonActions).then((response) => {
          // response.data is the users
          const lookUpdata = response.data;
          dispatch({
            type: types.getLookupData,
            payload: {
              lookUpData: lookUpdata,
            },
          });
        });
      });
  },

  getInvoice: (id: string) => (dispatch, getState) => {
    dispatch({ type: types.loading });

    const state: IReduxState = getState();
    invoiceDataAccess
      .getInvoice(id)(state.commonState.commonActions)
      .then((invoiceData) => {
        dispatch({
          type: types.getInvoice,
          payload: invoiceData.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },

  getClientInvoices: (clientId) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });

    const state: IReduxState = getState();
    invoiceDataAccess
      .getClientInvoiceList(clientId)(state.commonState.commonActions)
      .then((productData) => {
        dispatch({
          type: types.addToList,
          payload: {
            list: productData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },

  // cleanInvoice: (employeeId: string) => (dispatch: Dispatch) => {
  //   dispatch({ type: types.cleanInvoice, payload: employeeId });
  // },
  // removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
  //   dispatch({ type: types.removeUiMessage, payload: messageIndex });
  // },
};
export interface IInvoiceState {
  invoices: Array<any>;
  clientInvoiceDetails: Array<any>;
  bankAccountList: Array<any>;
  invoice: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  clientList: Array<any>;
  supplierList: Array<any>;
  productList: Array<any>;
  accountList: Array<any>;
  taxList: Array<any>;
  selectedAccountModel: any;
  accountListWithoutGroup: Array<any>;
  currentInvoice: string;
}

const initialState: IInvoiceState = {
  invoices: [],
  clientInvoiceDetails: [],
  invoice: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
  clientList: [],
  supplierList: [],
  productList: [],
  accountList: [],
  accountListWithoutGroup: [],
  taxList: [],
  selectedAccountModel: {},
  currentInvoice: "",
  bankAccountList: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IInvoiceState = initialState,
  action: Action
): IInvoiceState => {
  switch (action.type) {
    case types.cleanInvoice:
      if (
        state.invoice.id &&
        state.invoice.id.toString() === action.payload.toString()
      ) {
        return state;
      } else {
        return { ...state, uiMessages: [], invoice: {} };
      }
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        invoices: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.getInvoice:
      return {
        ...state,
        isLoading: false,
        invoice: action.payload,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };
    case types.getLookupData:
      return {
        ...state,
        clientList: action.payload.lookUpData.clientList,
        //supplierList: action.payload.lookUpData.clientList,
        productList: action.payload.lookUpData.productListmodels,
        // accountList: Object.entries(
        //   groupBy(action.payload.lookUpData.accountListmodels, (t) => t.type)
        // ).map(([label, options]) => ({ label, options })),
        taxList: action.payload.lookUpData.taxListmodels,
        // bankAccountList: Object.entries(
        //   groupBy(
        //     action.payload.lookUpData.bankAccountListmodels,
        //     (t) => t.type
        //   )
        // ).map(([label, options]) => ({ label, options })),
        currentInvoice: action.payload.lookUpData.currentInvoice,
        // selectedAccountModel: action.payload.lookUpData.selectedAccountModel,
        // accountListWithoutGroup: action.payload.lookUpData.accountListmodels,
      };
    default:
      return state;
  }
};
