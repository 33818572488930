import { getBills } from "@redux/bill/billActions";
import { IBillState } from "@redux/bill/billReducer";
import { useAppSelector } from "@redux/hook.type";
import { defaultPageSize, filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getBillListTableColumns } from "./bill-list.config";

function BillList(props) {
  const [selection, setSelection] = useState(null);
  const billState: IBillState = props.bill;
  const title = "Bill List";
  const dispatch = useDispatch();
  const listState = useAppSelector((state) => state.listState);
  // useEffect(() => {
  //   getBillList();
  // }, []);

  const getBillList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "purchaseDate-desc"
    ) => {
      dispatch(getBills(pageNo + 1, pageSize, filters, sortPreference));
    },
    [selection]
  );

  const billButtons = [
    {
      title: "Add Bill",
      onClick: (index) => {
        props.history.push("/accounting/purchase/bill/addedit/0");
      },
      iconName: "fa fa-plus",
      id: "billadd1",
    },
  ];

  const tableColumns = getBillListTableColumns(props);

  return (
    <div className="page-container">
      <PageTitleHeader header={title} buttons={billButtons} />
      <Filter
        filterOptions={[
          {
            propertyName: "BillNumber",
            placeholder: "Bill Number",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "SupplierName",
            placeholder: "Supplier Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(getBills(pageNo, pageSize, filters, sortData));
        }}
      />

      <SolpayGrid
        columns={tableColumns}
        rows={billState.bills}
        rowCount={billState.totalCount}
        loading={billState.isLoading}
        loadData={getBillList}
        triggerChange={(pageNumber, pageSize, sort) => {
          //console.log("page number", pageNumber);
          getBillList(pageNumber, pageSize, listState.filters, sort);
        }}
        // onPageChange={(pageNumber, details) => {

        //   console.log("page Number",pageNumber);
        //   dispatch( getBillList(pageNumber, 10, listState.filters, "purchaseDate-desc"));

        // }}
      />
    </div>
  );
}
const mapStateToProps = ({ bill, listState }) => ({ bill, listState });
export default connect(mapStateToProps)(BillList);
