import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as employeeActions } from "@redux/emp/employeeRedux";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import { useSnackbar } from "notistack";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Field,
  formValueSelector,
  InjectedFormProps,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
interface Props {
  employeeId: string;
  hasTFN: boolean;
  hasOffset: boolean;
}
function TabTaxInfo(props: InjectedFormProps<any, Props> & Props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const employeeId = props.employeeId;
  const dispatch = useDispatch();
  const history = useHistory();

  React.useEffect(() => {
    if (employeeId === "0") history.push("/payroll/employees");
  }, []);

  const onFormSubmit = (model) => {
    return employeeDataAccess
      .saveTaxInfo(
        props.employeeId,
        model
      )(commonState.commonActions)
      .then((data) => {
        enqueueSnackbar("Tax Info has been saved!", { variant: "success" });
        dispatch(employeeActions.getTaxInfo(employeeId));
      })
      .catch((error: Error) => {
        enqueueSnackbar("Tax Info could not been saved!", {
          variant: "error",
        });
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "100%",
          mx: "auto",
        }}
      >
        <form
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          noValidate
        >
          <Grid container spacing={6}>
            <Grid item xl={7} md={7} sm={12} xs={12}>
              <Field name="id" component={InputHidden} />
              <Field name="tenantId" component={InputHidden} />
              <Field name="employeeId" component={InputHidden} />
              <Field name="createdBy" component={InputHidden} />
              <Field name="createdOn" component={InputHidden} />
              <Field
                name="isAUResidant"
                component={SolpayCheckBox}
                label="Is Australian resident for tax purposes?"
              />
              <Field
                name="hasTFN"
                component={SolpayCheckBox}
                label="Has Tax File No?"
              />
              {props.hasTFN && (
                <Field
                  name="tfn"
                  component={InputFieldOneRow}
                  label="Tax File No"
                  placeholder="Tax File No"
                />
              )}
              <Field
                name="claimedThreshold"
                component={SolpayCheckBox}
                label="Claim tax-free threshold from this payer?"
              />
              <Field
                name="hasOffset"
                component={SolpayCheckBox}
                label="Claim tax offset?"
              />
              {props.hasOffset && (
                <Field
                  name="claimedOffset"
                  component={InputFieldOneRow}
                  label="Offset Amount"
                  placeholder="Offset Amount"
                />
              )}
              <Field
                name="hasHELPdebt"
                component={SolpayCheckBox}
                label="Has Higher Education Loan Program debt?"
              />

              <Field
                name="hasSFSSdebt"
                component={SolpayCheckBox}
                label="Has Financial Supplement debt?"
              />

              {props.error && (
                <Typography
                  variant="subtitle2"
                  component="div"
                  classes={{ root: style.error }}
                >
                  {props.error}
                </Typography>
              )}

              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}

const tabTaxInfo = reduxForm<any, Props>({
  form: "TabTaxInfo",
  enableReinitialize: true,
})(TabTaxInfo);

const selector = formValueSelector("TabTaxInfo");

const mapStateToProps = (state, ownProps) => ({
  hasTFN: selector(state, "hasTFN"),
  hasOffset: selector(state, "hasOffset"),
  initialValues: state.employee.taxInfo,
});

export default connect(mapStateToProps)(tabTaxInfo);
