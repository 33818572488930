import { useAppSelector } from "@redux/hook.type";
import {
  actions as paymentActions,
  IPaymentState,
} from "@redux/pay/paymentRedux";
import { IReduxState } from "@redux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getBillPaymentTable } from "./SubscriptionPaymentList.config";
import { defaultPageSize } from "common/constants";

const SubscriptionPaymentList = (props) => {
  //const billPaymentState: IPaymentState = props.payment;
  const [selection, setSelection] = useState(null);
  const title = "Bill Payments";
  const dispatch = useDispatch();
  const billPaymentState = useSelector((state: IReduxState) => state?.payment);
  const listState = useSelector((state: IReduxState) => state?.listState); 

  // const getAllBillPayments = useCallback(() => {
  //   dispatch(paymentActions.getAllBillPayments());
  // }, [selection]);
  const getAllBillPayments = useCallback((
    pageNo = 0,
    pageSize = defaultPageSize,
    filters = [],
    sortPreference = ""
  )=>{
    dispatch(paymentActions.getAllBillPayments(
      pageNo + 1,
      pageSize, 
      filters,
      sortPreference
    ));
  },[selection]);

  const tableColumns = getBillPaymentTable(props);

  return (
    <div className="page-container">
      <PageTitleHeader header={title} />

      <SolpayGrid
        columns={tableColumns}
        rows={billPaymentState?.billPayments}
        rowCount={billPaymentState?.totalCount}
        loading={billPaymentState?.isLoading}
        loadData={getAllBillPayments}
        triggerChange={(pageNumber, pageSize, sort) => {
          getAllBillPayments(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
};

const mapStateToProps = ({ payment, listState }) => ({
  payment,
  listState,
});
export default connect(mapStateToProps)(SubscriptionPaymentList);
