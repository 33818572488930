import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  fetchQuotationlookUpData,
  GetQuotationById,
} from "@redux/quotation/quotationActions";
import { quatationListUrl } from "common/config";
import QuotationDataAccess from "dataAccess/quotation/quotationDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import {
  InputFieldOneRow,
  InputFieldOneRowReadOnly,
  InputHidden,
} from "libComp/common/inputRenderes";
import SolpayModal from "libComp/common/solpayModal";
import SolpayReactSelectIcon from "libComp/common/solpaySelectRendersWithIcon";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { change, Field, FieldArray, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
import ClientFormView from "../../contacts/client/clientFormView";
import QuotationDetail from "./quotationDetail";
import { useSnackbar } from "notistack";

function QuotationFormView(props) {
  const style = useStyleClass();
  let clientList = [];
  const error = props.error;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  if (props.clientList.length > 0) clientList = props.clientList;


  const title = "Quotation List";

  const dispatch = useDispatch();
  const quotationId = props.match.params.id;

  const [showClientModal, setShowClientModal] = useState(false);

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const discountAmount = props.discountAmount ? props.discountAmount : 0.0;

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const clientAdd = (data) => {
    clientList.push(data);
  };

  useEffect(() => {
    if (quotationId > 0) {
      dispatch(GetQuotationById(quotationId));
    }
    dispatch(fetchQuotationlookUpData());
  }, []);

  let history = useHistory();

  const onQuotationFormSubmit = (model) => {
    console.log("model==",model);
    return QuotationDataAccess.saveQuotation(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success) {
          enqueueSnackbar("Quotation Saved Successful", { variant: "success" });
          props.history.push(quatationListUrl);
        } else
          enqueueSnackbar("Quotation could not been saved!", {
            variant: "error",
          });
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Accounting",
      to: null,
    },
    {
      title: "Sales",
      to: null,
    },
    {
      title: "Quotation",
      to: "/accounting/sales/quotations",
    },
  ];

  if (quotationId > 0) {
    BCMenuItems.push({
      title: "Edit Quotation",
      to: null,
    });
  } else {
    BCMenuItems.push({
      title: "New Quotation",
      to: null,
    });
  }

  return (
    <React.Fragment>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader
          header={quotationId === "0" ? "New Quotation" : "Edit Quotation"}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => {
            onQuotationFormSubmit(data);
          })}
        >
          <Grid container spacing={10} sx={{ width: "90%" }}>
            <Grid item xl={6} md={6}>
              <Field
                name="quotationNo"
                label="Quotation Number"
                component={InputFieldOneRowReadOnly}
              />
              <Field
                name="clientId"
                label="Client"
                validate={required}
                options={clientList}
                component={SolpayReactSelectIcon}
                Addlabel="Add a new Client"
                iconEvent={handleShow}
              />
              <Field component={InputHidden} name="clientList" />
              <Field
                name="reference"
                component={InputFieldOneRow}
                label="Reference"
              />
            </Grid>
            <Grid item xl={6} md={6}>
              <Field
                name="quotationDate"
                md={5}
                sm={6}
                component={DatePickerFieldOneRow}
                label="Quotation Date"
                validate={required}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xl={12} md={12} sx={{ overflow: "hidden" }}>
              <PageTitleHeader header="Products / Services" />
              <FieldArray
                name="clientQuotationDetails"
                component={QuotationDetail}
                props={{ dispatch, discountAmount }}
              />
            </Grid>
          </Grid>
          {error && (
            <Typography
              variant="subtitle2"
              component="div"
              classes={{ root: style.error }}
            >
              {error}
            </Typography>
          )}
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              Save
            </Button>
            &nbsp;
            <Button
              classes={{ root: style.button }}
              sx={{ m: 2 }}
              variant="contained"
              type="submit"
              disabled={props.submitting}
            >
              Save and Send email
            </Button>
          </Box>
        </form>
      </Box>
      <br />
      {show && (
        <SolpayModal
          onClose={handleClose}
          title="Add Client"
          data={
            <ClientFormView
              formShownInModal={show}
              clientId="0"
              pageTitle="Add Client"
              setClients={(data) => {
                clientAdd({
                  label: data.data.fullName,
                  id: data.data.clientId,
                  value: data.data.clientId,
                });
                dispatch(
                  change("QuotationFormView", `clientId`, data.data.clientId)
                );
                handleClose();
              }}
            />
          }
        />
      )}
    </React.Fragment>
  );
}
export default QuotationFormView;
