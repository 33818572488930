import { ICommonActions } from "@redux/commonRedux";
import { IFilter } from "@redux/listRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class superFundDataAccess {
  getAllSuperFundList =
    (pageNo, pageSize, filters: Array<IFilter>, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      return commonDataAccess.getData("Payroll/getallsuperfund", {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };

  getFundById = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Payroll/getsuperFundById", { id })(
      commonActions
    );
  };

  superFundSave = (model) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Payroll/superFundSave",
      model
    )(commonActions);
  };
}

export default new superFundDataAccess();
