import { Dispatch } from 'redux';
import paymentHeadDataAccess from 'dataAccess/pyr/paymentHeadDataAccess';
import { IReduxState } from '@redux';
const typesPrefix = 'PAYHEAD_';
const types = {
  addToList: typesPrefix + 'ADD_TO_LIST',
  addToOvertimeList: typesPrefix + 'ADD_TO_OVERTIME_LIST',
  addToAllowanceTypeList: typesPrefix + 'ADD_TO_ALLOWANCE_TYPE_LIST',
  getHead: typesPrefix + 'GET_PAYHEAD',
  failed: typesPrefix + 'FAILED',
  loading: typesPrefix + 'LOADING',
  removeUiMessage: typesPrefix + 'REMOVE_UI_MESSAGE',
};

export const actions = {
  getPaymentHeads: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentHeadDataAccess
      .getPaymentHeadList()(state.commonState.commonActions)
      .then((headData) => {
        dispatch({
          type: types.addToList,
          payload: {
            list: headData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getOvertimePaymentHeads: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentHeadDataAccess
      .getOvertimePaymentHeadList()(state.commonState.commonActions)
      .then((headData) => {
        dispatch({
          type: types.addToOvertimeList,
          payload: {
            list: headData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getAllAllowanceTypes: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentHeadDataAccess
      .getAllAllowanceTypes()(state.commonState.commonActions)
      .then((headData) => {
        dispatch({
          type: types.addToAllowanceTypeList,
          payload: {
            list: headData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getPaymentHead: (id: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentHeadDataAccess
      .getPaymentHead(id)(state.commonState.commonActions)
      .then((headData) => {
        dispatch({
          type: types.getHead,
          payload: headData.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};
export interface IPaymentHeadState {
  paymentheads: Array<any>;
  overtimePaymentheads: Array<any>;
  allowanceTypes: Array<any>;
  paymenthead: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: IPaymentHeadState = {
  paymentheads: [],
  overtimePaymentheads: [],
  allowanceTypes: [],
  paymenthead: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IPaymentHeadState = initialState,
  action: Action
): IPaymentHeadState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        paymentheads: action.payload.list,
      };
    case types.addToOvertimeList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        overtimePaymentheads: action.payload.list,
      };
    case types.addToAllowanceTypeList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        allowanceTypes: action.payload.list,
      };
    case types.getHead:
      return {
        ...state,
        isLoading: false,
        paymenthead: action.payload,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
