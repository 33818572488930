import { Typography } from "@mui/material";
import React from "react";

export default function CustomLabel({ name }) {
  var labelStyle = {
    // display: 'inline-block',
    color: "rgba(0, 0, 0, 0.6)",
  };
  return (
    <Typography
      variant="subtitle2"
      component="div"
      sx={{
        color: "rgba(0, 0, 0, 0.6)",
        marginBottom: "0px",
        fontWeight: 400,
        fontSize: "1rem",
      }}
    >
      {name} <span style={labelStyle}>*</span>
    </Typography>
  );
}
