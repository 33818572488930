import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';
class supplierDataAccess {
  saveIndividualSupplier = (supplier) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'Supplier/saveindividualsupplier',
      supplier
    )(commonActions);
  };
  saveCompanySupplier = (supplier) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'Supplier/savecompanysupplier',
      supplier
    )(commonActions);
  };
  getSupplierList =
    (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters['filter.' + item.propertyName] =
            item.value + '-' + item.type;
        }
      });
      return commonDataAccess.getData('Supplier/getallsupplier', {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };
  getSupplier =
    (supplierId, supplierType) => (commonActions: ICommonActions) => {
      switch (supplierType) {
        case '1':
          return commonDataAccess.getData('Supplier/getcompanysupplier', {
            id: supplierId,
          })(commonActions);
        case '2':
          return commonDataAccess.getData('Supplier/getindividualsupplier', {
            id: supplierId,
          })(commonActions);
      }
    };
  isSupplierCodeAvaiable = (clientCode, clientId) => {
    return commonDataAccess.getData('Supplier/issuppliercodeavailable', {
      supplierCode: clientCode,
      supplierId: clientId,
    })(null);
  };

  getClientByName = (client) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'ClientInvoice/getclientlist?term=' + client
    )(commonActions);
  };
  getSupplierByName = (supplier) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'ClientInvoice/getclientlist?term=' + supplier
    )(commonActions);
  };
  deleteSupplier = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      'Supplier/delete',
      {},
      { id: id }
    )(commonActions);
  };
}
export default new supplierDataAccess();
