import { IReduxState } from '@redux';
import addressDataAccess from 'dataAccess/crm/addressDataAccess';
import { Dispatch } from 'redux';
import { onForbidden, onUnauthorize } from 'services/commonService';
const typesPrefix = 'COMMON_';

const types = {
  getStateList: typesPrefix + 'GET_STATE_LIST',
  failed: typesPrefix + 'FAILED',
  loading: typesPrefix + 'LOADING',
  loadingEnd: typesPrefix + 'LOADING_END',
  removeUiMessage: typesPrefix + 'REMOVE_UI_MESSAGE',
  setHistory: typesPrefix + 'SET_HISTORY',
  setCommonActions: typesPrefix + 'SET_COMMON_ACTIONS',
};

export const actions = {
  getAllStates: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    addressDataAccess
      .getAllStates()(state.commonState.commonActions)
      .then((stateData) => {
        dispatch({
          type: types.getStateList,
          payload: {
            list: stateData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },

  loading: () => (dispatch: Dispatch) => {
    dispatch({ type: types.loading });
  },
  loadingEnd: () => (dispatch: Dispatch) => {
    dispatch({ type: types.loadingEnd });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
  setHistory: (history) => (dispatch: Dispatch) => {
    dispatch({ type: types.setHistory, payload: history });
  },
  setCommonActions: (history) => (dispatch: Dispatch) => {
    dispatch({
      type: types.setCommonActions,
      payload: {
        onUnauthorize: onUnauthorize(history, dispatch),
        onForbidden: onForbidden(history),
      },
    });
  },
};
export interface ICommonActions {
  onUnauthorize: Function;
  onForbidden: Function;
}
export interface ICommonState {
  stateList: Array<any>;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  history: any;
  commonActions: ICommonActions;
}

const initialState: ICommonState = {
  stateList: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: '',
  uiMessages: [],
  history: {},
  commonActions: undefined,
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: ICommonState = initialState,
  action: Action
): ICommonState => {
  switch (action.type) {
    case types.getStateList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        stateList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.loading:
      return { ...state, isLoading: true, isError: false };
    case types.loadingEnd:
      return { ...state, isLoading: false, isError: false };
    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.setHistory:
      return { ...state, history: action.payload };
    case types.setCommonActions:
      return { ...state, commonActions: action.payload };
    default:
      return state;
  }
};
