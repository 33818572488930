import PageButtons from "libComp/sections/pageButtons";
import payicon from "media/images/blueImages/payroll.svg";
import saleIcon from "media/images/blueImages/users.svg";
// import ReportMenuHeader from "libComp/pageMenuHeaders/reportMenuHeader";

function SalesReport() {
  const Buttons = [
    {
      header: "Customer Statement",
      description: "View / Print Customer Statement",
      to: "/reports/sales/customer-statement",
      imageSrc: saleIcon,
      imageAlt: "Sales",
    },
    {
      header: "Sales Statement",
      description: "View / Print Sales Statement",
      to: "/reports/sales/sales-statement",
      imageSrc: payicon,
      imageAlt: "Purchase",
    },
    {
      header: "Unpaid Reports",
      description: "View / Print Unpaid Reports",
      to: "/reports/sales/unpaid-reports",
      imageSrc: saleIcon,
      imageAlt: "Business",
    },
  ];
  return (
    <>
      <PageButtons buttons={Buttons}></PageButtons>;
    </>
  );
}

export default SalesReport;
