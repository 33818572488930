import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const getAgentListTableColumns = (props, extra) => {
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "companyName",
    },
    {
      headerName: "Code",
      field: "agentCode",
    },
    {
      headerName: "Commission Percentage",
      field: "commissionPercentage",
    },
    {
      headerName: "ABN",
      field: "companyABN",
    },
    {
      headerName: "Main Phone",
      field: "companyMainPhone",
    },
    {
      headerName: "Email",
      field: "companyEmail",
    },
    {
      headerName: "Action",
      field: "",
      width: 120,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              size="small"
              title="Edit"
              onClick={() => {
                // if (extra)
                //   props.history.push("/admin/org_user/" + params.row.id);
                // else props.history.push("/admin/user/" + params.row.id);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
