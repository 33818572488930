import { IReduxState } from "@redux";
import {
  actions as superPaymentActions,
  ISuperAnnuationState
} from "@redux/pyr/superAnnuationRedux";
import { filterOptions } from "common/constants";
import {
  default as superAnnuationDataAccess,
  default as superDataAccess
} from "dataAccess/pyr/superAnnuationDataAccess";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getSuperPaymentTable } from "./superPayment.config";

const SuperPayments = (props) => {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const superPaymentState: ISuperAnnuationState = props.superannuation;
  const commonState = useSelector((state: IReduxState) => state.commonState);

  const getSuperFundsList = useCallback(() => {
    dispatch(superPaymentActions.getSuperPaymentList());
  }, [selection]);

  const extra = {
    onDownload: (id) => {
      superDataAccess
        .getCsvFile(id)(commonState.commonActions)
        .then((data) => {
          //console.log(data);
        })
        .catch((error) => {
          //console.log(error)

        });
    },
  };

  const tableColumns = getSuperPaymentTable(props, extra);

  return (
    <>
      <div className="page-container">
        <PageTitleHeader
          header="Super Payment List"
          buttons={[
            {
              title: "Add Super Payment",
              onClick: (index) => {
                props.history.push(
                  "/payroll/super-payment/super-stream-report"
                );
              },
              iconName: "fa fa-plus",
              id: "sfladd1",
            },
          ]}
        />

        <Filter
          filterOptions={[
            {
              propertyName: "createdOn",
              placeholder: "Upload Date",
              inputType: "text",
              typeOptions: [filterOptions.contains],
            },
            {
              propertyName: "interchangeID",
              placeholder: "Interchange ID",
              inputType: "text",
              typeOptions: [filterOptions.contains],
            },
            {
              propertyName: "status",
              placeholder: "Status",
              inputType: "text",
              typeOptions: [filterOptions.contains],
            },
            {
              propertyName: "docUploadMessageDescription",
              placeholder: "Doc Upload Status",
              inputType: "text",
              typeOptions: [filterOptions.contains],
            },
          ]}
          onFilterChange={() => {
            dispatch(superAnnuationDataAccess.getSuperPaymentList());
          }}
        />

        <SolpayGrid
          columns={tableColumns}
          rows={superPaymentState.superPayment}
          // rowCount={superPaymentState.totalCount}
          loading={superPaymentState.isLoading}
          loadData={getSuperFundsList}
          triggerChange={() => {
            getSuperFundsList();
          }}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ superannuation }) => ({
  superannuation,
});

export default connect(mapStateToProps)(SuperPayments);
