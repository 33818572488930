import { ICommonActions } from "@redux/commonRedux";
import Axios, { AxiosError, AxiosResponse } from "axios";
import { apiPrefix, apiUrl } from "common/config";
import { IUser } from "models/iUser";
import commonDataAccess from "../commonDataAccess";
class userDataAccess {
  /**
   * Checks if the current token is valid
   * @returns the response sent by api
   */
  isAuthenticated = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Account/isauthenticated")(commonActions);
  };

  login = (userLoginInfo: IUser) => {
    userLoginInfo.rememberMe = true;
    return Axios.post(apiUrl + apiPrefix + "account/login", userLoginInfo)
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        throw new Error(error.response.data.message);
      });
  };
  switchTenant = (userLoginInfo: IUser) => {
    userLoginInfo.rememberMe = true;
    return Axios.post(
      apiUrl + apiPrefix + "account/switchTenant",
      userLoginInfo
    )
      .then((response: AxiosResponse) => {
        if (response.data.success) {
          return response.data;
        } else {
          throw new Error(response.data.message);
        }
      })
      .catch((error: AxiosError) => {
        throw new Error(error.response.data.message);
      });
  };
  signup = (user: IUser) => {
    return Axios.post(apiUrl + apiPrefix + "Account/signup", user)
      .then((response: AxiosResponse) => {
        return response.data;
      })
      .catch((error: AxiosError) => {
        throw new Error(error.response.data.message);
      });
  };

  isUserNameAvaiable = (userName) => {
    return commonDataAccess.getData(
      "Account/isnameavailable?userName=" + userName
    )(null);
  };

  alterOrganization = (tenantId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData("Account/altertenant", null, {
      tenantId,
    })(commonActions);
  };
  sigout = () => (commonActions: ICommonActions) => {
    return commonDataAccess.postDataNoResponse("Account/logout")(commonActions);
  };
  getPackges = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Account/getpackges")(commonActions);
  };
}
export default new userDataAccess();
