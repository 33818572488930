import {
  Box,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as superPaymentActions } from "@redux/pyr/superAnnuationRedux";
import moment from "moment";
import React, { useEffect } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useStyleClass } from "styles/theming/useStyleClass";

const SuperPaymentDetail = (props) => {
  const commonState = useSelector((state: IReduxState) => state.commonState);

  const superId = props.superId;

  const dispatch = useDispatch();

  useEffect(() => {
    if (superId && superId !== "0")
      dispatch(superPaymentActions.getSuperPaymentById(superId));
  }, [superId]);

  const style = useStyleClass();

  const tableHeaders = [
    "Name Title",
    "Full Name",
    "Mobile",
    "Telephone",
    "Email",
    "Fund Name",
    "Fund ABN",
    "Fund USI",
    "Supperannuation Guarantee Amount",
  ];

  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          p: 2,
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-around"
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Typography variant="subtitle1" gutterBottom component="div">
            Pay Period Start Date:{" "}
            <strong>
              {props.superPayment &&
                props.superPayment.startDate &&
                moment(props.superPayment.startDate).format("DD-MMM-yyyy")}
            </strong>
          </Typography>

          <Typography variant="subtitle1" gutterBottom component="div">
            Pay Period End Date:{" "}
            <strong>
              {props.superPayment &&
                props.superPayment.endDate &&
                moment(props.superPayment.endDate).format("DD-MMM-yyyy")}
            </strong>
          </Typography>

          <Typography variant="subtitle1" gutterBottom component="div">
            Interchange ID:{" "}
            <strong>
              {props.superPayment && props.superPayment.interchangeID}
            </strong>
          </Typography>
        </Stack>
        <br />

        <TableContainer component={Paper}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
              },
            }}
            aria-label="caption table"
          >
            <TableHead classes={{ root: style.tableHeader }}>
              <TableRow>
                {tableHeaders.map((item, index) => (
                  <TableCell key={index} sx={{ color: "#fff" }} align="center">
                    {item}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.superPayment &&
                props.superPayment.superStreamDataDetailViewModels &&
                props.superPayment.superStreamDataDetailViewModels.map(
                  (item, index) => (
                    <React.Fragment key={"pRun_" + index}>
                      <TableRow>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.employeeNameTitle}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.employeeFullName}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.mobile}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.telephone}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.employeeEmail}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.fundName}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.fundABN}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.fundUSI}
                        </TableCell>
                        <TableCell align="center" style={{ minWidth: 100 }}>
                          {item.superannuationGuaranteeAmount}
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  )
                )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  superId: ownProps.superId || (ownProps.match && ownProps.match.params.id),
  superPayment: state.superannuation.superPaymentId ?? [],
});
export default connect(mapStateToProps)(SuperPaymentDetail);
