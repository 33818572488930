import { actions as clientActions, IClientState } from "@redux/crm/clientRedux";
import { useAppSelector } from "@redux/hook.type";
import { defaultPageSize, filterOptions } from "common/constants";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { getClientTableColumn } from "./clien-list.config";

function ClientList(props) {
  const [selection, setSelection] = useState(null);
  const dispatch = useDispatch();
  const clientState: IClientState = props.client;
  const listState = useAppSelector((state) => state.listState);

  const getClientList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "Name-desc"
    ) => {
      dispatch(
        clientActions.getClients(pageNo + 1, pageSize, filters, sortPreference)
      );
    },
    [selection]
  );

  const tableColumns = getClientTableColumn(props);

  return (
    <div className="page-container">
      <PageTitleHeader
        header="Client List"
        buttons={[
          {
            title: "Add Business Client",
            onClick: (index) => {
              props.history.push("/contact/client/business/new/0");
            },
            iconName: "fa fa-plus",
            id: "bcadd1",
          },
          {
            title: "Add Individual Client",
            onClick: (index) => {
              props.history.push("/contact/client/individual/new/0");
            },
            iconName: "fa fa-plus",
            id: "icadd1",
          },
        ]}
      />
      <Filter
        filterOptions={[
          {
            propertyName: "Name",
            placeholder: "Client Name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "ClientCode",
            placeholder: "Client Code",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },

          {
            propertyName: "Phone",
            placeholder: "Phone",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            clientActions.getClients(pageNo, pageSize, filters, sortData)
          );
        }}
      />

      <SolpayGrid
        columns={tableColumns}
        rows={clientState.clients}
        rowCount={clientState.totalCount}
        loading={clientState.isLoading}
        loadData={getClientList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getClientList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}

const mapStateToProps = ({ client, listState }) => ({ client, listState });
export default connect(mapStateToProps)(ClientList);
