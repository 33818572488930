import React from 'react';
import logo from 'media/images/solpay-logo.png';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Header() {
  return (
    <div className="header-main">
      <header>
        <Navbar collapseOnSelect expand="lg" bg="light" variant="light">
          <Link to="/tenant" className="navbar-brand">
            <img style={{ width: '117px' }} src={logo} alt="SolPay Software" />
          </Link>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/small-business" className="nav-link">
                SMALL BUSINESSES
              </Link>
              <Link to="/pricing" className="nav-link">
                PRICING
              </Link>
              <Link to="/partners" className="nav-link">
                ACCOUNTANTS & PARTNERS
              </Link>

              <NavDropdown title="MORE" id="collasible-nav-dropdown">
                <Link to="/payroll" className="dropdown-item">
                  Payroll
                </Link>
                <Link to="/invoicing" className="dropdown-item">
                  Invoicing
                </Link>
                <Link to="/accounting" className="dropdown-item">
                  Accounting
                </Link>
                <Link to="/contact-us" className="dropdown-item">
                  Contact Us
                </Link>
                <NavDropdown.Divider />
                <Link to="/blog" className="dropdown-item">
                  Blog
                </Link>
              </NavDropdown>
            </Nav>
            <Nav>
              <Link to="/login" className="nav-link btn login-btn">
                LOGIN
              </Link>
              <Link to="/signup" className="nav-link btn free-trial-btn">
                FREE TRIAL
              </Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </header>
    </div>
  );
}

export default Header;
