import { GridColumns } from "@mui/x-data-grid";
import { filterOptions } from "common/constants";
import moment from "moment";
export const getFilterOptions = (props) => {
  const filterOption = [
    {
      propertyName: "TenantName",
      placeholder: "Client",
      inputType: "text",
      typeOptions: [filterOptions.contains],
    },
    {
      propertyName: "UserName",
      placeholder: "User",
      inputType: "text",
      typeOptions: [filterOptions.contains],
    },
    {
      propertyName: "FromDate",
      placeholder: "From Date",
      inputType: "date",
      typeOptions: [filterOptions.greaterThanEqual],
    },
    {
      propertyName: "ToDate",
      placeholder: "To Date",
      inputType: "date",
      typeOptions: [filterOptions.lessThanEqual],
    },
  ];
  return filterOption;
};
export const getAuditListTableColumns = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Activity On",
      field: "createdOn",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Client",
      field: "tenantName",
    },
    {
      headerName: "User",
      field: "userName",
    },
    {
      headerName: "Event",
      field: "activity",
    },
    // {
    //   headerName: "Data",
    //   field: "",
    // },
    {
      headerName: "Success/Fail",
      field: "success",
    },
    {
      headerName: "User IP",
      field: "userIP",
    },
    // {
    //   headerName: "Device",
    //   field: "",
    // },
    {
      headerName: "OS",
      field: "os",
    },
    {
      headerName: "Browser",
      field: "browserNameNVersion",
    },
  ];
  return columns;
};
