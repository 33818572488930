import AssessmentIcon from "@mui/icons-material/Assessment";
import { Box, Button, Divider, Grid } from "@mui/material";
import { IReduxState } from "@redux";
import salesDataAccess from "dataAccess/report/salesDataAccess";
import { DatePickerFieldOneRow } from "libComp/common/datePickers";
import solpayReactSelect from "libComp/common/solpaySelctRenders";
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { required } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";
// Import Worker
import { Worker } from "@react-pdf-viewer/core";
// Import the main Viewer component
import { TextDirection, Viewer } from "@react-pdf-viewer/core";
// Import the styles
import "@react-pdf-viewer/core/lib/styles/index.css";
// default layout plugin
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
// Import styles of default layout plugin
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";

function SalesStatement(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [reportdata, setreportdata] = useState("");
  const [customDate, setCustomDate] = useState(false);
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  useEffect(() => {}, []);

  const onFormSubmit = (model) => {
    salesDataAccess
      .getSalesStatement(model)(commonState.commonActions)
      .then((data) => {
        setreportdata(data);
        //const response: APIResponse = data;
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  // const createMarkup = () => {
  //   return { __html: reportdata };
  // };

  const style = useStyleClass();

  const handleCustomDate = (e) => {
    if (e === 8) setCustomDate(true);
    else setCustomDate(false);
  };

  // Breadcrumb
  let BCMenuItems = [];

  BCMenuItems = [
    {
      title: "Reports",
      to: null,
    },
    {
      title: "Sales",
      to: "/reports/sales",
    },
    {
      title: "Sales Statement",
      to: "/reports/sales/sales-statement",
    },
  ];

  return (
    <>
      <PageBreadcrumb menuItem={BCMenuItems} />
      <div className="page-container">
        <PageTitleHeader header="Sales Statement" />
        <br />

        <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
          <form
            noValidate
            onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Field
                  name="dateFilterOption"
                  component={solpayReactSelect}
                  options={[
                    { value: 1, label: "Today" },
                    { value: 2, label: "This Week" },
                    { value: 3, label: "This Month" },
                    { value: 4, label: "This Quarter" },
                    { value: 5, label: "Last Quarter" },
                    { value: 6, label: "This Year" },
                    { value: 7, label: "Last Year" },
                    { value: 8, label: "Custom Date" },
                  ]}
                  label="Invoice On"
                  validate={required}
                  onChange={handleCustomDate}
                />
                {customDate && (
                  <>
                    <Field
                      name="startDate"
                      md={5}
                      sm={6}
                      component={DatePickerFieldOneRow}
                      label="Start Date"
                    />

                    <Field
                      name="endDate"
                      md={5}
                      sm={6}
                      component={DatePickerFieldOneRow}
                      label="End Date"
                    />
                  </>
                )}
              </Grid>
            </Grid>
            <Divider />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                classes={{ root: style.button }}
                sx={{ m: 2 }}
                variant="contained"
                type="submit"
                disabled={props.submitting}
              >
                {props.submitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <AssessmentIcon />
                &nbsp;View Report
              </Button>
            </Box>
          </form>
          <Grid item xs={12} md={12}>
            {reportdata ? (
              <div className="viewer">
                <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.15.349/pdf.worker.min.js">
                  <Viewer
                    fileUrl={reportdata}
                    theme={{
                      theme: "auto",
                      direction: TextDirection.RightToLeft,
                    }}
                    plugins={[defaultLayoutPluginInstance]}
                    // renderLoader={(percentages: number) => (
                    //   <div style={{ width: '240px' }}>
                    //     <ProgressBar progress={Math.round(percentages)} />
                    //   </div>
                    // )}
                  />
                </Worker>
              </div>
            ) : (
              <div
                style={{
                  alignItems: "center",
                  border: "2px dashed rgba(0, 0, 0, .3)",
                  display: "flex",
                  fontSize: "2rem",
                  height: "100%",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                Preview area
              </div>
            )}

            <br></br>

            {/* <div dangerouslySetInnerHTML={createMarkup()}></div> */}
          </Grid>
        </Box>
      </div>
    </>
  );
}
const salesStatement = reduxForm<any>({
  form: "SalesStatement",
  enableReinitialize: true,
})(SalesStatement);
const mapStateToProps = (state, ownProps) => ({
  initialValues: {},
});
export default connect(mapStateToProps)(salesStatement);
