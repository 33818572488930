import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
interface Props {
  imageSrc: any;
  imageAlt: string;
  header: string;
  description: string;
  to: string;
}
function DashboardButtonCard(props: Props) {
  return (
    <a href={props.to} className="text">
      {props.imageSrc && <img src={props.imageSrc} alt={props.imageAlt} />}
      <h3 className="dashboard-icon-heading">{props.header}</h3>
      {props.description && <p>{props.description}</p>}
    </a>
  );
}

export default DashboardButtonCard;
