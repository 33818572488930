import {
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import OrderPaymentFields from "pages/payment/orderPaymentFields";
import React, { useState } from "react";
import { useStyleClass } from "styles/theming/useStyleClass";
const BillPayment = ({
  fields,
  meta,
  dispatch,
  discountAmount,
  paymentMethodOptions,
  history,
}) => {
  const [showProductModal, setShowProductModal] = useState(false);
  const [produectIndex, setProduectIndex] = useState(-1);

  const tableHeaders = [
    "Payment Date",
    "Paid Amount",
    "Payment Method",
    " ",
    " ",
  ];

  const style = useStyleClass();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => (
              <OrderPaymentFields
                key={index}
                field={field}
                index={index}
                fields={fields}
                paymentMethodOptions={paymentMethodOptions}
                history={history}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};
export default BillPayment;
