// import { quatationFormUrl, quatationListUrl } from "common/config";
// import AccountingPageMenuHeader from "libComp/pageMenuHeaders/accountingPageMenuHeader";
// import PageButtons from "libComp/sections/pageButtons";
// import PageTitleHeader from "libComp/sections/pageTitleHeader";
// import imageSetting from "media/images/blueImages/process.svg";
// import imageUser from "media/images/blueImages/users.svg";
import * as React from 'react';

function Sales() {
  // const MenuItems = [
  //    {
  //       title: "Sales",
  //       to: "/accounting/sales",
  //       imageSrc: imageUser,
  //       imageAlt: "icon",
  //       isActive: true,
  //    },
  //    {
  //       title: "Purchase",
  //       to: "/purchases",
  //       imageSrc: imageUser,
  //       imageAlt: "Purchase",
  //    },
  //    {
  //       title: "Banking",
  //       to: "/banking",
  //       imageSrc: imageUser,
  //       imageAlt: "View Banking",
  //    },
  //    {
  //       title: "Journals",
  //       to: "/journals",
  //       imageSrc: imageUser,
  //       imageAlt: "Journals",
  //    },
  //    {
  //       title: "Reports",
  //       to: "/reports",
  //       imageSrc: imageUser,
  //       imageAlt: "Reports",
  //    },
  //    {
  //       title: "Settings",
  //       to: "/settings",
  //       imageSrc: imageSetting,
  //       imageAlt: "Purchase",
  //    },
  //    {
  //       title: "Year Closing",
  //       to: "/accounting/yearclosing",
  //       imageSrc: imageSetting,
  //       imageAlt: "Year Closing",
  //    },
  // ];

  // const Buttons = [
  //    {
  //       header: "Business & Individual Clients",
  //       description: "Add or Edit Business & Individual Clients",
  //       to: "/contact/clients",
  //       imageSrc: imageUser,
  //       imageAlt: "Business & Individual Clients",
  //    },
  //    {
  //       header: "Products / Services",
  //       description: "Add or Edit Products / Services",
  //       to: "/accounting/sales/product",
  //       imageSrc: imageUser,
  //       imageAlt: "Products / Services",
  //    },
  //    {
  //       header: "Quotations",
  //       description: "Add or Edit Quotations",
  //       to:    quatationListUrl ,
  //       imageSrc: imageUser,
  //       imageAlt: "Quotations",
  //    },
  //    {
  //       header: "Invoices",
  //       description: "View / Print Reports",
  //       to: "/sales/invoice/list",
  //       imageSrc: imageUser,
  //       imageAlt: "Invoices",
  //    },
  //    {
  //       header: "Reports",
  //       description: "View Reports",
  //       to: "/accounting/sales/reports",
  //       imageSrc: imageUser,
  //       imageAlt: "Reports",
  //    },
  //    {
  //       header: "Report Templates",
  //       description: "Set Report Templates",
  //       to: "/accounting/sales/template",
  //       imageSrc: imageUser,
  //       imageAlt: "Report Templates",
  //    },
  // ];

  return (
    <div>
      {/* <AccountingPageMenuHeader /> */}
      {/* <PageTitleHeader header="Sales" /> */}
      {/* <PageButtons buttons={Buttons}></PageButtons>; */}
    </div>
  );
}

export default Sales;
