import { createTheme } from "@mui/material/styles";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#354aa5",
      light: "#45aaf2",
      dark: "#3867d6",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#0099cc",
      light: "#0099cc",
      dark: "#0099cc",
      contrastText: "#ffffff",
    },
    info: {
      main: "#778ca3",
      light: "#d1d8e0",
      dark: "#778ca3",
      contrastText: "#ffffff",
    },
  },
  shape: {
    borderRadius: 4,
  },
});
