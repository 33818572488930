import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useStyleClass } from "styles/theming/useStyleClass";
import "./SolpayTable.scss";

export const SolpayGrid = (props) => {
  const { rows, columns, triggerChange, rowCount, loading, pageNo } = props;
  const style = useStyleClass();

  let dataColumns = columns.map((col) => {
    if (col.width && col.width > 0) {
      return col;
    } else {
      return {
        ...col,
        flex: 1,
      };
    }
  });

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [selectionModel, setSelectionModel] = useState([]);

  useEffect(() => {
    triggerChange(page, pageSize);
  }, [page, pageSize]);
  useEffect(() => {
    setPage(props.pageNo);
  }, [props.pageNo]);

  return (
    <div style={{ display: "flex", height: "100%", width: "100%" }}>
      <DataGrid
        rows={rows}
        columns={dataColumns}
        pagination
        paginationMode="server"
        page={page}
        pageSize={pageSize}
        rowsPerPageOptions={[25, 50, 100]}
        rowCount={rowCount}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        onPageChange={(newPage) => {
         // console.log(newPage);
          setPage(newPage);
        }}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        loading={loading}
        autoHeight
        components={{
          Toolbar: GridToolbar,
        }}
        classes={{
          columnHeaders: style.tableHeader,
        }}
      />

      {/* <DataGrid
          {...gridProps}
          initialState={{
            pagination: { pageSize: config.rowsPerPageOptions[0] },
          }}
          columns={dataColumns}
          onPageChange={(pageNumber, details) => {
            currentPageNumber.current = pageNumber;
            dispatch(listActions.updatePageNo(pageNumber));
            triggerChange(
              pageNumber,
              currentPageSize.current,
              listState.sort,
              details
            );
          }}
          onPageSizeChange={(size, details) => {
            currentPageSize.current = size;
            dispatch(listActions.updatePageSize(size));
            triggerChange(1, size, listState.sort, details);
          }}
          onSortModelChange={(model: GridSortModel, details) => {
            const sortValue =
              model.length > 0 ? model[0].field + "-" + model[0].sort : "";
            dispatch(listActions.updateSortString(sortValue));
            triggerChange(
              listState.pageNo,
              listState.pageSize,
              sortValue,
              details
            );
          }}
          classes={{
            columnHeaders: style.tableHeader,
          }}
        /> */}
    </div>
  );
};
