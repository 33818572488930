import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as paymentActions } from "@redux/pay/paymentRedux";
import userDataAccess from "dataAccess/emp/userDataAccess";
import paymentDataAccess from "dataAccess/pay/paymentDataAccess";
import {
  InputFieldWithoutLabelMui,
  InputHidden,
} from "libComp/common/inputRenderes";
import { SolpayCheckBox } from "libComp/common/solpayInputRenderes";
import { APIResponse } from "models/response";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  Field,
  FieldArray,
  formValueSelector,
  reduxForm,
  SubmissionError,
} from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
interface IProps {
  organizationId: number;
  packageModules: Array<any>;
  organization: any;
  history: any;
}
function PackageChange(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const commonState = useSelector((state: IReduxState) => state.commonState);
  const [packagesForSelect, setPakcagesSelect] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    userDataAccess
      .getPackges()(commonState.commonActions)
      .then((data) => {
        setPakcagesSelect(
          data.data.map((item) => ({
            ...item,
            label: item.name,
            value: item.id,
          }))
        );
      });
  }, []);

  useEffect(() => {
    dispatch(paymentActions.getPackgesForChange(props.organizationId));
  }, [props.organizationId]);
  const onFormSubmit = (model) => {
    paymentDataAccess
      .changePackage(model.packageModules)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success)
          enqueueSnackbar("Package has been saved!", { variant: "success" });
        else
          enqueueSnackbar("Package could not been saved!", {
            variant: "error",
          });
        props.history.push("/admin/organizations");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <Box>
      <form
        noValidate
        onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
      >
        <FieldArray
          name="packageModules"
          component={PackageModules}
          props={{ packagesForSelect }}
        />
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            classes={{ root: style.button }}
            sx={{ m: 2 }}
            variant="contained"
            type="submit"
            disabled={props.submitting}
          >
            {props.submitting ? (
              <CircularProgress
                size={24}
                sx={{
                  color: "blue",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            ) : (
              <i className="fa fa-check"></i>
            )}
            Save
          </Button>
        </Box>
      </form>
    </Box>
  );
}
const packageChange = reduxForm<any, IProps>({
  form: "PackageChange",
  enableReinitialize: true,
})(PackageChange);
const selector = formValueSelector("PackageChange");

const mapStateToProps = (state: IReduxState, ownProps) => ({
  organizationId: state.tenant.organization.id,
  packageModules: state.payment.packageModules ?? [],
  initialValues: { packageModules: state.payment.packageModules },
});
export default connect(mapStateToProps)(packageChange);

const PackageModules = ({
  fields,
  meta,
  packagesForSelect,
  packageModules,
}) => {
  const tableHeaders = [
    "Module",
    "Package",
    "Complimentary",
    "Discount Percentage",
  ];

  const style = useStyleClass();

  return (
    <>
      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((field, index) => {
              return (
                <EachField
                  key={index}
                  field={field}
                  index={index}
                  fields={fields}
                  packagesForSelect={packagesForSelect}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const EachField = ({ fields, field, packagesForSelect, index }) => {
  const packages = packagesForSelect.filter(
    (i) => i.module === fields.get(index).module
  );
  return (
    <>
      <TableRow key={index}>
        <TableCell align="center">
          <Typography variant="subtitle1" component="p">
            {fields.get(index).module ?? "Payroll, Invoicing"}
          </Typography>
        </TableCell>
        <TableCell align="right" style={{ minWidth: 220, width: 240 }}>
          <Field name={`${field}.tenantId`} component={InputHidden} />
          <Field
            name={`${field}.packageId`}
            component={InputFieldWithoutLabelMui}
            options={[{ label: "" }, ...packages]}
            label="Package"
          />
        </TableCell>
        <TableCell align="left">
          <Field name={`${field}.isComplimentary`} component={SolpayCheckBox} />
        </TableCell>
        <TableCell align="right" style={{ minWidth: 220, width: 240 }}>
          <Field
            disabled={fields.get(index).isComplimentary}
            name={`${field}.discountPerc`}
            component={InputFieldWithoutLabelMui}
            label="Discount Percentage"
          />
        </TableCell>
      </TableRow>
    </>
  );
};
