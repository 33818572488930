import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from '../commonDataAccess';

class BankDataAccess {
  saveBankInfo =
    (bankInfo, entityId, entityTypeId) => (commonActions: ICommonActions) => {
      return commonDataAccess.postData('BankAccount/save', {
        ...bankInfo,
        entityId,
        entityTypeId,
      })(commonActions);
    };
  getBankAccounts =
    (entityTypeId, entityId) => (commonActions: ICommonActions) => {
      return commonDataAccess.getData('BankAccount/getall', {
        entityTypeId,
        entityId,
      })(commonActions);
    };
  getBankInfo = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('BankAccount/getbank', { id })(
      commonActions
    );
  };
}

export default new BankDataAccess();
