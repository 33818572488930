import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import paymentDataAccess from "dataAccess/pyr/payrunDataAccess";
import { Dispatch } from "redux";
import { actions as commonActions } from "../commonRedux";

const typesPrefix = "EMPLOYEE_";
const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getEmployee: typesPrefix + "GET_EMPLOYEE",
  cleanEmployee: typesPrefix + "CLEAN_EMPLOYEE",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
  psFailed: typesPrefix + "PS_FAILED",
  psLoading: typesPrefix + "PS_LOADING",
  psSuccess: typesPrefix + "PS_SUCCESS",
  taxFailed: typesPrefix + "TAX_FAILED",
  taxLoading: typesPrefix + "TAX_LOADING",
  taxSuccess: typesPrefix + "TAX_SUCCESS",
  leaveFailed: typesPrefix + "LEAVE_FAILED",
  leaveLoading: typesPrefix + "LEAVE_LOADING",
  leaveSuccess: typesPrefix + "LEAVE_SUCCESS",
  additionDeductionLoading: typesPrefix + "ADDITION_DEDUCTION_LOADING",
  additionDeductionFailed: typesPrefix + "ADDITION_DEDUCTION_FAILED",
  additionDeductionsSuccess: typesPrefix + "ADDITION_DEDUCTION_SUCCESS",
  getSalaryPaymentModeList: typesPrefix + "GET_SALARY_PAYMENT_MODE_LIST",
  paySlipsLoading: typesPrefix + "PAY_SLIPS_LOADING",
  paySlipsFailed: typesPrefix + "PAY_SLIPS_FAILED",
  paySlipsSuccess: typesPrefix + "PAY_SLIPS_SUCCESS",
  employeeType: typesPrefix + "EMPLOYEE_TYPE",
};

export const actions = {
  getEmployees:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = ""
    ) =>
    (dispatch, getState) => {
      dispatch(commonActions.loading());
      dispatch({ type: types.loading });
      let state: IReduxState = getState();
      employeeDataAccess
        .getEmployeeList(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((employeeData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: employeeData.data.list,
              totalCount: employeeData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        })
        .finally(() => {
          dispatch(commonActions.loadingEnd());
        });
    },
  cleanEmployee: (employeeId: string) => (dispatch: Dispatch) => {
    dispatch({ type: types.cleanEmployee, payload: employeeId });
  },
  getEmployee: (employeeId: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    employeeDataAccess
      .getEmployee(employeeId)(state.commonState.commonActions)
      .then((employeeData) => {
        dispatch({
          type: types.getEmployee,
          payload: {
            employee: employeeData.data,
            uiMessages: employeeData.uiMessages,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getPayAndSuperInfo:
    (employeeId: string) => (dispatch: Dispatch, getState) => {
      dispatch({ type: types.psLoading });
      const state: IReduxState = getState();
      employeeDataAccess
        .getPayInfo(employeeId)(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.psSuccess,
            payload: data.data,
          });
        })
        .catch((error: Error) => {
          dispatch({ type: types.psFailed, payload: error.message });
        });
    },
  getTaxInfo: (employeeId: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.taxLoading });
    const state: IReduxState = getState();
    employeeDataAccess
      .getTaxInfo(employeeId)(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.taxSuccess,
          payload: data.data,
        });
      })
      .catch((error: Error) => {
        dispatch({ type: types.taxFailed, payload: error.message });
      });
  },
  getLeaveInfo: (employeeId: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.leaveLoading });
    const state: IReduxState = getState();
    employeeDataAccess
      .getLeaveInfo(employeeId)(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.leaveSuccess,
          payload: data.data,
        });
      })
      .catch((error: Error) => {
        dispatch({ type: types.leaveFailed, payload: error.message });
      });
  },
  getAdditionDeduction:
    (employeeId: string) => (dispatch: Dispatch, getState) => {
      dispatch({ type: types.additionDeductionLoading });
      const state: IReduxState = getState();
      employeeDataAccess
        .getAdditionDeduction(employeeId)(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.additionDeductionsSuccess,
            payload: data.data,
          });
        })
        .catch((error: Error) => {
          //console.log(error);
          dispatch({
            type: types.additionDeductionFailed,
            payload: error.message,
          });
        });
    },
  getPaySlips:
    (
      employeeId: string,
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = ""
    ) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.paySlipsLoading });
      const state: IReduxState = getState();
      //console.log(pageNo);
      employeeDataAccess
        .getEmployeePaySlips(
          employeeId,
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((data) => {
          dispatch({
            type: types.paySlipsSuccess,
            payload: data.data,
          });
        })
        .catch((error: Error) => {
          //console.log(error);
          dispatch({ type: types.paySlipsFailed, payload: error.message });
        });
    },
  getAllSalaryPaymentMode: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    paymentDataAccess
      .getAllsalaryPaymentMode()(state.commonState.commonActions)
      .then((stateData) => {
        dispatch({
          type: types.getSalaryPaymentModeList,
          payload: {
            list: stateData.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getEmployeeType: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    employeeDataAccess
      .getEmployeeType()(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.employeeType,
          payload: {
            list: data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};

export interface IEmployeeState {
  employees: Array<any>;
  employee: any;
  employeeType: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  psIsLoading: boolean;
  psIsError: boolean;
  psErrorMessage: string;
  psInfo: any;
  taxIsLoading: boolean;
  taxIsError: boolean;
  taxErrorMessage: string;
  taxInfo: any;
  leaveIsLoading: boolean;
  leaveIsError: boolean;
  leaveErrorMessage: string;
  leaveInfo: any;
  additionDeductionIsError: boolean;
  additionDeductionIsLoading: boolean;
  additionDeductionErrorMessage: string;
  salaryPaymentModeList: Array<any>;
  additionDeduction: any;
  paySlipsIsError: boolean;
  paySlipsIsLoading: boolean;
  paySlipsErrorMessage: string;
  paySlips: any;
}

const initialState: IEmployeeState = {
  employees: [],
  salaryPaymentModeList: [],
  employeeType: {},
  employee: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
  psIsLoading: false,
  psIsError: false,
  psErrorMessage: "",
  psInfo: {},
  taxIsLoading: false,
  taxIsError: false,
  taxErrorMessage: "",
  taxInfo: {},
  leaveIsLoading: false,
  leaveIsError: false,
  leaveErrorMessage: "",
  leaveInfo: {},
  additionDeductionIsError: false,
  additionDeductionIsLoading: false,
  additionDeductionErrorMessage: "",
  additionDeduction: {},
  paySlipsIsError: false,
  paySlipsIsLoading: false,
  paySlipsErrorMessage: "",
  paySlips: null,
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IEmployeeState = initialState,
  action: Action
): IEmployeeState => {
  switch (action.type) {
    case types.cleanEmployee:
      if (
        state.employee.id &&
        state.employee.id.toString() === action.payload.toString()
      ) {
        return state;
      } else {
        return { ...state, uiMessages: [] };
      }
    case types.getEmployee:
      return {
        ...state,
        isLoading: false,
        employee: action.payload.employee,
        uiMessages: action.payload.uiMessages,
      };
    case types.employeeType:
      return {
        ...state,
        isLoading: false,
        employeeType: action.payload.list,
        uiMessages: action.payload.uiMessages,
      };
    case types.getSalaryPaymentModeList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        salaryPaymentModeList: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        employees: action.payload.list,
        totalCount: action.payload.totalCount,
      };

    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }

    case types.psLoading:
      return { ...state, psIsLoading: true, psIsError: false };

    case types.psFailed:
      return {
        ...state,
        psIsLoading: false,
        psIsError: true,
        psErrorMessage: action.payload,
      };

    case types.psSuccess:
      return {
        ...state,
        psIsError: false,
        psIsLoading: false,
        psInfo: action.payload,
      };

    case types.taxLoading:
      return { ...state, taxIsLoading: true, taxIsError: false };

    case types.taxFailed:
      return {
        ...state,
        taxIsLoading: false,
        taxIsError: true,
        taxErrorMessage: action.payload,
      };

    case types.taxSuccess:
      return {
        ...state,
        taxIsError: false,
        taxIsLoading: false,
        taxInfo: action.payload,
      };

    case types.leaveLoading:
      return { ...state, leaveIsLoading: true, leaveIsError: false };

    case types.leaveFailed:
      return {
        ...state,
        leaveIsLoading: false,
        leaveIsError: true,
        leaveErrorMessage: action.payload,
      };

    case types.leaveSuccess:
      return {
        ...state,
        leaveIsError: false,
        leaveIsLoading: false,
        leaveInfo: action.payload,
      };

    case types.additionDeductionLoading:
      return {
        ...state,
        additionDeductionIsLoading: true,
        additionDeductionIsError: false,
      };

    case types.additionDeductionFailed:
      return {
        ...state,
        additionDeductionIsLoading: false,
        additionDeductionIsError: true,
        additionDeductionErrorMessage: action.payload,
      };

    case types.additionDeductionsSuccess:
      return {
        ...state,
        additionDeductionIsError: false,
        additionDeductionIsLoading: false,
        additionDeduction: action.payload,
      };
    case types.paySlipsLoading:
      return { ...state, paySlipsIsLoading: true, paySlipsIsError: false };

    case types.paySlipsFailed:
      return {
        ...state,
        paySlipsIsLoading: false,
        paySlipsIsError: true,
        paySlipsErrorMessage: action.payload,
      };

    case types.paySlipsSuccess:
      //console.log(action);
      return {
        ...state,
        paySlipsIsError: false,
        paySlipsIsLoading: false,
        paySlips: action.payload,
      };
    default:
      return state;
  }
};
