import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class superAnnuation {
  getOrganizationIsRegistered = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SuperAnnuation/isregistered")(
      commonActions
    );
  };
  getOrganizationToReview = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SuperAnnuation/getorganizationtoreview")(
      commonActions
    );
  };
  reviewOrganization = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SuperAnnuation/revieworganization",
      data
    )(commonActions);
  };
  getSuperAuthoriser =
    (id, hasToUpdate = false) =>
    (commonActions: ICommonActions) => {
      return commonDataAccess.getData("SuperAnnuation/getsuperauthoriser", {
        id,
        hasToUpdate,
      })(commonActions);
    };
  reviewSuperAuthoriser = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SuperAnnuation/reviewsuperauthoriser",
      data
    )(commonActions);
  };
  getBankDetails =
    (id, hasToUpdate = false) =>
    (commonActions: ICommonActions) => {
      return commonDataAccess.getData("SuperAnnuation/getbankingdetails", {
        id,
        hasToUpdate,
      })(commonActions);
    };
  reviewBankDetails = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SuperAnnuation/reviewbankingdetails",
      data
    )(commonActions);
  };
  getBillingContact =
    (id, hasToUpdate = false) =>
    (commonActions: ICommonActions) => {
      return commonDataAccess.getData("SuperAnnuation/getbillingcontact", {
        id,
        hasToUpdate,
      })(commonActions);
    };
  reviewBillingContact = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SuperAnnuation/reviewbillingcontact",
      data
    )(commonActions);
  };
  getPDSApproval =
    (id, hasToUpdate = false, returnUrl = "") =>
    (commonActions: ICommonActions) => {
      return commonDataAccess.postDataNoResponse(
        "SuperAnnuation/getpdsapproval",
        null,
        {
          id,
          hasToUpdate,
          returnUrl,
        }
      )(commonActions);
    };
  updateSuperOrganization = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SuperAnnuation/updatesuperorganization",
      null,
      {
        id,
      }
    )(commonActions);
  };
  getSuperPaymentList = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SuperAnnuation/super-payment-list")(
      commonActions
    );
  };
  getSuperPaymentById = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SuperAnnuation/super-payment-detail", {
      id,
    })(commonActions);
  };
  getSuperStreamReport = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SuperAnnuation/stream-report")(
      commonActions
    );
  };
  getCsvFile = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SuperAnnuation/super-payment-csv", { id })(
      commonActions
    );
  };
}

export default new superAnnuation();
