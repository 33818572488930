import { Download, Preview, Refresh } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { formatDate } from "libComp/utility.service";

export const getSuperPaymentTable = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Upload Date",
      field: "createdOn",
      sortable: true,
      renderCell: (params) => {
        return formatDate(params.row?.createdOn);
      },
    },
    {
      headerName: "Interchange ID",
      field: "interchangeID",
      sortable: true,
    },
    {
      headerName: "Pay Start Date",
      field: "startDate",
      sortable: true,
      renderCell: (params) => {
        return formatDate(params.row?.startDate);
      },
    },
    {
      headerName: "Pay End Date",
      field: "endDate",
      sortable: true,
      renderCell: (params) => {
        return formatDate(params.row?.endDate);
      },
    },
    {
      headerName: "Doc Upload Status",
      field: "docUploadMessageDescription",
      sortable: true,
    },
    {
      headerName: "Status",
      field: "status",
      sortable: true,
    },
    {
      headerName: "Amount",
      field: "amount",
      sortable: true,
    },
    {
      headerName: "Actions",
      field: "progress",
      sortable: false,
      width: 100,
      align: "center",

      renderCell: (params) => {
        return (
          <div>
            <IconButton
              title="Refresh"
              size="small"
              onClick={() => {
                window.location.reload();
              }}
            >
              <Refresh />
            </IconButton>
            <IconButton
              title="Download"
              size="small"
              onClick={() => {
                extra?.onDownload(params.row.id);
              }}
            >
              <Download />
            </IconButton>
            <IconButton
              title="View"
              size="small"
              onClick={() => {
                props.history.push(
                  "/payroll/super-payment/view/" + params.row.id
                );
              }}
            >
              <Preview />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
