import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import { actions as commonActions } from "../commonRedux";
import agentDataAccess from "dataAccess/agent/agentDataAccess";

const types = {
    addToList: "AGENT_ADD_TO_LIST",
    failed: "AGENT_FAILED",
    loading: "AGENT_LOADING",
  };

export const actions = {
  getAllAgents:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = ""
    ) =>
    (dispatch, getState) => {
      dispatch(commonActions.loading());
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      agentDataAccess
        .getAgentsList(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((agentData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: agentData.data.list,
              totalCount: agentData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        })
        .finally(() => {
          dispatch(commonActions.loadingEnd());
        });
    }
};

export interface IAgentState {
  agents: Array<any>;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: IAgentState = {
  agents: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IAgentState = initialState,
  action: Action
): IAgentState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        agents: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    default:
      return state;
  }
};
