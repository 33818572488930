import { Button, Divider, Grid, Stack, Typography } from "@mui/material";
import { useStyleClass } from "styles/theming/useStyleClass";

interface IButton {
  title: string;
  onClick: Function;
  iconName: string;
  id: string;
}
interface IProps {
  header: string;
  buttons?: IButton[];
}
function PageTitleHeader(props) {
  const style = useStyleClass();

  return (
    <Grid item>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={{ xs: 2, sm: 2, md: 4, lg: 12 }}
        sx={{ m: 1 }}
      >
        <Typography
          sx={{
            fontSize: "1.2em",
            fontWeight: 500,
            color: "rgba(0, 0, 0, 0.87)",
            display: "flex",
            justifyContent: "space-between",
          }}
          gutterBottom
        >
          {props.header}
        </Typography>

        <Stack spacing={2} direction="row">
          {props.buttons &&
            props.buttons.map((button, index) => (
              <Button
                classes={{ root: style.button }}
                variant="contained"
                disabled={props?.disabled}
                key={button.id}
                onClick={() => {
                  button.onClick(index);
                }}
              >
                <i className={button.iconName}></i> &nbsp; {button.title}
              </Button>
            ))}
        </Stack>
      </Stack>
      <Divider />
      <br />
    </Grid>
  );
}

export default PageTitleHeader;
