import { connect } from "react-redux";

import { Box } from "@mui/material";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import SuperPaymentDetail from "./superPaymentDetail";

const ViewSuperPayment = (props) => {
  const superId = props.match.params.id;

  // Breadcrumb
  let BCMenuItems = [];
  BCMenuItems = [
    {
      title: "Super Payment",
      to: null,
    },
    {
      title: "Super Payment List",
      to: "/payroll/super-payments",
    },
    {
      title: "Payment Detail",
      to: null,
    },
  ];

  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <PageBreadcrumb menuItem={BCMenuItems} />
        <PageTitleHeader
          header="Payment Detail"
          buttons={[
            {
              title: "Back to Super Payment List",
              onClick: (index) => {
                props.history.push("/payroll/super-payments");
              },
              iconName: "fa fa-long-arrow-left",
              id: "proadd1",
            },
          ]}
        />
        <SuperPaymentDetail superId={superId} />
      </Box>
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  superPayment: state.superannuation.superPaymentId ?? [],
});

export default connect(mapStateToProps)(ViewSuperPayment);
