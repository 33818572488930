import { IReduxState } from "@redux";
import { IListState } from "@redux/listRedux";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { connect, useDispatch, useSelector } from "react-redux";
import { useState, useCallback } from "react";
import { actions as agentActions, IAgentState } from "@redux/agent/agentRedux";
import { defaultPageSize } from "common/constants";
import { getAgentListTableColumns } from "./agentList.config";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";

function AgentList(props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const agentState: IAgentState = props.agentList;
  const listState: IListState = props.listState;
  const title = "Agent List";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const getAgentList = useCallback(
    (
      pageNo = 0,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = ""
    ) => {
      dispatch(
        agentActions.getAllAgents(pageNo + 1, pageSize, filters, sortPreference)
      );
    },
    [selection]
  );

  let extraProps = {};

  const agentButtons = [
    {
      title: "Add New Agent",
      onClick: (index) => {},
      iconName: "fa fa-plus",
      id: "",
    },
  ];

  const tableColumns = getAgentListTableColumns(props, extraProps);
  return (
    <>
      <div className="page-container">
        <PageTitleHeader header={title} buttons={agentButtons} />
        {/* <Filter
          filterOptions={[
            {
              propertyName: "PayFrom",
              placeholder: "Pay From",
              inputType: "date",
              typeOptions: [filterOptions.greaterThanEqual],
            },
            {
              propertyName: "PayTo",
              placeholder: "Pay To",
              inputType: "date",
              typeOptions: [filterOptions.lessThanEqual],
            },
          ]}
          onFilterChange={(pageNo, pageSize, filters, sortData) => {
            dispatch(
              paymentActions.getPreviuosPays(pageNo,pageSize,filters,sortData)
            );
          }}
        /> */}
        <SolpayGrid
          columns={tableColumns}
          rows={agentState.agents}
          rowCount={agentState.totalCount}
          loading={agentState.isLoading}
          loadData={getAgentList}
          triggerChange={(pageNumber, pageSize, sort) => {
            getAgentList(pageNumber, pageSize, listState.filters, sort);
          }}
        />
      </div>
    </>
  );
}
const mapStateToProps = ({ agentList, listState }) => ({agentList,listState});
export default connect(mapStateToProps)(AgentList);