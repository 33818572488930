import { IPosts } from "./IPages";

export const PAGES: IPosts[] = [
  {
    menuItem: "Dashboard",
    to: "/tenant",
    children: [],
    selectedIndex: 0,
    searchLink: "tenant",
  },
  //   {
  //     menuItem: "Payroll",
  //     to: "/tenant/payroll",
  //     children: [],
  //     selectedIndex: 1,
  //   },
  {
    menuItem: "Payroll",
    isSuperAdmin: false,
    searchLink: "payroll",
    to: null,
    children: [
      {
        subMenu: "Employees",
        description: "Manage Employees",
        childTo: "/payroll/employees",
        selectedIndex: 1,
        searchLink: "payroll",
        childSubmenu: [],
        hasFormView: [
          {
            formViewMenuName: "Employee Add/Edit",
            formViewMenuPath: "/payroll/employee/addedit/",
            selectedIndex: 1,
            searchLink: "payroll",
          },
        ],
      },
      {
        subMenu: "Pay",
        description: "Pay the Employees",
        childTo: "/payroll/payrun/startpay",
        selectedIndex: 1,
        childSubmenu: [],
        searchLink: "payroll",
        hasFormView: [
          {
            formViewMenuName: "Review Pay",
            formViewMenuPath: "/payroll/payrun/reviewpay/",
            selectedIndex: 1,
            searchLink: "payroll",
          },
        ],
      },
      {
        subMenu: "Previous Pays",
        description: "Previous Payslips",
        childTo: "/payroll/pastpayrun",
        selectedIndex: 1,
        childSubmenu: [],
        searchLink: "payroll",
        hasFormView: [
          {
            formViewMenuName: "Pay Summaries",
            formViewMenuPath: "/payroll/payrun/summary/",
            selectedIndex: 1,
            searchLink: "payroll",
          },
        ],
      },
      {
        subMenu: "Payroll Settings",
        description: "Additional payroll settings",
        childTo: "/payroll/payroll-settings",
        selectedIndex: 1,
        childSubmenu: [],
        showDivider: true,
        searchLink: "payroll",
        hasFormView: [
          {
            formViewMenuName: "Addition Deduction Payment",
            formViewMenuPath: "/payroll/payroll-settings/paymenthead/addedit/",
            selectedIndex: 1,
            searchLink: "payroll",
          },
        ],
      },
      {
        subMenu: "Superannuation",
        description: "Superannuation Registration, Payment",
        childTo: null,
        selectedIndex: 1,
        showDivider: true,
        searchLink: "payroll",
        childSubmenu: [
          {
            childMenu: "Organization Details",
            childSubmenuTo: "/payroll/superregistration",
            selectedIndex: 1,
            searchLink: "payroll",
            secondChildSubMenu: [
              {
                secondChildSubMenuName: "Set Up Superannuation",
                secondChildSubMenuPath: "/payroll/reviewbusiness",
                selectedIndex: 1,
                searchLink: "payroll",
              },
              {
                secondChildSubMenuName: "Set Up Superannuation",
                secondChildSubMenuPath: "/payroll/reviewsuperauthorizer/",
                selectedIndex: 1,
                searchLink: "payroll",
              },
              {
                secondChildSubMenuName: "Set Up Superannuation",
                secondChildSubMenuPath: "/payroll/reviewbankdetails/",
                selectedIndex: 1,
                searchLink: "payroll",
              },
              {
                secondChildSubMenuName: "Set Up Superannuation",
                secondChildSubMenuPath: "/payroll/reviewbankdetails/",
                selectedIndex: 1,
                searchLink: "payroll",
              },
            ],
          },
          {
            childMenu: "Super Payment",
            childSubmenuTo: "/payroll/super-payments",
            selectedIndex: 1,
            searchLink: "payroll",
          },
          // {
          //   childMenu: "Super payment",
          //   childSubmenuTo: "/payroll/superannuation",
          //   selectedIndex: 1,
          //   searchLink: "payroll",
          //   secondChildSubMenu: [
          //     {
          //       secondChildSubMenuName: "Invoice Form",
          //       secondChildSubMenuPath: "/accounting/sales/invoice/addedit/",
          //       selectedIndex: 2,
          //       searchLink: "accounting",
          //     },
          //   ],
          // },
        ],
      },
      {
        subMenu: "Reports",
        description: "View, Print or Email Reports",
        childTo: "/payroll/Report",
        childSubmenu: [],
        selectedIndex: 1,
        searchLink: "payroll",
      },
      // {
      //   subMenu: "Time Sheet & Rostering",
      //   description: "Time Sheet & Rostering",
      //   childTo: null,
      //   childSubmenu: [],
      //   selectedIndex: 7,
      //   searchLink: "payroll",
      // },
    ],
    selectedIndex: 1,
  },
  {
    menuItem: "Invoices",
    isSuperAdmin: false,
    to: null,
    searchLink: "accounting",
    children: [
      {
        subMenu: "Sales",
        description: "Manage Invoices",
        childTo: null,
        selectedIndex: 2,
        showDivider: true,
        searchLink: "accounting",
        childSubmenu: [
          {
            childMenu: "Invoices",
            childSubmenuTo: "/accounting/sales/invoices",
            selectedIndex: 2,
            searchLink: "accounting",
            secondChildSubMenu: [
              {
                secondChildSubMenuName: "Invoice Form",
                secondChildSubMenuPath: "/accounting/sales/invoice/addedit/",
                selectedIndex: 2,
                searchLink: "accounting",
              },
            ],
          },
          {
            childMenu: "Quotations",
            childSubmenuTo: "/accounting/sales/quotations/",
            selectedIndex: 2,
            searchLink: "accounting",
            secondChildSubMenu: [
              {
                secondChildSubMenuName: "Quotation Form",
                secondChildSubMenuPath: "/accounting/sales/quotation/addedit/",
                selectedIndex: 2,
                searchLink: "accounting",
              },
            ],
          },
        ],
      },
      {
        //subMenu: "Purchase",
        subMenu: "Products",
        description: "View Purchase",
        childTo: null,
        selectedIndex: 2,
        showDivider: true,
        searchLink: "accounting",
        childSubmenu: [
          // {
          //   childMenu: "Bills",
          //   childSubmenuTo: "/accounting/purchase/bills",
          //   selectedIndex: 2,
          //   searchLink: "accounting",
          //   secondChildSubMenu: [
          //     {
          //       secondChildSubMenuName: "Bill Form",
          //       secondChildSubMenuPath: "/accounting/purchase/bill/addedit/",
          //       selectedIndex: 2,
          //       searchLink: "accounting",
          //     },
          //   ],
          // },
          {
            childMenu: "Products",
            childSubmenuTo: "/accounting/purchase/products",
            selectedIndex: 2,
            searchLink: "accounting",
            secondChildSubMenu: [
              {
                secondChildSubMenuName: "Product Form",
                secondChildSubMenuPath: "/accounting/purchase/product/addedit/",
                selectedIndex: 2,
                searchLink: "accounting",
              },
            ],
          },
        ],
      },
      {
        subMenu: "Contacts",
        description: "View Contacts",
        childTo: null,
        selectedIndex: 2,
        showDivider: true,
        searchLink: "accounting",
        childSubmenu: [
          // {
          //   childMenu: "Suppliers",
          //   childSubmenuTo: "/accounting/contacts/suppliers",
          //   selectedIndex: 2,
          //   searchLink: "accounting",
          //   secondChildSubMenu: [
          //     {
          //       secondChildSubMenuName: "Supplier Form",
          //       secondChildSubMenuPath: "/accounting/contacts/supplier/",
          //       selectedIndex: 2,
          //       searchLink: "accounting",
          //     },
          //   ],
          // },
          {
            childMenu: "Clients",
            childSubmenuTo: "/contact/clients",
            selectedIndex: 2,
            searchLink: "accounting",
            secondChildSubMenu: [
              {
                secondChildSubMenuName: "Client Form",
                secondChildSubMenuPath: "/contact/client/",
                selectedIndex: 2,
                searchLink: "accounting",
              },
            ],
          },
        ],
      },
      // {
      //   subMenu: "Banking",
      //   description: "View Purchase",
      //   childTo: "/accounting/banking",
      //   childSubmenu: [],
      //   selectedIndex: 2,
      //   searchLink: "accounting",
      // },
      // {
      //   subMenu: "Journals",
      //   description: "View Journals",
      //   childTo: "/accounting/journals",
      //   childSubmenu: [],
      //   selectedIndex: 2,
      //   searchLink: "accounting",
      // },
      {
        subMenu: "Reports",
        description: "View Reports",
        childTo: "/reports/sales",
        childSubmenu: [],
        selectedIndex: 2,
        searchLink: "accounting",
      },
      // {
      //   subMenu: "Year Closing",
      //   description: "Close Accounts",
      //   childTo: "/accounting/yearclosing",
      //   childSubmenu: [],
      //   selectedIndex: 2,
      //   searchLink: "accounting",
      // },
    ],
  },
  // { menuItem: "Settings", to: "/settings", children: [] }, Payroll Settings
  {
    menuItem: "Settings",
    isSuperAdmin: false,
    selectedIndex: 3,
    to: null,
    searchLink: "settings",
    children: [
      {
        subMenu: "Subscription",
        description: "Add / Edit Subscription",
        childTo: null,
        selectedIndex: 3,
        showDivider: true,
        searchLink: "settings",
        childSubmenu: [
          {
            childMenu: "Payment List",
            childSubmenuTo: "/settings/subscription/payments",
            selectedIndex: 3,
            secondChildSubMenu: [],
            searchLink: "settings",
          },
          {
            childMenu: "Packages",
            childSubmenuTo: "/settings/subscription/packages",
            selectedIndex: 3,
            secondChildSubMenu: [],
            searchLink: "settings",
          },
          {
            childMenu: "Payment / Card",
            childSubmenuTo: "/settings/subscription/card",
            selectedIndex: 3,
            secondChildSubMenu: [],
            searchLink: "settings",
          },
          {
            childMenu: "Unsubscribe",
            childSubmenuTo: "/settings/subscription/unsubscribe",
            selectedIndex: 3,
            secondChildSubMenu: [],
            searchLink: "settings",
          },
        ],
      },
      {
        subMenu: "Business Settings",
        description: "Manage Business Settings",
        childTo: "/settings/business",
        selectedIndex: 3,
        childSubmenu: [],
        searchLink: "settings",
      },
      // {
      //   subMenu: "Accounts",
      //   description: "Account List",
      //   childTo: "/settings/accounts",
      //   selectedIndex: 3,
      //   childSubmenu: [],
      //   searchLink: "settings",
      // },
      // {
      //   subMenu: "Bank Feeds",
      //   description: "Bank Feeds Settings",
      //   childTo: "/settings/bankfeeds",
      //   selectedIndex: 3,
      //   childSubmenu: [],
      //   showDivider: true,
      //   searchLink: "settings",
      // },

      // {
      //   subMenu: "Email Settings",
      //   description: "Manage Email Settings",
      //   childTo: null,
      //   selectedIndex: 3,
      //   childSubmenu: [],
      //   searchLink: "settings",
      // },
    ],
  },
  {
    menuItem: "Admin",
    isSuperAdmin: true,
    to: null,
    searchLink: "admin",
    children: [
      {
        subMenu: "Organizations",
        description: "Manage Organizations / Clients",
        childTo: "/admin/organizations",
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
      },
      {
        subMenu: "Users",
        description: "Manage Users",
        childTo: "/admin/users/current",
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
      },
      {
        subMenu: "All Organization's Users",
        description: "Manage Users",
        childTo: "/admin/users/all",
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
        //showDivider: true,
      },
      {
        subMenu: "Agents",
        description: "Manage Agents",
        childTo: "/admin/agent-lists",
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
        showDivider: true,
      },
      {
        subMenu: "View Payments",
        description: "View Payment List",
        childTo: "/admin/paymentlist",
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
        showDivider: true,
        // hasFormView: [
        //   {
        //     formViewMenuName: "Payment Add/Edit",
        //     formViewMenuPath: "/admin/subscription/payments/",
        //     selectedIndex: 4,
        //     searchLink: "admin",
        //   },
        // ],
      },
      {
        subMenu: "Unsubscribe Requests",
        description: "Unsubscribe Requests List",
        childTo: "/admin/unsubscriptions",
        selectedIndex: 4,
        searchLink: "admin",
        childSubmenu: [],
      },
      {
        subMenu: "View Tickets",
        description: "View Tickets",
        childTo: "/admin/tickets",
        selectedIndex: 4,
        childSubmenu: [],
        showDivider: true,
        searchLink: "admin",
      },
      {
        subMenu: "Super Funds",
        description: "Manage Super Funds",
        childTo: "/admin/superfunds",
        selectedIndex: 4,
        childSubmenu: [],
        showDivider: true,
        searchLink: "admin",
      },
      {
        subMenu: "Email Settings",
        description: "Manage Email Settings",
        childTo: null,
        selectedIndex: 4,
        childSubmenu: [],
        showDivider: true,
        searchLink: "admin",
      },
      {
        subMenu: "Audit Log (Activity)",
        description: "Audit Log (Activity)",
        childTo: "/admin/audit-activity",
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
      },
      {
        subMenu: "Audit Log (Data Change)",
        description: "Audit Log (Data Change)",
        childTo: null,
        selectedIndex: 4,
        childSubmenu: [],
        searchLink: "admin",
      },
    ],
  },
  {
    menuItem: "Reports",
    to: null,
    isSuperAdmin: false,
    searchLink: "reports",
    children: [
      {
        subMenu: "Sales",
        description: "Sales Report",
        childTo: "/reports/sales",
        selectedIndex: 5,
        childSubmenu: [],
        searchLink: "reports",
      },
      {
        subMenu: "Payroll",
        description: "Payroll Report",
        childTo: "/payroll/Report",
        selectedIndex: 5,
        childSubmenu: [],
        searchLink: "reports",
      },
      // {
      //   subMenu: "Business",
      //   description: "Business Report",
      //   childTo: "/reports/business",
      //   selectedIndex: 5,
      //   childSubmenu: [],
      //   searchLink: "reports",
      // },
      {
        subMenu: "GST",
        description: "GST Report",
        childTo: "/reports/gst",
        selectedIndex: 6,
        childSubmenu: [],
        searchLink: "reports",
      },
    ],
  },
  {
    menuItem: "Help",
    to: "/help",
    isSuperAdmin: false,
    children: [],
    searchLink: "help",
  },
];
