import {
  Button,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
} from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableContainer from "@mui/material/TableContainer";
import { actions as paymentActions } from "@redux/pay/paymentRedux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyleClass } from "styles/theming/useStyleClass";

const MyCreditCard = ({ data }) => {
  const tableHeaders = ["Card Number", "Next Payment Date", "+/-"];

  const dispatch = useDispatch();

  const history = useHistory();

  React.useEffect(() => {
    dispatch(paymentActions.getAllCreditCards());
  }, []);

  const style = useStyleClass();

  const title = "My Credit Cards";

  const creditCardButtons = [
    {
      title: "Update Credit Card",
      onClick: (index) => {
        history.push("/settings/subscription/card");
      },
      iconName: "fa fa-arrow-up",
      id: "billadd1",
    },
  ];

  return (
    <Box sx={{ px: 4, py: 2 }}>
      <PageTitleHeader header={title} buttons={creditCardButtons} />

      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.data?.map((item, index) => (
              <TableRow key={index}>
                <TableCell align="center">{item?.cardNumber}</TableCell>
                <TableCell align="center">{item?.nextPaymentDate}</TableCell>
                {item?.IsActive && (
                  <TableCell align="center">
                    <Button variant="outlined">Retry</Button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  data: { ...state.payment.creditCards },
});
export default connect(mapStateToProps)(MyCreditCard);
