import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { IReduxState } from "@redux";
import { IListState } from "@redux/listRedux";
import {
  actions as paymentActions,
  IPayRunState,
} from "@redux/pyr/payRunRedux";
import paymentDataAccess from "dataAccess/pyr/payrunDataAccess";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import Filter from "libComp/sections/filter";
import { defaultPageSize, filterOptions } from "common/constants";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useSnackbar } from "notistack";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getPreviousPayTableColumns } from "./previous-pay.config";

function PreviousPay(props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const payState: IPayRunState = props.payrun;
  const listState: IListState = props.listState;
  const [showConfirm, setShowConfirm] = useState(false);
  const [payRunId, setPayRunId] = useState("");
  const title = "Previous Pays";
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const getPreviousPayList = useCallback(
    (pageNo = 0, pageSize = defaultPageSize, filters = [], sortPreference = "") => {
      dispatch(
        paymentActions.getPreviuosPays(
          pageNo + 1,
          pageSize,
          filters,
          sortPreference
        )
      );
    },
    [selection]
  );


  const deletePayRun = (id) => {
    paymentDataAccess
      .deletePreviuosPayList(id)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Previous Pay has been deleted!", {
            variant: "success",
          });
          getPreviousPayList();
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      })
      .finally(() => setShowConfirm(false));
  };

  let extraProps = {
    onDeleteAction: (id) => {
      setPayRunId(id);
      setShowConfirm(true);
    },
  };

  const tableColumns = getPreviousPayTableColumns(props, extraProps);

  return (
    <>
      <div className="page-container">
        <PageTitleHeader header={title} />

        <Dialog
          fullScreen={fullScreen}
          open={showConfirm}
          onClose={() => setShowConfirm(false)}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title">
            {"Do you want to delete this payment?"}
          </DialogTitle>
          <DialogActions>
            <Button onClick={() => setShowConfirm(false)}>No</Button>
            <Button
              onClick={() => {
                deletePayRun(payRunId);
                setShowConfirm(false);
              }}
            >
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Filter
          filterOptions={[
            {
              propertyName: "PayFrom",
              placeholder: "Pay From",
              inputType: "date",
              typeOptions: [filterOptions.greaterThanEqual],
            },
            {
              propertyName: "PayTo",
              placeholder: "Pay To",
              inputType: "date",
              typeOptions: [filterOptions.lessThanEqual],
            },
          ]}
          onFilterChange={(pageNo, pageSize, filters, sortData) => {
            dispatch(
              paymentActions.getPreviuosPays(pageNo,pageSize,filters,sortData)
            );
          }}
        />
        <SolpayGrid
          columns={tableColumns}
          rows={payState.previousPayments}
          rowCount={payState.totalCount}
          loading={payState.isLoading}
          loadData={getPreviousPayList}
          triggerChange={(pageNumber, pageSize, sort) => {
            getPreviousPayList(pageNumber, pageSize, listState.filters, sort);
          }}
        />
      </div>
    </>
  );
}

const mapStateToProps = ({ payrun, listState }) => ({payrun,listState});
export default connect(mapStateToProps)(PreviousPay);
