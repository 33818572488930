import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";

export const getClientTableColumn = (props) => {
  const columns: GridColumns = [
    {
      headerName: "Client Name",
      field: "name",
    },
    {
      headerName: "Client Code",
      field: "clientCode",
      sortable: false,
    },
    {
      headerName: "Client Type",
      field: "clientType",
      sortable: false,
    },
    {
      headerName: "Tax File No",
      field: "taxFileNo",
      sortable: false,
    },
    {
      headerName: "ABN",
      field: "abn",
      sortable: false,
    },
    {
      headerName: "Email",
      field: "email",
    },
    {
      headerName: "Phone",
      field: "phone",
      sortable: false,
    },
    {
      headerName: "Action",
      field: "",
      width: 90,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="">
            <IconButton
              size="small"
              onClick={() => {
                props.history.push(
                  "/contact/client/" +
                    params.row.clientType +
                    "/edit/" +
                    params.row.clientId
                );
              }}
            >
              <Edit />
            </IconButton>
          </div>
        );
      },
    },
  ];

  return columns;
};
