import { Box } from "@mui/material";
import {
  actions as ticketActions,
  ITicketState,
} from "@redux/ticket/ticketRedux";
import { IReduxState } from "@redux";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getTicketTableColumns } from "./ticket-list.config";
import { defaultPageSize } from "common/constants";

function Tickets(props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const title = "Ticket List";

  const ticketState = useSelector((state: IReduxState) => state?.ticket);
  const listState = useSelector((state: IReduxState) => state?.listState);

  const getTicketsList = useCallback((
    pageNo = 0,
    pageSize = defaultPageSize,
    filters = [],
    sortPreference = ""
  ) => {
    dispatch(ticketActions.getAll(pageNo + 1, pageSize, filters, sortPreference));
  }, [selection]);

  const tableColumns = getTicketTableColumns(props);

  return (
    <div className="page-container">
      <PageTitleHeader header={title} />
      <Box
        sx={{
          "& .super-app-theme--resolved": {
            bgcolor: (theme) => "#f3e08e",

            "&:hover": {
              bgcolor: (theme) => "#fd9191",
            },
          },
        }}
      >
        <SolpayGrid
          columns={tableColumns}
          rows={ticketState.tickets}
          rowCount={ticketState.totalCount}
          loading={ticketState.isLoading}
          loadData={getTicketsList}
          triggerChange={(pageNumber, pageSize, sort) => {
            getTicketsList(pageNumber, pageSize, listState.filters, sort);
          }}
          getRowClassName={(params) => {
            return `super-app-theme--${
              !params.row.resolved ? "resolved" : "null"
            }`;
          }}
        />
      </Box>
    </div>
  );
}

const mapStateToProps = ({ ticket }) => ({
  ticket,
});

export default connect(mapStateToProps)(Tickets);
