import { IconButton } from "@mui/material";
import { ArrowCircleRight } from "@mui/icons-material";
import { GridColumns } from "@mui/x-data-grid";
import moment from "moment";

export const getPaymentListTableColumns = (props, extra = {}) => {
  //console.log(props);
  //console.log(props.props);
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "name",
    },
    {
      headerName: "Amount",
      field: "displayAmount",
    },
    {
      headerName: "TxnID",
      field: "txnID",
    },
    {
      headerName: "Triggered On",
      field: "triggeredOn",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Due Date",
      field: "dueDate",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Settlement Date",
      field: "settlementDate",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Successfull",
      field: "successfull",
      sortable: false,
    },
    {
      headerName: "Response Message",
      field: "responseText",
      sortable: false,
    },
    // {
    //   headerName: "Actions",
    //   field: "",
    //   width: 120,
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => {
    //     //console.log(params.row.tenantId);
    //     return (
    //       <div key={"btn_panel" + params.row.id}>
    //         <IconButton
    //           size="small"
    //           title="Go"
    //             onClick={() => {
    //               //props.props.history.push("/settings/subscription/payments");
    //               //props.props.history.push("/settings/subscription/payments/" + params.row.tenantId);
    //             }}
    //         >
    //           <ArrowCircleRight />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    // },
  ];
  return columns;
};

export const getUnpaidSubscriptionsTableColumns = (props, extra = {}) => {
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "tenantName",
    },
    {
      headerName: "Amount",
      field: "displayAmount",
    },
    // {
    //   headerName: "TxnID",
    //   field: "txnID",
    // },
    {
      headerName: "Subs. Start On",
      field: "payPeriodStart",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Subs. End On",
      field: "payPeriodEnd",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },

    {
      headerName: "Month Frequency",
      field: "monthFrequency",
      sortable: false,
    },
    // {
    //   headerName: "Actions",
    //   field: "",
    //   width: 120,
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div key={"btn_panel" + params.row.id}>
    //         <IconButton
    //           size="small"
    //           title="Go"
    //             onClick={() => {
    //               //props.props.history.push("/settings/subscription/payments");
    //             }}
    //         >
    //           <ArrowCircleRight />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    // },
  ];
  return columns;
};
export const getPaidSubscriptionsTableColumns = (props, extra = {}) => {
  const columns: GridColumns = [
    {
      headerName: "Name",
      field: "tenantName",
    },
    {
      headerName: "Amount",
      field: "displayAmount",
    },
    {
      headerName: "Subs. Start On",
      field: "payPeriodStart",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Subs. End On",
      field: "payPeriodEnd",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Month Frequency",
      field: "monthFrequency",
      sortable: false,
    },
    {
      headerName: "Triggered On",
      field: "triggeredOn",
      valueFormatter: (params) => {
        return moment(params.value as any).format("DD-MM-yyyy");
      },
    },
    {
      headerName: "Successfull",
      field: "successfull",
      sortable: false,
    },
    {
      headerName: "Response Message",
      field: "responseText",
      sortable: false,
    },
    {
      headerName: "TxnID",
      field: "txnID",
    },
    // {
    //   headerName: "Actions",
    //   field: "",
    //   width: 120,
    //   align: "center",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div key={"btn_panel" + params.row.id}>
    //         <IconButton
    //           size="small"
    //           title="Go"
    //             onClick={() => {
    //               //props.props.history.push("/settings/subscription/payments");
    //             }}
    //         >
    //           <ArrowCircleRight />
    //         </IconButton>
    //       </div>
    //     );
    //   },
    // },
  ];
  return columns;
};
