import { IReduxState } from "@redux";
import { localStorageKeys } from "common/constants";
import userDataAccess from "dataAccess/emp/userDataAccess";
import { IUser } from "models/iUser";
const typesPrefix = "USER_";
const types = {
  logoutStart: typesPrefix + "LOGOUT_START",
  logout: typesPrefix + "LOGOUT",
  login: typesPrefix + "LOGIN",
  alterOrg: typesPrefix + "ALTER_TENANT",
  auth: typesPrefix + "AUTH",
  refreshUser: typesPrefix + "REFRESH_USER",
  getCompanies: typesPrefix + "GET_COMPANIES",
  loading: typesPrefix + "LOADING",
  switchTenant: typesPrefix + "SWITCH_IN",
};

export const actions = {
  login: (user: IUser) => (dispatch) => {
    dispatch({ type: types.login, payload: user });
  },
  alterOrg: (id) => (dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    userDataAccess
      .alterOrganization(id)(state.commonState.commonActions)
      .then((data) => {
        dispatch({ type: types.alterOrg, payload: data.data });
      });
  },
  switchTenant: (user: IUser) => (dispatch) => {
    dispatch({ type: types.switchTenant, payload: user });
  },
  logout: (history) => (dispatch, getState) => {
    const state: IReduxState = getState();
    dispatch({ type: types.logoutStart });
    userDataAccess
      .sigout()(state.commonState.commonActions)
      .then((data) => {
        localStorage.removeItem(localStorageKeys.authorization);
        localStorage.removeItem(localStorageKeys.user);
        history.push("/login");
        dispatch({ type: types.logout });
      })
      .catch(() => {
        localStorage.removeItem(localStorageKeys.authorization);
        localStorage.removeItem(localStorageKeys.user);
        history.push("/login");
        dispatch({ type: types.logout });
      });
  },
  refreshUser: (user: IUser) => (dispatch) => {
    dispatch({ type: types.refreshUser, user });
  },
};
export interface IUserState {
  user: IUser;
  isLoading: boolean;
  isLoggingOut: boolean;
}
const initialState: IUserState = {
  user:
    localStorage.getItem(localStorageKeys.user) !== "undefined" &&
      localStorage.getItem(localStorageKeys.user)
      ? JSON.parse(localStorage.getItem(localStorageKeys.user))
      : {},
  isLoading: false,
  isLoggingOut: false,
};

export const reducer = (state: any = initialState, action: any): IUserState => {
  const { type, payload } = action;

  switch (type) {
    case types.logoutStart:
      return { ...state, isLoggingOut: true };
    case types.logout:
      return { ...state, user: {}, isLoggingOut: false };
    case types.login:
      return { ...state, user: payload };
    case types.switchTenant:
      return { ...state, user: payload };
    case types.alterOrg:
      return {
        ...state,
        isLoading: false,
        user: {
          ...state.user,
          tenantId: payload.tenantId,
          canUserAddClient: payload.canUserAddClient,
          isAgentUser: payload.isAgentUser,
          isPaidTenant: payload.isPaidTenant,
          tenantName: payload.tenantName,
        },
      };
    case types.loading:
      return { ...state, isLoading: true };

    case types.auth:
      return { ...state, user: payload };
    case types.refreshUser:
      return { ...state, user: payload };
    default:
      return state;
  }
};
