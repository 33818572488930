import { Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { formatDateTime } from "libComp/utility.service";

export const getJournalListColumns = (props) => {
  const columns: GridColumns = [
    {
      headerName: "Reference",
      field: "transactionNo",
      sortable: false,
    },
    {
      headerName: "Date",
      field: "transactionDate",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        return formatDateTime(params.row?.transactionDate);
      },
    },
    {
      headerName: "Note",
      field: "narration",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      sortable: false,
      width: 90,
      align: "center",
      renderCell: (params) => {
        return (
          <>
            <IconButton
              title="Edit"
              size="small"
              onClick={() => {
                props.history.push(
                  "/accounting/journal/addedit/" + params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>
          </>
        );
      },
    },
  ];
  return columns;
};
