import { Alert } from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as employeeActions,
  IEmployeeState,
} from "@redux/emp/employeeRedux";
import { IListState } from "@redux/listRedux";
import { defaultPageSize, filterOptions } from "common/constants";
import employeeDataAccess from "dataAccess/emp/employeeDataAccess";
import Filter from "libComp/sections/filter";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { SolpayGrid } from "libComp/SolpayTable/SolpayGrid";
import { useSnackbar } from "notistack";
import React, { useCallback, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { getEmployeesTable } from "../employees.config";

function Employees(props) {
  const dispatch = useDispatch();
  const [selection, setSelection] = useState(null);
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const employeeState: IEmployeeState = props.employee;
  const listState: IListState = props.listState;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  React.useEffect(() => {
    dispatch(employeeActions.getEmployeeType());
  }, []);

  const getEmployeeList = useCallback(
    (pageNo = 0, pageSize = defaultPageSize, filters = [], sortPreference = "") => {
      
      dispatch(
        employeeActions.getEmployees(
          pageNo + 1,
          pageSize,
          filters,
          sortPreference
        )
      );
    },
    [selection]
  );

  const extra = {
    onDelete: (id) => {
      employeeDataAccess
        .deleteEmployee(id)(commonState.commonActions)
        .then((data) => {
          if (data.success) {
            enqueueSnackbar("Delete has been saved!", { variant: "success" });
            dispatch(
              employeeActions.getEmployees(
                listState.pageNo,
                listState.pageSize,
                listState.filters,
                listState.sort
              )
            );
          } else {
            throw new Error(data.message);
          }
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        });
    },
  };

  const tableColumns = getEmployeesTable(props, extra);

  const exceeded = employeeState?.employeeType?.message;

  return (
    <div className="page-container">
      {exceeded ? (
        <PageTitleHeader
          header="Employee List"
          disabled
          buttons={[
            {
              title: "Add Employee",
              onClick: (index) => {
                props.history.push("/payroll/employee/addedit/0");
              },
              iconName: "fa fa-plus",
              id: "empadd1",
            },
          ]}
        />
      ) : (
        <PageTitleHeader
          header="Employee List"
          buttons={[
            {
              title: "Add Employee",
              onClick: (index) => {
                props.history.push("/payroll/employee/addedit/0");
              },
              iconName: "fa fa-plus",
              id: "empadd1",
            },
          ]}
        />
      )}

      {exceeded && (
        <>
          <Alert
            variant="filled"
            severity="warning"
            // onClose={() => {
            //   dispatch(employeeActions.removeUiMessage(index));
            // }}
          >
            {exceeded}
          </Alert>
          <br />
        </>
      )}

      <Filter
        filterOptions={[
          {
            propertyName: "FromDate",
            placeholder: "Joining From",
            inputType: "date",
            typeOptions: [filterOptions.greaterThanEqual],
          },
          {
            propertyName: "ToDate",
            placeholder: "Joining To",
            inputType: "date",
            typeOptions: [filterOptions.lessThanEqual],
          },
          {
            propertyName: "FullName",
            placeholder: "Full name",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "CurrentPosition",
            placeholder: "Job title",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
          {
            propertyName: "HomePhone",
            placeholder: "Phone",
            inputType: "text",
            typeOptions: [filterOptions.contains],
          },
        ]}
        onFilterChange={(pageNo, pageSize, filters, sortData) => {
          dispatch(
            employeeActions.getEmployees(pageNo, pageSize, filters, sortData)
          );
        }}
      />
      <SolpayGrid
        columns={tableColumns}
        rows={employeeState.employees}
        rowCount={employeeState.totalCount}
        loading={employeeState.isLoading}
        loadData={getEmployeeList}
        triggerChange={(pageNumber, pageSize, sort) => {
          getEmployeeList(pageNumber, pageSize, listState.filters, sort);
        }}
      />
    </div>
  );
}
const mapStateToProps = ({ employee, listState }) => ({ employee, listState });

export default connect(mapStateToProps)(Employees);
