import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class JournalAccess {
  getAll = 
    (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters['filter.' + item.propertyName] =
            item.value + '-' + item.type;
        }
      });
      return commonDataAccess.getData('Transaction/getall', {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };
  saveJournal = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData('Transaction/save', data)(commonActions);
  };
  getJournal = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('Transaction/getbyid', { id })(
      commonActions
    );
  };
  getAllAccounts = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('Transaction/getallaccounts')(
      commonActions
    );
  };
  getTaxCodes = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData('Transaction/getalltaxcode')(commonActions);
  };
}

export default new JournalAccess();
