import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as tenantActions, ITenantState } from "@redux/tnt/tenantRedux";
import tenantDataAccess from "dataAccess/tnt/tenantDataAccess";
import { InputFieldOneRow, InputHidden } from "libComp/common/inputRenderes";
import {} from "react-bootstrap";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { email } from "services/validators";
import { useStyleClass } from "styles/theming/useStyleClass";

interface Props {
  tenantId: string;
  tenant: ITenantState;
  history: any;
}
function TabBillingContactInfo(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const tenantId = props.tenant.businessInfo.id;
  const tenantState: ITenantState = props.tenant;
  const dispatch = useDispatch();
  const onFormSubmit = (model) => {
    return tenantDataAccess
      .saveBillingContactInfo(model)(commonState.commonActions)
      .then((data) => {
        toast.success("Saved Successful");
        dispatch(tenantActions.getBillingConactInfo());
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const style = useStyleClass();

  return (
    <>
      <Box sx={{ flex: 1, width: "90%", mx: "auto" }}>
        <form
          noValidate
          onSubmit={props.handleSubmit((data) => onFormSubmit(data))}
        >
          {tenantState.uiMessages.map((item, index) => (
            <>
              <Alert
                key={index}
                variant="filled"
                severity="warning"
                onClose={() => {
                  dispatch(tenantActions.removeUiMessage(index));
                }}
              >
                {item.messageText}
              </Alert>
              <br />
            </>
          ))}

          <Grid container spacing={6}>
            <Grid item xl={6} md={6}>
              <Field name="tenantId" component={InputHidden} />
              <Field
                name="email"
                component={InputFieldOneRow}
                label="Email"
                placeholder="Email"
                validate={[email]}
              />
              <Divider />
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  classes={{ root: style.button }}
                  sx={{ m: 2 }}
                  variant="contained"
                  type="submit"
                  disabled={props.submitting}
                >
                  {props.submitting ? (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  ) : (
                    <i className="fa fa-check"></i>
                  )}{" "}
                  Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </>
  );
}
const tabSysAdminInfo = reduxForm<any, Props>({
  form: "TabBillingContactInfo",
  enableReinitialize: true,
})(TabBillingContactInfo);
const mapStateToProps = (state, ownProps) => ({
  tenant: state.tenant,
  initialValues: state.tenant.billingContactInfo,
});

export default connect(mapStateToProps)(tabSysAdminInfo);
