import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class unsubscriptionDataAccess {
  getAll = (pageNo, pageSize, filters, sortPreference) => (commonActions: ICommonActions) => {
    let _filters = {};
    filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters['filter.' + item.propertyName] =
          item.value + '-' + item.type;
      }
    });
    return commonDataAccess.getData("Unsubscribe/getUnsubscribeList", {
      pageNo,
      pageSize,
      sortPreference,
      ..._filters,
    })(
      commonActions
    );
  };

  deleteTenant = (id, tenantId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Unsubscribe/unsubscribeTenant",
      {},
      { unsubscribeRequestId: id, unsubscribeTenantId: tenantId }
    )(commonActions);
  };

  cancelUSC = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Unsubscribe/cancelUnsubscribeRequest",
      {},
      { id: id }
    )(commonActions);
  };
}

export default new unsubscriptionDataAccess();
