// import PayrollPageMenuHeader from "libComp/pageMenuHeaders/payrollPageMenuHeader";
import { IReduxState } from "@redux";
import { actions as superActions } from "@redux/pyr/superAnnuationRedux";
import PageButtons from "libComp/sections/pageButtons";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import imageUser from "media/images/blueImages/users.svg";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
function SuperAnnuation(params) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(superActions.getOrganizationIsRegistered());
  }, []);
  const resgisteredButton = [
    {
      header: "Update Organization Details",
      description: "Update Organization Details For Super Payment",
      to: "/payroll/superregistration",
      imageSrc: imageUser,
      imageAlt: "icon",
    },
    {
      header: "Super payment",
      description: "Superannuation payment List",
      to: "/payroll/super-payments",
      imageSrc: imageUser,
      imageAlt: "icon",
    },
    {
      header: "Process Below $450",
      description: "Process Payment Below $450",
      to: "/payroll/runmonthlyaudit",
      imageSrc: imageUser,
      imageAlt: "icon",
    },
  ];

  const isRegistered = params.isRegistered;
  const buttons = [
    {
      header: "Super  Registration",
      description: "Registration For Automated Super Payment",
      to: "/payroll/superregistration",
      imageSrc: imageUser,
      imageAlt: "icon",
    },
    {
      header: "Process Below $450",
      description: "Process Payment Below $450",
      to: "/payroll/runmonthlyaudit",
      imageSrc: imageUser,
      imageAlt: "icon",
    },
  ];
  return (
    <React.Fragment>
      <div className="page-container">
        <PageTitleHeader header="Superannuation"></PageTitleHeader>
        <PageButtons
          buttons={isRegistered ? resgisteredButton : buttons}
        ></PageButtons>
      </div>
    </React.Fragment>
  );
}
const mapStatetoProps = (state: IReduxState, ownProops) => ({
  isRegistered: state.superannuation.isRegistered,
});

export default connect(mapStatetoProps)(SuperAnnuation);
