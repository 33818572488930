import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class QuotationDataAccess {
  getList =
    (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters['filter.' + item.propertyName] =
            item.value + '-' + item.type;
        }
      });
      //return commonDataAccess.getData('ClientInvoice/getallquotation', {
      return commonDataAccess.getData('ClientQuotation/getall', {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };

  getClientQuotationLookupData = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      'ClientQuotation/quotationlookup',
      {}
    )(commonActions);
  };
  saveQuotation = (quotation) => (commonActions: ICommonActions) => {
    console.log("quotation ---->>", quotation)
    return commonDataAccess.postData(
      'ClientQuotation/savequotation',
      quotation
    )(commonActions);
  };
  getQuotation = (id) => (commonActions: ICommonActions) => {
    //return commonDataAccess.getData('ClientInvoice/getinvoice', { id })(
    return commonDataAccess.getData('ClientQuotation/getbyid', { id })(
      commonActions
    );
  };
}

export default new QuotationDataAccess();
