import { actions as userActions } from '@redux/user/userRedux';
export const SavePPFFromBiteArray = (fileName, fileContent) => {
  const element = document.createElement('a');
  const file = new Blob([fileContent], { type: 'application/pdf' });
  if (window.navigator && (window.navigator as any).msSaveBlob) {
    //workaround for ie11
    (window.navigator as any).msSaveBlob(file, fileName);
  } else {
    element.href = URL.createObjectURL(file);
    element.download = fileName; //"myFile.xml"
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};

export const onUnauthorize = (history, dispatch) => {
  return () => {
    dispatch(userActions.logout(history));
  };
};

export const onForbidden = (history) => {
  return () => {
    history.push('/forbidden');
  };
};
