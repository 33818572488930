import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import { actions as commonActions } from "@redux/commonRedux";
import unsubscriptionDataAccess from "dataAccess/unsubscription/unsubscriptionDataAccess";
import { Dispatch } from "redux";

const typesPrefix = "UNSUBSCRIPTION_";

const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getAll: typesPrefix + "GET_ALL",
  getById: typesPrefix + "GET_UNSUBSCRIPTION__BY_ID",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
};

export const actions = {
  getAll: (
    pageNo = 1,
    pageSize = defaultPageSize,
    filters = [],
    sortPreference = "purchaseDate-desc"
    ) => 
    (dispatch, getState) => {
    dispatch(commonActions.loading());
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    unsubscriptionDataAccess
      .getAll(
        pageNo,
        pageSize,
        filters,
        sortPreference
      )(state.commonState.commonActions)
      .then((unsubscriptionData) => {
        dispatch({
          type: types.addToList,
          payload: {
            list: unsubscriptionData.data.list,
            totalCount: unsubscriptionData.data.totalCount,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(()=>{
        dispatch(commonActions.loadingEnd());
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};

export interface IUnsubscritionState {
  unsubscriptions: Array<any>;
  dataCount: number;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: IUnsubscritionState = {
  unsubscriptions: [],
  dataCount: 0,
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};

interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IUnsubscritionState = initialState,
  action: Action
): IUnsubscritionState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        unsubscriptions: action.payload.list,
        totalCount: action.payload.totalCount,
      };

    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    default:
      return state;
  }
};
