import { IReduxState } from "@redux";
import clientDataAccess from "dataAccess/contact/clientDataAccess";
import addressDataAccess from "dataAccess/crm/addressDataAccess";
import bankDataAccess from "dataAccess/crm/bankDataAccess";
import { Dispatch } from "redux";

const typesPrefix = "CLIENT_";
const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getClient: typesPrefix + "GET_CLIENT",
  getAddresses: typesPrefix + "GET_ADDRESSES",
  getAddress: typesPrefix + "GET_ADDRESS",
  getBankAccounts: typesPrefix + "GET_BANK_ACCOUNTS",
  getBankInfo: typesPrefix + "GET_ACCOUNT",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
  clearClient: typesPrefix + "CLEAR_CLIENT",
};

export const actions = {
  getClients:
    (pageNo = 1, pageSize = 10, filters = [], sortPreference = "Name-asc") =>
    (dispatch: Dispatch, getState: Function) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      clientDataAccess
        .getClientList(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((clientData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: clientData.data.list,
              totalCount: clientData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },
  getClient:
    (clientId: string, entityType: string) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      clientDataAccess
        .getClient(
          clientId,
          entityType
        )(state.commonState.commonActions)
        .then((clientData) => {
          dispatch({
            type: types.getClient,
            payload: clientData.data,
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },
  getAddresses:
    (entityId: string, entityTypeId: string) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      addressDataAccess
        .getAddresses(
          entityTypeId,
          entityId
        )(state.commonState.commonActions)
        .then((addressData) => {
          dispatch({
            type: types.getAddresses,
            payload: addressData.data,
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },
  getAddress: (id: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    addressDataAccess
      .getAddress(id)(state.commonState.commonActions)
      .then((addressData) => {
        dispatch({
          type: types.getAddress,
          payload: addressData.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getBankAccounts:
    (entityId: string, entityTypeId: string) =>
    (dispatch: Dispatch, getState) => {
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      bankDataAccess
        .getBankAccounts(
          entityTypeId,
          entityId
        )(state.commonState.commonActions)
        .then((bankData) => {
          dispatch({
            type: types.getBankAccounts,
            payload: bankData.data,
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        });
    },
  getBankAccount: (id: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    bankDataAccess
      .getBankInfo(id)(state.commonState.commonActions)
      .then((bankData) => {
        dispatch({
          type: types.getBankInfo,
          payload: bankData.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
  clearClient: (id) => (dispatch: Dispatch) => {
    dispatch({ type: types.clearClient, payload: id });
  },
};

export interface IClientState {
  clients: Array<any>;
  client: any;
  address: any;
  addresses: Array<any>;
  account: any;
  bankaccounts: Array<any>;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  designationList: Array<any>;
}

const initialState: IClientState = {
  clients: [],
  client: {},
  address: {},
  addresses: [],
  account: {},
  bankaccounts: [],
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
  designationList: [],
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IClientState = initialState,
  action: Action
): IClientState => {
  switch (action.type) {
    case types.getClient:
      return {
        ...state,
        isLoading: false,
        client: action.payload,
      };
    case types.getAddresses:
      return {
        ...state,
        isLoading: false,
        addresses: action.payload,
      };
    case types.getAddress:
      return {
        ...state,
        isLoading: false,
        address: action.payload,
      };
    case types.getBankAccounts:
      return {
        ...state,
        isLoading: false,
        bankaccounts: action.payload,
      };
    case types.getBankInfo:
      return {
        ...state,
        isLoading: false,
        account: action.payload,
      };
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        clients: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.clearClient:
      return {
        ...state,
        isLoading: false,
        isError: false,
        // client: [],
        ...initialState,
      };

    default:
      return state;
  }
};
