import {
  Box,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as payRunActions } from "@redux/pyr/payRunRedux";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { useStyleClass } from "styles/theming/useStyleClass";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { styled } from '@mui/material/styles';

function PayRunSummaryView(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const user = useSelector((state: IReduxState) => state.user.user);
  const [showConfirm, setShowConfirm] = useState(false);
  const [payId, setpayId] = useState("");
  const [viewedIndex, setViewedIndex] = useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const payRunId = props.payRunId;
  const dispatch = useDispatch();
  useEffect(() => {
    if (payRunId && payRunId !== "0")
      dispatch(payRunActions.getPayrunSummary(payRunId));
  }, [payRunId]);
  if(props.currentIndex != -1 && !viewedIndex.includes(props.currentIndex))
    {
      viewedIndex.push(props.currentIndex);
      setViewedIndex(viewedIndex)
    }
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    }
  }));

  const tableHeaders = ["Employee Name", "Gross", "PAYG", "Super", "Take Home"];

  const style = useStyleClass();
  return (
    <>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          py: 1,
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Typography variant="subtitle1" gutterBottom component="div">
            Pay Run:{" "}
            <span style={{ fontSize: "1rem", fontWeight: 450 }}>
              ({props.payRun && props.payRun.frequencyText})&nbsp;&nbsp;
              {(props.payRun &&
                props.payRun.payFrom &&
                moment(props.payRun.payFrom).format("DD-MMM-yyyy")) +
                " - " +
                (props.payRun &&
                  props.payRun.payTo &&
                  moment(props.payRun.payTo).format("DD-MMM-yyyy"))}
            </span>
          </Typography>

          <Typography variant="subtitle1" gutterBottom component="div">
            Pay On:{" "}
            <span style={{ fontSize: "1rem", fontWeight: 450 }}>
              {props.payRun &&
                props.payRun.payDate &&
                moment(props.payRun.payDate).format("DD-MMM-yyyy")}
            </span>
          </Typography>
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <TableHead sx={{ backgroundColor: "#489ecd" }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.payRun &&
              props.payRun.paySlips &&
              props.payRun.paySlips.map((item, index) => (
                <React.Fragment key={"pRun_" + index}>
                  <StyledTableRow
                    hover
                    sx={{
                      boxShadow:
                        props.currentIndex === index
                          ? "inset 1px 0 0 #dadce0, inset -1px 0 0 #dadce0, 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%)"
                          : "",
                    }}
                  >
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      <Box sx={{ display: "flex" }}>
                        <RemoveRedEyeIcon
                          sx={{
                            mr: 1,
                            color: viewedIndex[index] === index
                            ? "#007bff"
                            : "gray"
                             
                          }}
                        />
                       {item.payee.fullname}
                      </Box>
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: 100 }}>
                      {item.amountGross ? item.amountGross.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: 100 }}>
                      {item.taxPaid ? item.taxPaid.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: 100 }}>
                      {item.superContribution ? item.superContribution.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="right" style={{ minWidth: 100 }}>
                      <Box sx={{ display: "flex" }}>
                        {item.takeHome ? item.takeHome.toFixed(2) : "0.00"}
                        {index === props.currentIndex ? (
                          <ArrowRightIcon sx={{ ml: 1 }} />
                        ) : (
                          ""
                        )}
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
            <TableRow>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong>Totals</strong>
              </TableCell>
              <TableCell align="right" style={{ minWidth: 100 }}>
                <strong>{props.payRun.totalGross ? props.payRun.totalGross.toFixed(2) : "0.00"}</strong>
              </TableCell>
              <TableCell align="right" style={{ minWidth: 100 }}>
                <strong> {props.payRun.totalTaxPaid ? props.payRun.totalTaxPaid.toFixed(2) : "0.00"}</strong>
              </TableCell>
              <TableCell align="right" style={{ minWidth: 100 }}>
                <strong> {props.payRun.totalSuperContribution ? props.payRun.totalSuperContribution.toFixed(2) : "0.00"}</strong>
              </TableCell>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong> {props.payRun.totalTakeHome ? props.payRun.totalTakeHome.toFixed(2) : "0.00"}</strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  payRunId: ownProps.payRunId || (ownProps.match && ownProps.match.params.id),
  payRun: state.payrun.payRun ?? [],
});
export default connect(mapStateToProps)(PayRunSummaryView);
