import { IReduxState } from '@redux';
import { Dispatch } from '@reduxjs/toolkit';
import quotationDataAccess from 'dataAccess/quotation/quotationDataAccess';
import {
  GET_LOOKUPDATA,
  GET_QUOTATION,
  PRODUCT_LIST_FOR_QUOTATION,
  QUOTATION_ADD_TO_LIST,
  QUOTATION_FAILED,
  QUOTATION_LOADING,
} from './quotationTypes';

export const getQuotations =
  (
    pageNo = 1,
    pageSize = 25,
    filters = [],
    sortPreference = ''
    //sortPreference = 'quotationDate-desc'
  ) =>
  (dispatch: Dispatch, getState) => {
    dispatch(fetchQuotationLoading());
    const state: IReduxState = getState();
    quotationDataAccess
      .getList(
        pageNo,
        pageSize,
        filters,
        sortPreference
      )(state.commonState.commonActions)
      .then((productData) => {
        dispatch(fetchQuotationAddToList(productData));
      })
      .catch((error: Error) => {
        dispatch(fetchQuotationError(error));
      });
  };
export const fetchQuotationlookUpData = () => (dispatch, getState) => {
  dispatch(fetchQuotationLoading());
  const state: IReduxState = getState();
  quotationDataAccess
    .getClientQuotationLookupData()(state.commonState.commonActions)
    .then((response) => {
      // response.data is the users
      const lookUpdata = response.data;
      dispatch(fetchPurchaseQuotationLookUpData(lookUpdata));
    })
    .catch((error) => {
      // error.message is the error message
      dispatch(fetchQuotationError(error.message))(
        state.commonState.commonActions
      ).then((response) => {
        // response.data is the users
        const lookUpdata = response.data;
        dispatch(fetchPurchaseQuotationLookUpData(lookUpdata));
      });
    });
};
export const GetQuotationById = (id) => (dispatch, getState) => {
  dispatch(fetchQuotationLoading());
  const state: IReduxState = getState();
  quotationDataAccess
    .getQuotation(id)(state.commonState.commonActions)
    .then((Data) => {
      dispatch(FetchQuotationById(Data));
    })
    .catch((error: Error) => {
      dispatch(fetchQuotationError(error));
    });
};
const fetchPurchaseQuotationLookUpData = (lookUpData) => {
  return {
    type: GET_LOOKUPDATA,
    payload: {
      lookUpData: lookUpData,
    },
  };
};
const fetchProductList = () => {
  return {
    type: PRODUCT_LIST_FOR_QUOTATION,
  };
};
const fetchQuotationLoading = () => {
  return { type: QUOTATION_LOADING };
};
const fetchQuotationError = (error) => {
  return {
    type: QUOTATION_FAILED,
    payload: error.message,
  };
};
const fetchQuotationAddToList = (productData) => {
  return {
    type: QUOTATION_ADD_TO_LIST,
    payload: {
      list: productData.data.list,
      totalCount: productData.data.totalCount,
    },
  };
};
const FetchQuotationById = (Data) => {
  return {
    type: GET_QUOTATION,
    payload: {
      quotation: Data.data,
    },
  };
};
