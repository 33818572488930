import { LinearProgress, LinearProgressProps } from "@mui/material"
import { height } from "@mui/system";

interface SolpayLinearProgress extends LinearProgressProps {
    height?: string;
}

const DEFAULT_VALUE: SolpayLinearProgress = {
    variant: "determinate",
    height: "18px"
}

export const SolpayLinearProgressBar = (props: SolpayLinearProgress) => {

    let config = { ...DEFAULT_VALUE, ...props }

    const { height, ...barProps } = config;

    return (

        <div style={{ position: "relative", display: "flex", alignItems: "ceenter", width: "100%" }}>
            <LinearProgress {...barProps} style={{ height: height, display: "flex", width: "100%" }} />
            <div style={{
                position: "absolute",
                color: "#ffffff",
                width: "100%",
                textAlign: "center",
                fontSize: "12px"
            }}>{barProps.value}%</div>
        </div>
    )
}