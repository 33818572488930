import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class ticketDataAccess {
  getAll = 
  (pageNo, pageSize, filters, sortPreference) => 
  (commonActions: ICommonActions) => {
    let _filters = {};
    filters.forEach((item) => {
      if (item.value && item.value.length > 0) {
        _filters['filter.' + item.propertyName] =
          item.value + '-' + item.type;
      }
    });
    return commonDataAccess.getData("SupportTicket/ticketList",{
      pageNo,
      pageSize,
      sortPreference,
      ..._filters,
    })(commonActions);
  };

  getTicket = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("SupportTicket/getById", { id })(
      commonActions
    );
  };

  resolvedTicket = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SupportTicket/resolvedTicket",
      {},
      { id: id }
    )(commonActions);
  };

  saveReplies = (replies) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SupportTicket/saveReply",
      replies
    )(commonActions);
  };

  saveTicket = (model) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "SupportTicket/saveTicket",
      model
    )(commonActions);
  };
}

export default new ticketDataAccess();
