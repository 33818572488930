import { ICommonActions } from '@redux/commonRedux';
import commonDataAccess from 'dataAccess/commonDataAccess';

class ATOReportDataAccess {
    getPrepareYearDropdown = () => (commonActions: ICommonActions) => {
        return commonDataAccess.getData('Payroll/prepareYearDropdown')(
          commonActions
        );
      };
      atoReportGenerate = (data) => (commonActions: ICommonActions) => {
        return commonDataAccess.postDataForATOReportFile(
          "Payroll/atoreport",
          data
        )(commonActions);
      };

      
  }
  
  export default new ATOReportDataAccess();