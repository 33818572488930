import {
  Box,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  CircularProgress,
} from "@mui/material";
import { IReduxState } from "@redux";
import { actions as payRunActions } from "@redux/pyr/payRunRedux";
import paymentDataAccess from "dataAccess/pyr/payrunDataAccess";
import moment from "moment";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { SavePPFFromBiteArray } from "services/commonService";
import { useStyleClass } from "styles/theming/useStyleClass";
import { styled } from "@mui/material/styles";
import PrintIcon from "@mui/icons-material/Print";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { display } from "@mui/system";

function PayRunSummaryTableView(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const user = useSelector((state: IReduxState) => state.user.user);
  const [showConfirm, setShowConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [payId, setpayId] = useState("");

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const payRunId = props.payRunId;
  const dispatch = useDispatch();
  useEffect(() => {
    if (payRunId && payRunId !== "0")
      dispatch(payRunActions.getPayrunSummary(payRunId));
  }, [payRunId]);

 

  const onClickPrint = async (id) => {
    try {
      setLoading(true);
      const response = await paymentDataAccess.printPaySlip(id)(
        commonState.commonActions
      );
      //console.log(response);
      SavePPFFromBiteArray("payslip.pdf", response);
    } catch (error) {
      //console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const sendEmail = async (id) => {
    try {
      setLoading(true);
      const data = await paymentDataAccess.emailPaySlip(id)(commonState.commonActions);
      if (data.success) {
        if (data.uiMessages && data.uiMessages.length > 0) {
          const messageType = data.uiMessages[0].messageType;
          const messageText = data.uiMessages[0].messageText;
          const variant = messageType === 0 ? "success" : "error";
          enqueueSnackbar(messageText, { variant });
        }
      }
    } catch (error) {
      enqueueSnackbar(error.message, { variant: "error" });
    } finally {
      setLoading(false);
      setShowConfirm(false);
    }
  };
  

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  }));
  const tableHeaders = ["Employee Name", "Gross", "PAYG", "Super", "Take Home"];

  const style = useStyleClass();
  return (
    <>
      {loading && (
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            position: "absolute",
            width: "-webkit-fill-available",
            height: "-webkit-fill-available",
          }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          py: 1,
        }}
      >
        <Stack
          direction="row"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}
        >
          <Typography variant="subtitle1" gutterBottom component="div">
            Pay Run:{" "}
            <span style={{ fontSize: "1rem", fontWeight: 450 }}>
              ({props.payRun && props.payRun.frequencyText})&nbsp;&nbsp;
              {(props.payRun &&
                props.payRun.payFrom &&
                moment(props.payRun.payFrom).format("DD-MMM-yyyy")) +
                " - " +
                (props.payRun &&
                  props.payRun.payTo &&
                  moment(props.payRun.payTo).format("DD-MMM-yyyy"))}
            </span>
          </Typography>

          <Typography variant="subtitle1" gutterBottom component="div">
            Pay On:{" "}
            <span style={{ fontSize: "1rem", fontWeight: 450 }}>
              {props.payRun &&
                props.payRun.payDate &&
                moment(props.payRun.payDate).format("DD-MMM-yyyy")}
            </span>
          </Typography>
        </Stack>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="caption table">
          <TableHead sx={{ backgroundColor: "#489ecd" }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="left">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.payRun &&
              props.payRun.paySlips &&
              props.payRun.paySlips.map((item, index) => (
                <React.Fragment key={"pRun_" + index}>
                  <StyledTableRow>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      {item.payee.fullname}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      {item.amountGross ? item.amountGross.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      {item.taxPaid ? item.taxPaid.toFixed(2) : "0.00"}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      {item.superContribution
                        ? item.superContribution.toFixed(2)
                        : "0.00"}
                    </TableCell>
                    <TableCell align="left" style={{ minWidth: 100 }}>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {item.takeHome ? item.takeHome.toFixed(2) : "0.00"}
                        <Box sx={{ display: "flex" }}>
                          <PrintIcon
                            sx={{ mr: 1, cursor: "pointer" }}
                            onClick={() => onClickPrint(item.id)}
                          />
                          <ForwardToInboxIcon
                            sx={{ cursor: "pointer" }}
                            onClick={() => sendEmail(item.id)}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </StyledTableRow>
                </React.Fragment>
              ))}
            <TableRow>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong>Totals</strong>
              </TableCell>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong>
                  {props.payRun.totalGross
                    ? props.payRun.totalGross.toFixed(2)
                    : "0.00"}
                </strong>
              </TableCell>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong>
                  {" "}
                  {props.payRun.totalTaxPaid
                    ? props.payRun.totalTaxPaid.toFixed(2)
                    : "0.00"}
                </strong>
              </TableCell>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong>
                  {" "}
                  {props.payRun.totalSuperContribution
                    ? props.payRun.totalSuperContribution.toFixed(2)
                    : "0.00"}
                </strong>
              </TableCell>
              <TableCell align="left" style={{ minWidth: 100 }}>
                <strong>
                  {" "}
                  {props.payRun.totalTakeHome
                    ? props.payRun.totalTakeHome.toFixed(2)
                    : "0.00"}
                </strong>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const mapStateToProps = (state, ownProps) => ({
  payRunId: ownProps.payRunId || (ownProps.match && ownProps.match.params.id),
  payRun: state.payrun.payRun ?? [],
});
export default connect(mapStateToProps)(PayRunSummaryTableView);
