import { IReduxState } from "@redux";
import { GetQuotationById } from "@redux/quotation/quotationActions";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import QuotationFormView from "./quotationFormView";

const addEditQuotation = reduxForm<any>({
  form: "QuotationFormView",
  enableReinitialize: true,
})(QuotationFormView);
const selector = formValueSelector("QuotationFormView");

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getQuotation: () => 
    dispatch(GetQuotationById(ownProps.match.params.id)),
  };
};

const initialValues = {
  quotationDate: new Date(),
  clientQuotationDetails: [
    {
      isGSTApplicable: true,
      isService: false,
      quantity: 0,
      totalAmount: 0,
    },
  ],
};

const mapStateToProps = (state: IReduxState, ownProps) => ({

  clientList: state.quotation.clientList,
  productList: state.quotation.productList,
  //accountList: state.quotation.accountList,
  taxList: state.quotation.taxList,
  //accountListWithoutGroup: state.quotation.accountListWithoutGroup,

  initialValues:
    ownProps.match.params.id !== "0"
      ? { ...state.quotation.quotation.quotation }
      : { ...initialValues, quotationNo: state.quotation.currentQuotation },
});

export default connect(mapStateToProps, mapDispatchToProps)(addEditQuotation);
