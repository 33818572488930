import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class InvoiceDataAccess {
  getInvoiceList =
    (pageNo, pageSize, filters, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      filters.forEach((item) => {
        if (item.value && item.value.length > 0) {
          _filters["filter." + item.propertyName] =
            item.value + "-" + item.type;
        }
      });
      return commonDataAccess.getData("ClientInvoice/getall", {
        pageNo,
        pageSize,
        sortPreference,
        ..._filters,
      })(commonActions);
    };
  getClientInvoiceList = (clientId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("ClientInvoice/getclientinvoices", {
      clientId,
    })(commonActions);
  };
  // getQuotationList =
  //   (pageNo, pageSize, filters, sortPreference) =>
  //     (commonActions: ICommonActions) => {
  //       let _filters = {};
  //       filters.forEach((item) => {
  //         if (item.value && item.value.length > 0) {
  //           _filters["filter." + item.propertyName] =
  //             item.value + "-" + item.type;
  //         }
  //       });
  //       return commonDataAccess.getData("ClientInvoice/getallquotation", {
  //         pageNo,
  //         pageSize,
  //         sortPreference,
  //         ..._filters,
  //       })(commonActions);
  //     };
  saveInvoice = (invoice) => (commonActions: ICommonActions) => {
    console.log(invoice);
    return commonDataAccess.postData(
      "ClientInvoice/saveinvoice",
      invoice
    )(commonActions);
  };
  getInvoice = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("ClientInvoice/getinvoice", { id })(
      commonActions
    );
  };
  saveInvoicePayment = (invoicePayment) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "ClientInvoice/saveinvoicepayment",
      invoicePayment
    )(commonActions);
  };
  deleteInvoiceDetail =
    (invoiceDetailId) => (commonActions: ICommonActions) => {
      return commonDataAccess.postData(
        "ClientInvoice/deleteclientinvoicedetail?invoiceDetailId=" +
          invoiceDetailId
      )(commonActions);
    };

  getAllPaymentMethod = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("ClientInvoice/getallpaymentmethod")(
      commonActions
    );
  };
  revertInvoicePayment = (paymentId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "ClientInvoice/revertinvoice?id=" + paymentId
    )(commonActions);
  };
  getInvoiceLookupData = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData(
      "ClientInvoice/invoicelookup",
      {}
    )(commonActions);
  };
}

export default new InvoiceDataAccess();
