import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class DetailedReportDataAccess {
  detailsReportGenerate = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postDataForPdf(
      "Payroll/detailsReport",
      data
    )(commonActions);
  };
}

export default new DetailedReportDataAccess();
