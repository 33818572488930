import { accountTypes } from './../../common/enums';
import { Dispatch } from "redux";
import journalAccess from "dataAccess/bkp/journalDataAcess";
import { IReduxState } from "@redux";
import { actions as commonActions } from "@redux/commonRedux";
import { defaultPageSize } from "common/constants";
const typesPrefix = "TRANSACTION_";
const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getJournal: typesPrefix + "GET_BY_ID",
  getAllAccountHead: typesPrefix + "GET_ALL_ACCOUNT_HEAD",
  getAllTaxCode: typesPrefix + "GET_ALL_TAX_CODE",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
};
export default function groupBy<T extends Record<string, any>, K extends keyof T>(
  array: T[],
  key: K | { (obj: T): string }
): Record<string, T[]> {
  const keyFn = key instanceof Function ? key : (obj: T) => obj[key];
  return array.reduce((objectsByKeyValue, obj) => {
    const value = keyFn(obj);
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {} as Record<string, T[]>);
}

export const actions = {
  getAll:
    (
      pageNo = 1,
      pageSize = defaultPageSize,
      filters = [],
      sortPreference = "TransactionDate-desc"
    ) =>
    (dispatch, getState) => {
      dispatch(commonActions.loading());
      dispatch({ type: types.loading });
      const state: IReduxState = getState();
      journalAccess
        .getAll(
          pageNo,
          pageSize,
          filters,
          sortPreference
        )(state.commonState.commonActions)
        .then((payData) => {
          dispatch({
            type: types.addToList,
            payload: {
              list: payData.data.list,
              totalCount: payData.data.totalCount,
            },
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: types.failed,
            payload: error.message,
          });
        })
        .finally(()=>{
          dispatch(commonActions.loadingEnd());
        });
    },
  getAllAccountHead: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    journalAccess
      .getAllAccounts()(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getAllAccountHead,
          payload: {
            accountHeads: data.data,
            accountHeadSelectList:data.data.map(function(value,index){
              return{
                label: "("+value.accountNo +") " + value.name,
                value:value.id,
                type:value.accountType
              } 

        })
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  getJournal: (id) => (dispatch, getState) => {
    dispatch({ type: types.loading });
    dispatch(commonActions.loading());
    const state: IReduxState = getState();
    journalAccess
      .getJournal(id)(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getJournal,
          payload: {
            journal: data.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
      .finally(() => dispatch(commonActions.loadingEnd()));
  },
  getAllTaxCode: () => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    journalAccess
      .getTaxCodes()(state.commonState.commonActions)
      .then((data) => {
        dispatch({
          type: types.getAllTaxCode,
          payload: {
            taxCodes: data.data,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },
  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};
export interface IJournalState {
  journals: Array<any>;
  accountHeads: Array<any>;
  accountHeadSelectList:  Array<any>;
  taxCodes: Array<any>;
  journal: any;
  totalCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
  accountHeadSelectListByGroup:any;
}

const initialState: IJournalState = {
  journals: [],
  accountHeads: [],
  taxCodes: [],
  journal: {},
  totalCount: 0,
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
  accountHeadSelectList:[],
  accountHeadSelectListByGroup:[]
};
interface Action {
  type: string;
  payload: any;
}

export const reducer = (
  state: IJournalState = initialState,
  action: Action
): IJournalState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        journals: action.payload.list,
        totalCount: action.payload.totalCount,
      };
    case types.getAllAccountHead:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        accountHeads: action.payload.accountHeads,
        accountHeadSelectList: action.payload.accountHeadSelectList,
        accountHeadSelectListByGroup:Object.entries(groupBy(action.payload.accountHeadSelectList,t=>t.type)).map(([label, options]) => ({ label, options }))
      };
    case types.getAllTaxCode:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        taxCodes: action.payload.taxCodes,
      };
    case types.getJournal:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        journal: action.payload.journal,
      };
    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    default:
      return state;
  }
};
