import RemoveIcon from "@mui/icons-material/Remove";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { IReduxState } from "@redux";
import { GetBillById } from "@redux/bill/billActions";
import BillDataAccess from "dataAccess/bill/billDataAccess";
import { DatePickerFieldOneRowWithoutLabel } from "libComp/common/datePickers";
import {
  InputFieldWithoutLabelMui,
  InputSimpleSelectWithoutLabel,
} from "libComp/common/inputRenderes";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Field, SubmissionError } from "redux-form";

const OrderPaymentFields = ({
  fields,
  field,
  index,
  paymentMethodOptions,
  history,
}) => {
  let id = fields.get(index) && fields.get(index).id ? fields.get(index).id : 0;

  const commonState = useSelector((state: IReduxState) => state.commonState);

  const dispatch = useDispatch();

  let updateState = 0;

  const revertPayment = () => {
    BillDataAccess.revertBillPayment(id)(commonState.commonActions)
      .then((data) => {
        toast.success("payment Revert Successfully");
        updateState = data;
        const billId = fields.get(index).billId;
        dispatch(GetBillById(billId));
        fields.remove(index);
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  useEffect(() => {}, [updateState]);

  return (
    <>
      <TableRow key={index}>
        <TableCell align="center">
          <Field
            name={`${field}.paymentDate`}
            label="Payment Date	"
            component={DatePickerFieldOneRowWithoutLabel}
          />
        </TableCell>
        <TableCell align="center">
          <Field
            label="Paid Amount"
            name={`${field}.paidAmount`}
            component={InputFieldWithoutLabelMui}
          />
        </TableCell>
        <TableCell align="center">
          <Field
            name={`${field}.paymentMethod`}
            options={paymentMethodOptions}
            component={InputSimpleSelectWithoutLabel}
          />
        </TableCell>
        <TableCell align="center">
          <IconButton
            color="secondary"
            aria-label="remove"
            onClick={() => {
              revertPayment();
            }}
          >
            <RemoveIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
};

export default OrderPaymentFields;
