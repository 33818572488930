import { IReduxState } from "@redux";
import { GetBillById } from "@redux/bill/billActions";
import groupBy from "@redux/bkp/transactionRedux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import OrderPaymentFormView from "./orderPaymentFormView";

const addEditOrderPayment = reduxForm<any>({
  form: "OrderPaymentFormView",
  enableReinitialize: true,
})(OrderPaymentFormView);

const selector = formValueSelector("PaymentFormView");
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getBill: () => dispatch(GetBillById(ownProps.match.params.id)),
  };
};
const initialValues = {
  paymentDate: new Date(),
  // pueDate: new Date(),
  // purchaseBillPayments: [],
  // purchaseBillDetails: [{ isGSTApplicable: true, isService: false,quantity:0,totalAmount:0 }],
};
const mapStateToProps = (state: IReduxState, ownProps) => ({
  // supplierList:state.bill.supplierList,
  // productList:state.bill.productList,
  // accountList:state.bill.accountList,
  // taxList:state.bill.taxList,
  // accountListWithoutGroup:state.bill.accountListWithoutGroup,

  //  clientName: selector(state, "supplierName"),
  //  clientInvoiceDetails: selector(state, "purchaseBillDetails"),
  //  clientInvoicePayments: selector(state, "purchaseBillPayments"),
  //  discount: selector(state, "discount"),
  billId: state.bill.bill.bill.Id,
  initialValues: {
    // paymentDate:new Date(),
    ...initialValues,
    ...state.bill.bill.bill,
    ...state.bill.bill.bankAccountList,
    payAmount: state.bill.bill.bill.totalDue,
    bankAccountList: Object.entries(
      groupBy(state.bill.bill.bill.bankAccountListmodels, (t) => t.type)
    ).map(([label, options]) => ({ label, options })),
  },
});
// const mapStateToProps = ({ BillFormView, listState }) => ({ BillFormView, listState });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addEditOrderPayment);
