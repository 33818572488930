import { IReduxState } from "@redux";
import { defaultPageSize } from "common/constants";
import { actions as commonActions } from "@redux/commonRedux";
import ticketDataAccess from "dataAccess/ticket/ticketDataAccess";

import { Dispatch } from "redux";

const typesPrefix = "TICKET_";

const types = {
  addToList: typesPrefix + "ADD_TO_LIST",
  getAll: typesPrefix + "GET_ALL",
  getTicket: typesPrefix + "GET_TICKET__BY_ID",
  failed: typesPrefix + "FAILED",
  loading: typesPrefix + "LOADING",
  removeUiMessage: typesPrefix + "REMOVE_UI_MESSAGE",
};

export const actions = {
  getAll: 
  (
    pageNo = 1,
    pageSize = defaultPageSize,
    filters = [],
    sortPreference = ""
  ) =>
  (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });
    const state: IReduxState = getState();
    ticketDataAccess
      .getAll(
        pageNo,
        pageSize,
        filters,
        sortPreference
      )(state.commonState.commonActions)
      .then((ticketData) => {
        dispatch({
          type: types.addToList,
          payload: {
            list: ticketData.data.list,
            totalCount: ticketData.data.totalCount,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      })
  },

  getTicket: (id) => (dispatch: Dispatch, getState) => {
    dispatch({ type: types.loading });

    const state: IReduxState = getState();
    ticketDataAccess
      .getTicket(id)(state.commonState.commonActions)
      .then((Data) => {
        dispatch({
          type: types.getTicket,
          payload: Data.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: types.failed,
          payload: error.message,
        });
      });
  },

  removeUiMessage: (messageIndex) => (dispatch: Dispatch) => {
    dispatch({ type: types.removeUiMessage, payload: messageIndex });
  },
};

export interface ITicketState {
  tickets: Array<any>;
  ticket: any;
  totalCount: number;
  dataCount: number;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  uiMessages: Array<any>;
}

const initialState: ITicketState = {
  tickets: [],
  dataCount: 0,
  totalCount: 0,
  ticket: {},
  isLoading: false,
  isError: false,
  errorMessage: "",
  uiMessages: [],
};

interface Action {
  type: string;
  payload: any;
}

export const ticketReducer = (
  state: ITicketState = initialState,
  action: Action
): ITicketState => {
  switch (action.type) {
    case types.addToList:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        tickets: action.payload.list,
        totalCount: action.payload.totalCount,
      };

    case types.getTicket:
      return {
        ...state,
        isLoading: false,
        errorMessage: null,
        ticket: action.payload,
      };

    case types.loading:
      return { ...state, isLoading: true, isError: false };

    case types.failed:
      return {
        ...state,
        isLoading: false,
        isError: true,
        errorMessage: action.payload,
      };

    case types.removeUiMessage: {
      let uiMessages = [...state.uiMessages];
      uiMessages.splice(action.payload, 1);
      return { ...state, uiMessages: uiMessages };
    }
    default:
      return state;
  }
};
