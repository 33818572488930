import { ICommonActions } from "@redux/commonRedux";
import { IFilter } from "@redux/listRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class PayRunDataAccess {
  // getPreviuosPayList = (pageNo,pageSize,filters: Array<IFilter>,sortPreference) => {
  //   let _filters = {};
  //   filters.forEach((item) => {
  //     if (item.value && item.value.length > 0) {
  //       _filters["filter." + item.propertyName] = item.value + "-" + item.type;
  //     }
  //   });
  //   return commonDataAccess.getData("PayRun/getpastpayruns", {
  //     pageNo,
  //     pageSize,
  //     sortPreference,
  //     ..._filters,
  //   });
  // };

  getPreviuosPayList =
    (pageNo, pageSize, filters: Array<IFilter>, sortPreference) =>
      (commonActions: ICommonActions) => {
        let _filters = {};
        filters.forEach((item) => {
          if (item.value) {
            _filters["filter." + item.propertyName] =
              item.value + "-" + item.type;
          }
        });
        return commonDataAccess.getData("PayRun/getpastpayruns", {
          pageNo,
          pageSize,
          sortPreference,
          ..._filters, 
        })(commonActions);
      };


  getListForStartPayRun = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("PayRun/StartPayrun")(commonActions);
  };

  startPayRun = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData("PayRun/StartPayrun", data)(commonActions);
  };

  getPayRunToReview = (payRunId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("PayRun/getpayruntoreview", { payRunId })(
      commonActions
    );
  };

  completePayRunReview = (data) => (commonActions: ICommonActions) => {
    console.log(data);
    return commonDataAccess.postData(
      "PayRun/completepayrunreview",
      data
      
    )(commonActions);
  };

  getPaySlipToReview = (paySlipId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("PayRun/getpaysliptoreview", { paySlipId })(
      commonActions
    );
  };

  updatePaySlip = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "PayRun/updatepayslip",
      data
    )(commonActions);
  };

  getPayrollFreequency = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("PayRun/getpayrollfreequency")(
      commonActions
    );
  };

  getPayrunSummary = (payRunId) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("PayRun/getpayrunsummary", { payRunId })(
      commonActions
    );
  };

  deletePreviuosPayList = (payRunId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "PayRun/deletepayrun",
      {},
      { payRunId }
    )(commonActions);
  };

  emailPaySlip = (paySlipId) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "EmployeePaySlip/emailpayslip",
      {},
      { id: paySlipId }
    )(commonActions);
  };

  getAllsalaryPaymentMode = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("EmployeePayInfo/getallsalarypaymentmode")(
      commonActions
    );
  };

  printPaySlip = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.postDataForPdf(
      "EmployeePaySlip/printpayslip?id=" + id + "&print=" + true
    )(commonActions);
  };

  isPayToDateCorrect = (frequency, payFrom, payTo) => {
    return commonDataAccess.postData("PayRun/isPayToDateCorrect", {
      frequency,
      payFrom,
      payTo,
    })(null);
  };
}

export default new PayRunDataAccess();
