import { IconButton, Stack, TableCell, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Field, change } from "redux-form";
import { connect, useDispatch, useSelector } from "react-redux";
import {
  InputFieldWithoutLabelMui,
  InputHidden,
} from "libComp/common/inputRenderes";
import SolpayModal from "libComp/common/solpayModal";
import SolpayReactSelectIconWithoutLabel from "libComp/common/solpayModalWithoutLabel";
import SolpayReactSelectWithoutLabelForm from "libComp/common/SolpayReactSelectWithoutLabelForm";
import {
  actions as invoiceAction,
  IInvoiceState,
} from "@redux/inv/invoiceRedux";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { required } from "services/validators";
import ProductFormView from "pages/inv/product/productFormView";

export const InvoiceDetailRow = ({ index, row, fields }) => {
  //const form = useForm("InvoiceFormView");
  const dispatch = useDispatch();
  const setFormValue = (field, value) => {
    let formName = "InvoiceFormView";
    dispatch(change(formName, field, value));
  };
  let rowQuantity =
    fields.get(index) && fields.get(index).quantity
      ? fields.get(index).quantity
      : 1;
  let rowUnitPrice =
    fields.get(index) && fields.get(index).unitPrice
      ? fields.get(index).unitPrice
      : 0;
  let rowDiscountAmount =
    fields.get(index) && fields.get(index).discountAmount
      ? fields.get(index).discountAmount
      : 0;
  let rowTaxCodeId =
    fields.get(index) && fields.get(index).taxCodeId
      ? fields.get(index).taxCodeId
      : 0;
  let productList = useSelector(
    (state: IInvoiceState) => state.invoice.productList
  );
  const taxList = useSelector((state: IInvoiceState) => state.invoice.taxList);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    calculateAmount();
  });
  const calculateAmount = (
    afterDelete: boolean = false,
    deleteIndex: number = -1
  ) => {
    console.log("calculateAmount");
    let rowSubtotal = 0.0;
    let rowTotalDiscount = 0.0;

    if (rowQuantity > 0 && rowUnitPrice > 0)
      rowSubtotal = rowQuantity * rowUnitPrice - rowDiscountAmount;

    if (fields.get(index) !== undefined) {
      //rowSubtotal !== 0
      fields.get(index).totalAmount = rowSubtotal;
      setFormValue(`${row}.totalAmount`, rowSubtotal);
    }
    calculateTotalAmount();
  };

  const calculateTotalAmount = (
    afterDelete: boolean = false,
    deleteIndex: number = -1
  ) => {
    console.log("calculateTotalAmount");
    if (fields.length > 0) {
      let subtotal = 0;
      let totalDiscount = 0;
      let taxAmount = 0;
      let totalPayment = 0;

      fields.forEach((element, index) => {
        if (fields.get(index) && fields.get(index).productId) {
          if (!afterDelete || (afterDelete && deleteIndex != index)) {
            if (fields.get(index) && fields.get(index).totalAmount)
              subtotal += Number(fields.get(index).totalAmount);
            if (fields.get(index) && fields.get(index).discountAmount)
              totalDiscount += Number(fields.get(index).discountAmount);
            if (
              fields.get(index) &&
              fields.get(index).totalAmount > 0 &&
              fields.get(index).taxRate
            )
              taxAmount +=
                (fields.get(index).totalAmount * fields.get(index).taxRate) /
                100;
          } else {
          }
        }
      });

      // let totalDue = subtotal + taxAmount;
      let totalDue = subtotal + taxAmount - totalPayment;
      // // totalAmountIncGST= subtotal + taxAmount;
      setFormValue(`discountAmount`, Number(totalDiscount).toFixed(2));
      setFormValue(`subtotal`, subtotal.toFixed(2));

      setFormValue(`taxAmount`, taxAmount.toFixed(2));

      setFormValue(`totalPayment`, Number(totalDue).toFixed(2));
      setFormValue(`totalDue`, Number(totalDue).toFixed(2));

      setFormValue(`paidAmount`, totalPayment.toFixed(2));
    }
  };
  var updateProductList = () => {
    // dispatch({ type: types.getLookupData });
    dispatch(invoiceAction.fetchInvoicelookUpData());
  };

  // const removeRow = (index) => {
  //   debugger;
  //   //fields.remove(index);
  //   if (fields.length === 1 || fields.length === undefined) {
  //     fields.push({});
  //   }
  //   fields.remove(index);
  //   //fields.splice(index, 1);
  //   if (fields.length > 0) {
  //     fields.forEach((element, idx) => {
  //       calculateAmount(true, idx);
  //     });
  //   }
  // };
  const getTaxRate = (id) => {
    let value;
    if (typeof id != "undefined") {
      value = taxList.find((o) => o.id == id);
      return value.rate;
    }
  };
  const onChangeTaxCode = (value) => {
    console.log(value);
    setFormValue(`${row}.taxCodeId`, value);
    var taxRate = getTaxRate(value);
    setFormValue(`${row}.taxRate`, taxRate);
    calculateAmount();
  };
  const onChangeProductHandler = (data) => {
    setFormValue(`${row}.itemDesctiption`, data.label);

    setFormValue(`${row}.productId`, data.id);

    setFormValue(`${row}.quantity`, 1);

    setFormValue(
      `${row}.unitPrice`,
      typeof data.purchaseUnitPrice === "undefined"
        ? 0.0
        : data.purchaseUnitPrice
    );

    // let accId =
    //   typeof data.salesAccountId === "undefined"
    //     ? selectedAccountModel.id
    //     : data.salesAccountId;

    // dispatch(
    //   change(
    //     "InvoiceFormView",
    //     `clientInvoiceDetails[${index}].accountId`,
    //     accId
    //   )
    // );
    let taxCodeId =
      typeof data.saleTaxId === "undefined"
        ? taxList.length > 0
          ? taxList[0].id
          : ""
        : data.saleTaxId;
    console.log(taxCodeId);
    setFormValue(`${row}.taxCodeId`, taxCodeId);
    let taxRate = getTaxRate(taxCodeId);
    console.log(taxRate);
    setFormValue(`${row}.taxRate`, taxRate);
    setFormValue(`${row}.discountAmount`, 0.0);

    const initQuantity =
      typeof data.saleUnitPrice !== "undefined"
        ? data.saleUnitPrice * 1
        : 0.0;

    setFormValue(`clientInvoiceDetails[${index}].totalAmount`, initQuantity);

    calculateAmount();
  };
  return (
    <React.Fragment key={index}>
      <TableRow>
        <TableCell align="center" style={{ minWidth: 210 }}>
          <Field
            name={`${row}.productId`}
            options={productList}
            onDataSelect={(data) => {
              onChangeProductHandler(data);
              // resetRowAfterProductSelect(data);
            }}
            component={SolpayReactSelectIconWithoutLabel}
            Addlabel="Add a new Product"
            iconEvent={handleShow}
          />
          <Field name={`${row}.productId`} component={InputHidden} />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 180 }}>
          <Field
            name={`${row}.itemDesctiption`}
            component={InputFieldWithoutLabelMui}
            type="text"
            label="Description"
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field
            label="Quantity"
            name={`${row}.quantity`}
            component={InputFieldWithoutLabelMui}
            onChange={() => {
              calculateAmount();
            }}
          />
        </TableCell>
        <TableCell align="center" style={{ minWidth: 110 }}>
          <Field
            label="Unit Price"
            name={`${row}.unitPrice`}
            component={InputFieldWithoutLabelMui}
            onChange={() => {
              calculateAmount();
            }}
          />
        </TableCell>
        {/* <TableCell align="center" style={{ minWidth: 170 }}>
          <Field
            name={`${field}.accountId`}
            component={SolpayReactSelectWithoutLabelForm}
            onDataSelect={(data) => {
              dispatch(
                change(
                  "InvoiceFormView",
                  `clientInvoiceDetails[${index}].accountId`,
                  data.value
                )
              );
            }}
            options={accountList}
            validate={!`${field}.productId` && [required]}
          />
          <Field component={InputHidden} name="accountId"></Field>
        </TableCell> */}
        <TableCell align="center" style={{ minWidth: 170 }}>
          <Field
            hidden={true}
            name={`${row}.taxRate`}
            component={InputHidden}
          />
          <Field
            name={`${row}.taxCodeId`}
            component={SolpayReactSelectWithoutLabelForm}
            // onDataSelect={(data) => {
            //   onChangeTaxCode(data);
            // }}
            onChange={onChangeTaxCode}
            options={taxList}
            validate={!`${row}.productId` && [required]}
          />
          <Field component={InputHidden} name="taxCodeId" />
        </TableCell>
        <TableCell align="center">
          <Field
            label="Amount"
            name={`${row}.totalAmount`}
            component={InputFieldWithoutLabelMui}
            onChange={() => {
              calculateAmount();
            }}
          />
        </TableCell>
        <TableCell align="center">
          <Stack direction="row">
            <IconButton
              color="secondary"
              aria-label="add"
              onClick={() => {
                fields.push();
                //console.log(fields.length);
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              color="secondary"
              aria-label="remove"
              onClick={() => {
                let id = fields.get(index) ? fields.get(index).id : 0;
                //removeRow(index);
                fields.remove(index);
                if (fields.length === 1) {
                  fields.push();
                }
                // calculateAmount();
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Stack>
        </TableCell>
      </TableRow>
      {show && (
        <SolpayModal
          onClose={handleClose}
          title="Add Product"
          data={
            <ProductFormView
              showInModal={show}
              productId="0"
              pageTitle="Add Product"
              setProduct={(data) => {
                updateProductList();
                setFormValue(`${row}.productId`, data.data.id);
                onChangeProductHandler(data.data);
                handleClose();
              }}
            />
          }
        />
      )}
    </React.Fragment>
  );
};
//export default connect(null, mapDispatchToProps)(InvoiceDetailRow);
