import {
  Box,
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";

import { actions as ticketActions } from "@redux/ticket/ticketRedux";
import ticketDataAccess from "dataAccess/ticket/ticketDataAccess";
import { InputHidden } from "libComp/common/inputRenderes";
import { SolpayTicketReplyTextArea } from "libComp/common/solpayTicketReplyTextArea";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import { APIResponse } from "models/response";
import moment from "moment";
import { useSnackbar } from "notistack";
import PageBreadcrumb from "pageBreadcrumbs/pageBreadcrumb";
import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

function SupportTicketFormView(props) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const dispatch = useDispatch();
  const ticketId = props.match.params.id;

  const commonState = useSelector((state: IReduxState) => state.commonState);

  function onSaveReplyFormSubmit(model) {
    model.supportTicketId = model.id;
    return ticketDataAccess
      .saveReplies(model)(commonState.commonActions)
      .then((data) => {
        const response: APIResponse = data;
        if (response.success)
          enqueueSnackbar("Reply has been saved!", { variant: "success" });
        else
          enqueueSnackbar("Reply could not been saved!", { variant: "error" });
        props.history.push("/admin/tickets");
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  }

  React.useEffect(() => {
    dispatch(ticketActions.getTicket(ticketId));
  }, [ticketId]);

  const handleResolved = () => {
    ticketDataAccess
      .resolvedTicket(ticketId)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          enqueueSnackbar("Ticket has been resolved!", { variant: "success" });
          dispatch(ticketActions.getTicket(ticketId));
        } else {
          throw new Error(data.message);
        }
      })
      .catch((error) => {
        enqueueSnackbar(error.message, { variant: "error" });
      });
  };

  // Breadcrumb
  let MenuItems = [];
  MenuItems = [
    {
      title: "Tickets",
      to: "/admin/tickets",
    },
    {
      title: "Ticket Details",
      to: "/accounting/purchase/bill/addedit/{billId}",
    },
  ];

  const style = useStyleClass();

  return (
    <>
      <PageBreadcrumb menuItem={MenuItems} />
      <Stack sx={{ width: "96%", mx: "auto" }}>
        <PageTitleHeader header={"Support Token"} />
      </Stack>
      <Box
        sx={{
          flex: 1,
          width: "96%",
          mx: "auto",
          border: 1,
          borderRadius: 1,
          borderColor: "grey.200",
          p: 2,
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            px: { xs: 2, sm: 2, md: 4, lg: 6, xl: 6 },
          }}
        >
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                sx={{ mt: 0.5 }}
              >
                Ticket No:
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.ticket.ticketNo}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                sx={{ mt: 0.5 }}
              >
                Type:
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.ticket.type}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                sx={{ mt: 0.5 }}
              >
                Resolved?:
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.ticket.resolved ? "Yes" : "No"}
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6} spacing={2}>
            <Button
              onClick={handleResolved}
              classes={{ root: style.button }}
              variant="contained"
              type="submit"
              disabled={props.ticket.resolved}
            >
              Resolved
            </Button>
            <Stack direction="row" spacing={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                sx={{ mt: 0.5 }}
              >
                Posted By:
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.ticket.createdByName}
              </Typography>
            </Stack>

            <Stack direction="row" spacing={2}>
              <Typography
                variant="subtitle2"
                gutterBottom
                component="div"
                sx={{ mt: 0.5 }}
              >
                Resolved On:
              </Typography>
              <Typography variant="subtitle1" gutterBottom component="div">
                {props.ticket.resolvedOn
                  ? moment(props.ticket.resolvedOn as any).format(
                      "DD-MM-yyyy HH:mm:ss A"
                    )
                  : "Not Resolved Yet"}
              </Typography>
            </Stack>
          </Grid>
        </Grid>

        <Box sx={{ minWidth: "100%" }}>
          <Card>
            <CardContent
              sx={{
                p: 2,
              }}
            >
              <Typography
                color="text.secondary"
                gutterBottom
                sx={{
                  fontSize: 16,
                  p: 2,
                  backgroundColor: "#4196EB",
                  color: "#fff",
                  borderRadius: 1,
                }}
              >
                <strong>Conversation</strong>
              </Typography>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Typography sx={{ my: 1.5, mx: 2 }} color="text.secondary">
                  {props.ticket.createdByName} ({props.ticket.tenant})
                </Typography>

                <Typography sx={{ my: 1.5, mx: 2 }} color="text.secondary">
                  Posted On:
                  {moment(props.ticket.createdOn as any).format(
                    "DD-MM-yyyy HH:mm:ss A"
                  )}
                </Typography>
              </Stack>
              <Typography
                sx={{ mx: 2 }}
                variant="subtitle1"
                component="div"
                color="text.secondary"
              >
                <strong>Subject: {props.ticket.subject}</strong>
              </Typography>
              <Typography sx={{ mt: 1.5, mx: 2 }} color="text.secondary">
                {props.ticket.body}
              </Typography>
            </CardContent>
            <Divider />
            <Box sx={{ m: 2, p: 2, mt: 1 }}>
              <Typography sx={{ m: 2 }} color="text.secondary">
                <strong>Replies:</strong>
              </Typography>

              {props.ticket?.supportTicketReplies?.map((elem, idx) => (
                <>
                  <Stack
                    sx={{
                      backgroundColor: "#d6d6ff",
                      px: 2,
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Typography sx={{ my: 1.5, mx: 2 }} color="text.secondary">
                      {elem.createdByName} ({elem.tenant})
                    </Typography>

                    <Typography sx={{ my: 1.5, mx: 2 }} color="text.secondary">
                      Posted On:
                      {moment(elem.createdOn as any).format(
                        "DD-MM-yyyy HH:mm:ss A"
                      )}
                    </Typography>
                  </Stack>
                  <Typography sx={{ mt: 1.5, mx: 2 }} color="text.secondary">
                    {elem.message}
                  </Typography>
                  <br />
                </>
              ))}
            </Box>
          </Card>
        </Box>
        <br />
        <Box
          sx={{
            "& .MuiTextField-root": { m: 1, width: "100%" },
          }}
        >
          <form
            onSubmit={props.handleSubmit((data) => {
              onSaveReplyFormSubmit(data);
            })}
            noValidate
          >
            <Field component={InputHidden} name="isSuperAdmin" />

            <Field
              name="message"
              component={SolpayTicketReplyTextArea}
              label="Reply"
            />
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                disabled={props.submitting}
                classes={{ root: style.button }}
                sx={{ m: 2 }}
                variant="contained"
                type="submit"
              >
                Reply
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
}

const addEditSupportTicket = reduxForm({
  form: "SupportTicketFormView",
  enableReinitialize: true,
})(SupportTicketFormView);

// selector = formValueSelector("SupportTicketFormView");

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getTicket: () =>
      dispatch(ticketActions.getTicket(ownProps.match.params.id)),
  };
};

const mapStateToProps = (state, ownProps) => ({
  ...state.ticket,
  initialValues: {
    //type: state.ticket.type,
    ...state.ticket.ticket,
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(addEditSupportTicket);
