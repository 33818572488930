import {
  CLEAN_SUPPLIER,
  GET_SUPPLIER,
  SUPPLIER_ADD_TO_LIST,
  SUPPLIER_FAILED,
  SUPPLIER_LOADING,
  SUPPLIER_REMOVE_UI_MESSAGE,
  SUPPLIER_SELECTED,
} from './supplierTypes';
import { Dispatch } from '@reduxjs/toolkit';
import { IReduxState } from '@redux';
import supplierDataAccess from 'dataAccess/contact/supplierDataAccess';
import { initialize } from 'redux-form';

export const getSuppliers =
  (pageNo = 1, pageSize = 2, filters = [], sortPreference = 'id-desc') =>
  (dispatch: Dispatch, getState) => {
    dispatch({ type: SUPPLIER_LOADING });
    const state: IReduxState = getState();
    supplierDataAccess
      .getSupplierList(
        pageNo,
        pageSize,
        filters,
        sortPreference
      )(state.commonState.commonActions)
      .then((supplierData) => {
        dispatch({
          type: SUPPLIER_ADD_TO_LIST,
          payload: {
            list: supplierData.data.list,
            totalCount: supplierData.data.totalCount,
          },
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: SUPPLIER_FAILED,
          payload: error.message,
        });
      });
  };

export const suppliserUiMessage = (messageIndex) => (dispatch: Dispatch) => {
  dispatch({ type: SUPPLIER_REMOVE_UI_MESSAGE, payload: messageIndex });
};
export const setSelectedSupplier = (row) => (dispatch: Dispatch) => {
  dispatch({ type: SUPPLIER_SELECTED, payload: row });
};
export const getSupplierById =
  (id: string, type: string) => (dispatch: Dispatch, getState) => {
    dispatch({ type: SUPPLIER_LOADING });
    const state: IReduxState = getState();
    supplierDataAccess
      .getSupplier(
        id,
        type
      )(state.commonState.commonActions)
      .then((supplierData) => {
        dispatch({
          type: GET_SUPPLIER,
          payload: supplierData.data,
        });
      })
      .catch((error: Error) => {
        dispatch({
          type: SUPPLIER_FAILED,
          payload: error.message,
        });
      });
  };
export const clearSupplireList =
  (supplierId: string) => (dispatch: Dispatch) => {
    dispatch({ type: CLEAN_SUPPLIER, payload: supplierId });
  };
