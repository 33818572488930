import {
  IJournalState,
  reducer as journalReducer
} from "@redux/bkp/transactionRedux";
import { ICommonState, reducer as commonReducer } from "@redux/commonRedux";
import { IClientState, reducer as clientReducer } from "@redux/crm/clientRedux";
import {
  IEmployeeState,
  reducer as employeeReducer
} from "@redux/emp/employeeRedux";
import {
  IInvoiceState,
  reducer as invoiceReducer
} from "@redux/inv/invoiceRedux";
import {
  IProductState,
  reducer as productReducer
} from "@redux/inv/productRedux";
import { IListState, reducer as listReducer } from "@redux/listRedux";
import {
  IPaymentState,
  reducer as paymentReducer
} from "@redux/pay/paymentRedux";
import {
  IPaymentHeadState,
  reducer as paymentHeadReducer
} from "@redux/pyr/paymentHeadRedux";
import {
  ISuperAnnuationState,
  reducer as superAnnuationReducer
} from "@redux/pyr/superAnnuationRedux";
import {
  ISuperFundState,
  reducer as superFundReducer
} from "@redux/pyr/superFundRedux";
import { ITenantState, reducer as tenantReducer } from "@redux/tnt/tenantRedux";
import { IUserState, reducer as userReducer } from "@redux/user/userRedux";
import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { IBillState, reducer as BillReducer } from "./bill/billReducer";
import { ITaxState, reducer as taxReducer } from "./bkp/tax/taxReducer";
import {
  ISupplierState,
  reducer as supplierReducer
} from "./contact/supplierReducer";
import { IPayRunState, reducer as payRunReducer } from "./pyr/payRunRedux";
import { IAgentState, reducer as agentReducer } from "./agent/agentRedux";
import {
  IQuotationState,
  reducer as QuotationReducer
} from "./quotation/quotationReducer";
import {
  ITicketState,
  ticketReducer as TicketReducer
} from "./ticket/ticketRedux";
import {
  IUnsubscritionState,
  reducer as unsubscriptionReducer
} from "./unsubscription/unsubscriptionRedux";
import {
  IUserListState,
  reducer as userListReducer
} from "./user/userListRedux";

export interface IReduxState {
  user: IUserState;
  userList: IUserListState;
  agentList : IAgentState;
  client: IClientState;
  employee: IEmployeeState;
  product: IProductState;
  paymenthead: IPaymentHeadState;
  invoice: IInvoiceState;
  payrun: IPayRunState;
  payment: IPaymentState;
  journal: IJournalState;
  listState: IListState;
  tenant: ITenantState;
  superFund: ISuperFundState;
  unsubscription: IUnsubscritionState;
  ticket: ITicketState;
  superannuation: ISuperAnnuationState;
  commonState: ICommonState;
  tax: ITaxState;
  bill: IBillState;
  supplier: ISupplierState;
  quotation: IQuotationState;
  form: any;
}
export default combineReducers({
  user: userReducer,
  userList: userListReducer,
  agentList : agentReducer,
  client: clientReducer,
  employee: employeeReducer,
  product: productReducer,
  paymenthead: paymentHeadReducer,
  invoice: invoiceReducer,
  payment: paymentReducer,
  payrun: payRunReducer,
  journal: journalReducer,
  listState: listReducer,
  superFund: superFundReducer,
  tenant: tenantReducer,
  superannuation: superAnnuationReducer,
  commonState: commonReducer,
  tax: taxReducer,
  bill: BillReducer,
  ticket: TicketReducer,
  supplier: supplierReducer,
  quotation: QuotationReducer,
  form: formReducer,
  unsubscription: unsubscriptionReducer,
});
