import { ICommonActions } from '@redux/commonRedux';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import { apiPrefix, apiUrl } from 'common/config';
import { localStorageKeys } from 'common/constants';
import commonDataAccess from 'dataAccess/commonDataAccess';
class customerDataAccess {
    base64toBlob = (data: string) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };
    getAllTimePeriod = () => (commonActions: ICommonActions) => {
        return commonDataAccess.getData('Report/gettimeperiod')(commonActions);
    };

    getCustomerStatement = (model) => (commonActions: ICommonActions) => {
        return Axios.post(apiUrl + apiPrefix + 'ClientInvoice/customerstatement', model, {
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf;base64',
                Authorization: `bearer ${localStorage.getItem(
                    localStorageKeys.authorization
                )}`,
            },
        })
            .then((response: AxiosResponse) => {
                if (response.data) {
                    const blob = this.base64toBlob(response?.data?.fileContents);
                    const url = URL.createObjectURL(blob);
                    return url;
                } else {
                    throw new Error(response.data);
                }
            })
            .catch((error: AxiosError) => {
                throw new Error(error.message);
            });
    };
    getClientList = (term: string) => (commonActions: ICommonActions) => {
        return commonDataAccess.getData('ClientInvoice/getclientlist', {
            term: term
        })(
            commonActions
        );
    };
}
export default new customerDataAccess();
