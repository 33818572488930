import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

function HomeVideo() {
  return (
    <div className="section">
      <Container>
        <Row>
          <Col sm={7} md={7} xs={12}>
            <div className="embed-responsive embed-responsive-16by9">
              <iframe
                className="embed-responsive-item"
                width="816"
                height="500"
                src="https://www.youtube.com/embed/_htHpZrJq-k?rel=0"
                allowFullScreen
              ></iframe>
            </div>
          </Col>
          <Col sm={5} md={5} xs={12}>
            <div className="video-right">
              <h1>
                No more Payroll <br />& Invoicing stress.
              </h1>
              <p className="">
                Find out how Solpay can help you manage your Payroll & Invoicing
                tasks on the go so you can concentrate on what matters most.{' '}
                <span style={{ color: '#167ac6' }}>Your Business!</span>{' '}
              </p>
              <br />
              <a href="/sign-up">Try Solpay FREE for 30 Days</a>
              <p className="mt-4">Immediate access. No credit card required.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default HomeVideo;
