import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import { IReduxState } from "@redux";
import {
  actions as superActions,
  ISuperAnnuationState,
} from "@redux/pyr/superAnnuationRedux";
import superAnnuationDataAccess from "dataAccess/pyr/superAnnuationDataAccess";
import { InputFieldOneRowReadOnly } from "libComp/common/inputRenderes";
import PageTitleHeader from "libComp/sections/pageTitleHeader";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Field, reduxForm, SubmissionError } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";

function ReviewSuperAuthorizer(props) {
  const commonState = useSelector((state: IReduxState) => state.commonState);
  const superannuation: ISuperAnnuationState = props.superannuation;
  const superregistrationId = props.match.params.id;
  const hasToUpdate = props.match.params.hastoupdate;
  const dispatch = useDispatch();
  const [uiMessage, setUiMessage] = useState([]);

  useEffect(() => {
    if (superregistrationId) {
      dispatch(
        superActions.getSuperAuthoriser(superregistrationId, hasToUpdate)
      );
    }
  }, [superregistrationId, hasToUpdate]);
  useEffect(() => {
    setUiMessage(props.uiMessages);
  }, [props.uiMessages]);

  const reviewSuperAuthoriser = (model) => {
    superAnnuationDataAccess
      .reviewSuperAuthoriser(model)(commonState.commonActions)
      .then((data) => {
        if (data.success) {
          props.history.push(
            "/payroll/reviewbankdetails/" + data.data.id + "/" + hasToUpdate
          );
        } else toast.error(data.message);
      })
      .catch((error: Error) => {
        throw new SubmissionError({ _error: error.message });
      });
  };

  const steps = ["Organization", "Authoriser", "Bank", "Billing Contact"];

  const style = useStyleClass();

  return (
    <React.Fragment>
      <div className="page-container">
        <PageTitleHeader header="Set Up Superannuation" />

        {uiMessage &&
          uiMessage.map((item, index) => (
            <>
              <>
                <Alert
                  key={index}
                  variant="filled"
                  severity="warning"
                  onClose={() => {
                    setUiMessage([]);
                  }}
                >
                  <Typography
                    variant="body1"
                    dangerouslySetInnerHTML={{
                      __html: item.messageText,
                    }}
                  />
                </Alert>
                <br />
              </>
            </>
          ))}
        <Box sx={{ width: "100%" }}>
          <Stepper alternativeLabel activeStep={1}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <br />
          <form
            noValidate
            onSubmit={props.handleSubmit((model) =>
              reviewSuperAuthoriser(model)
            )}
          >
            <Typography
              variant="h5"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
              }}
            >
              Who will be charge of approving the payments
            </Typography>
            <Typography
              variant="subtitle2"
              component="div"
              sx={{
                textAlign: "center",
                fontWeight: 500,
                mb: 4,
              }}
            >
              This person must be a Payroll Admin and have permission to make
              banking decisions on behalf of the business
            </Typography>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              sx={{ width: "90%" }}
            >
              <Grid item xs={12} sm={12} md={6}>
                <Field
                  name="systemAdminFirstName"
                  label="First Name"
                  disabled={true}
                  component={InputFieldOneRowReadOnly}
                />
                <Field
                  disabled={true}
                  name="systemAdminLastName"
                  label="Last Name"
                  component={InputFieldOneRowReadOnly}
                />
                <Field
                  disabled={true}
                  name="systemAdminEmail"
                  label="Admin Email"
                  component={InputFieldOneRowReadOnly}
                />
                <Field
                  disabled={true}
                  name="systemAdminContactNumber"
                  label="Contact Number"
                  component={InputFieldOneRowReadOnly}
                />
                <Divider />
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <Button
                    classes={{ root: style.button }}
                    variant="contained"
                    sx={{ mr: 1 }}
                    onClick={() => {
                      props.history.push("/payroll/reviewbusiness");
                    }}
                  >
                    Back
                  </Button>
                  {props.superAuthoriser.systemAdminFirstName &&
                    props.superAuthoriser.systemAdminEmail &&
                    props.superAuthoriser.systemAdminContactNumber && (
                      <>
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button
                          sx={{ mr: 1 }}
                          classes={{ root: style.button }}
                          variant="contained"
                          type="submit"
                        >
                          Next
                        </Button>
                      </>
                    )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </div>
    </React.Fragment>
  );
}

const reviewSuperAuthorizer = reduxForm<any>({
  form: "ReviewSuperAuthorizer",
  enableReinitialize: true,
})(ReviewSuperAuthorizer);

const mapStatetoProps = (state: IReduxState, ownProops) => ({
  superannuation: state.superannuation,
  superAuthoriser: state.superannuation.superAuthoriser,
  uiMessages: state.superannuation.uiMessages,
  initialValues: { ...state.superannuation.superAuthoriser },
});

export default connect(mapStatetoProps)(reviewSuperAuthorizer);
