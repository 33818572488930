import { ICommonActions } from "@redux/commonRedux";
import { IFilter } from "@redux/listRedux";
import commonDataAccess from "dataAccess/commonDataAccess";
class tenantDataAccess {
  getAll =
    (type, filters: Array<IFilter>, pageNo, pageSize, sortPreference) =>
    (commonActions: ICommonActions) => {
      let _filters = {};
      if (Array.isArray(filters)) {
        filters.forEach((item) => {
          if (item.value) {
            _filters["filter." + item.propertyName] =
              item.value + "-" + item.type;
          }
        });
      }
      return commonDataAccess.getData("Tenant/getlist", {
        type,
        ..._filters,
        pageNo,
        pageSize,
        sortPreference,
      })(commonActions);
    };

  getById = (id) => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Tenant/getbyid", { id })(commonActions);
  };
  saveOrganization = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData("Tenant/save", data)(commonActions);
  };
  getBusinessInfo = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Tenant/getbusinessinfo")(commonActions);
  };
  getPayerInfo = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Tenant/getpayerinfo")(commonActions);
  };
  getSysAdminsInfo = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Tenant/getsystemadministrator")(
      commonActions
    );
  };
  getBillingContactInfo = () => (commonActions: ICommonActions) => {
    return commonDataAccess.getData("Tenant/getbillingcontact")(commonActions);
  };
  saveBusinessInfo = (businessinfo) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Tenant/savebusinessinfo",
      businessinfo
    )(commonActions);
  };
  savePayerInfo = (payerinfo) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Tenant/savepayer",
      payerinfo
    )(commonActions);
  };
  saveSysAdminsInfo = (admininfo) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Tenant/savesystemadministrator",
      admininfo
    )(commonActions);
  };
  saveBillingContactInfo = (billinginfo) => (commonActions: ICommonActions) => {
    return commonDataAccess.postData(
      "Tenant/savebillingcontact",
      billinginfo
    )(commonActions);
  };
}

export default new tenantDataAccess();
