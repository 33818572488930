import {
  FormControl,
  Grid,
  Radio,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ReduxFieldProps } from "./fied-prop.type";

export const solpayCheckBoxForFormColumn = ({
  input,
  label,
  className,
  id,
  defaultChecked,
}) => (
  <Form.Group>
    <Row>
      <Col md={5}></Col>
      <Col md={7}>
        <input
          className="css-checkbox"
          id={id ?? "chk"}
          checked={input.value}
          onChange={() => {
            input.value = !input.value;
          }}
          defaultChecked={defaultChecked}
          type="checkbox"
        />
        {/* <Form.Check
      {...input}
      id={id}
      className={className}
      type="checkbox"
      checked={input.value}
      label={label}
    /> */}
        <label className="css-label" htmlFor={id ?? "chk"}>
          <i className="icon-coffee"></i>
          {label}
        </label>
      </Col>
    </Row>
  </Form.Group>
);

export const SolpayCheckBox = (props) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  const labelAlign = config.labelAlign ? config.labelAlign : "right";

  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        flexDirection: layout === "horizontal" ? "row" : "column",
        marginBottom: "21px",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={props.labelCol ? props.labelCol : 4}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 7
        }
      >
        <Switch
          {...props.input}
          checked={props.input.value === true ? true : false}
        />
      </Grid>
    </Grid>
  );
};

export const SolpayRadio = (props) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  const labelAlign = config.labelAlign ? config.labelAlign : "right";
  const [value, setValue] = React.useState(props?.input?.value);

  return (
    <Grid
      container
      spacing={1}
      style={{
        display: "flex",
        flexDirection: layout === "horizontal" ? "row" : "column",
        marginBottom: "21px",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={props.labelCol ? props.labelCol : 4}
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 7
        }
      >
        <Radio
          {...props.input}
          // value={props.input.value ? true : false}
          onChange={props.onChange}
          value={props.label}
        />
      </Grid>
    </Grid>
  );
};

export const SolpayInputField = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  mdI,
  smI,
  style,
  child,
  meta: { touched, error, warning },
}) => (
  <Form.Group style={style}>
    <Row>
      <Col sm={smL ? smL : 5} md={mdL ? mdL : 5}>
        <Form.Label className="control-label">{label}</Form.Label>
      </Col>
      <Col
        sm={smI ? smI : smL ? 12 - smL : 7}
        md={mdI ? mdI : mdL ? 12 - mdL : 7}
      >
        <Form.Control
          {...input}
          as={type}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
      {child}
    </Row>
  </Form.Group>
);

export const SolpayInputFieldOneRow = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  mdI,
  smI,
  style,
  child,
  meta: { touched, error, warning },
}) => (
  <Form.Group style={style}>
    <Row>
      <Col sm={smL ? smL : 5} md={mdL ? mdL : 5}>
        <Form.Label className="control-label">{label}</Form.Label>
      </Col>
      <Col
        sm={smI ? smI : smL ? 12 - smL : 7}
        md={mdI ? mdI : mdL ? 12 - mdL : 7}
      >
        <Form.Control
          {...input}
          as="input"
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
      {child}
    </Row>
  </Form.Group>
);

// export const SolpayAsyncInputFieldOneRow = ({
//   input,
//   label,
//   placeholder,
//   type,
//   disabled,
//   mdL,
//   smL,
//   mdI,
//   smI,
//   style,
//   child,
//   meta: { asyncValidating, touched, error, warning },
// }) => (
//   <Form.Group style={style}>
//     <Row>
//       <Col sm={smL ? smL : 5} md={mdL ? mdL : 5}>
//         <Form.Label className="control-label">{label}</Form.Label>
//       </Col>
//       <Col
//         sm={smI ? smI : smL ? 12 - smL : 7}
//         md={mdI ? mdI : mdL ? 12 - mdL : 7}
//       >
//         <div className={asyncValidating ? "async-validating" : ""}>
//           <Form.Control
//             {...input}
//             as="input"
//             type={type}
//             disabled={disabled}
//             placeholder={placeholder}
//           />

//           {touched && error && (
//             <Form.Control.Feedback style={{ display: "block" }} type="invalid">
//               {error}
//             </Form.Control.Feedback>
//           )}
//         </div>
//       </Col>
//       {child}
//     </Row>
//   </Form.Group>
// );

export const SolpayAsyncInputFieldOneRow = (props: ReduxFieldProps) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: layout === "horizontal" ? "100%" : "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: layout === "horizontal" ? "100%" : "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            label={props.label}
            size="small"
            type={props.type}
            required={props.required}
            value={props.input?.value}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
