import { Delete, Edit } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { GridColumns } from "@mui/x-data-grid";
import { SolpayLinearProgressBar } from "libComp/ProgressBar/SolpayLinearProgress";
import { formatDate } from "libComp/utility.service";

export const getEmployeesTable = (props, extra = null) => {
  const columns: GridColumns = [
    {
      headerName: "Full Name",
      field: "fullName",
    },
    {
      headerName: "Profile Completeness",
      field: "completenessPerc",
      sortable: false,
      renderCell: (params) => {
        return <SolpayLinearProgressBar value={params.row?.completenessPerc} />;
      },
    },
    {
      headerName: "Joining Date",
      field: "joiningDate",
      width: 130,
      renderCell: (params) => {
        return formatDate(params.row?.joiningDate);
      },
    },
    {
      headerName: "Job Title",
      field: "currentPosition",
      sortable: false,
    },
    {
      headerName: "Phone",
      field: "homePhone",
      sortable: false,
    },
    {
      headerName: "Mobile",
      field: "mobile",
      sortable: false,
    },
    {
      headerName: "Email",
      field: "email",
      sortable: false,
    },
    {
      headerName: "Active",
      field: "active",
      width: 80,
      align: "center",
      sortable: false,
    },
    {
      headerName: "Actions",
      field: "",
      sortable: false,
      width: 85,
      align: "center",
      renderCell: (params) => {
        return (
          <div>
            <IconButton
              title="Edit"
              size="small"
              onClick={() => {
                props.history.push(
                  "/payroll/employee/addedit/" + params.row.id
                );
              }}
            >
              <Edit />
            </IconButton>
            <IconButton
              title="Delete"
              size="small"
              onClick={() => {
                extra?.onDelete(params.row.id);
              }}
            >
              <Delete />
            </IconButton>
          </div>
        );
      },
    },
  ];
  return columns;
};
