import DeleteIcon from "@mui/icons-material/Delete";
import {
  Autocomplete,
  Box,
  Button,
  createFilterOptions,
  FormControl,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import React from "react";
import { Col, Form, InputGroup, ListGroup, Row } from "react-bootstrap";
import { useStyleClass } from "styles/theming/useStyleClass";
import CustomDatePicker, { CustomDatePickerReadonly } from "./dataPicker";
import InputAdornment from '@mui/material/InputAdornment';
import { ReduxFieldProps } from "./fied-prop.type";

export const InputField = ({
  input,
  label,
  placeholder,
  type,
  meta: { touched, error, warning },
}) => (
  <Form.Group>
    <Form.Label style={{ minHeight: "17px", minWidth: "10px" }}>
      {label}
    </Form.Label>
    <Form.Control {...input} as="input" type={type} placeholder={placeholder} />
    {touched && error && (
      <Form.Control.Feedback style={{ display: "block" }} type="invalid">
        {error}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);
export const MuiInput = (props: ReduxFieldProps) => {
  return (
    <FormControl
      {...props.input}
      style={{ width: "100%", marginBottom: "14px" }}
    >
      <TextField
        error={props.meta.error && !props.meta.valid}
        helperText={props.meta.error}
        label={props.label}
        size="small"
        id="input-text"
        type={props.type}
        required={props.required}
      />
    </FormControl>
  );
};
export const InputHidden = ({ input }) => (
  <Form.Control {...input} type="hidden" />
);

export const InputFieldOneRow = (props) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  const endAdornment = props.endAdornment;
  const style = useStyleClass();
  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            classes={{ root: style.inputStyle }}
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            placeholder={props.label}
            size="small"
            type={props.type}
            required={props.required}
            value={props.input?.value}
            InputLabelProps={{ shrink: true }}
            autoComplete="off"
            InputProps={{
              endAdornment: <InputAdornment position="end">{endAdornment ? endAdornment : ""}</InputAdornment>,
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const InputFieldOneRowReadOnly = (props: ReduxFieldProps) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  const style = useStyleClass();

  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            classes={{ root: style.inputStyle }}
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            size="small"
            disabled
            type={props.type}
            required={props.required}
            placeholder={props.label}
            value={props.input?.value}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              readOnly: true,
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const InputFieldOneRowBoderLess = (props: ReduxFieldProps) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  const style = useStyleClass();

  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <h2
          style={{
            fontSize: "14px",
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </h2>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              "& .Mui-focused": { borderColor: "white" },
              "& .MuiOutlinedInput-root": {
                "&:hover": {
                  borderColor: "white",
                },
                "& .Mui-focused": { borderColor: "white" },
                "& > fieldset": { borderColor: "white" },
              },
            }}
            classes={{ root: style.inputStyle }}
            variant="outlined"
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            type={props.type}
            required={props.required}
            placeholder={props.label}
            value={props.input?.value}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              readOnly: true,
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const InputFieldTwoRow = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  mdI,
  smI,
  meta: { touched, error, warning },
}) => (
  <Form.Group>
    <Form.Label className="control-label">{label}</Form.Label>

    <Form.Control
      {...input}
      as="input"
      type={type}
      disabled={disabled}
      placeholder={placeholder}
    />
    {touched && error && (
      <Form.Control.Feedback style={{ display: "block" }} type="invalid">
        {error}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);

const useStyles = makeStyles({
  root: {
    "& .MuiInputBase-root": {
      "& .MuiInputBase-input": {
        padding: "7.5px 14px",
      },
    },
    "& .MuiInputLabel-formControl ": {
      top: "-8px",
    },
    "& .Mui-focused": {
      top: "0px",
    },
    "& .MuiInputLabel-shrink": {
      top: "0px",
    },
    "& .MuiOutlinedInput-root": {
      padding: 0,
    },

    "& label.Mui-focused": {
      display: "none",
    },
    "& legend": {
      display: "none",
    },
    "& .MuiInputLabel-root": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: 0,
    },
    inputPlaceholderStyle: {
      "&::placeholder": {
        fontSize: "14px",
      },
    },
  },
});

export const AutoCompleteInputFieldOneRowMui = (props) => {
  const classes = useStyles();

  const [value, setValue] = React.useState<string | null>(null);
  const [inputValue, setInputValue] = React.useState(props.input.value);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option: any) => option.value,
  });

  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        <FormControl
          {...props.input}
          style={{ width: "100%" }}
          classes={{ root: classes.root }}
        >
          <Autocomplete
            classes={{ root: classes.root }}
            freeSolo
            value={value}
            onChange={(event: any, newValue: string | null) => {
              // setValue(newValue);
              props.onDataSelect(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            getOptionLabel={(option) => option.text}
            renderOption={(props, option, { selected }) => (
              <li {...props} key={option?.id}>
                {/* <Checkbox
                  icon={icon}
                  checkedIcon={checkedIcon}
                  style={{ marginRight: 8 }}
                  checked={selected}
                /> */}
                {option.value}
              </li>
            )}
            // isOptionEqualToValue={(option, value) => option.id === value.id}
            filterOptions={filterOptions}
            disablePortal
            id="combo-box"
            options={props.dataSource}
            renderInput={(params) => (
              <TextField {...params} label={props.label} />
            )}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const AutoCompleteInputFieldOneRow = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  mdI,
  smI,
  dataSource,
  onDataSelect,
  child,
  meta: { touched, error, warning, active, asyncValidating },
}) => {
  return (
    <Form.Group>
      <Row>
        <Col sm={smL ? smL : 5} md={mdL ? mdL : 5}>
          <Form.Label className="control-label">{label}</Form.Label>
        </Col>
        <Col
          sm={mdI ? mdI : smL ? 12 - smL : 7}
          md={mdI ? mdI : mdL ? 12 - mdL : 7}
          style={{ position: "relative" }}
        >
          <Form.Control
            {...input}
            as="input"
            autoComplete="off"
            type={type}
            disabled={disabled}
            placeholder={placeholder}
          />

          <div className={asyncValidating ? "async-validating" : ""}></div>
          {active && (
            <ListGroup className="auto-complete-container">
              {dataSource.map((data, index) => (
                <ListGroup.Item
                  action
                  type="button"
                  onMouseDown={() => {
                    onDataSelect(data);
                  }}
                  key={index}
                >
                  {data.value}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
          {touched && error && (
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </Col>
        {child && (
          <Col md={2} className="pl-0">
            {child}
          </Col>
        )}
      </Row>
    </Form.Group>
  );
};

export const AutoCompleteInputFieldOneRowWithoutlabel = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdI,
  smI,
  child,
  dataSource,
  onDataSelect,
  meta: { touched, error, warning, active, asyncValidating },
}) => {
  return (
    <Row>
      <Col
        sm={smI ? smI : 12}
        md={mdI ? mdI : 12}
        style={{ position: "relative" }}
      >
        <Form.Control
          {...input}
          as="input"
          autoComplete="off"
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
        <div className={asyncValidating ? "async-validating" : ""}></div>
        {active && (
          <ListGroup className="auto-complete-container">
            {dataSource.map((data, index) => (
              <ListGroup.Item
                action
                type="button"
                onMouseDown={() => {
                  onDataSelect(data);
                }}
                key={index}
              >
                {data.value}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
      {child && (
        <Col
          sm={12 - (smI ? smI : 2)}
          md={12 - (mdI ? mdI : 2)}
          className="pl-0"
        >
          {child}
        </Col>
      )}
    </Row>
  );
};

export const InputFieldOneRowAsyncValidation = ({
  input,
  label,
  placeholder,
  type,
  mdL,
  smL,
  meta: { asyncValidating, touched, error, warning },
}) => (
  <Form.Group>
    <Row>
      <Col sm={smL ? smL : 5} md={mdL ? mdL : 5}>
        <Form.Label className="control-label">{label}</Form.Label>
      </Col>
      <Col sm={smL ? 12 - smL : 7} md={mdL ? 12 - mdL : 7}>
        <Form.Control
          {...input}
          as="input"
          type={type}
          placeholder={placeholder}
        />
        <div className={asyncValidating ? "async-validating" : ""}></div>
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
    </Row>
  </Form.Group>
);

export const InputFieldOneRowWithLabel = ({
  input,
  label,
  placeholder,
  type,
  unit,
  meta: { touched, error, warning },
}) => (
  <Form.Group>
    <Row>
      <Col sm={5} md={5}>
        <Form.Label className="control-label">{label}</Form.Label>
      </Col>
      <Col sm={7} md={7}>
        <InputGroup>
          <Form.Control
            {...input}
            as="input"
            type={type}
            placeholder={placeholder}
          />
          <InputGroup.Append>
            <InputGroup.Text>{unit}</InputGroup.Text>
          </InputGroup.Append>
        </InputGroup>
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
    </Row>
  </Form.Group>
);

export const InputFieldWithoutLabel = ({
  input,
  label,
  placeholder,
  disabled,
  type,
  meta: { asyncValidating, touched, error, warning },
}) => (
  <div>
    <Form.Control
      disabled={disabled}
      {...input}
      as="input"
      placeholder={placeholder}
    />
    <div className={asyncValidating ? "async-validating" : ""}></div>
    {touched && error && (
      <Form.Control.Feedback style={{ display: "block" }} type="invalid">
        {error}
      </Form.Control.Feedback>
    )}
  </div>
);
export const InputFieldWithoutLabelReadOnly = ({
  input,
  label,
  placeholder,
  style,
  type,
  meta: { touched, error, warning },
}) => (
  <div>
    <Form.Control
      readOnly={true}
      {...input}
      as="input"
      style={style}
      placeholder={placeholder}
    />
  </div>
);

export const InputSimpleSelect = ({
  input,
  label,
  placeholder,
  options,
  mdL,
  mdI,
  smL,
  type,
  meta: { touched, error, warning },
}) => {
  return (
    <Form.Group controlId="exampleForm.SelectCustom">
      <Row>
        <Col sm={smL ? smL : 5} md={mdL ? mdL : 5}>
          <Form.Label className="text-right d-block">{label}</Form.Label>
        </Col>
        <Col
          sm={mdI ? mdI : smL ? 12 - smL : 7}
          md={mdI ? mdI : mdL ? 12 - mdL : 7}
        >
          <div className="select-style">
            <select {...input} as="select" placeholder={placeholder}>
              {options &&
                options.map((item, index) => (
                  <option value={item.value} key={index}>
                    {item.label}
                  </option>
                ))}
            </select>
          </div>
          {touched && error && (
            <Form.Control.Feedback style={{ display: "block" }} type="invalid">
              {error}
            </Form.Control.Feedback>
          )}
        </Col>
      </Row>
    </Form.Group>
  );
};
export const InputSimpleSelectWithoutLabel = ({
  input,
  placeholder,
  options,
  onDataSelect,
  defaultValue,
  meta: { touched, error, warning },
}) => {
  return (
    <div>
      <div className="select-style">
        <select {...input} as="select" placeholder={placeholder}>
          {options &&
            options.map((item, index) => (
              <option
                onMouseDown={() => {
                  onDataSelect(item.value);
                }}
                value={item.value}
                key={index}
              >
                {item.label}
              </option>
            ))}
        </select>
      </div>
      {touched && error && (
        <Form.Control.Feedback style={{ display: "block" }} type="invalid">
          {error}
        </Form.Control.Feedback>
      )}
    </div>
  );
};
export const InputGroupSelectWithoutLabel = ({
  input,
  placeholder,
  options,
  child,
  onDataSelect,
  defaultValue,
  meta: { touched, error, warning },
}) => {
  return (
    <Row>
      <Col md={10}>
        <div className="select-style">
          <select {...input} as="select" placeholder={placeholder}>
            <option value={null}>{"-- Choose --"}</option>
            {options &&
              options.map((item, index) => (
                <optgroup key={index} label={item.label}>
                  {item.options &&
                    item.options.map((item, index) => (
                      <option
                        onMouseDown={() => {
                          onDataSelect(item.value);
                        }}
                        value={item.value}
                        key={index}
                      >
                        {item.label}
                      </option>
                    ))}
                </optgroup>
              ))}
          </select>
        </div>

        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
      {child && (
        <Col md={2} className="pl-0">
          {child}
        </Col>
      )}
    </Row>
  );
};
export const AutoCompleteInputGroupFieldOneRowWithoutlabel = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdI,
  smI,
  child,
  dataSource,
  onDataSelect,
  meta: { touched, error, warning, active, asyncValidating },
}) => {
  return (
    <Row>
      <Col
        sm={smI ? smI : 12}
        md={mdI ? mdI : 12}
        style={{ position: "relative" }}
      >
        <Form.Control
          {...input}
          as="input"
          autoComplete="off"
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
        <div className={asyncValidating ? "async-validating" : ""}></div>
        {active && (
          <ListGroup className="auto-complete-container">
            {dataSource.map((data, index) => (
              <ListGroup.Item
                action
                type="button"
                onMouseDown={() => {
                  onDataSelect(data);
                }}
                key={index}
              >
                {data.value}
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
      {child && (
        <Col
          sm={12 - (smI ? smI : 2)}
          md={12 - (mdI ? mdI : 2)}
          className="pl-0"
        >
          {child}
        </Col>
      )}
    </Row>
  );
};

export const TextAreaField = ({
  input,
  label,
  placeholder,
  rows,
  mdL,
  mdI,
  type,
  meta: { touched, error, warning },
}) => (
  <Form.Group>
    <Row>
      <Col md={mdL ? mdL : 5}>
        <Form.Label className="control-label">{label}</Form.Label>
      </Col>
      <Col md={mdI ? mdI : mdL ? 12 - mdL : 7}>
        <Form.Control
          {...input}
          as="textarea"
          rows={rows}
          placeholder={placeholder}
        />

        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </Col>
    </Row>
  </Form.Group>
);

export const CheckBox = ({ input, label, className, id, defaultChecked }) => (
  <Form.Group>
    <Form.Check
      {...input}
      id={id}
      className={className}
      type="checkbox"
      checked={input.value}
      label={label}
    />
  </Form.Group>
);

export const DesignCheckBox = ({
  input,
  label,
  className,
  id,
  defaultChecked,
}) => (
  <Form.Group>
    <input
      className="css-checkbox"
      id={id != null ? id : "chk"}
      checked={input.value}
      onChange={() => {
        input.value = !input.value;
      }}
      defaultChecked={defaultChecked}
      type="checkbox"
    />
    {/* <Form.Check
      {...input}
      id={id}
      className={className}
      type="checkbox"
      checked={input.value}
      label={label}
    /> */}
    <label className="css-label" htmlFor={id ?? "chk"}>
      <i className="icon-coffee"></i>
      {label}
    </label>
  </Form.Group>
);

export const CheckBoxNoLabel = ({ input }) => (
  <Form.Check {...input} type="checkbox" checked={input.value} />
);
export const DateFieldRenderer = ({
  input,
  label,
  placeholder,
  smL,
  mdL,
  smI,
  mdI,
  meta: { touched, error, warning },
}) => (
  <Form.Group as={Row} controlId="formPlaintextPassword" className="label">
    <Form.Label
      column
      sm={smL ? smL : 5}
      md={mdL ? mdL : 5}
      className="control-label"
    >
      {label}
    </Form.Label>
    <Col
      sm={smI ? smI : smL ? 12 - smL : 7}
      md={mdI ? mdI : mdL ? 12 - mdL : 7}
    >
      <div className="input-date-picker-container">
        <CustomDatePicker
          {...input}
          placeholder={placeholder ? placeholder : ""}
        />
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </div>
    </Col>
  </Form.Group>
);

export const DateFieldReadOnlyRenderer = ({
  input,
  label,
  placeholder,
  smL,
  mdL,
  smI,
  mdI,
  meta: { touched, error, warning },
}) => (
  <Form.Group as={Row} controlId="formPlaintextPassword" className="label">
    <Form.Label
      column
      sm={smL ? smL : 5}
      md={mdL ? mdL : 5}
      className="control-label"
      style={{ textAlign: "right" }}
    >
      {label}
    </Form.Label>
    <Col
      sm={smI ? smI : smL ? 12 - smL : 7}
      md={mdI ? mdI : mdL ? 12 - mdL : 7}
    >
      <div className="input-date-picker-container">
        <CustomDatePickerReadonly
          {...input}
          placeholder={placeholder ? placeholder : ""}
        />
        {touched && error && (
          <Form.Control.Feedback style={{ display: "block" }} type="invalid">
            {error}
          </Form.Control.Feedback>
        )}
      </div>
    </Col>
  </Form.Group>
);
export const DateFieldRendererWithoutLabel = ({
  input,
  label,
  placeholder,
  smI,
  mdI,
  meta: { touched, error, warning },
}) => (
  <Col sm={smI ? smI : 12} md={mdI ? mdI : 12}>
    <div className="input-date-picker-container">
      <CustomDatePicker
        {...input}
        placeholder={placeholder ? placeholder : ""}
      />
    </div>
  </Col>
);
export const DateFieldRendererTwoRow = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  mdI,
  smI,
  meta: { touched, error, warning },
}) => (
  <Form.Group>
    <Form.Label className="control-label" style={{ textAlign: "right" }}>
      {label}
    </Form.Label>
    <div>
      <CustomDatePicker
        {...input}
        placeholder={placeholder ? placeholder : ""}
      />
    </div>
    {touched && error && (
      <Form.Control.Feedback style={{ display: "block" }} type="invalid">
        {error}
      </Form.Control.Feedback>
    )}
  </Form.Group>
);

export const InputSimpleRadio = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  value,
  meta: { touched, error, warning },
}) => (
  <React.Fragment>
    <Box>
      <Typography
        variant="body1"
        component="div"
        sx={{
          mx: 2,
          display: "flex",
          textAlign: "left",
          marginBottom: "0px",
          marginTop: "10px",
          alignItems: "baseline"
        }}
      >
        <input
          style={{
            marginLeft: "15px",
            marginRight: "15px",
            cursor: "pointer",
          }}
          {...input}
          type={type}
          disabled={disabled}
          placeholder={placeholder}
        />
        <Box><strong>{label}</strong></Box>
      </Typography>
    </Box>
  </React.Fragment>
);

export const SolpayInputRadio = ({
  input,
  label,
  placeholder,
  type,
  disabled,
  mdL,
  smL,
  value,
  meta: { touched, error, warning },
}) => (
  <label>
    <input
      {...input}
      type={type}
      disabled={disabled}
      placeholder={placeholder}
    />
    <strong>&nbsp; &nbsp;{label}</strong>
  </label>
);

export const InputFieldWithoutLabelMui = (props) => {
  const style = useStyleClass();
  return (
    <Grid container>
      <Grid item>
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            classes={{ root: style.inputStyle }}
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            placeholder={props.label}
            size="small"
            disabled={props.disabled}
            type={props.type}
            required={props.required}
            value={props.input?.value}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const InputFieldWithoutLabelMuiTable = (props) => {
  const style = useStyleClass();
  return (
    <Grid container>
      <Grid item>
        <FormControl
          {...props.input}
          style={{ width: "60%" }}
          sx={{ padding: "0" }}
        >
          <TextField
            sx={{ padding: "0px 14px" }}
            classes={{ root: style.inputStyle }}
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            placeholder={props.label}
            size="small"
            disabled={props.disabled}
            type={props.type}
            required={props.required}
            value={props.input?.value}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              sx: {
                "&::placeholder": {
                  fontSize: "14px",
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const SolpayInputFieldForTable = (props: ReduxFieldProps) => {
  return (
    <Grid container>
      <Grid item>
        <FormControl
          {...props.input}
          style={{ width: "100%" }}
          variant="outlined"
        >
          <OutlinedInput
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            aria-describedby="outlined-weight-helper-text"
            size="small"
            type={props.type}
            required={props.required}
            value={props.input?.value}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};
export const InputFieldRowReadOnlyMui = (props) => {
  let checkLabel = props.label === "Total";

  return (
    <Grid container>
      <Grid item>
        <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            variant="standard"
            size="small"
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            // label={props.label}
            type={props.type}
            required={props.required}
            value={props.input?.value}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
              inputProps: {
                style: {
                  textAlign: "center",
                  fontWeight: checkLabel ? 600 : null,
                },
              },
            }}
          />
        </FormControl>
      </Grid>
    </Grid>
  );
};

export const InputFieldHiddenRow = (props: ReduxFieldProps) => {
  let config = { layout: "horizontal", ...props };
  let layout = config.layout === "horizontal" ? "horizontal" : "vertical";
  return (
    <Grid
      container
      spacing={1.2}
      style={{
        display: "flex",
        marginBottom: "21px",
        flexDirection: layout === "horizontal" ? "row" : "column",
        width: "100%",
      }}
    >
      <Grid
        item
        md={props.labelCol ? props.labelCol : 4}
        xl={
          props.labelCol
            ? props.labelCol > 11
              ? props.labelCol
              : props.labelCol + 1
            : 4
        }
      >
        <Typography
          variant="subtitle2"
          component="div"
          sx={{
            textAlign: layout === "horizontal" ? "right" : "left",
            marginBottom: "0px",
            marginTop: "10px",
          }}
        >
          {props.label}
        </Typography>
      </Grid>
      <Grid
        item
        style={{
          flex: "1 1 auto",
          width: "100%",
        }}
        md={props.fieldColumn ? props.fieldColumn : 8}
        xl={
          props.fieldColumn
            ? props.fieldColumn > 11
              ? props.fieldColumn
              : props.fieldColumn + 1
            : 8
        }
      >
        {/* <FormControl {...props.input} style={{ width: "100%" }}>
          <TextField
            error={props.meta.error && props.meta.touched && !props.meta.valid}
            helperText={props.meta.touched ? props.meta.error : ""}
            label={props.label}
            size="small"
            type={props.type}
            required={props.required}
            value={props.input?.value}
          />
        </FormControl> */}
      </Grid>
    </Grid>
  );
};

export const InputUploadImage = (props) => {
  const style = useStyleClass();
  return (
    <FormControl {...props.input} style={{ width: "100%", margin: 4 }}>
      <label htmlFor="contained-button-file">
        <Input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={props.onChange}
        />
        <Button
          variant="contained"
          classes={{ root: style.button }}
          component="span"
        >
          {props.placeholder}
        </Button>
      </label>
    </FormControl>
  );
};

export const InputDeleteImage = (props) => {
  const style = useStyleClass();
  return (
    <FormControl {...props.input} style={{ width: "100%", margin: 4 }}>
      <label htmlFor="contained-button-file">
        <Input
          accept="image/*"
          id="contained-button-file"
          multiple
          type="file"
          onChange={props.onChange}
        />
        <Button
          variant="contained"
          classes={{ root: style.button }}
          startIcon={<DeleteIcon />}
          onClick={props.onClick}
        >
          Delete
        </Button>
      </label>
    </FormControl>
  );
};

const Input = styled("input")({
  display: "none",
});
