import { IReduxState } from "@redux";
import { actions as invoiceAction } from "@redux/inv/invoiceRedux";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import InvoiceFormView from "./invoiceFormView";

const addEditInvoice = reduxForm<any>({
  form: "InvoiceFormView",
  enableReinitialize: true,
})(InvoiceFormView);
const selector = formValueSelector("InvoiceFormView");

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getInvoice: () =>
      dispatch(invoiceAction.getInvoice(ownProps.match.params.id)),
  };
};

const initialValues = {
  invoiceDate: new Date(),
  deliveredOn: new Date(),
  //quotationDate: new Date(),
  clientInvoicePayments: [],
  clientInvoiceDetails: [
    { isGSTApplicable: true, isService: false, quantity: 0, totalAmount: 0 },
  ],
};
const mapStateToProps = (state: IReduxState, ownProps) => ({
  clientList: state.invoice.clientList,
  //supplierList: state.invoice.supplierList,
  productList: state.invoice.productList,
  //accountList: state.invoice.accountList,
  taxList: state.invoice.taxList,
  //accountListWithoutGroup: state.invoice.accountListWithoutGroup,
  // discount: selector(state, "discount"),
  initialValues:
    ownProps.match.params.id !== "0"
      ? { ...state.invoice.invoice }
      : { ...initialValues, invoiceNo: state.invoice.currentInvoice },
});
export default connect(mapStateToProps, mapDispatchToProps)(addEditInvoice);
