import { IReduxState } from "@redux";
import { GetBillById } from "@redux/bill/billActions";
import { connect } from "react-redux";
import { formValueSelector, reduxForm } from "redux-form";
import BillFormView from "./billFormView";

const addEditBill = reduxForm<any>({
  form: "BillFormView",
  enableReinitialize: true,
})(BillFormView);
const selector = formValueSelector("BillFormView");
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getBill: () => dispatch(GetBillById(ownProps.match.params.id)),
  };
};
const initialValues = {
  purchaseDate: new Date(),
  purchaseBillPayments: [],
  purchaseBillDetails: [
    { isGSTApplicable: true, isService: false, quantity: 0, totalAmount: 0 },
  ],
};

const mapStateToProps = (state: IReduxState, ownProps) => ({
  supplierList: state.bill.supplierList,
  productList: state.bill.productList,
  accountList: state.bill.accountList,
  taxList: state.bill.taxList,
  accountListWithoutGroup: state.bill.accountListWithoutGroup,
  //  clientName: selector(state, "supplierName"),
  //  clientInvoiceDetails: selector(state, "purchaseBillDetails"),
  //  clientInvoicePayments: selector(state, "purchaseBillPayments"),
  //  discount: selector(state, "discount"),
  initialValues:
    ownProps.match.params.id !== "0"
      ? {
          ...state.bill.bill.bill,
        }
      : { ...initialValues, billNumber: state.bill.currentBill },
});
// const mapStateToProps = ({ BillFormView, listState }) => ({ BillFormView, listState });
export default connect(mapStateToProps, mapDispatchToProps)(addEditBill);
