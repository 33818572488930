import {
  Divider,
  Paper,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { InputFieldRowReadOnlyMui } from "libComp/common/inputRenderes";
import React, { useState } from "react";
import { Field } from "redux-form";
import { useStyleClass } from "styles/theming/useStyleClass";
import { QuotationDetailRow } from "./quotationDetailRow";

const QuotationDetail = ({ fields, meta, dispatch, discountAmount }) => {
  console.log("QuotationDetail");
  const [showProductModal, setShowProductModal] = useState(false);
  const [produectIndex, setProduectIndex] = useState(-1);

  const tableHeaders = [
    "Products / Services",
    "Description",
    "Quantity",
    "Unit Price",
    //"Account",
    "Tax",
    "Amount",
    "+/-",
  ];

  const style = useStyleClass();

  return (
    <React.Fragment>
      <TableContainer component={Paper}>
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
            },
          }}
          aria-label="caption table"
        >
          <TableHead classes={{ root: style.tableHeader }}>
            <TableRow>
              {tableHeaders.map((item, index) => (
                <TableCell key={index} sx={{ color: "#fff" }} align="center">
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {fields.map((item, index) => (
              <QuotationDetailRow
                key={index}
                row={item}
                index={index}
                fields={fields}
              />
            ))}
            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
              >
                Sub Total
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  name="subTotal"
                  label="Sub Total"
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 0 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
              >
                Discount
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  name="discountAmount"
                  label="Discount"
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 0 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
              >
                Total Tax
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  name="taxAmount"
                  label="Total Tax"
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 0 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={5} style={{ padding: 0 }}></TableCell>
              <TableCell colSpan={2} style={{ padding: 1 }}>
                <Divider classes={{ root: style.divider }} variant="middle" />
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell colSpan={5}></TableCell>
              <TableCell
                align="right"
                classes={{ root: style.detailPageTableCell }}
                style={{
                  fontWeight: 600,
                  fontSize: "1.2rem",
                }}
              >
                Total
              </TableCell>
              <TableCell classes={{ root: style.detailPageTableCellSize }}>
                <Field
                  style={{
                    fontWeight: 600,
                    fontSize: "1.2rem",
                  }}
                  label="Total"
                  name="totalPayment"
                  component={InputFieldRowReadOnlyMui}
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </React.Fragment>
  );
};

export default QuotationDetail;
