import { ICommonActions } from "@redux/commonRedux";
import commonDataAccess from "dataAccess/commonDataAccess";

class EmployeeLeaveBalanceReportDataAccess {
  employeeLeaveBalanceReportGenerate = (data) => (commonActions: ICommonActions) => {
    return commonDataAccess.postDataForPdf(
      "Payroll/employeeleavebalancereport",
      data
    )(commonActions);
  };
}

export default new EmployeeLeaveBalanceReportDataAccess();
