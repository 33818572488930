import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import * as React from "react";

const MODALSTYLE = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 8,
};

const SolpayModal = (props) => {
  const modalCustom = MODALSTYLE;
  const [open, setOpen] = React.useState(true);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Dialog
        sx={modalCustom}
        fullScreen={fullScreen}
        open={open}
        onClose={props.onClose}
        maxWidth="xl"
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {props.title}
          <IconButton
            edge="end"
            color="inherit"
            onClick={props.onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {
            <DialogContentText sx={{ padding: "10px" }}>
              {props.data}
            </DialogContentText>
          }
        </DialogContent>
      </Dialog>
    </>
  );
};
export default SolpayModal;
